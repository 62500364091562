<template>
<div :class="$t('FCB.fcb-class-name')">
	<ss-header mode="Home" bg="rgb(38, 5, 5)"></ss-header>

	<div class="fcb-1">
          <div class="container">
              <img class="fcb-logo" :src="require(`../assets/image/FCB/fcb-logo-${$t('CFooters.bannerLang')}.png`)" alt="">
  

              <p class="fcb-desc">{{$t('FCB.fcb-page1.text2')}}</p>

              <p class="fcb-desc" v-if="$t('FCB.fcb-page1.text3')">
                    {{$t('FCB.fcb-page1.text3')}}
                </p>
          </div>
      </div>

      <div class="fcb-2">
          <div class="container">
              <video muted="muted" autoplay="autoplay" loop="loop" playsinline poster="../assets/image/FCB/FCB-2.jpg" class="about-video_page-screen-0__video">
            <source src="../assets/image/FCB/FCB-Preview.mp4" type="video/mp4">
            </video>
              <p class="fcb-title">{{$t('FCB.fcb-page2.text1')}}</p>
              <img class="fcb-star" src="../assets/image/FCB/FCB-star.svg" alt="">
              <p class="fcb-desc" v-html="$t('FCB.fcb-page2.text2')">
              </p>
              <a href="/FCB-video.mp4" target="_blank">
                {{$t('FCB.video-btn')}}
            </a>
          </div>
      </div>

      <div class="fcb-3">
          <div class="container">
              <p class="fcb-title">{{$t('FCB.fcb-page3.text1')}}</p>
              <img class="fcb-star" src="../assets/image/FCB/FCB-star.svg" alt="">
              <p class="fcb-desc">{{$t('FCB.fcb-page3.text2')}}</p>
<p class="fcb-desc">{{$t('FCB.fcb-page3.text3')}}</p>
<p class="fcb-desc">{{$t('FCB.fcb-page3.text4')}}
              </p>
          </div>
      </div>

      <div class="fcb-4">
          <div class="container">
              <p class="fcb-title">{{$t('FCB.fcb-page4.text1')}}</p>
              <img class="fcb-star" src="../assets/image/FCB/FCB-star.svg" alt="">
              <p class="fcb-desc">{{$t('FCB.fcb-page4.text2')}}</p>
                  <p class="fcb-desc">{{$t('FCB.fcb-page4.text3')}} 
              </p>
          </div>
      </div>

      <div class="fcb-5">
          <div class="container">
              <p class="fcb-title">{{$t('FCB.fcb-page5.text1')}}</p>
              <img class="fcb-star" src="../assets/image/FCB/FCB-star.svg" alt="">
              <p class="fcb-desc">{{$t('FCB.fcb-page5.text2')}}</p>
                  <p class="fcb-desc">{{$t('FCB.fcb-page5.text3')}}
              </p>
          </div>
      </div>

      <div class="fcb-6">
          <div class="container">
              <p class="fcb-title">{{$t('FCB.fcb-page6.text1')}}</p>
              <img class="fcb-star" src="../assets/image/FCB/FCB-star.svg" alt="">
              <p class="fcb-desc">{{$t('FCB.fcb-page6.text2')}}</p>
                  <p class="fcb-desc">{{$t('FCB.fcb-page6.text3')}}</p>
                  <p class="fcb-desc">{{$t('FCB.fcb-page6.text4')}}
              </p>
          </div>
      </div>

      <div class="fcb-7">
          <div class="container" style="position: relative;">
              <p class="fcb-title">{{$t('FCB.fcb-page7.text1')}}</p>
              <img class="fcb-star" src="../assets/image/FCB/FCB-star.svg" alt="">
              <div class="row fcb-news-list">
                <div class="swiper-wrapper slide-box">
                        <div class="news-li swiper-slide" v-for="(item, index) in newData" :key="index" v-if="item.tips != '' && index < 4"
						@click="jumPage(item)">
                            <div class="thumbnail">
                                <a @click="jumPage(item)"><img :src="item.logo" :alt="item.tit"></a>
                                <div class="caption">
                                    <h3><a @click="jumPage(item)">{{item.tit}}</a></h3>
                                    <p>{{item.tips}}</p>
                                    <span>{{item.time}}</span>
                                </div>
                            </div>
                        </div>
                </div>
            </div>

          <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
      </div>
      <new-footer></new-footer>
</div>
</template>

<script>
    import 'swiper/css/swiper.css'
    import Swiper from 'swiper'
	import {
		mapState,
	} from 'vuex'
	export default {
		head() {
			return {
				title: this.title1,
				meta: [ // set meta
					{
						name: 'keyWords',
						content: 'EBC,EBC平台,EBC官网,EBC金融官网,EBC开户,EBC金融'
					},
					{
						name: 'description',
						content: 'EBC金融官网是由EBC金融集团EBC Financial Group成立的,主要提供,EBC金融,EBC开户交易服务,只需一个EBC综合平台账户,即可交易多种差价合约（CFD）:衍生品、美股、数字货币、期货指数、贵金属和能源等.'
					}
				]
			}
		},
		data() {
			return {
				infoData: "",
				newData: "",
                pagIndex: 0,
                num: 3,
                newsdisplay: "none"
			}
		},
		computed: {
			...mapState({
				lang: state => state.Lan,
			})
		},
        destroyed() {
			window.removeEventListener('scroll', this.scroll, false)
		},
		created() {
			this.getNewData()
            $('html,body').animate({
				scrollTop: 0
			}, 0);
		},
        mounted(){
           window.addEventListener('scroll', this.scroll)
           setTimeout(() => {
            if (!this.isMobile()) {
                this.swiper = new Swiper('.fcb-7 .row', {
					navigation: { // 如果需要前进后退按钮
						nextEl: '.fcb-7 .swiper-button-next',
						prevEl: '.fcb-7 .swiper-button-prev'
					},
					slidesPerView: 3,
                    spaceBetween: 25,
					autoplay: true, // 启动自动切换，等同于以下设置
					loop: true // 循环模式选项
                })
            }else{
                this.swiper = new Swiper('.fcb-7 .row', {
					slidesPerView: 1.15,
                    spaceBetween: 20,
					freeMode: true
                })
            }
            }, 3000)

            
        },
		methods: {
			getNewData() {
				let parms;
				if (this.lang === 'zh_CN') {
					parms = 'zh_CN'
				} else {
					parms = 'en_US'
				}
				this.$http("/ebc/api/getLearnCategory/run", {
					lang: parms,
				}).then(res => {
					this.newData = res.data.data[0].info
				})
			},
			jumPage(idx) {
				this.$router.push({
					name: 'LearningCenterInfo',
					query: {
						id: idx.id,
						lang: this.$store.state.Lan
					}
				})
			},
            isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				return flag;
			}
		}
	}

</script>

<style>
@charset "UTF-8";
.c-footer .fp-overflow{
		display: unset !important;
	}
@font-face{
    font-family: 'Gilroy Bold';
    src : url('../assets/font/Gilroy-ExtraBold.otf');
    font-style: normal;
    font-display: swap;
}
@font-face{
    font-family: 'Gilroy Light';
    src : url('../assets/font/Gilroy-Light.otf');
    font-style: normal;
    font-display: swap;
}

.fcb-1{
  background: url(../assets/image/FCB/FCB-1.jpg?t=1712562560) 100% / auto 100% no-repeat;
  margin-top: 0px;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-top: 240px;
}
.fcb-1 .fcb-logo{
  margin-bottom: 40px;
  width: 35%;
}
.fcb-1 .fcb-1-title{
  color: #FFF;
  text-align: center;
  font-family: Gilroy Bold;
  font-size: 20px;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 50px;
}
.fcb-1 .fcb-1-title br{
  display: none;
}




.fcb-2{
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  text-align: center;
}
.fcb-2 video{
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.fcb-2 a{
  color: #000;
    font-family: Gilroy Light;
    font-size: 14px;
    padding: 13px 30px 11px;
    margin-top: 20px;
    display: inline-block;
    background: #FFBC3A;
}

.fcb-title{
  color: #FFBC3A;
  text-align: center;
  font-family: Gilroy Bold;
  font-size: 60px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 1.28px;
  width: 68%;
  margin: 0 auto;
}
.fcb-star{
  margin-top: 22px;
  margin-bottom: 22px;
}
.fcb-desc{
  color: #FFF;
  text-align: center;
  font-family: Gilroy Light;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  width: 62%;
  margin: 0 auto 12px;
  opacity: 0.9;
}
.fcb-desc span{
  font-family: Gilroy Bold;
  font-weight: 900;
}



.fcb-3{
  background: url(../assets/image/FCB/FCB-3.jpg) 100% / auto 100% no-repeat;
  margin-top: 0px;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fcb-4{
  background: url(../assets/image/FCB/FCB-4.jpg?t=1712562558) 100% / auto 100% no-repeat;
  margin-top: 0px;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.fcb-4 .fcb-hover-pc{
  margin-top: 90px;
  width: 100%;
}
.fcb-4 .fcb-hover-m{
  display: none;
}



.fcb-5{
  background: url(../assets/image/FCB/FCB-5.jpg) 100% / auto 100% no-repeat;
  margin-top: 0px;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fcb-6{
  background: url(../assets/image/FCB/FCB-6.jpg?t=1712562557) 100% / auto 100% no-repeat;
  margin-top: 0px;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fcb-7{
  background: url(../assets/image/FCB/FCB-7.jpg) 100% / auto 100% no-repeat;
  margin-top: 0px;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 105vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.fcb-7 .fcb-title{
  width: 100%;
}
.fcb-image-list{
  width: 1000px;
  overflow: hidden;
  margin: 10px auto 0;
}
.fcb-image-list img{
  border-radius: 6px;
}
.fcb-image-list .swiper-slide-shadow-left,.fcb-image-list .swiper-slide-shadow-right{
  display: none !important;
}


.cn-fcb-box .fcb-title{
  font-family: 'Noto Serif SC';
  font-size: 48px;
}
.cn-fcb-box .fcb-desc{
  font-family: "Source Han Sans CN";
  line-height: 40px;
  width: 60%;
}


.fcb-register{
  width: max-content;
  padding: 14px 40px 14px 60px;
  border-radius: 100px;
  background: var(--Linear, linear-gradient(90deg, #861A18 0%, #19172B 100%));
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.35);
  position: relative;
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
}
.fcb-register img{
  width: 13px;
  position: absolute;
  left: 30px;
  top: 15px;
}
.fcb-register p{
  color: #FFBC3A;
  text-align: center;
  font-family: Gilroy Light;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
  float: left;
}
.fcb-register span{
  width: 1px;
  height: 18px;
  background: rgba(255, 255, 255, 0.40);
  display: block;
  float: left;
  margin: 0 20px;
}
.fcb-register a{
  color: #fff;
  opacity: 0.9;
  font-family: Gilroy Light;
  font-size: 16px;
}


.fcb-7 .container{
  width: 1100px;
}
.fcb-7 .news-li{
    padding: 0;
    padding-bottom: 20px;
    border-radius: 5px;
    margin-bottom: 30px;
    background-color: #fff;
    height: auto;
}
.fcb-7 .news-li img{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transform: none;
    object-position: left;
    width: 100%;
    height: auto;
}
.fcb-7 .news-li h3 a{
    margin: 14px 16px 10px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    display: block;
    color: #333;
    overflow:hidden; 
    text-overflow:ellipsis;
    display:-webkit-box; 
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2; 
    text-align: left;
}
.fcb-7 .news-li p{
    margin: 0 16px 10px;
    color: #666;
    font-size: 16px;
    line-height: 1.5;
    font-family: auto;
    overflow:hidden; 
    text-overflow:ellipsis;
    display:-webkit-box; 
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2; 
    text-align: left;
}
.fcb-7 .news-li span{
    padding: 0 16px 10px;
    color: #666;
    font-size: 14px;
    text-align: left;
}
.fcb-7 .fcb-news-list{
    overflow: hidden;
    margin-top: 50px;
    text-align: left;
}
.fcb-7 .swiper-wrapper{
    padding: 0;
}
.fcb-7 .swiper-button-prev {
    left: -52px;
    background-image: url(../assets/image/FCB/fcb-prev.svg);
    width: 22px;
    height: 44px;
    background-size: 90%;
    background-repeat: no-repeat;
    top: 62%;
}

.fcb-7 .swiper-button-next {
    right: -52px;
    background-image: url(../assets/image/FCB/fcb-next.svg);
    width: 22px;
    height: 44px;
    background-size: 90%;
    background-repeat: no-repeat;
    top: 62%;
}
.swiper-button-next:after, .swiper-button-prev:after {
    content: "";
}

.cn-fcb-box .fcb-1 .fcb-desc{
  width: 100%;
}

.cn-fcb-box .fcb-1{
  padding-top: 130px;
}



@media(max-width:1700px) {
  .fcb-register{
    display: none;
  }
  .fcb-1 .fcb-1-title{
    margin-bottom: 40px;
  }
  .fcb-1 .fcb-logo{
    width: 30%;
  }
}

@media(max-width:1700px) {
  .fcb-register{
    display: none;
  }
  .fcb-1 .fcb-1-title{
    margin-bottom: 40px;
  }
  .fcb-1 .fcb-logo{
    width: 30%;
  }

  .fcb-2{
    height: 80vh;
  }
}

@media(max-width:1600px) {
  .fcb-2{
    height: 70vh;
  }
  .fcb-1{
    padding-top: 60px;
  }
}

@media(max-width:1500px) {
  .fcb-2{
    height: auto;
    padding: 110px 0;
  }
}

@media(max-width:1400px) {
    .fcb-1 .fcb-logo{
      width: 34%;
      margin-bottom: 30px;
    }
    .fcb-1 .fcb-1-title{
      font-size: 18px;
    }

    .fcb-register{
      display: none;
    }

    .fcb-title{
      font-size: 42px;
    }
    .fcb-desc{
      font-size: 16px;
    }

    .fcb-image-list{
      width: 750px;
    }

    .cn-fcb-box .fcb-title{
      font-size: 38px;
    }
    .fcb-7{
        height: 140vh;
        display: block;
        padding-top: 80px;
    }

    .fcb-4 .fcb-hover-pc{
      margin-top: 40px;
      width: 100%;
    }
}

@media(max-width:995px) {
    .fcb-image-list{
      width: 100%;
    }

    .fcb-1 .fcb-logo{
      width: 76%;
    }
    .fcb-1 .fcb-1-title{
      font-size: 16px;
    }
    .fcb-title{
      font-size: 32px;
      width: 90%;
    }
    .fcb-2 video{
      position: absolute;
      left: 50%;
      top: 0;
      width: 1400px;
      min-width: 0;
      transform: translateX(-50%);
    }
    .fcb-2{
      overflow: hidden;
      height: 85vh;
    }
    .fcb-desc{
      width: 100%;
      line-height: 28px;
    }
    .fcb-7{
      height: auto;
      padding: 80px 0;
    }
    .fcb-7 .fcb-title {
      width: 90%;
    }
    .cn-fcb-box .fcb-title{
      font-size: 28px;
      width: 92%;
    }
    .cn-fcb-box .fcb-desc{
      line-height: 40px;
      width: 96%;
    }
    .fcb-image-list img{
      width: 100%;
    }
    .fcb-4 .fcb-hover-pc{
      display: none;
    }
    .fcb-4 .fcb-hover-m{
      display: inline-block;
      width: 100%;
      margin-top: 30px;
    }
    .fcb-4{
      height: 100vh;
    }
    .fcb-7 .container{
      width: 100%;
      overflow: hidden;
    }
    .fcb-register{
      bottom: 40px;
      display: none;
    }
    .fcb-7 .fcb-news-list{
      padding-left: 4%;
    }
    /*.fcb-register p{
      font-size: 14px;
      line-height: 18px;
    }
    .fcb-register a{
      font-size: 14px;
      line-height: 18px;
    }
    .fcb-register span{

    }
    .fcb-register img{
      top: 16px;
    }*/

    .fcb-1 .fcb-desc {
        width: 100%;
        line-height: 28px;
        font-size: 16px;
    }

    .fcb-7 .news-li h3 a{
      font-size: 18px;
    }
    .fcb-7 .news-li p{
      font-size: 14px;
    }
}

</style>

