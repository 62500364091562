<template>
	<div class="dividend" :class="$t('Dividend.box-name')">
		<ss-header mode="Home" bg="rgb(38, 5, 5)"></ss-header>
		<div class="cfd-t-sub1">
                    <h1 class="p1">{{$t('Dividend.screen0.title')}}</h1>
                    <img src="../assets/image/dividend/fth1-line.png" alt="">
                    <p class="p2">{{$t('Dividend.screen0.desc')}}</p>
                    <a :href="khUrl" class="btn">{{$t('Dividend.public-btn-text1')}}</a>
                </div>

                <div class="dividend-account1">
                    <div class="container">
                        <p class="dividend-public-title">{{$t('Dividend.screen1.title')}}</p>
                        <p class="dividend-public-line"></p>
                        <div class="dividend-page-screen-1">
    <div data-wow-duration="2s" class="container-xl page-screen fadeIn wow">
        <div class="page-screen__title flex">
					<div class="page-screen__title__text flex-1" v-for="(itx,idx) in tab" :key="idx">
						{{itx}}
					</div>
				</div>
        <div class="page-screen__ul">
					<div class="page-screen__ul__li flex" v-for="(item,index) in data" :key="index">
						<div class="text flex-1">
							{{item.symbol}}
						</div>
						<div class="text flex-1">
							{{item.long}}
						</div>
						<div class="text flex-1">
							{{item.short}}
						</div>
						<div class="text flex-1">
							{{item.date}}
						</div>
					</div>
				</div>
    </div>
</div>
                        <div class="btn-box">
                            <a class="dividend-public-link2" :href="khUrl">{{$t('Dividend.public-btn-text1')}}<img class="arrow1" src="../assets/image/dividend/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/dividend/fca12.png" alt=""></a>
                        </div>
                    </div>
                </div>


                <div class="cfd-section1 cfd-section cfd-section7 trade8">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <p class="title cn-title1 pc-title">{{$t('Dividend.screen2.title')}}</p>
                    <p class="line pc-line"></p>
                    <router-link class="dividend-public-link2 pc-btn" to="/AllProducts">{{$t('Dividend.public-btn-text2')}}<img class="arrow1" src="../assets/image/dividend/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/dividend/fca12.png" alt=""></router-link>
                </div>
                <div class="col-md-6 market s1-d2">
                    <p class="title cn-title1 m-title">{{$t('Dividend.screen1.title')}}</p>
                    <p class="line m-line"></p>
                    <ul>
                        <li id="EURUSD">
										<span class="name">EURUSD</span>
										<span class="float"
											:class="{'red': forex.EURUSD.percentage < 0,'green':forex.EURUSD.percentage > 0}">{{forex.EURUSD.num}}</span>
										<p class="bid">Bid</p>
										<p class="bid-price green" :class="{'red':forex.EURUSD.percentage < 0}">
											{{tear(forex.EURUSD.bid_price)[0] || '--'}}<span>.{{tear(forex.EURUSD.bid_price)[1] || '--'}}</span>
										</p>
										<p class="spread"><span class="s1">Spread</span> &nbsp;&nbsp;&nbsp; <span
												class="s2">{{forex.EURUSD.chadian || '--'}}</span></p>
										<p class="line"></p>
										<p class="ask">Ask</p>
										<p class="ask-price green" :class="{'red':forex.EURUSD.percentage < 0}">
											{{tear(forex.EURUSD.ask_price)[0] || '--'}}<span>.{{tear(forex.EURUSD.ask_price)[1] || '--'}}</span>
										</p>
										<a class="buy" :href="$store.state.khUrl">BUY</a>
										<a class="sell" :href="$store.state.khUrl">SELL</a>
									</li>
									<li id="XAUUSD">
										<span class="name">XAUUSD</span>
										<span class="float"
											:class="{'red': forex.XAUUSD.percentage < 0,'green':forex.XAUUSD.percentage > 0}">{{forex.XAUUSD.num}}</span>
										<p class="bid">Bid</p>
										<p class="bid-price green" :class="{'red':forex.XAUUSD.percentage < 0}">
											{{tear(forex.XAUUSD.bid_price)[0] || '--'}}<span>.{{tear(forex.XAUUSD.bid_price)[1] || '--'}}</span>
										</p>
										<p class="spread"><span class="s1">Spread</span> &nbsp;&nbsp;&nbsp; <span
												class="s2">{{forex.XAUUSD.chadian || '--'}}</span></p>
										<p class="line"></p>
										<p class="ask">Ask</p>
										<p class="ask-price green" :class="{'red':forex.XAUUSD.percentage < 0}">
											{{tear(forex.XAUUSD.ask_price)[0] || '--'}}<span>.{{tear(forex.XAUUSD.ask_price)[1] || '--'}}</span>
										</p>
										<a class="buy" :href="$store.state.khUrl">BUY</a>
										<a class="sell" :href="$store.state.khUrl">SELL</a>
									</li>
									<li id="USDJPY">
										<span class="name">USDJPY</span>
										<span class="float"
											:class="{'red': forex.USDJPY.percentage < 0,'green':forex.USDJPY.percentage > 0}">{{forex.USDJPY.num}}</span>
										<p class="bid">Bid</p>
										<p class="bid-price green" :class="{'red':forex.USDJPY.percentage < 0}">
											{{tear(forex.USDJPY.bid_price)[0] || '--'}}<span>.{{tear(forex.USDJPY.bid_price)[1] || '--'}}</span>
										</p>
										<p class="spread"><span class="s1">Spread</span> &nbsp;&nbsp;&nbsp; <span
												class="s2">{{forex.USDJPY.chadian || '--'}}</span></p>
										<p class="line"></p>
										<p class="ask">Ask</p>
										<p class="ask-price green" :class="{'red':forex.USDJPY.percentage < 0}">
											{{tear(forex.USDJPY.ask_price)[0] || '--'}}<span>.{{tear(forex.USDJPY.ask_price)[1] || '--'}}</span>
										</p>
										<a class="buy" :href="$store.state.khUrl">BUY</a>
										<a class="sell" :href="$store.state.khUrl">SELL</a>
									</li>
                    </ul>
                </div>
                <router-link class="dividend-public-link2 m-btn" to="/AllProducts">{{$t('Dividend.public-btn-text2')}}<img class="arrow1" src="../assets/image/dividend/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/dividend/fca12.png" alt=""></router-link>
            </div>
        </div>
    </div>

	<page-slogan></page-slogan>
	<new-footer></new-footer>
	</div>
</template>

<script>
	import WOW from 'wowjs';
	import md5 from 'js-md5';
	import {
		mapState
	} from "vuex";
	export default {
		data() {
			return {
				tab: ['Symbol', 'Long Dividend', 'Short Dividend', 'EX-Dividend Day'],
				data: [],
				khUrl: 'https://client.ebccrm.com/signup/index',
				seoInfo: "",
				forex: {
					"XAUUSD": {
						chadian: "",
						bid_price: "0.00",
						ask_price: "0.00",
						num: "",
						percentage: 0
					},
					"EURUSD": {
						chadian: "",
						bid_price: "0.00",
						ask_price: "0.00",
						num: "",
						percentage: 0
					},
					"USDJPY": {
						chadian: "",
						bid_price: "0.00",
						ask_price: "0.00",
						num: "",
						percentage: 0
					},
				},
				EURUSDask: "",
				USDJPYask: "",
				AUDUSDask: "",
				newData: [],
				time: "",
			}
		},
		metaInfo() {
			return {
				title: this.seoInfo.title || '',
				meta: [ // set meta
					{
						name: 'keyWords',
						content: this.seoInfo.keyWords || ''
					},
					{
						name: 'description',
						content: this.seoInfo.description || ''
					}
				]
			}
		},
		created() {
			this.$getSeoInfo({
				lang: this.$store.state.Lan,
				path: "/dividend"
			}).then(res=>{
				this.seoInfo = res
			})
			this.$http('/ebc/api/getCfd/run').then(res => {
				console.log(res);
				this.data = res.data.data
			})
			let par = localStorage.getItem('advert')
			let lang = localStorage.getItem('language')
			let parms;
			if (lang === 'ct') {
				parms = 'tw'
			} else if (lang === 'ja') {
				parms = 'jp'
			} else if (lang === 'vi') {
				parms = 'zh'
			} else {
				parms = lang
			}
			par = JSON.parse(par)
			if (par != null) {
				this.khUrl = this.khUrl + '?utm_source=' + par.utm_source + '&utm_medium=' + par.utm_medium +
					'&utm_campaign=' + par.utm_campaign + '&utm_term=' + par.utm_term + '&utm_content=' + par.utm_content +
					'&hl=' + parms
			} else {
				this.khUrl = this.khUrl + '?hl=' + parms
			}
			this.getPortfolio()
		},
		mounted() {
			let wow = new WOW.WOW({
				boxClass: 'wow',
				animateClass: 'animated',
				offset: 0,
				mobile: true,
				live: false
			});
			wow.init();
		},
		computed: {
			...mapState({
				Lan: (state) => state.Lan,
			}),
		},
		methods: {
			getCount(idx1, idx2) {
				const r = /^\+?[1-9][0-9]*$/; // 正整数
				// 如果判断不符合正则，则不是正整数不能提交
				let num = 0;
				let a1 = 0
				let a2 = 0
				if (!r.test(idx1)) {
					a1 = idx1.toString().split(".")[1].length;
				}
				if (!r.test(idx2)) {
					a2 = idx2.toString().split(".")[1].length;
				}
				if (a1 > a2) {
					if (a1 === 1) {
						num = idx1 * 10 - idx2 * 10
					} else if (a1 === 2) {
						num = idx1 * 100 - idx2 * 100
					} else if (a1 === 3) {
						num = idx1 * 1000 - idx2 * 1000
					} else if (a1 === 4) {
						num = idx1 * 10000 - idx2 * 10000
					} else if (a1 === 5) {
						num = idx1 * 100000 - idx2 * 100000
					}
				} else {
					if (a2 === 1) {
						num = idx1 * 10 - idx2 * 10
					} else if (a2 === 2) {
						num = idx1 * 100 - idx2 * 100
					} else if (a2 === 3) {
						num = idx1 * 1000 - idx2 * 1000
					} else if (a2 === 4) {
						num = idx1 * 10000 - idx2 * 10000
					} else if (a2 === 5) {
						num = idx1 * 100000 - idx2 * 100000
					}
				}
				return num.toFixed(0);
			},
			getPortfolio() {
				let time = new Date().getMilliseconds()
				this.$http('/ebc/api/getToken/run', {
					date: time,
					CheckSum: md5(time + "YISENCE")
				}).then(res => {
					let url = "wss://pricecloud.eappservice.com/ws?token=" + res.data.data
					this.init(url)
				})
			},
			tear(item) {
				let data = JSON.stringify(item)
				return data.split('.')
			},
			init(url) {
				this.ws = new WebSocket(url)

				//监听是否连接成功
				this.ws.onopen = () => {
					//连接成功则发送一个数据
					this.ws.send('连接成功');
				}

				//接听服务器发回的信息并处理展示
				this.ws.onmessage = (data) => {
					if (data.data == 'Ws connected success！' || data.data == '连接成功') return
					let info = JSON.parse(data.data)
					if (info.tick.length == 0) return
					info.tick.forEach(o => {
						o.chadian = this.getCount(o.ask_price * 1, o.bid_price * 1)
					})
					if (info.symbol == 'XAUUSD') {
						this.forex.XAUUSD = info.tick[0]
						let float = info.tick[0].ask_price - this.XAUUSDask
						this.XAUUSDask = info.tick[0].ask_price
						float = Math.floor(float * 100) / 100
						this.forex.XAUUSD.percentage = float
						if (float == 0) {
							this.forex.XAUUSD.num = '0.00'
						} else {
							if (float < 0) {
								this.forex.XAUUSD.num = float + '%'
							} else {
								this.forex.XAUUSD.num = "+" + float + '%'
							}
						}
					} else if (info.symbol == 'EURUSD') {
						this.forex.EURUSD = info.tick[0]
						let float = info.tick[0].ask_price - this.EURUSDask
						this.EURUSDask = info.tick[0].ask_price
						float = Math.floor(float * 100) / 100
						this.forex.EURUSD.percentage = float
						if (float == 0) {
							this.forex.EURUSD.num = '0.00'
						} else {
							if (float < 0) {
								this.forex.EURUSD.num = float + '%'
							} else {
								this.forex.EURUSD.num = "+" + float + '%'
							}
						}
					} else if (info.symbol == 'USDJPY') {
						this.forex.USDJPY = info.tick[0]
						let float = info.tick[0].ask_price - this.USDJPYask
						this.USDJPYask = info.tick[0].ask_price
						float = Math.floor(float * 100) / 100
						this.forex.USDJPY.percentage = float
						if (float == 0) {
							this.forex.USDJPY.num = '0.00'
						} else {
							if (float < 0) {
								this.forex.USDJPY.num = float + '%'
							} else {
								this.forex.USDJPY.num = "+" + float + '%'
							}
						}
					} 
				}

				//监听连接关闭事件
				this.ws.onclose = () => {
					//监听整个过程中websocket的状态
					console.log('ws连接状态：' + this.ws.readyState);
				}

				//监听并处理error事件
				this.ws.onerror = function(error) {
					console.log(error);
				}
			},
		}
	}
</script>

<style>
  @charset "UTF-8";
  .c-footer .fp-overflow{
		display: unset !important;
	}
  .cfd-fth{
  background: url(../assets/image/dividend/trade-bg.png) 100%/auto 100% no-repeat;
  height: 360px;
  margin-top: 0px;
  text-align: center;
  padding-top: 140px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.cfd-fth .p1{
  font-size: 38px;
  color: #FFFFFF;
  font-weight: 700;
  letter-spacing: 4px;
}
.cfd-fth .p2{
  font-size: 16px;
  color: #e6e6e6;
  letter-spacing: 2px;
  margin-top: 16px;
  width: 37%;
  margin: 0 auto;
  line-height: 30px;
}
.cfd-fth .p3{
  letter-spacing: 2px;
  opacity: 0.2;
  font-family: 'Baskerville Old Face';
  font-size: 46px;
  margin-top: 24px;
  color: #FFFFFF;
}
.cfd-fth img{
  margin-top: 26px;
  width: 300px;
  margin-bottom: 14px;
}

.reward-list-m{
  display: none;
}
.fth6 table thead th br{
  display: none;
}
.fth2-lock{
  position: fixed;
  right: 0;
  left: 0;
  top: 80px;
  z-index: 999;
}
.profile-name{
  font-size: 40px;
  color: #ffffff;
  position: absolute;
  top: 19%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#transmit5 img{
  width: 7px;
    margin-left: 10px;
}

 .trade1 .dividend-public-link2, .trade3 .dividend-public-link2{
  margin-bottom: 16px;
 }









.fca7{
  background: url(../assets/image/dividend/fca44.jpg) 100%/auto 100% no-repeat;
  height: 880px;
  background-position: center center;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.fca7 .d1 .p1{
  font-size: 36px;
  line-height: 58px;
  color: #e5e5e5;
  width: 70%;
  margin-left: 12%;
  font-weight: 700;
  margin-top: 320px;
  letter-spacing: 2px;
}
.fca7 .d1 .p2{
  color: #FFFFFF;
  font-size: 20px;
  letter-spacing: 2px;
  margin: 40px 0;
  margin-left: 12%;
}
.fca7 .d1 .p2 em{
  width: 60px;
  height: 1px;
  display: inline-block;
  border-bottom: 1px #ffffff solid;
  float: left;
}
.fca7 .d1 .p2 span{
  margin: 0 12px;
  float: left;
}
.fca7 .d1 .p3{
  margin-left: 12%;
  letter-spacing: 2px;
  color: #FFFFFF;
  font-size: 16px;
  float: left;
  margin-top: 48px;
  width: 100%;
}
.fca7 .d2{
  margin-top: 160px;
}



.fca7 .form-btn {
    padding: 6px 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    font-size: 18px;
    font-weight: 700;
    position: absolute;
    bottom: 90px;
    cursor: pointer;
    color: #4D0101;
}

.fca7 .form-btn span {
    margin-right: 10px;
    display: inline-block;
    line-height: 2;
    /* border-bottom: 1px solid #8b8b8b; */
}

.fca7 .form-box {
    width: 640px;
    height: 530px;
    position: absolute;
    top: 150px;
    right: 15%;
    padding: 3px 71px 68px 200px;
    background: linear-gradient(90deg, rgba(61, 1, 1, 0.08) 0%, rgba(45, 1, 1, 0.81) 100%);
}

.fca7 .form-box:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 190px;
    left: 0;
    /* border: 1px solid #7d1614; */
    opacity: .5
}

.fca7 .form-box .form-control {
    height: 42px;
    padding: inherit
}

.fca7 .form-box .testing {
    margin-top: 40px;
    display: flex;
    align-items: center
}

.fca7 .form-box .testing__title {
    font-size: 24px
}

.fca7 .form-box .testing__kind {
    padding-left: 10px
}

.fca7 .form-box .testing__kind .input {
    border: 1px solid #ced4da;
    font-size: 1rem;
    font-weight: 400;
    line-height: 40px;
    color: #212529;
    background-color: #fff;
    border-radius: .25rem;
    width: 50px;
    height: 40px;
    text-align: center;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.fca7 .col-md-3 {
    padding: 0 5px
}

.fca7 .select-label {
    cursor: pointer;
    display: flex;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    /* background-color: #fff; */
    border-radius: 21px;
    height: 42px;
    border: 1px solid #ddd;
    color: #fff;
}

.fca7 .select-label--active {
    background-color: #ffffff;
    color: #3D0101;
    border-color: #ffffff;
}

.fca7 .form-label {
    font-size: 16px;
    margin-top: 40px;
    /* font-weight: 700; */
    color: #ffffff;
    letter-spacing: 2px;
}

.fca7 .form-title {
    margin-top: 20px;
    font-size: 36px;
    font-weight: 600;
    color: #333;
    line-height: 1.5
}

.fca7 .mt110 {
    margin-top: 80px
}

.fca7 .form-inquiry {
    height: 50px;
    width: 125px;
    background-color: #f5f5f5;
    padding: 15px 0 0 10px
}

.fca7 .form-inquiry__en {
    font-size: 12px;
    color: #666
}

.fca7 .form-inquiry__text {
    margin-top: 8px;
    color: #333;
    font-size: 20px;
    font-weight: 700
}
.form-control {
    color: #fff;
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid #ffffff;
    border-radius: 0px;
}
#verify{
  width: 92%;
}
.fca7 #contaccfd-t-submit img{
  width: 9px;
  margin-top: 2px;
}
.rotate {
  transition: all 0.2s ease-in-out;
  transform: rotate(-90deg);
}
.rotate-leave{
  transition: all 0.2s ease-in-out;
  transform: rotate(+90deg);
}




.cfd-section1{
    padding: 30px 0px 120px;
}
.cfd-section .bottom {
    background: rgba(45, 1, 1, 0.7);
    height: 150px;
    position: absolute;
    bottom: 70px;
    width: 100%;
    padding-top: 28px;
}
.cfd-section .bottom div{
    text-align: center;
}
.cfd-section .bottom img{
    width: 20%;
}
.cfd-section .bottom .col-md-3:nth-child(1) img{
    width: 28%;
    margin-top: 4px;
}
.cfd-section .bottom .col-md-3:nth-child(4) img{
    width: 26%;
    margin-top: 14px;
}
.cfd-section .bottom p{
    color: #8D9095;
    font-size: 16px;
    margin-top: 12px;
}
.cfd-section .bottom div{
    height: 64px;
}
.cfd-section .title{
    color: #3D0101;
    font-size: 36px;
    line-height: 56px;
    margin-top: 0px;
    font-weight: 700;
    letter-spacing: 1px;
}
.cfd-section .desc{
    color: #999;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 30px;
}
.cfd-section .line{
    width: 60px;
    border: 1px solid #AF8147;
    margin: 40px 0 44px;
}
.cfd-section .learn-more{
    display: inline-block;
    padding: 12px 30px;
    border: 1px #fff solid;
    font-size: 14px;
    color: #ffffff;
    margin-top: 46px;
}
.cfd-section .learn-more:hover{
    border: 1px #AF8147 solid;
    background: #AF8147;
    color: #3D0101;
}
.cfd-section1 .market li{
    float: left;
    background-color: rgba(0,0,0,0.9);
    width: 31%;
    margin: 0 1%;
    padding: 20px 3%;
    position: relative;
    border-radius: 4px;
}
.cfd-section1 .market li .name{
    color: #AF8147;
    font-size: 16px;
}
.cfd-section1 .market li .float{
    float: right;
    font-size: 14px;
    font-family: auto;
    color: #949699;
}
.cfd-section1 .market li .bid,.ask{
    color: #949699;
    margin-top: 20px;
    font-size: 14px;
}
.cfd-section1 .market li .bid-price,.ask-price{
    font-size: 26px;
    font-family: auto;
    margin-top: 12px;
    letter-spacing: 1px;
}
.cfd-section1 .market li .bid-price span,.ask-price span{
    font-size: 36px;
}
.cfd-section1 .market li .green{
    color: #00B427;
}
.cfd-section1 .market li .red{
    color: #FF1120;
}
.cfd-section1 .market li .spread{
    width: 80%;
    height: 26px;
    background-color: #292A2C;
    margin: 20px auto 0;
    position: relative;
    z-index: 9;
    text-align: center;
}
.cfd-section1 .market li .spread .s1{
    font-size: 12px;
    color: #BABCBF;
    line-height: 26px;
}
.cfd-section1 .market li .spread .s2{
    color: #e0e0e0;
    font-size: 14px;
    font-family: auto;
    line-height: 26px;
}
.cfd-section1 .market li .line{
    position: absolute;
    width: 81%;
    top: 112px;
    border: 1px solid #2B2C2D;
}
.cfd-section1 .market li a{
    background-color: #2B2C2D;
    padding: 10px 23px;
    float: left;
    border-radius: 16px;
    margin-top: 18px;
    font-size: 12px;
}
.cfd-section1 .market li .buy{
    color: #00B427;
}
.cfd-section1 .market li .sell{
    float: right;
    color: #FF1120;
}

.cfd-section0{
  padding: 80px 0;
}

.cfd-section0 .d1{
  background: linear-gradient(90deg, rgba(148, 150, 153, 0.06) 0%, rgba(148, 150, 153, 0.00) 100%);
  padding: 140px 100px;
}
.cfd-section0 .d2{
  padding: 140px 0 0 60px;
}

.cfd-section0 .d1 .row div{
  text-align: center;
  width: 50%;
}
.cfd-section0 .d1 .row div img{
    
}
.cfd-section0 .d1 .row div p{
  color: #080E1C;
  font-size: 16px;
  margin-top: 18px;
  letter-spacing: 1px;
  line-height: 26px;
}
.cfd-section0 .line{
  margin: 40px 0 50px;
}

.cfd-section .s1-d2{
  margin-top: 6px;
}


.cfd-section3 .d1 .row div{
  width: 33.3%;
}
.cfd-section3 .d1{
  padding: 140px 50px 140px 60px;
}
.cfd-section3{
  padding-top: 0px;
}
.cfd-section .title{
  width: 80%;
}


.cfd-section4 .d1{
  padding: 140px 40px 140px 50px;
}
.cfd-section4 .d1 .row div{
  width: 25%;
}
.cfd-section4 .d1 .row div img{
  width: 122px;
}




.cfd-section5 .line{
  margin: 40px 0 20px;
}

.cfd-section6 .d1 .row div img{
  width: 50px;
}

.cfd-section6 .d1 .row div{
  width: 25%;
}
.cfd-section6 .d1{
   padding: 140px 40px 140px 50px;
}
.cfd-section6 .d1 .row div p{
  line-height: 24px;
  margin-top: 16px;
}

.cfd-section4{
  padding-top: 0px;
}

.cfd-section6{
  padding-top: 0px;
}

.cfd-section4 .dividend-public-link,.cfd-section6 .dividend-public-link{
  margin-top: 16px;
}

.cfd-section .cfd-section1-s1-d2{
  margin-top: 26px;
}

.cfd-section4 .d2{
  padding-top: 90px;
  padding-left: 100px;
}
.cfd-section6 .d2{
  padding-top: 70px;
  padding-left: 100px;
}
.cfd-section5 .dividend-public-link2{
  margin-top: 26px;
}
.cfd-fth .line{
    display: none;
}
.cfd-fth .btn{
  display: none;
}

.cfd-fth .p1 br{
  display: none;
}
.m-title,.m-line,.m-desc,.m-btn{
  display: none;
}

.trade5 .title,.trade7 .title{
  width: 100%;
}
.trade7 .d1 .row div p{
  padding: 0 8%;
}

.en-style .cfd-fth .p1{
  letter-spacing: 0px;
}
.en-style .cfd-fth .p2{
  letter-spacing: 0px;
}
.en-style .cfd-section .title{
  letter-spacing: 0px;
}
.en-style .cfd-section0 .d1 .row div p{
  letter-spacing: 0px;
  padding: 0 3%;
}
.en-style .dividend-public-link2,.en-style .dividend-public-link{
  letter-spacing: 0px;
}
.en-style .cfd-section .title{
  width: 92%;
}
.en-style .cfd-section6 .d2{
  padding-left: 70px;
}
.en-style .cfd-section4 .d2{
  padding-left: 140px;
  padding-top: 100px;
}
.en-style .cfd-section4 .d1{
  padding-right: 0px;
}


.cfd-t-sub1{
  background: url(../assets/image/dividend/cfd-bg.jpg) 100%/auto 100% no-repeat;
  height: 390px;
  margin-top: 0px;
  text-align: center;
  padding-top: 126px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.cfd-t-sub1 img {
    margin-top: 26px;
    width: 330px;
    margin-bottom: 14px;
}
.cfd-t-sub1 .p1{
    color: #FFF;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    margin-top: 19px;
}
.line{
    width: 80px;
    border: 1px solid #D9D9D9;
    margin: 60px auto 50px;
}
.cfd-t-sub1 .p2{
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    width: 666px;
    margin: 0 auto;
    line-height: 36px;
    opacity: 0.9;
}
.cfd-t-sub1 .btn{
    display: block;
    width: max-content;
    padding: 10px 56px;
    height: 46px;
    font-size: 16px;
    border-radius: 2px;
    background: #3D0101;
    color: #AF8147;
    margin: 28px auto;
}
.cfd-t-sub1 .btn:hover{
    color: #3D0101;
    background: #AF8147;
}

.dividend-public-title{
    color: #3D0101;
    text-align: center;
    font-size: 34px;
    font-weight: 700;
}
.dividend-public-line{
    width: 70px;
    border: 1px solid #AF8147;
    margin: 46px auto 36px;
}
.dividend-public-title2{
    color: #3D0101;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}
.public-desc{
    width: 54%;
    color: #666666;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    margin: 20px auto 0;
}


.cfd-t-sub2{
    background: #F8F8F8;
    padding: 80px 0;
}
.cfd-t-sub2 .row{
    background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
    margin: 46px auto;
    padding: 50px 10% 50px 17%;
    width: 1000px;
}
.cfd-t-sub2 .row div{
    margin-top: 10px;
}
.cfd-t-sub2 .row img{
    width: 30px;
    position: relative;
    top: 7px;
}
.cfd-t-sub2 .row p{
    color: #080E1C;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    display: inline;
    line-height: 46px;
    margin-left: 16px;
}


.public-btn{
    display: block;
    width: max-content;
    padding: 13px 40px;
    height: 44px;
    font-size: 16px;
    border-radius: 2px;
    color: #3D0101;
    margin: 50px auto 0px;
    text-align: center;
    border: 1px solid #3D0101;
    border-radius: 2px;
}
.public-btn:hover{
    color: #3D0101;
    background: #AF8147;
    border: 1px solid #AF8147;
}

.btn-box{
    width: max-content;
    margin: auto;
}


.cfd-t-sub3{
    background: #ffffff;
    padding: 90px 0 160px;
}
.cfd-t-sub3 .row{
    margin: 54px auto;
    width: 1200px;
}
.cfd-t-sub3 .row div{
    text-align: center;
}
.cfd-t-sub3 .row img{
    width: 65px;
    position: relative;
    top: 26px;
}
.cfd-t-sub3 .row img{
    width: 90px;
}
.cfd-t-sub3 .row .title{
    color: #080E1C;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 48px;
    margin-top: 22px;
}
.cfd-t-sub3 .row .desc{
    color: #080E1C;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
    padding: 0 10%;
}


.cfd-t-sub4{
    background: #F8F8F8;
    padding: 80px 0;
}
.cfd-t-sub4 img{
    display: block;
    margin: 40px auto;
    width: 60%;
    background: #fff;
    padding: 30px;
}
.cfd-t-sub4 .public-desc{
    width: 60%;
    text-align: left;
}


.cfd-t-sub5{
    background: #FFFFFF;
    padding: 80px 0;
}
.cfd-t-sub5 .cfd-t-sub5-img{
    display: block;
    margin: 40px auto;
    width: 1000px;
}
.cfd-t-sub5 .public-desc{
    width: 500px;
}
.cfd-t-sub5-content{
    position: relative;
    width: 1000px;
    margin: 0 auto;
}
.cfd-t-sub5-bottom{
    position: absolute;
    bottom: 0px;
    height: 80px;
    background: rgba(29, 1, 1, 0.75);
    width: 1000px;
    text-align: center;
}
.cfd-t-sub5-bottom p{
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline;
    padding: 0 60px;
    line-height: 74px;
}
.cfd-t-sub5-bottom img{
    width: 7px;
    margin-left: 10px;
}
.cfd-t-sub5-bottom span{
    font-size: 26px;
    font-weight: bold;
}
.public-slogan{
    padding: 28px 0px;
    background: #F8F8F8;
    color: #3D0101;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    font-weight: 600;
}

.cfd-t-subtner-box-en .cfd-t-sub2 .row{
    padding: 50px 0% 26px 22%;
}
.cfd-t-subtner-box-en .cfd-t-sub2 .row div{
    width: 100%;
}
.cfd-t-subtner-box-en .cfd-t-sub1 .p1{
    line-height: 52px;
    margin-top: 60px;
}
.cfd-t-subtner-box-en .public-desc{
    width: 750px;
}

.cfd-t-subtner-box-en .cfd-t-sub3 .row p{
    margin-left: 10px;
    display: block;
    line-height: 26px;
    margin-top: 30px;
}
.cfd-t-subtner-box-en .cfd-t-sub1 .p2{
    letter-spacing: 0px;
    width: 718px;
    line-height: 30px;
}
.cfd-t-subtner-box-en .cfd-t-sub2 .row p{
    letter-spacing: 0px;
}
.cfd-t-sub1 .btn-ug{
    width: 190px;
}


.cfd-t-sub5 .container{
    max-width: 1100px;
}
.cfd-t-sub5 .row div{
    text-align: center;
    margin-top: 30px;
    margin-bottom: 40px;
}
.cfd-t-sub5 .row img{
    width: 80%;
}
.cfd-t-sub5 .row p{
    color: #666666;
    font-size: 16px;
    line-height: 26px;
    width: 90%;
    margin: 30px auto 0;
}

.cfd-t-sub6{
    background: #F8F8F8;
    padding: 80px 0;
}
.cfd-t-sub6 .container{
    max-width: 1100px;
}
.cfd-t-sub6 .row div{
    background: #fff;
    width: auto;
    margin: 10px 2% 20px;
    height: 44px;
    padding: 14px 20px 14px 46px;
    position: relative;
    border-radius: 3px;
}
.cfd-t-sub6 .row div img{
    width: 18px;
    position: absolute;
    top: 13px;
    left: 20px;
}
.cfd-t-sub6 .row div p{
    float: left;
    font-size: 16px;
}
.cfd-t-sub1 .p1 br{
    display: none;
}

.en-style .cfd-t-sub1 .p2{
    letter-spacing: 0px;
}
.en-style .cfd-t-sub1 .p1{
    margin-top: 80px;
}
.vi-box .cfd-t-sub1 .p1{
    margin-top: 40px;
}




.dividend-public-link{
    background: #3d0101;
    /* padding: 11px 28px; */
    color: #fff;
    font-size: 16px;
    letter-spacing: 1px;
    display: block;
    width: max-content;
    padding: 15px 29px;
    float: left;
    margin-top: 20px;
}
.dividend-public-link img {
    width: 7px;
    margin-left: 12px;
}
.dividend-public-link:hover{
  color: #ffffff;
  background: #AF8147;
}

.dividend-public-link2{
    padding: 14px 28px;
    color: #3d0101;
    font-size: 16px;
    border: 1px solid #3d0101;
    margin-right: 12px;
    letter-spacing: 1px;
    display: block;
    width: max-content;
    float: left;
    margin-top: 20px;
}
.dividend-public-link2 img {
    width: 7px;
    margin-left: 12px;
}
.dividend-public-link2:hover{
  color: #ffffff;
  background: #3d0101;
  border: 1px solid #3d0101;
}
.arrow2{
  display: none!important;
}
.dividend-public-link2:hover .arrow1{
  display: none!important;
}
.dividend-public-link2:hover .arrow2{
  display: inline-block!important;
}


.product {
    width: 1000px;
    margin: 80px auto;
}

.product .accordion-header {
    cursor: pointer
}

.product .table-list {
    width: 100%;
    border: none;
    position: relative;
    flex-direction: inherit;
    background: #949699;
}

.product .table-list .flex-1 {
    font-weight: 700;
    line-height: 40px;
    color: #ffffff;
    font-size: 14px;
    padding: 2px 12px;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    cursor: pointer;
    font-family: auto;
    text-align: center;
}

.product .table-list .flex-1.active {
    background-color: #3D0101;
    color: #ffffff;
}

.product-box__nav {
    /* margin-bottom: 4px; */
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    color: #666666;
    padding: 0 12px;
    background-color: #F8F8F8;
    padding: 4px 0;
}

.product-box__nav div {
    line-height: 40px;
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: auto;
    text-align: center;
}

.product-box__accordion-item {
    background: #f7f7f7;
    border: none
}

.product-box__accordion-item .accordion-button {
    padding: 10px;
    font-size: 14px;
    background: #ffffff;
    color: #333
}

.product-box__accordion-item .accordion-body-head {
    width: 100%;
    padding: 12px 0;
    background-color: #949699;
    color: #f7f7f7
}

.product-box__accordion-item .accordion-body-head div {
    flex: 1
}

.product-box__accordion-header {
    margin-bottom: 0
}

.product-box .accordion-body-tr {
    width: 100%;
    padding: 12px 0;
    font-family: auto;
}

.product-box .accordion-body-tr div {
    flex: 1;
    text-align: center;
}

.product-box .accordion-body-tr div:nth-of-type(2) {
    color: #48ba13
}

.product-box .accordion-body-tr .red {
    color: #cc3221 !important
}

.product-box .accordion-body-tr div:nth-of-type(5) {
    color: #48ba13
}

.product .look-all {
    display: flex;
    justify-content: center;
    color: #8b8b8b;
    padding: 20px 0;
    font-size: 16px;
}

.product .look-all span {
    padding-top: 5px;
    border-top: 1px solid #8b8b8b
}

.product .c-underline__top:after {
    /* background: #7d1614;
    height: 4px */
}

.accordion-body{
    font-size: 14px;
    font-family: auto;
    background: #f8f8f8;
}


.rotate .accordion-button:after{
    transform: rotate(180deg);
}


.en-style2 .cfd-t-sub1 .p2{
    letter-spacing: 0px;
}
.en-style2 .cfd-t-sub3 .row .title{
    letter-spacing: 0px;
    line-height: 26px;
    margin-top: 32px;
    margin-bottom: 14px;
}
.en-style2 .cfd-t-sub3 .row .desc{
    letter-spacing: 0px;
}
.en-style2 .dividend-public-link2{
    letter-spacing: 0px;
}
.en-style2 .dividend-public-link{
    letter-spacing: 0px;
}

.cfd-t-sub1 .btn{
    display: none;
}

.dividend-account1{
    padding: 80px 0 170px;
    background: #f8f8f8;
}
.dividend-account1 .container{
    width: 1100px;
    FONT-WEIGHT: 100;
}
.dividend-account1 .row .title{
    font-size: 28px;
    color: #080E1C;
    font-weight: 700;
    margin-bottom: 30px;
    letter-spacing: 1px;
}
.dividend-account1 .row .title2{
    color: #3D0101;
}
.dividend-account1 .row .desc1,.dividend-account1 .row .desc2,.dividend-account1 .row .desc3{
    font-size: 17px;
    line-height: 50px;
    letter-spacing: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.dividend-account1 .row .div1{
    margin-top: 60px;
}
.dividend-account1 .row .desc1{
    color: #949699;
}
.dividend-account1 .row .desc2{
    color: #080E1C;
}
.dividend-account1 .row .desc3{
    color: #3D0101;
}
.dividend-account1 .dividend-public-link2{
    margin-right: 0px;
    margin-top: 40px;
}
.dividend-account2{
    padding: 100px 0 80px;
    background: #f8f8f8;
}

.dividend-account2 .row img{
    width: 80%;
    margin: 40px auto 40px;
}

.dividend-account2 .public-desc{
    color: #949699;
    font-size: 18px;
}

.dividend-account2 .dividend-public-link2{
    float: unset;
    margin: 0 auto;
}

.dividend-account3{
    padding: 100px 0 80px;
}

.dividend-account3 .row img{
    width: 80%;
    margin: 40px auto 40px;
}

.dividend-account3 .public-desc{
    color: #949699;
    font-size: 18px;
}

.dividend-account3 .dividend-public-link2{
    float: unset;
    margin: 0 auto;
}

.en-style2 .cfd-t-sub1 .p2{
    letter-spacing: 0px;
}
.en-style2 .dividend-account1 .row .desc1, .en-style2 .dividend-account1 .row .desc2, .en-style2 .dividend-account1 .row .desc3{
    letter-spacing: 0px;
}
.en-style2 .dividend-account1 .row .title{
    letter-spacing: 0px;
    font-size: 26px;
}
.en-style2 .public-desc{
    width: 80%;
}
.en-style2 .dividend-public-title{
    padding: 0 10%;
    line-height: 38px;
}


.dividend-page-screen-1 {
    min-height: 600px;
    margin-top: 50px;
}

.dividend-page-screen-1 .page-screen__title {
    height: 60px;
    line-height: 60px;
    background-color: #ffffff;
    text-align: center;
}

.dividend-page-screen-1 .page-screen__title__text {
    font-size: 18px;
    font-weight: 700;
    text-indent: 20px;
    color: #070707;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: auto;
}

.dividend-page-screen-1 .page-screen__ul__li {
    height: 50px;
    line-height: 50px;
    text-align: center;
}

.dividend-page-screen-1 .page-screen__ul__li .text {
    font-size: 16px;
    text-indent: 20px;
    color: #070707;
    font-family: auto;
    font-weight: 400;
}

.dividend-page-screen-1 .page-screen__ul__li:nth-child(2n+2) {
    background-color: #f7f7f7
}

.dividend-page-screen-1 .page-screen__btn {
    text-align: center;
    padding: 46px 0 100px
}

.dividend-page-screen-1 .page-screen__btn .text {
    display: inline-block;
    min-width: 112px;
    height: 38px;
    border: 1px solid #d1d1d1;
    line-height: 40px;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    padding: 0 10px
}
.dividend-page-screen-1 .page-screen__ul__li{
    background-color: #F9F9F9;
}
.dividend-page-screen-1 .page-screen__ul__li:nth-child(2n+2) {
    background-color: #ffffff;
}

.dividend-page-screen-1 .page-screen__btn .text.vi-box {
    font-family: "UKIJ Tuz Tom", "Open Sans", "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", Arial, Verdana, Tahoma, sans-serif
}


.dividend-account1 .dividend-public-title{
    margin-top: 0px;
    margin-bottom: 0px;
}

.pc-btn{
    display: block;
}
.m-btn{
    display: none;
}

.trade8{
    padding-top: 120px;
}
.trade8 .title{
    margin-top: 50px;
}

.dividend-account1 .dividend-public-title{
    letter-spacing: 0px;
}

.en-style2 .trade8 .title{
    letter-spacing: 0px;
    margin-top: 46px;
    line-height: 50px;
}

.m-title,.m-line,.m-desc,.m-btn{
  display: none;
}

@media(max-width:767px) {

    .dividend-page-screen-1 .page-screen__title__text,
    .dividend-page-screen-1 .page-screen__ul__li .text {
        text-indent: 10px
    }
}


@media(max-width:1400px) {
    .cfd-t-sub1 .p1{
        font-size: 34px;
    }
    .cfd-t-sub1 .p2{
        line-height: 32px;
        font-size: 16px;
        width: 600px;
    }
    .cfd-t-sub4 img{
        width: 700px;
    }
    .cfd-t-sub5 .cfd-t-sub5-img{
        width: 700px;
    }
    .cfd-t-sub5-bottom{
        width: 700px;
        height: 68px;
    }
    .cfd-t-sub5-content{
        width: 700px;
    }
    .cfd-t-sub5-bottom p{
        line-height: 60px;
    }
    .cfd-t-subtner-box-en .cfd-t-sub1{
        height: 650px;
    }
}


@media(max-width:995px) {
	.cfd-fth .p1 {
    padding: 0 5%;
  }
  .fth4 .row .list img{
    width: 100px;
    height: 100px;
  }
  .profile-name {
      font-size: 40px;
      top: 19.5%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  .cfd-fth{
    background: url(../assets/image/dividend/trade-bg-m.png) 100%/auto 100% no-repeat;
    height: 650px;
    margin-top: 0px;
    text-align: center;
    padding-top: 126px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .cfd-fth img{
    display: none;
  }

  .cfd-fth .p3{
    font-size: 28px;
    opacity: 0.3;
  }
  .cfd-fth .p1{
    font-size: 30px;
  }
  .fth2{
    display: none;
  }
  .dividend-public-title{
    font-size: 26px;
    margin-bottom: 20px;
  }
  .fth3 .row{
    background: none;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
  }
  .link{
    margin-top: 50px;
  }
  .fth3 .row div{
    margin: 22px 0;
  }
  .fth3 .row div:nth-child(1){
    width: 100%;
  }
  .fth3 .row div:nth-child(2),.fth3 .row div:nth-child(3),.fth3 .row div:nth-child(4),.fth3 .row div:nth-child(5){
    width: 50%;
  }
  .fth3 .p1{
    font-size: 150px;
  }
  .fth3 .p2{
    font-size: 22px;
    line-height: 33px;
    margin-top: 61px;
  }
  .fth3 .p3{
    line-height: 24px;
    font-size: 14px;
    margin-top: 14px;
  }
  .fth4 {
    margin-top: 100px;
  }
  .fth4 .r1{
    overflow: hidden;
    margin-top: 40px;
    padding: 0px 38px 0 0;
  }
  .fth4 .r2 .col-md-4{
    width: 33%;
  }
  .fth5{
    margin-top: 100px;
  }
  .fth5 .row div{
    width: 93%;
    margin: 0 auto;
  }
  .fth5 .row{
    margin-top: 0px;
  }
  .fth5 .row div .col-sm-6{
    width: 50%;
  }
  .fth5 .row div .sr-row{
    padding: 0;
    margin: 0;
    background: none;
    margin-top: 0px!important;
  }
  .fth5 .row div .sr-row div{
    background: none;
    padding: 0;
    margin: 0;
  }
  .fth5 .row .p4{
    margin-top: 10px;
  }
  .fth5 .row .chess{
    margin-top: 30px !important;
  }
  .fth5 .row .p1{
    margin-top: 10px;
  }
  .fth5 .row .p2{
    margin-top: 50px;
  }
  .fth5 .row .p2 .s1{
    font-size: 16px;
  }
  .fth5 .row .p2 .s2{
    font-size: 16px;
  }
  .reward-list-pc{
    display: none;
  }
  .reward-list-m{
    display: block;
  }
  .fth5 .row div:nth-child(1){
    margin-top: 20px;
  }
  .fth5 .row div:nth-child(2){
    margin-top: 20px;
  }
  .fth5 .row div:nth-child(3){
    margin-top: 20px;
  }
  .fth5 .row div:nth-child(4){
    margin-top: 20px;
  }
  .fth5 .row div:nth-child(5){
    margin-top: 20px;
  }
  .reward-list-m div{
    background: linear-gradient(0deg, rgba(148, 150, 153, 0.06) 0%, rgba(148, 150, 153, 0.02) 100%);
  }
  .reward-list-m .sr-row div:nth-child(1){
    width: 40% !important;
  }
  .reward-list-m .sr-row div:nth-child(2){
    width: 60% !important;
  }
  .fth5 .row div .tip1{
    font-size: 14px;
    line-height: 20px;
    margin-top: 4px;
  }
  .fth5 .row div:nth-child(5) .p1{
    margin-top: 60px;
  }
  .reward-list-m .col-md-2{
    padding-top: 18px!important;
    padding-bottom: 18px!important;
  }
  .fth6 table thead th{
    font-size: 14px;
    line-height: 18px;
  }
  .fth6 table thead th br{
    display: block;
  }
  .fth6 table thead th img {
    width: 76%;
    left: 11%;
    top: -15px;
  }
  .fth6 table thead th:nth-child(1){
    width: 26%;
  }
  .fth6 table tbody td{
    font-size: 13px;
    line-height: 10px;
  }
  .fth6 table{
    width: 92%;
  }
  .fth6 table thead th{
    height: 56px;
  }
  .fth6 table thead th:nth-child(1) div{
    position: relative;
    top: 6%;
  }
  .fth6 table tbody tr .img1{
    width: 40%;
  }
  .fth6 table tbody tr .img2{
    width: 33%;
    left: 45%;
  }
  .fth6 table tbody td {
    font-size: 13px;
    line-height: 50px;
  }
  .fth6 table tbody tr:nth-child(even) td{
    line-height: 60px;
  }
  .fth6 table tbody tr:nth-child(even) {
    height: 60px;
    line-height: 60px;
  }
  .fth6 table tbody tr:last-child td:first-child {
    line-height: 18px;
    padding-top: 7px;
  }
  .fth6{
    margin-top: 100px;
    margin-bottom: 80px;
  }
  .fth6 .row {
    margin-top: 50px;
  }
  .fca7 .d1 .p3{
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .fth5 .row .p2{
    margin-top: 40px;
  }
  .fth5 .row .p2 .s1{
    font-size: 14px;
  }
  .fth5 .row .p2 .s2{
    font-size: 14px;
  }
  .fth5 .row div:nth-child(1) .chess,.fth5 .row div:nth-child(2) .chess, .fth5 .row div:nth-child(3) .chess, .fth5 .row div:nth-child(4) .chess, .fth5 .row div:nth-child(5) .chess{
    margin-bottom: 4px;
  }
  .fth5 .row div:nth-child(5) .p1{
    margin-top: 54px;
  }
  .fth5 .sr-row{
    width: 100% !important;
  }

  .fth4 .row .list .p3{
    font-size: 26px;
  }
  .fth4 .row .list .sp1{
    font-size: 16px;
  }
  .fth4 .row .list .sp2{
    font-size: 12px;
    margin-top: 6px;
  }

  .fca3 .d2{
    padding-left: 8%;
  }
  .home-header .navbar-brand img{
    width: 120px;
    height: auto;
  }
    .fca1{
      background: url(../assets/image/dividend/fca1-m.jpg) 100%/auto 100% no-repeat;
      height: 280px;
      background-position: center center;
          padding-top: 118px;
    }
    .fca1 p{
        font-size: 28px;
    }
    .fca1 img {
      margin-top: 22px;
      width: 150px;
    }
    .fca2{
      margin-top: 35px;
      margin-bottom: 66px;
    }
    .fca2 .d1{
      padding: 46px 0px;
      padding-left: 10%;
    }
    .fca2 .img1{
      margin-top: 4px;
      width: 32%;
    }
    .fca2 .img2{
      width: 18%;
      margin-top: 2px;
    }
    .fca2 span{
      margin: 0px 18% 0 8%;
      height: 58px;
    }
    .fca2 p{
      width: 100%;
      padding: 0px 6%;
      margin-top: 22px;
    }

    .fca3{
      margin-top: 35px;
    }
    .fca3 .d1{
      padding: 46px 0px;
      padding-left: 10%;
    }
    .fca3 .img1{
      margin-top: 4px;
      width: 32%;
    }
    .fca3 .img2{
      width: 43%;
      margin-top: 6px;
    }
    .fca3 span{
      margin: 0px 8% 0 8%;
      height: 58px;
    }
    .fca3 p{
      width: 100%;
      padding: 0px 6% 0 0%;
      margin-top: 22px;
    }
    .fca4{
      margin: 20px 0;
    }
    .fca4 .title{
      font-size: 22px;
      line-height: 40px;
      padding: 0 6%;
      margin-bottom: 26px;
    }
    .fca4 .row div{
      margin: 20px 0;
      text-align: left;
    }
    .fca4 .row div img{
      float: left;
      width: 50px;
      margin-left: 4%;
      margin-top: 12px;
    }
    .fca4 .row div p{
      float: left;
      margin-top: 0px;
      width: 64%;
      margin-left: 12%;
    }
    .fca4 .row div p br{
      display: none;
    }
    .fca4 .row{
      padding: 16px 7%;
    }



    .fca5{
      margin: 80px 0 10px;
    }
    .fca5 .title{
      font-size: 22px;
      line-height: 40px;
      padding: 0 6%;
      margin-bottom: 26px;
    }
    .fca5 .r1 div{
      margin: 20px 0;
      text-align: left;
    }
    .fca5 .r1 div img{
      float: left;
      width: 23%;
      margin-left: 8%;
      margin-top: 12px;
    }
    .fca5 .r1 div p{
      float: left;
      margin-top: 11%;
      width: 60%;
      margin-left: 8%;
      font-size: 18px;
    }
    .fca5 .r1 div p br{
      display: none;
    }
    .fca5 .r1{
      padding: 16px 7%;
    }
    .fca5 .r2 .c1{
      text-align: center;
      padding-right: 7px;
      margin-bottom: 54px;
    }
    .fca5 .r2 .c2{
      text-align: center;
      padding-right: 11px;
    }
    .fca5 .r2{
      margin-top: 46px;
    }

    .fca6{
      margin: 100px 0 10px;
    }
    .fca6 .title{
      font-size: 22px;
      line-height: 40px;
      padding: 0 10%;
      margin-bottom: 26px;
    }
    .fca6 .row{
      padding: 0px 8%;
      background: none;
    }
    .fca6 .row div .p2{
      padding-left: 0px;
      margin-bottom: 50px;
    }

    .contact-us {
        height: auto;
        padding-bottom: 64px
    }

    .contact-us .form-bg {
        width: 100%;
        display: block;
        margin: 0;
        border: none
    }

    .contact-us .form-box {
        height: auto;
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
        border: none;
        padding: 40px 8%;
        margin-top: -30px
    }

    .contact-us .form-box:after {
        display: none
    }

    .contact-us .form-box .form-btn {
        position: relative;
        left: 0;
        top: 0;
        margin-top: 60px
    }

    .contact-us .form-box .row {
        margin: 0
    }

    .contact-us .form-box .row>* {
        padding: 0
    }

    .contact-us .form-box .mt110 {
        margin-top: 43px
    }

    .contact-us .form-box .col-md-3 {
        margin-top: 12px;
        margin-right: 20px;
        width: 62px;
        height: 28px
    }

    .contact-us .form-box .select-label {
        height: 100%
    }

    .contact-us .form-box .form-label {
        font-size: 16px;
        margin-top: 32px
    }

    .fca7 .d1 .p1{
      margin: 90px auto 0px;
      font-size: 28px;
      line-height: 44px;
      text-align: center;
      width: 90%;
    }
    .fca7 .d1 .p2{
      margin: 20px auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .fca7 .d1 .p2 em{
      margin-top: 5px;
    }
    .fca7 .d1 .p3{
      margin-top: 16px;
      margin-bottom: 20px;
    }
    .fca7 .d2{
      margin-top: 74px;
    }
    .fca7 .d1 .p2{
      font-size: 18px;
    }
    .fca7 .verify-code{
      width: 50%;
    }
    .fca7 .verify-input{
      width: 50%;
    }
    #verify {
      width: 100%;
    }
    .contact-us .form-box .select-label{
      height: 34px;
      width: 80px;
    }
    .contact-us .form-box .col-md-3{
      margin-right: 40px;
    }
    .contact-us .form-box .form-btn{
        margin-top: 36px;
    }
    .contact-us .form-box{
      padding-bottom: 80px;
    }
    .fca7 .d1 .p3{
      font-size: 14px;
    }
    .fca7 {
      background: url(../assets/image/dividend/fca44-m.jpg) 100%/auto 100% no-repeat;
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    .cfd-fth .p1{
      margin-top: 40px;
      line-height: 58px;
      font-size: 36px;
    }
    .cfd-fth .line{
      width: 62px;
      border: 1px solid #D9D9D9;
      margin: 26px auto 0px;
      display: block;
    }
    .cfd-fth .p2{
      width: 100%;
      padding: 8%;
      font-size: 16px;
      line-height: 26px;
    }
    .cfd-fth .btn{
      display: block;
      width: 56%;
      padding: 10px 40px;
      height: 46px;
      font-size: 16px;
      border-radius: 2px;
      background: #3D0101;
      color: #AF8147;
      margin: 14px auto;
    }
    .m-title,.m-line,.m-desc,.m-btn{
      display: block;
    }
    .pc-title,.pc-line,.pc-desc,.pc-btn{
      display: none;
    }
    .cfd-fth .p1 br{
      display: block;
    }
    .cfd-section0 .d1{
      background: none;
    }
    .trade1{
      background: #f8f8f8;
      padding: 50px 5%;
    }
    .trade1 .d1{
      padding: 0px;
    }
    .cfd-section .title{
      text-align: center;
      width: 100%;
    }
    .cfd-section .line{
      margin: 26px auto 30px;
      width: 46px;
    }
    .cfd-section .title{
      font-size: 28px;
    }

    .dividend-public-link,.dividend-public-link2{
      width: 70%;
      text-align: center;
      margin: 20px auto 10px;
      float: unset;
    }

    .trade1 .d2{
      padding: 0;
    }
    .trade1 .d1{
      margin-bottom: 40px;
    }
    .trade1 .d1 .row div img{
      width: 110px;
    }
    .cfd-section .market li:nth-child(3){
        display: none;
    }
    .cfd-section .market li {
      width: 48%;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    .cfd-section .title{
      line-height: 42px;
    }
    .cfd-section .cfd-section1-s1-d2{
      margin-top: 0px;
    }
    .trade2{
      padding: 50px 5%;;
    }
    .cfd-section .desc{
      width: 100% !important;
      font-size: 16px;
      line-height: 24px;
    }
    .cfd-section .market li .line{
        width: 88%;
        top: 130.5px;
    }
    .cfd-section .market li{
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .trade2 .market{
      margin-bottom: 20px;
    }
    .trade3{
      background: #f8f8f8;
      padding: 50px 5%;
    }
    .trade3 .d1{
      padding: 0;
      margin-bottom: 30px;
    }
    .trade3 .d1 .row div{
      margin: 20px 0;
    }
    .trade3 .d1 .row div:nth-child(1){
        width: 100%;
    }
    .trade3 .d1 .row div:nth-child(2),.trade3 .d1 .row div:nth-child(3){
        width: 50%;
    }
    .cfd-section .d2{
      padding: 0;
    }
    .cfd-section3 .d1 .row div img{
      width: 122px;
    }
    .trade4{
      padding: 50px 5%;
    }
    .trade4 .m-desc{
      text-align: center;
    }
    .trade4 .market{
      margin-bottom: 20px;
    }
    .trade5{
      padding: 50px 5%;
      background: #f8f8f8;
    }
    .trade5 .d1{
      padding: 0;
      margin-bottom: 30px;
    }
    .trade5 .d1 .row div{
      width: 50%;
      margin: 30px 0;
    }
    .trade5 .dividend-public-link2{
      margin-top: 0px;
    }

    .trade6{
      padding: 50px 5%;
    }
    .trade6 .d1{
      padding: 0;
      margin-bottom: 30px;
    }
    .trade6 .d1 .row div{
      width: 50%;
      margin: 30px 0;
    }
    .trade6 .dividend-public-link2{
      margin-top: 40px;
    }

    .trade7{
      padding: 50px 5%;
      background: #f8f8f8;
    }
    .trade7 .d1{
      padding: 0;
      margin-bottom: 30px;
    }
    .trade7 .d1 .row div{
      width: 50%;
      margin: 30px 0;
    }
    .trade7 .dividend-public-link2{
      margin-top: 0px;
    }

    .trade8{
      padding: 50px 5%;
    }
    .trade8 .d1{
      padding: 0;
      margin-bottom: 30px;
    }
    .trade8 .d1 .row div{
      width: 50%;
      margin: 30px 0;
    }
    .trade8 .dividend-public-link2{
      margin-top: 40px;
    }
    .en-style .cfd-fth .p1{
      font-size: 32px;
      padding: 0 10%;
      line-height: 44px;
    }
    .en-style .cfd-section .title{
      width: 100%;
      font-size: 26px;
      line-height: 34px;
    }
    .en-style .cfd-section4 .d2{
      padding: 0;
    }
    .en-style .cfd-section6 .d2{
      padding: 0;
    }
    .pc-btn{
        display: none;
    }
    .m-btn{
        display: block;
    }
    .cfd-t-sub1 .p1 br{
        display: block !important;
    }
    .cfd-t-sub2 .row{
        width: 90%;
        padding: 20px 0% 0px 13%;
        margin: 30px auto;
    }
    .cfd-t-sub2 .row p{
        font-size: 14px;
    }
    .cfd-t-sub2 .row div{
        margin-top: 0px;
    }
    .cfd-t-sub3 .row{
        width: 100%;
        padding: 19px 0% 0px 0%;
        margin: 10px auto 30px;
        background: #ffffff;
    }
    .cfd-t-sub3 .row div{
        width: 50%;
        margin-bottom: 30px;
    }
    .cfd-t-sub3 .row img{
        width: 90px;
    }
    .dividend-public-link2{
        padding: 14px 18px;
        margin-top: 0px;
        text-align: center;
        width: 100%;
    }
    .dividend-public-link{
        padding: 15px 19px;
        margin-top: 0px;
        width: 100%;
        text-align: center;
    }
    .cfd-t-sub3 .row .title{
        
    }
    .cfd-t-sub3 .row .desc{
        font-size: 15px;
        padding: 0 0%;
        line-height: 26px;
    }
    .cfd-t-sub5-content{
        width: 100%;
    }
    .cfd-t-sub5 .cfd-t-sub5-img{
        width: 100%;
    }
    .cfd-t-sub5-bottom{
        width: 100%;
        height: 46px;
    }
    .cfd-t-sub5-bottom p{
        line-height: 42px;
        font-size: 14px;
        padding: 0px 20px;
    }
    .cfd-t-sub5-bottom span{
        font-size: 18px;
    }
    .cfd-t-sub5-bottom img{
        width: 6px;
        margin-left: 6px;
    }
    .cfd-t-sub1 .p2{
        width: 100%;
        padding: 8%;
        font-size: 16px;
        line-height: 30px;
    }
    .line{
        width: 62px;
        border: 1px solid #D9D9D9;
        margin: 26px auto 0px;
    }
    .cfd-t-sub1 .p1{
        margin-top: 20px;
        line-height: 58px;
        font-size: 34px;
    }
    .cfd-t-sub1 .btn{
        margin: 14px auto;
    }
    .public-desc{
        width: 90%;
        font-size: 15px;
        line-height: 24px;
    }
    .cfd-t-sub4 .public-desc,.cfd-t-sub5 .public-desc{
        width: 90%;
    }
    .cfd-t-sub2,.cfd-t-sub3,.cfd-t-sub4,.cfd-t-sub5{
        padding: 56px 0;
    }
    .dividend-public-line{
        width: 50px;
        margin: 40px auto 0px;
    }
    .dividend-public-title{
        font-size: 26px;
    }
    .cfd-t-sub2 .row img{
        width: 28px;
    }
    .cfd-t-sub4 img{
        width: 90%;
    }
    .public-slogan{
        padding: 20px 20px;
        line-height: 28px;
    }
    .cfd-t-subtner-box-en .cfd-t-sub2 .row{
        padding: 20px 0% 0px 3%;
    }
    .cfd-t-subtner-box-en .cfd-t-sub2 .row div{
        width: 100%;
        margin-top: 14px;
    }
    .cfd-t-subtner-box-en .cfd-t-sub2 .row div p{
        line-height: 30px;
    }
    .cfd-t-subtner-box-en .cfd-t-sub1 .p1{
        line-height: 44px;
        font-size: 28px;
        width: 90%;
        margin: 20px auto 0;
    }
    .dividend-public-title2{
        line-height: 26px;
    }
    .cfd-t-subtner-box-en .cfd-t-sub5-bottom p{
        font-size: 12px;
        letter-spacing: 0px;
        padding: 0px 8px;
    }
    .cfd-t-subtner-box-en .cfd-t-sub5-bottom span{
        font-size: 16px;
    }
    .cfd-t-subtner-box-en .public-desc{
        width: 90%;
    }
    .cfd-t-subtner-box-en .cfd-t-sub1 .p2{
        width: 100%;
        font-size: 14px;
        line-height: 26px;
    }
    .cfd-t-subtner-box-en .cfd-t-sub2 .row{
        width: 100%;
    }
    .cfd-t-subtner-box-en .cfd-t-sub2 .row img{
        width: 24px;
    }
    .cfd-t-subtner-box-en .cfd-t-sub1 .p1{
        font-size: 26px;
        line-height: 34px;
    }
    .cfd-t-subtner-box-en .dividend-public-title{
        font-size: 22px;
        line-height: 32px;
    }
    .cfd-t-subtner-box-en .public-slogan{
        padding: 16px 0px;
        line-height: 28px;
        font-size: 13px;
    }
    .cfd-t-subtner-box-en .cfd-t-sub2 .row div p{
        line-height: 30px;
        margin-left: 6px;
    }
    .cfd-t-subtner-box-en .cfd-t-sub2 .row div{
        text-align: center;
    }
    .cfd-t-subtner-box-en .cfd-t-sub3 .row p {
        margin-left: 8px;
        display: block;
        line-height: 18px;
        margin-top: 8px;
        font-size: 12px;
    }
    .public-slogan {
        padding: 16px 0px;
        line-height: 28px;
        font-size: 13px;
    }
    .cfd-t-sub1 .p1 br{
        display: block;
    }
    .cfd-t-sub4 img{
        padding: 0;
        background: none;
        margin: 0 auto;
        width: 96%;
    }
    .public-btn{
        margin: 40px auto 0px;
    }
    .cfd-t-sub4 .public-desc{
        margin-top: 30px;
    }
    .cfd-t-sub5 .row div{
        width: 50%;
        margin-bottom: 0px;
    }
    .cfd-t-sub5 .row p{
        font-size: 14px;
        width: 82%;
        margin: 20px auto 0;
        line-height: 22px;
    }
    .cfd-t-sub5 .row img{
        width: 88%;
    }
    .cfd-t-sub6 .row div p{
        font-size: 14px;
    }
    .cfd-t-sub6 .row div{
        margin-bottom: 6px;
        margin-left: 4%;
        padding: 16px 10px 14px 36px;
    }
    .cfd-t-sub6 .row div img{
        top: 13px;
        left: 12px;
    }
    .cfd-t-sub6{
        padding-bottom: 50px;
    }
    .en-style .cfd-t-sub6 .row div{
        width: 92%;
        line-height: 22px;
        height: auto;
        padding: 14px 10px 12px 36px;
    }
    .en-style .cfd-t-sub6 .row div p{
        font-size: 14px;
    }
    .en-style .cfd-t-sub6 .row div img{
        top: 18px;
        left: 12px;
        width: 13px;
    }
    .en-style .dividend-public-title{
        font-size: 23px;
    }
    .en-style .cfd-t-sub1 .p1{
        line-height: 46px;
        font-size: 30px;
        margin-top: 55px;
    }
    .product{
        width: 100%;
    }
    .product .table-list .flex-1:nth-child(4),.product .table-list .flex-1:nth-child(5){
        display: none;
    }
    .accordion-body{
        padding: 0;
    }
    .product-box__nav{
        font-size: 12px;
    }
    .product-box__accordion-item .accordion-button{
        font-size: 12px;
    }
    .accordion-body{
        font-size: 12px;
    }
    .page-screen-3 .problem-box .accordion .accordion-body{
        padding: 1rem 1.25rem;
    }
    .page-screen-3 .problem-box .accordion{
        padding-top: 0px;
    }
    .product .table-list .flex-1{
        font-size: 13px;
    }
    .page-screen-3 .problem-box .accordion{
        margin-bottom: 50px;
    }
    .product .accordion-button:after{
        display: none;
    }
    .product-box__accordion-item .accordion-button{
        padding: 8px 0;
    }
    .page-screen-3{
        padding-bottom: 100px;
    }
    .en-style2 .dividend-public-link2{
        float: unset;
        margin-bottom: 16px;
    }
    .en-style2 .dividend-public-link{
        float: unset;
    }
    .en-style2 .cfd-t-sub3 .row .title{
        line-height: 24px;
    }
    .en-style2 .cfd-t-sub3 .row .desc{
        margin-top: 14px;
    }
    .en-style2 .product{
        margin-top: 30px;
    }
    .en-style2 .dividend-public-title{
        padding: 0 8%;
        line-height: 32px;
    }
    .en-style2 .page-screen-3{
        padding-bottom: 70px;
    }
    .en-style2 .product{
        margin-bottom: 30px;
    }
    .cfd-t-sub1 .btn{
        display: block;
    }
    .cfd-t-sub1 {
        background: url(../assets/image/dividend/cfd-bg-m.jpg) 100%/auto 100% no-repeat;
        height: 520px;
        margin-top: 0px;
        text-align: center;
        padding-top: 110px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
    .cfd-t-sub1 .p1{
        font-size: 27px;
    }
    .dividend-account1{
        padding: 50px 2% 30px;
        /* background: #ffffff; */
    }
    .dividend-account1 .container{
        width: 100%;
    }
    .dividend-account1 .row div{
        width: 33.3%;
    }
    .dividend-account1 .row .title{
        font-size: 17px;
        letter-spacing: 0px;
        white-space: nowrap;
        max-width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 20px;
    }
    .dividend-account1 .row .desc1, .dividend-account1 .row .desc2, .dividend-account1 .row .desc3{
        font-size: 14px;
        line-height: 20px;
        margin-top: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        letter-spacing: 0px;
    }
    .dividend-account1 .row .div1 {
        margin-top: 22px;
        text-align: right;
    }
    .dividend-account1 .btn-box{
        display: none;
    }
    .cfd-t-sub3{
        background: #f8f8f8;
    }
    .cfd-t-sub3 .row{
        background: #f8f8f8;
    }
    .cfd-t-sub3 .row div{
        width: 100%;
    }
    .dividend-public-link, .dividend-public-link2{
        width: 70%;
        text-align: center;
        margin: 20px auto 10px;
        float: unset;
    }
    .btn-box{
        width: 100%;
    }
    .dividend-account2{
        background: #ffffff;
        padding: 60px 3%;
    }
    .dividend-account2 .public-desc{
        width: 100% !important;
        font-size: 16px;
        line-height: 24px;
    }
    .dividend-account2 .row img{
        width: 100%;
        margin: 20px auto 30px;
    }
    .dividend-account3{
        padding: 60px 3%;
        background: #f8f8f8;
    }
    .dividend-account3 .public-desc{
        width: 100% !important;
        font-size: 16px;
        line-height: 24px;
    }
    .dividend-account3 .row img{
        width: 100%;
    }
    
    .dividend-public-line{
        margin: 30px auto 30px;
    }
    .cfd-t-sub3 .dividend-public-line{
        margin-bottom: 0px;
    }
    .en-style2 .cfd-t-sub1 .p2{
        padding-top: 14px;
    }
    .en-style2 .dividend-account1 .row .title{
        font-size: 16px;
    }
    .en-style2 .dividend-public-title{
        font-size: 24px;
    }
    .en-style2 .cfd-t-sub3 .row .desc{
        padding: 0 10%;
    }
    .cfd-t-sub1 .p2{
        padding-top: 12px;
    }
    .dividend-account1 .container{
        padding: 0;
    }
    .dividend-page-screen-1 .page-screen__title__text{
        font-size: 16px;
    }
    .dividend-page-screen-1 .page-screen__title__text:last-child{
        display: none;
    }
    .dividend-page-screen-1 .page-screen__ul__li .text:last-child{
        display: none;
    }
    .dividend-page-screen-1 .page-screen__title__text, .dividend-page-screen-1 .page-screen__ul__li .text{
        font-size: 14px;
    }
    .container-xl{
        padding: 0;
    }
    .dividend-page-screen-1{
        margin-top: 20px;
    }
    .dividend-account1 .btn-box{
        display: block;
    }
    .dividend-account1 .dividend-public-link2{
        margin: 30px auto;
    }
    .trade8{
        padding-top: 0px;
    }
    .en-style2 .cfd-t-sub1 .p1{
        margin-top: 3px;
    }
    .en-style2 .trade8 .title{
        margin-top: 40px;
        line-height: 36px;
        font-size: 24px;
    }
}

@media(max-width:400px) {
    .cfd-t-sub6 .row div p{
        font-size: 13px;
    }
    .cfd-t-sub6 .row div{
        margin-bottom: 6px;
        margin-left: 4%;
        padding: 14px 10px 12px 34px;
        height: 38px;
    }
    .cfd-t-sub6 .row div img{
        top: 13px;
        left: 12px;
        width: 14px;
    }
    .en-style .cfd-t-sub6 .row div{
        width: 92%;
        line-height: 22px;
        height: auto;
    }
    .en-style .cfd-t-sub6 .row div p{
        font-size: 14px;
    }
    .dividend-account1 .row .title{
        font-size: 16px;
    }
}

@media(max-width:390px) {
    .dividend-account1 .row .desc1, .dividend-account1 .row .desc2, .dividend-account1 .row .desc3{
        font-size: 13px;
    }
}




</style>
