<template>
	<div class="never">
		<div class="never-bg">
			<img src="../assets/image/never/bg.png" alt="">
		</div>
		<div class="never-head">
			<div class="head-box flex">
				<div class="head-box__logo">
					<router-link to="/"><img style="width: 74%;" src="../assets/image/No1/Group-19.png"
						 class="img"></router-link>
				</div>
				<div class="head-box__tit flex-1">
					<router-link to="/" class="btns">www.ebcfin.com</router-link>
				</div>
				<div class="head-box__btn flex">
					<a class="left-btn" href="https://client.ebccrm.com/signup/index?hl=en">Free Account</a>
					<a class="right-btn" href="https://client.ebccrm.com/?hl=en">Login</a>
				</div>
			</div>
		</div>
		<div class="never-h1">
			<div class="never-h1__tit">
				<img src="../assets/image/never/f-1.4a680dd-en.png" alt="">
			</div>

			<div class="never-h1__name flex">
				<p class="text">Exceptional Brilliant Care for Every Committed Trader.</p>
			</div>
			<div class="never-h1__info flex">

				<div class="info-box">
					<div class="info-box__h3">
						Premium Benefits
					</div>
					<div class="info-box__h1">
						Swap Free Account
					</div>
					<div class="info-box__h2">
						SWAP-free for all users trading<br />
						the six major currency pairs
					</div>
					<div class="info-box__time">
						「 Till 31th August 2023 」
					</div>
					<a class="info-box__btn flex"
						href="https://client.ebccrm.com/signup/index?utm_source=landingpagewin2022&amp;utm_medium=banner&amp;utm_campaign=ebc">
						Apply Now
					</a>
				</div>
				<div class="info-bg flex-1">
					<img src="../assets/image/never/c-2.74c01e1-en.png" alt="" class="d-none d-md-block">
					<img src="../assets/image/never/c-2.74c01e1-en.png" alt="" class="d-block d-md-none">
				</div>
			</div>
			<div class="never-h1__tit4">
				Swap Free Products Include
			</div>
			<div class="never-h1__img5">
				<img src="../assets/image/LandingPage/c-1.svg" alt="">
			</div>

		</div>
		<div class="never-swiper">

			<div class="box2-line">
				<img src="../assets/image/never/icon.png" alt="">
			</div>
			<div class="never-swiper__box2">
				<div class="box2-top flex">
					<div class="box2-top__li flex-1 flex flex-column">
						<div class="img">
							<img src="../assets/image/never/zj-icon.png" alt="">
						</div>
						<div class="tit">
							SECURITY OF FUNDS
						</div>
					</div>
					<div class="box2-top__li flex-1 flex flex-column">
						<div class="img">
							<img src="../assets/image/never/aq-icon.png" alt="">
						</div>
						<div class="tit">
							RAW SPREADS
						</div>
					</div>
				</div>
				<div class="box2-bot flex">
					<div class="box2-bot__li flex-1">
						<div class="text">
							Client funds are held in world-class banks and are subjected to strict regulation. We offer a variety of safe and convenient deposit and withdrawal methods.
						</div>
						<div class="text">
						</div>
					</div>
					<div class="box2-bot__li flex-1">
						<div class="text">
							We follow BBO (Best Bid Best Offer) trading rules to produce the best offer for our clients
						</div>
						<div class="text">
						</div>
					</div>
				</div>
			</div>
			<div class="never-swiper__box1 d-none d-md-block">
				<div class="box1-btn">
					<a class="btn"
						href="https://client.ebccrm.com/signup/index?utm_source=landingpagewin2022&amp;utm_medium=banner&amp;utm_campaign=ebc">
						Apply Now
					</a>
				</div>
			</div>
			<div class="box2-line d-none d-md-block">
				<img src="../assets/image/never/icon.png" alt="">
			</div>
			<div class="never-swiper__box3">
				<div class="box3-pag flex">
					<div class="box3-pag__left flex flex-column">
						<div class="img">
							<img src="../assets/image/never/aq-icon.png" alt="">
						</div>
						<div class="tit">
							SECURITY OF FUNDS
						</div>
					</div>
					<div class="box3-pag__right flex-1">
						<div class="text">
							Client funds are held in world-class banks and are subjected to strict regulation. We offer a variety of safe and convenient deposit and withdrawal methods.
						</div>
						<div class="text">
						</div>
					</div>
				</div>
				<div class="box3-pag flex">
					<div class="box3-pag__left flex flex-column">
						<div class="img">
							<img src="../assets/image/never/zj-icon.png" alt="">
						</div>
						<div class="tit">
							RAW SPREADS
						</div>
					</div>
					<div class="box3-pag__right flex-1">
						<div class="text">
							We follow BBO (Best Bid Best Offer) trading rules to produce the best offer for our clients
						</div>
						<div class="text">
						</div>
					</div>
				</div>
				<div class="never-swiper__box1">
					<div class="box1-btn">
						<a class="btn"
							href="https://client.ebccrm.com/signup/index?utm_source=landingpagewin2022&amp;utm_medium=banner&amp;utm_campaign=ebc">
							Apply Now
						</a>
					</div>
				</div>
				<div class="box3-line">
					<img src="../assets/image/never/icon.png" alt="">
				</div>
			</div>
			<div class="never-swiper__box1">
				<div class="box1-tit flex">
					<img src="../assets/image/No1/Path-10196.png" alt="">
					<div class="name flex-1">
						Terms and Conditions
					</div>
				</div>
				<div class="box1-info">
					<div class="tit1 flex">
						<div class="tit1-num">
							1.
						</div>
						<div class="tit1-desc flex-1">
							Interest-free products include: EUR/USD, GBP/USD, AUD/USD, USD/CHF, USD/CAD, NZD/USD.
						</div>
					</div>
					<div class="tit1 flex">
						<div class="tit1-num">
							2.
						</div>
						<div class="tit1-desc flex-1">
							Swap Free Campaign: EBC will waive the interest rate for you if the overnight interest rate is negative, and you will still receive the interest rate if the overnight interest rate is positive.
						</div>
					</div>
					<div class="tit1 flex">
						<div class="tit1-num">
							3.
						</div>
						<div class="tit1-desc flex-1">
							Users who participate in this event are not allowed to participate in other EBC events at the same time.
						</div>
					</div>
					<div class="tit1 flex">
						<div class="tit1-num">
							4.
						</div>
						<div class="tit1-desc flex-1">
							The final right to interpret this event rests with EBC and EBC reserves the right to terminate it at any time.
						</div>
					</div>
					<div class="tit1 flex">
						<div class="tit1-num">
							5.
						</div>
						<div class="tit1-desc flex-1">
							Swap Free till 31th August 2023
						</div>
					</div>
				</div>
			</div>

		</div>
		<div class="never-tips flex flex-column">
			<div class="text">
				For more information, please contact our customer service or your dedicated consultant
			</div>
			<div class="text">
				EBC FCA and ASIC authority dual regulation, Dedicated to helping institutional and professional investors succeed
			</div>
		</div>
		<div class="never-country flex flex-column">
			<div class="never-country__ul flex">
				<div class="never-country__ul__li flex-1">
					<div class="img">
						<img src="../assets/image/never/gq1.png" alt="">
					</div>
					<div class="info flex flex-column">
						<div class="text">
							EBC Financial Group (UK) Ltd
						</div>
						<div class="text">
							is authorized and regulated by the Financial Conduct Authority
						</div>
						<div class="text">
							Reference Number: 927552
						</div>
					</div>
				</div>
				<div class="never-country__ul__li flex-1">
					<div class="img">
						<img src="../assets/image/never/gq2.png" alt="">
					</div>
					<div class="info flex flex-column">
						<div class="text">
							EBC Financial Group (Australia) Pty Ltd
						</div>
						<div class="text">
							is authorized and regulated by the Australian Securities and Investments Commission
						</div>
						<div class="text">
							Reference Number: 500991
						</div>
					</div>
				</div>
			</div>
			<div class="never-country__line">
				<img src="../assets/image/never/icon.png" alt="">
			</div>
		</div>
		<div class="never-footer flex flex-column">
			<div class="never-footer-top">
				Only time allows you to know me better
			</div>
			<div class="never-footer-bot">
				Let’s Build The Future
			</div>
		</div>
	</div>
</template>
<!-- Google tag (gtag.js) -->
<!-- <script async src="https://www.googletagmanager.com/gtag/js?id=G-TJ431PDHZX"></script>
<script>
	window.dataLayer = window.dataLayer || [];

	function gtag() {
		dataLayer.push(arguments);
	}
	gtag('js', new Date());
	gtag('config', 'G-TJ431PDHZX');
</script> -->
<script>
	if (process.browser) {
		var {
			WOW
		} = require('wowjs')
	}
	export default {
		metaInfo() {
			return {
				title: 'Free Swap | EBC Financial Group',
				meta: [ // set meta
					{
						name: 'keyWords',
						content: 'EBC,EBC平台,EBC官网,EBC金融官网,EBC开户,EBC金融'
					},
					{
						name: 'description',
						content: 'EBC金融官网是由EBC金融集团EBC Financial Group成立的,主要提供,EBC金融,EBC开户交易服务,只需一个EBC综合平台账户,即可交易多种差价合约（CFD）:衍生品、美股、数字货币、期货指数、贵金属和能源等.'
					}
				]
			}
		},
		data() {
			return {
				current: 0,
				endTimestamp: '1654012799',
				timeDate: {
					day: '000',
					hour: '00',
					minute: '00',
					second: '00'
				},
			}
		},
		mounted() {
			this.$nextTick(() => {
				if (process.browser) { // 在页面mounted生命周期里面 根据环境实例化WOW
					new WOW({
						animateClass: 'animate__animated'
					}).init()
				}
			});
		},
		created() {

		},
		methods: {
			formatTime() {
				const $time = this.endTimestamp - parseInt((+new Date()) / 1000)
				this.timeDate = {
					day: this.zeroFill(Math.floor($time / (3600 * 24)), 2),
					hour: this.zeroFill(Math.floor(($time % (3600 * 24)) / 3600)),
					minute: this.zeroFill(Math.floor((($time % (3600 * 24)) % 3600) / 60)),
					second: this.zeroFill(Math.floor($time % 60))
				}
				setTimeout(() => {
					this.formatTime()
				}, 1000)
			},
			changSw() {
				if (this.current === 0) {
					this.current = 1
				} else if (this.current === 1) {
					this.current = 2
				} else {
					this.current = 0
				}
				setTimeout(() => {
					this.changSw()
				}, 6000)
			},
			zeroFill(num, fixed = 1) {
				const arr = []
				arr.length = fixed
				arr.fill(9)
				return num > arr.join() ? String(num) : '0' + num
			},
			chang(idx) {
				this.current = idx;
			}
		}
	}
</script>

<style scoped>
	.never {
  font-family: 'PingFang SC', 'Helvetica Neue', 'Microsoft YaHei UI', 'Microsoft YaHei', 'Noto Sans CJK SC', Sathu, EucrosiaUPC, Arial, Helvetica, sans-serif;
  background: linear-gradient(to bottom, #3B0909, #921111);
  position: relative;
}

.never-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 720px;
}

.never-head {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: #540000;
  padding: 15px 0;
  z-index: 1000;
}

.never-head .head-box {
  max-width: 1080px;
  margin: auto;
  align-items: center;
}

.never-head .head-box__tit {
  text-align: center;
}

.never-head .head-box__tit .btns {
  color: #a97c50;
  font-family: Times New Roman, Georgia, serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 70px;
}

.never-head .head-box__btn {
  align-items: center;
}

.never-head .head-box__btn .left-btn {
  display: block;
  color: #c49a6c;
  border-radius: 5px;
  font-size: 20px;
  font-family: Noto Sans SC, Helvetica, Arial, Lucida, sans-serif;
  background-color: #760006;
  padding: 0.3em 1em;
  line-height: 1.7em;
}

.never-head .head-box__btn .right-btn {
  margin-left: 20px;
  font-size: 20px;
  font-family: Noto Sans SC, Helvetica, Arial, Lucida, sans-serif !important;
  color: #e3e3e3;
  padding: 0.3em 1em;
  text-decoration: underline;
}

.never-swiper {
  margin: 40px auto;
  max-width: 1080px;
  position: relative;
}

.never-swiper__box1 {
  margin-bottom: 60px;
}

.never-swiper__box1 .box1-tit {
  margin-top: 60px;
  align-items: center;
}

.never-swiper__box1 .box1-tit .name {
  font-size: 42px;
  color: #F7BF83;
  padding-left: 10px;
}

.never-swiper__box1 .box1-tit img {
  width: 32px;
}

.never-swiper__box1 .box1-info {
  text-align: center;
  padding: 40px 0;
  letter-spacing: 6px;
}

.never-swiper__box1 .box1-info .tit {
  color: #fff;
  padding-bottom: 20px;
  font-size: 32px;
  font-weight: 600;
}

.never-swiper__box1 .box1-info .tit1 {
  color: #fff;
  padding-bottom: 40px;
  font-size: 24px;
  font-weight: 400;
  line-height: 48px;
  text-align: left;
}

.never-swiper__box1 .box1-info .tit1-num {
  text-indent: 10px;
  padding-right: 5px;
}

.never-swiper__box1 .box1-info .ps {
  color: #F7BF83;
  padding-bottom: 60px;
  font-size: 26px;
  font-weight: 400;
}

.never-swiper__box1 .box1-btn {
  text-align: center;
}

.never-swiper__box1 .box1-btn .btn {
  cursor: pointer;
  width: 476px;
  height: 108px;
  color: black;
  font-size: 36px;
  line-height: 78px;
  background: url(../assets/image/never/f-3.svg) no-repeat top left;
  background-size: 100% 100%;
}

.never-swiper__box3 {
  display: none;
}

.never-swiper__box2 {
  margin: 80px 0 60px 0;
}

.never-swiper__box2 .box2-top__li {
  align-items: center;
}

.never-swiper__box2 .box2-top__li .img {
  height: 200px;
}

.never-swiper__box2 .box2-top__li .img img {
  height: 100%;
}

.never-swiper__box2 .box2-top__li .tit {
  padding: 50px 0;
  color: #fff;
  font-size: 32px;
  letter-spacing: 6px;
}

.never-swiper__box2 .box2-bot {
  padding: 0px 0 50px;
}

.never-swiper__box2 .box2-bot__li {
  color: #fff;
  text-align: center;
  font-size: 18px;
  letter-spacing: 4px;
  font-weight: 400;
  line-height: 32px;
}

.never-h1 {
  margin: 60px auto;
  max-width: 1080px;
  padding-top: 140px;
}

.never-h1__info {
  position: relative;
  margin-top: 40px;
  z-index: 1;
  align-items: center;
}

.never-h1__info .info-box__h3 {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  letter-spacing: 16px;
}

.never-h1__info .info-box__btn {
  height: 60px;
  color: #450505;
  width: 200px;
  justify-content: center;
  background: url(../assets/image/LandingPage/btn.svg) no-repeat top center;
  background-size: 100% 100%;
}

.never-h1__info .info-box__h1 {
  font-size: 48px;
  font-weight: bold;
  color: #fff;
  letter-spacing: 10px;
  padding: 20px 0;
}

.never-h1__info .info-box__h2 {
  color: #F7DE97;
  font-size: 44px;
  font-weight: 700;
  letter-spacing: 3px;
  line-height: 1.5;
}

.never-h1__info .info-box__time {
  color: #fff;
  font-size: 28px;
  letter-spacing: 4px;
  margin: 40px 0;
  line-height: 50px;
  background: url(../assets/image/LandingPage/c-3.svg) no-repeat top center;
  background-size: 100% 100%;
  display: inline-block;
}

.never-h1__info .info-bg img {
  max-width: 100%;
}

.never-h1__tit {
  text-align: center;
}

.never-h1__tit img {
  width: 70%;
}

.never-h1__card {
  text-align: center;
  padding: 80px 0;
}

.never-h1__card img {
  width: 30%;
}

.never-h1__tit1 {
  color: #e5c07f;
  font-size: 48px;
  text-align: center;
  padding-bottom: 40px;
}

.never-h1__tit2 {
  color: #fff;
  font-size: 48px;
  text-align: center;
  padding-bottom: 30px;
}

.never-h1__tit3 {
  color: #e5c07f;
  font-size: 48px;
  text-align: center;
  padding-bottom: 30px;
}

.never-h1__tit4 {
  color: #e5c07f;
  font-size: 48px;
  text-align: center;
  padding-bottom: 40px;
  padding-top: 80px;
}

.never-h1__img5 {
  text-align: center;
  position: relative;
  z-index: 10;
}

.never-h1__img5 img {
  width: 70%;
}

.never-h1__name {
  justify-content: center;
  font-size: 32px;
  padding-top: 30px;
  padding-bottom: 50px;
}

.never-h1__name .text {
  color: #f7de97;
}

.never-h1__name p {
  color: #F7DE97;
}

.never-tips {
  margin: 0px auto 140px;
  max-width: 1080px;
  align-items: center;
  font-size: 32px;
  font-weight: 500;
  color: #fff;
  line-height: 62px;
}

.never-country {
  margin: auto;
  max-width: 1080px;
}

.never-country__ul {
  padding-bottom: 60px;
  width: 100%;
}

.never-country__ul__li {
  align-items: center;
  color: #ffffff;
  font-size: 20px;
}

.never-country__ul__li .img {
	width: 126px;
    height: 76px;
    margin-right: 3px;
    float: left;
}

.never-country__ul__li .info {
  line-height: 27px;
}

.never-footer {
  padding: 60px 0;
  margin: auto;
  max-width: 1080px;
  align-items: center;
  font-size: 28px;
  color: #fff;
  letter-spacing: 2px;
}

.never-footer-bot {
  color: #F7BF83;
  padding-top: 14px;
  font-size: 32px;
  letter-spacing: 2px;
}

@media (max-width: 500px) {
  .never-head .head-box {
    padding: 0 10px;
  }
  .never-head .head-box__logo {
    flex: 1;
  }
  .never-head .head-box__logo img {
    width: 90%;
  }
  .never-head .head-box__tit {
    display: none;
  }
  .never-head .head-box__btn {
    flex: 1;
    justify-content: right;
  }
  .never-head .head-box__btn .right-btn {
    margin-left: 0;
    font-size: 14px;
  }
  .never-head .head-box__btn .left-btn {
    padding: 0.3em 0.6em;
    font-size: 14px;
  }
  .never-bg {
    width: 100%;
    bottom: 0;
    top: initial;
  }
  .never-swiper {
    margin: 20px 10px;
    position: relative;
  }
  .never-swiper__box1 {
    margin-bottom: 20px;
  }
  .never-swiper__dost {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }
  .never-swiper__dost__pag {
    width: 16px;
    height: 16px;
    background-color: #AB2B2B;
    border-radius: 8px;
    cursor: pointer;
  }
  .never-swiper__dost__pag.active {
    background-color: #F06969;
  }
  .never-swiper__dost__pag:nth-child(2) {
    margin: 0 50px;
  }
  .never-swiper__box1 .box1-tit {
    margin-top: 0px;
    align-items: center;
  }
  .never-swiper__box1 .box1-tit .name {
    font-size: 24px;
    color: #F7BF83;
  }
  .never-swiper__box1 .box1-tit img {
    width: 20px;
    display: none;
  }
  .never-swiper__box1 .box1-info {
    text-align: center;
    padding: 20px 0 0;
    letter-spacing: 0px;
  }
  .never-swiper__box1 .box1-info .tit {
    color: #fff;
    padding-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
  }
  .never-swiper__box1 .box1-info .tit1 {
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
  }
  .never-swiper__box1 .box1-info .ps {
    color: #F7BF83;
    padding-bottom: 20px;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }
  .never-swiper__box1 .box1-btn {
    text-align: center;
  }
  .never-swiper__box1 .box1-btn .btn {
    cursor: pointer;
    width: 250px;
    height: 60px;
    background: url(../assets/image/never/f-3.svg) no-repeat top left;
    background-size: 100% 100%;
    font-size: 18px;
    line-height: 39px;
  }
  .never-swiper__box2 {
    display: none;
  }
  .never-swiper__box2 .box2-top__li {
    align-items: center;
  }
  .never-swiper__box2 .box2-top__li .img {
    height: 100px;
  }
  .never-swiper__box2 .box2-top__li .img img {
    height: 100%;
  }
  .never-swiper__box2 .box2-top__li .tit {
    padding: 50px 0;
    color: #fff;
    font-size: 24px;
    letter-spacing: 6px;
  }
  .never-swiper__box2 .box2-bot {
    padding: 50px 0;
  }
  .never-swiper__box2 .box2-bot__li {
    color: #fff;
    text-align: center;
    font-size: 18px;
    letter-spacing: 4px;
    font-weight: 400;
    line-height: 32px;
  }
  .never-swiper__box3 {
    display: flex;
    margin: 60px 0 0px;
    flex-direction: column;
  }
  .never-swiper__box3 .box3-pag {
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 40px;
  }
  .never-swiper__box3 .box3-pag__left {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .never-swiper__box3 .box3-pag__left .img {
    text-align: center;
  }
  .never-swiper__box3 .box3-pag__left .img img {
    width: 50%;
  }
  .never-swiper__box3 .box3-pag__left .tit {
    color: #F7BF83;
    font-size: 32px;
    padding: 30px 0;
  }
  .never-swiper__box3 .box3-pag__right {
    color: #fff;
    font-size: 18px;
    line-height: 32px;
    width: 100%;
    text-align: center;
  }
  .never-swiper__box3 .box3-line {
    margin: 20px 0;
  }
  .never-h1 {
    margin: 40px 10px;
    padding-top: 100px;
  }
  .never-h1__img5 img {
    width: 100%;
  }
  .never-h1__info {
    margin: 0;
    z-index: 1;
    align-items: center;
    flex-direction: column;
  }
  .never-h1__info .info-box__h3 {
    font-size: 28px;
    font-weight: bold;
    color: #fff;
    letter-spacing: 16px;
  }
  .never-h1__info .info-box__btn {
    height: 45px;
    color: #450505;
    width: 100%;
    justify-content: center;
    background: url(../assets/image/LandingPage/btn.svg) no-repeat top center;
    background-size: 100% 100%;
  }
  .never-h1__info .info-box__h1 {
    font-size: 36px;
    font-weight: bold;
    color: #fff;
    letter-spacing: 10px;
    padding: 20px 0;
  }
  .never-h1__info .info-box__h2 {
    color: #F7DE97;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 3px;
    line-height: 1.5;
  }
  .never-h1__info .info-box__time {
    color: #fff;
    font-size: 18px;
    letter-spacing: 2px;
    margin: 20px 0;
    line-height: 50px;
    background: url(../assets/image/LandingPage/c-3.svg) no-repeat top center;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
  }
  .never-h1__info .info-bg {
    width: 100%;
    padding: 20px 0;
  }
  .never-h1__info .info-bg img {
    max-width: 100%;
  }
  .never-h1__tit1 {
    font-size: 24px;
    padding-bottom: 40px;
  }
  .never-h1__tit2 {
    color: #fff;
    font-size: 24px;
    text-align: center;
    padding-bottom: 30px;
    line-height: 1.5;
  }
  .never-h1__tit3 {
    font-size: 24px;
    padding-bottom: 30px;
  }
  .never-h1__tit4 {
    font-size: 24px;
    padding-top: 20px;
  }
  .never-h1__name {
    justify-content: center;
    font-size: 18px;
    padding-top: 30px;
    flex-direction: column;
    align-items: center;
    line-height: 32px;
  }
  .never-h1__name p {
    color: #D9BC96;
  }
  .never-tips {
    margin: 0px 10px 20px;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    line-height: 32px;
    position: relative;
    z-index: 10;
  }
  .never-tips .text {
    text-align: center;
  }
  .never-country {
    margin: auto 10px;
  }
  .never-country__ul {
    padding-bottom: 20px;
    padding-left: 10px;
    width: 100%;
    flex-direction: column;
  }
  .never-country__ul__li {
    align-items: center;
    color: #ffffff;
    font-size: 20px;
    width: 100%;
  }
  .never-country__ul__li:last-child {
    margin-top: 20px;
  }
  .never-country__ul__li .img {
    width: 90px;
    height: initial;
    margin-right: 6px;
  }
  .never-country__ul__li .info {
    line-height: initial;
    flex: 1;
    font-size: 13px;
  }
  .never-country__ul__li .info .text:nth-child(2) {
    padding: 7px 0 5px;
  }
  .never-footer {
    margin: auto;
    align-items: center;
    font-size: 15px;
    color: #fff;
    letter-spacing: 2px;
    padding: 20px 0;
  }
  .never-footer-bot {
    color: #F7BF83;
    padding-top: 14px;
    font-size: 14px;
    letter-spacing: 2px;
  }
}
.never-h1{
            padding-top: 120px;
        }
        .never-h1__info{
            margin-top: 24px;
        }
        .never-h1__tit img{
            width: 86%;
        }
        .never-h1__info .info-bg img{
            max-width: 86%;
            float: right;
        }
        .never-h1__info .info-box__h3{
            letter-spacing: 1px;
            font-weight: normal;
        }
        .never-h1__info .info-box__h1{
            letter-spacing: 1px;
            font-size: 48px;
        }
        .never-h1__info .info-box__h2{
            letter-spacing: 1px;
            font-size: 34px;
            margin-top: 6px;
            line-height: 1.2;
            font-weight: normal;
        }
        .never-h1__info .info-box__time{
            letter-spacing: 2px;
        }
        .never-h1__info .info-box__btn{
            cursor: pointer;
            width: 266px;
            height: 100px;
            color: #584725;
            font-size: 24px;
            line-height: 86px;
            background: url(../assets/image/never/f-3.7530014.svg) no-repeat 0 0;
            background-size: 100% 100%;
        }
        .never-h1__tit4{
            padding-bottom: 0px;
        }
        .never-swiper__box1 .box1-btn .btn{
            color: #584725;
        }
        .never-swiper__box2 .box2-top__li .tit{
            letter-spacing: 1px;
        }
        .never-swiper__box2 .box2-bot__li{
            letter-spacing: 1px;
            line-height: 29px;
        }
        .never-swiper__box1 .box1-info{
            letter-spacing: 1px;
        }
        .never-swiper__box1 .box1-info .tit1{
            font-size: 22px;
            line-height: 32px;
        }
        .never-tips{
            max-width: 1000px;
            line-height: 38px;
            font-size: 22px;
            text-align: center;
        }
        .never-country__ul__li .img{
            width: 126px;
            height: 76px;
            margin-right: 3px;
            float: left;
        }
        .never-country__ul__li .img img{
            width: 110px;
            display: inline-block;
            margin-top: 19px;
        }
        .never-country__ul__li .info {
            line-height: 28px;
            font-size: 18px;
        }
        .never-country__ul__li:nth-child(2){
            padding-left: 30px;
        }
        .never-footer{
            letter-spacing: 1px;
        }
        .never-footer-bot{
            letter-spacing: 1px;
        }
        @media (max-width: 500px){
            .never-head .head-box__logo img {
                width: 74%;
            }
            .never-h1__tit img{
                width: 88%;
            }
            .never-h1{
                padding-top: 95px;
            }
            .never-h1__name p{
                line-height: 24px;
                font-size: 18px;
                width: 80%;
                text-align: center;
            }
            .never-h1__name{
                padding-top: 15px;
            }
            .never-h1__info .info-box__h3{
                font-size: 18px;
            }
            .never-h1__info .info-box__h1{
                font-size: 30px;
            }
            .never-h1__info .info-box__h2{
                font-size: 20px;
            }
            .never-h1__info .info-box__btn{
                width: 236px;
                margin: 0 auto;
                font-size: 20px;
                line-height: 88px;
            }
            .never-h1__info{
                margin-top: 10px;
            }
            .never-h1__info .info-bg img{
                float: none;
                max-width: 100%;
                padding: 0px 5%;
            }
            .never-swiper__box3 .box3-pag__left .tit{
                font-size: 26px;
            }
            .never-swiper__box3 .box3-pag__right{
                line-height: 30px;
                padding: 0px 3%;
            }
            .never-swiper__box1 .box1-info .tit1{
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 10px;
            }
            .never-tips{
                max-width: 1000px;
                line-height: 26px;
                font-size: 15px;
                text-align: center;
                color: #fff;
                margin: 50px 0;
                padding: 0 4%;
            }
            .never-country__ul__li:nth-child(2){
                padding-left: 0px;
            }
            .never-country__ul__li .img img{
                width: 100px;
            }
            .never-country__ul__li .img{
                width: 120px;
            }
            .never-country__ul__li .info .text:nth-child(2){
                padding: 0;
            }
            .never-country__ul__li .info{
                line-height: 24px;
                font-size: 16px;
                padding-right: 3%;
            }
        }
        @media (max-width: 408px){
            .never-country__ul__li .info{
                font-size: 14px;
            }
        }
</style>