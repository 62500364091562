<template>
  <div class="zero">
    <div class="zero-head">
      <div class="head-box flex">
        <div class="head-box__logo">
          <router-link to="/"><img src="../assets/image/No1/Group-19.png" title=" EBC官网地址 https://www.ebcfin.com"
              class="img"></router-link>
        </div>
        <div class="head-box__tit flex-1">
          <router-link to="/" class="btns">www.ebcfin.com</router-link>
        </div>
        <div class="head-box__btn flex">
          <a class="left-btn" href="https://client.ebccrm.com/signup/index?hl=zh">免费开户</a>
          <a class="right-btn" href="https://client.ebccrm.com/?hl=zh">登录</a>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="zero-video">
        <div class="zero-video__title flex flex-ajcenter flex-column">
          <img src="../assets/image/img/icon-2.svg" class="img">
          <img src="../assets/image/img/icon-9.png" class="img">
        </div>
        <video class="zero-video__box" muted="muted" autoplay="autoplay" loop="loop" id="startvideo" playsinline="true"
          webkit-playsinline="true" :poster="require('../assets/image/img/vedio-trading.gif')">
          <source src="../assets/image/Home/video-1.mp4" type="video/mp4">
          </source>
          <object width="" height="" type="application/x-shockwave-flash" data="myvideo.swf">
            <param name="movie" value="myvideo.swf" />
            <param name="flashvars" value="autostart=true&amp;file=myvideo.swf" />
          </object>
        </video>
      </div>
    </div>
    <div class="content">
      <div class="zero-desc">
        每一个<p>梦想</p>都始于足下，每一步迈进都能成就<p>不平凡</p><br><br>桥水、德邵、文艺复兴 …… <br>他们都从平凡启航，或许最初并不知道自己能成为全球领先的对冲基金，<br>但他们从一开始就知道自己心中的那个<p>梦
        </p><br><br>
        <p>From Zero to Hero</p> 与你，一样<br>
        每个交易员心中都有一个<p>英雄梦</p>
      </div>
      <a href="https://trading.ebfin.com" class="zero-title" @mouseover="getName(2)" @mouseout="getName(1)">
        <img :src="require(`../assets/image/img/over-${name}.png`)" class="img">
      </a>
    </div>
    <div class="zero-modular modular-one">
      <div class="content flex">
        <div class="modular-one__info">
          <div class="zero-modular__tit">
            优势一:轻松实现<p>"毫秒级"</p>跟单
          </div>
          <div class="zero-modular__desc">
            基于MT底层通讯协议
          </div>
          <div class="zero-modular__desc1 flex">
            <img src="../assets/image/img/icon-xx.svg" class="img">免费
            <img src="../assets/image/img/icon-xx.svg" class="img">非ea插件跟单
            <img src="../assets/image/img/icon-xx.svg" class="img">跟单无需打开MT
          </div>
        </div>
      </div>
    </div>
    <div class="zero-modular modular-two">
      <div class="content flex">
        <div class="modular-two__info">
          <div class="zero-modular__tit">
            优势二:<p>"更专业"</p>的信号选择
          </div>
          <div class="zero-modular__desc">
            首次引入基金评级系统。标准差、夏普比、净值余额比...<br>
            专业评级指标将辅助你选择一个真正优质的信号。<br>
            <div class="btns">
              <a href="https://trading.ebfin.com" target="_blank">
                登录社区
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="zero-modular modular-three">
      <div class="content flex">
        <div class="modular-three__info">
          <div class="zero-modular__tit">
            优势三:保护您的<p>"交易隐私"</p>
          </div>
          <div class="zero-modular__desc">
            公私域信号分离，为交易隐私保驾护航；<br>
            公域信号引流，私域信号变现，帮助交易者解决市场转化问题。
          </div>
        </div>
      </div>
    </div>
    <div class="zero-modular modular-four">
      <div class="content flex">
        <div class="modular-four__info">
          <div class="zero-modular__tit">
            优势四:<p>"AI选手"</p>一键筛选信号
          </div>
          <div class="zero-modular__desc">
            根据跟单者风险/收益偏好，一键筛选目标信号，<br>
            轻松复制全球顶级交易策略。
          </div>
        </div>
      </div>
    </div>
    <div class="zero-modular modular-five">
      <div class="content flex">
        <div class="modular-five__info">
          <div class="zero-modular__tit">
            优势五:去伪存真的<p>“交易社区”</p>
          </div>
          <div class="zero-modular__desc">
            集成化交易研究交流社区 ：EBC研究院、<br>
            订单流学习基地、对冲基金研究策略…<br>
            助您在交易之路上保持进步，学习成长。<br><br />
            <div class="btns">
              <a href="https://trading.ebfin.com" target="_blank">
                登录社区
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="zero-modular modular-six">
      <div class="content flex">
        <div class="modular-six__info">
          <div class="zero-modular__tit">
            <p>数百万</p>投资者的智慧结晶，触手可及！<br />
          </div>
          <div class="zero-modular__desc">
            让您的投资之路离专业更近，让您的交易信心更笃定从容！
          </div>
        </div>
      </div>
    </div>
    <div class="zero-footer">
      <div class="content">
        <div class="zero-footer__info flex flex-ajcenter">
          <img src="../assets/image/img/icon-xx.svg" class="img">
          <div class="info-line flex-1">

          </div>
          <div class="info-desc">
            每个认真交易的人都值得被认真对待
          </div>
          <div class="info-line flex-1">

          </div>
          <img src="../assets/image/img/icon-xx.svg" class="img">
        </div>
        <div class="zero-footer__logo flex flex-ajcenter">
          <img src="../assets/image/img/icon-6.svg" class="img">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  if (process.browser) {
    var {
      WOW
    } = require('wowjs')
  }
  export default {
    async asyncData(that) {
      return {

      }
    },
    metaInfo() {
      return {
        title: '春日不迟 于金相识|EBC Financial Group',
        meta: [ // set meta
          {
            name: 'keyWords',
            content: 'EBC,EBC平台,EBC官网,EBC金融官网,EBC开户,EBC金融'
          },
          {
            name: 'description',
            content: 'EBC金融官网是由EBC金融集团EBC Financial Group成立的,主要提供,EBC金融,EBC开户交易服务,只需一个EBC综合平台账户,即可交易多种差价合约（CFD）:衍生品、美股、数字货币、期货指数、贵金属和能源等.'
          }
        ]
      }
    },
    data() {
      return {
        name:1
      }
    },
    mounted() {

    },
    created() {

    },
    methods: {
      getName(name){
        this.name = name
      }
    }
  }
</script>

<style lang="scss">
  .zero {
    background: #ECEBE9;
    padding-top: 146px;

    .content {
      max-width: 1400px;
      margin: auto;
    }

    p {
      display: inline-block;
    }

    &-modular {
      &.modular-one {
        .modular-one__info {
          padding: 200px 50px;
          background: url("../assets/image/img/icon-7.svg")no-repeat top right;
          background-size: auto 100%;
          width: 100%;
        }
      }

      &.modular-two {
        .content {
          justify-content: flex-end;
          background: url("../assets/image/img/icon-8.svg")no-repeat bottom left;
          background-size: auto 100%;
        }
        .modular-two__info {
          padding: 100px 50px;
          .btns{
            justify-content: flex-end;
          }
        }
      }

      &.modular-three {

        .modular-three__info {
          width: 100%;
          background: url("../assets/image/img/icon-3.svg")no-repeat top right;
          background-size: auto 95%;
          padding: 100px 50px;
        }
      }

      &.modular-four {
        .content {
          justify-content: flex-end;
          background: url("../assets/image/img/icon-ai.svg")no-repeat bottom left;
          background-size: auto 90%;
        }
        .modular-four__info {
          padding: 200px 0;
        }
      }

      &.modular-five {
        .modular-five__info {
          padding: 0px 50px 150px;
          width: 100%;
          background: url("../assets/image/img/icon-5.svg")no-repeat top right;
          background-size: auto 100%;

          .btn {
            justify-content: initial;
          }
        }
      }

      &.modular-six {
        .modular-six__info {
          padding: 0px 0 100px 150px;

          .zero-modular__desc {
            margin-top: 20px;
          }
        }
      }

      &__tit {
        color: rgba(38, 7, 6, 1);
        font-size: 32px;
        letter-spacing: 8px;
        margin-top: 40px;
        line-height: 1.2;

        p {
          font-size: 46px;
          color: rgba(187, 140, 75, 1);
        }
      }

      &__desc {
        margin-top: 60px;
        color: rgba(38, 7, 6, 1);
        font-size: 32px;
        letter-spacing: 2px;
        line-height: 1.6;

        .btns {
          display: flex;
          text-align: center;
          a {
            background: url("../assets/image/img/btn.png")no-repeat;
            background-size: 100%;
            width: 210px;
            height: 76px;
            line-height: 50px;
            color: rgba(69, 5, 5, 1);
            font-size: 24px;
            font-weight: 600;
            letter-spacing: 6px;
            margin-top: 40px;
            padding-left: 7px;
          }
        }
      }

      &__desc1 {
        color: rgba(38, 7, 6, 1);
        font-size: 32px;
        letter-spacing: 2px;
        line-height: 1.6;
        align-items: center;
        margin-top: 10px;

        .img {
          margin: 0 10px;
        }
      }
    }


    &-footer {
      margin: 100px 0 0px;
      padding-bottom: 50px;

      &__info {
        .info-desc {
          color: rgba(192, 151, 108, 1);
          font-size: 36px;
          font-weight: 400;
          letter-spacing: 6px;
        }

        .info-line {
          border-bottom: 1.45px solid rgba(163, 123, 80, 1.00);
          margin: 0 20px;
        }
      }

      &__logo {
        margin-top: 20px;
      }
    }

    &-icon {
      margin-top: 140px;
      justify-content: center;
    }

    &-title {
      width: 100%;
      display: block;
      position: relative;
      padding: 0 50px;
      margin-top: 100px;
      img{
        width: 100%;
      }
    }

    &-desc {
      font-size: 30px;
      color: rgba(86, 17, 17, 1);
      line-height: 1.5;
      letter-spacing: 2px;
      padding: 0 100px;

      p {
        font-size: 40px;
        font-weight: bold;
      }
    }

    &-video {
      position: relative;

      &__box {
        width: 100%;
        margin-top: 50px;
      }

      &__title {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        min-height: 100px;
        background: url("../assets/image/img/icon-1.svg")no-repeat;
        background-size: 100%;

        .img {
          max-width: 50%;
          margin-top: 40px;
          &:last-child{
            margin-top: 25px;
            max-width: 20%;
          }
        }
      }
    }

    &-head {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      background-color: rgba(84, 0, 0, 1);
      padding: 15px 0;
      z-index: 1000;

      .head-box {
        max-width: 1400px;
        margin: auto;
        align-items: center;

        &__logo {}

        &__tit {
          text-align: center;

          .btns {
            color: #a97c50;
            font-family: Times New Roman, Georgia, serif;
            font-weight: 700;
            font-size: 22px;
            line-height: 70px;
          }
        }

        &__btn {
          align-items: center;

          .left-btn {
            display: block;
            color: #c49a6c;
            border-radius: 5px;
            font-size: 20px;
            font-family: Noto Sans SC, Helvetica, Arial, Lucida, sans-serif;
            background-color: #760006;
            padding: 0.3em 1em;
            line-height: 1.7em;
          }

          .right-btn {
            margin-left: 20px;
            font-size: 20px;
            font-family: Noto Sans SC, Helvetica, Arial, Lucida, sans-serif !important;
            color: #e3e3e3;
            padding: 0.3em 1em;
            text-decoration: underline
          }
        }
      }
    }
  }

  @media (max-width: 500px) {
    .zero {
      padding-top: 110px;

      &-head {
        .head-box {
          padding: 0 10px;

          &__logo {
            flex: 1;

            img {
              width: 90%;
            }
          }

          &__tit {
            display: none;
          }

          &__btn {
            flex: 1;
            justify-content: flex-end;

            .right-btn {
              margin-left: 0;
              font-size: 14px;
            }

            .left-btn {
              padding: 0.3em 0.6em;
              font-size: 14px;
            }
          }
        }
      }

      &-modular {
        &.modular-one {
          .modular-one__info {
            background: url("../assets/image/img/icon-7.svg")no-repeat top right;
            background-size: auto 60%;
            padding: 50px 10px;
          }
        }

        &.modular-two {


          .content {
            justify-content: flex-end;
            background: url("../assets/image/img/icon-8.svg")no-repeat bottom left;
            background-size: auto 50%;
          }

          .modular-two__info {
            padding:0 10px 50px 10px;
          }
        }

        &.modular-three {
          .modular-three__info {
            width: 100%;
            background: url("../assets/image/img/icon-3.svg")no-repeat top right;
            background-size: auto 60%;
            padding: 20px 10px;
          }
        }

        &.modular-four {
          .content {
            justify-content: flex-end;
            background: url("../assets/image/img/icon-ai.svg")no-repeat top left;
            background-size: auto 70%;
          }
          .modular-four__info {
            padding:0 10px 20px 10px;
            min-width: 50%;
            max-width: 70%;
          }
        }

        &.modular-five {
          .modular-five__info {
            padding: 0px 10px 20px;
            width: 100%;
            background: url("../assets/image/img/icon-5.svg")no-repeat bottom right;
            background-size: auto 50%;

            .btns {
              justify-content: initial;
              a{
                margin-top: 0px;
              }
            }
          }
        }

        &.modular-six {
          .modular-six__info {
            padding: 0px 10px 50px 10px;

            .zero-modular__desc {
              margin-top: 20px;
            }
          }
        }

        &__tit {
          color: rgba(38, 7, 6, 1);
          font-size: 14px;
          letter-spacing: 2px;
          margin-top: 40px;
          line-height: 1.2;
          white-space: nowrap;
          font-weight: 600;
          p {
            font-size: 20px;
            color: rgba(187, 140, 75, 1);
          }
        }

        &__desc {
          margin-top: 20px;
          color: rgba(38, 7, 6, 1);
          font-size: 14px;
          letter-spacing: 2px;
          line-height: 1.6;
          font-weight: 600;
          .btn {
            display: flex;
            justify-content: flex-end;

            a {
              background: url("../assets/image/img/btn.png")no-repeat;
              background-size: 100%;
              width: 180px;
              height: 53px;
              line-height: 45px;
              color: rgba(69, 5, 5, 1);
              font-size: 18px;
              font-weight: 600;
              letter-spacing: 6px;
              margin-top: 40px;
            }
          }
        }

        &__desc1 {
          color: rgba(38, 7, 6, 1);
          font-size: 14px;
          letter-spacing: 2px;
          line-height: 1.6;
          align-items: center;
          margin-top: 10px;
          font-weight: 600;
          .img {
            margin: 0 5px 0 0;
            width: 18px;
            height: 18px;
          }
        }
      }

      &-footer {
        margin: 20px 0 0px;
        padding-bottom: 50px;

        &__info {
          padding: 0 10px;

          .info-desc {
            color: rgba(192, 151, 108, 1);
            font-size: 12px;
            letter-spacing: 0px;
          }

          .info-line {
            border-bottom: 1.45px solid rgba(163, 123, 80, 1.00);
            margin: 0 4px;
          }

          .img {
            width: 14px;
            height: 14px;
          }
        }

        &__logo {
          margin-top: 20px;

          img {
            max-width: 30%;
          }
        }
      }

      &-icon {
        margin-top: 50px;
        justify-content: center;

        img {
          max-width: 30%;
        }
      }

      &-title {
        margin-top: 40px;
        padding: 0 10px;
      }

      &-desc {
        font-size: 14px;
        color: rgba(86, 17, 17, 1);
        line-height: 1.5;
        letter-spacing: 2px;
        padding: 0 10px;

        p {
          font-size: 20px;
          font-weight: bold;
        }
      }

      &-video {
        position: relative;

        &__box {
          width: 100%;
        }

        &__title {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          min-height: 100px;
          background: url("../assets/image/img/icon-1.svg")no-repeat top;
          background-size: 100%;
          .img {
            max-width: 70%;
            margin-top: -30px;
            &:last-child{
              margin-top: 10px;
              max-width: 36%;
            }
          }
        }
      }
    }
  }
</style>
