<template>
	<div class="AllProducts">
		<ss-header mode="Home" bg="rgba(38, 5, 5, 1)"></ss-header>
		<div class="page-screen-1">
			<div class="container-xxl">
				<c-products></c-products>
			</div>
		</div>
		<ss-contact-us mode="all"></ss-contact-us>
		<new-footer></new-footer>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		created() {},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
	.AllProducts {
		.page-screen-1 {
			padding-top: 100px;
		}
	}
</style>
