<template>
	<div class="why-ebc" :class="$t('ManagingYourPortfolio.box-name')">
		<ss-header mode="Home" bg="rgba(38, 5, 5, 1)"></ss-header>
		<div class="about-fth1">
        <p class="p1" v-html="$t('WhyEBC.screen0.title')"></p>
        <img src="../assets/image/TradingAccount/fth1-line.png" alt="">
    </div>

    <div class="about-fth2" id="about-fth2" :style="{top:topNum + 'px'}">
        <a href="javascript:;" id="transmit1" @click="onClick(1)" class="sub-nav" :class="{'about-active':pagIndex == 1}">{{$t('WhyEBC.screen1.link1')}}</a>
        <a href="javascript:;" id="transmit2" @click="onClick(2)" class="sub-nav" :class="{'about-active':pagIndex == 2}">{{$t('WhyEBC.screen1.link2')}}</a>
        <a href="javascript:;" id="transmit3" @click="onClick(3)" class="sub-nav" :class="{'about-active':pagIndex == 3}">{{$t('WhyEBC.screen1.link3')}}</a>
        <a href="javascript:;" id="transmit4" @click="onClick(4)" class="sub-nav" :class="{'about-active':pagIndex == 4}">{{$t('WhyEBC.screen1.link4')}}</a>
        <a href="javascript:;" id="transmit5" @click="onClick(5)" class="sub-nav" :class="{'about-active':pagIndex == 5}">{{$t('WhyEBC.screen1.link5')}}</a>
        <a href="javascript:;" id="transmit6" @click="onClick(6)" class="sub-nav" :class="{'about-active':pagIndex == 6}">{{$t('WhyEBC.screen1.link6')}}</a>
        <a href="javascript:;" id="transmit7" @click="onClick(7)" class="sub-nav" :class="{'about-active':pagIndex == 7}">{{$t('WhyEBC.screen1.link7')}}</a>
    </div>

    <div class="about1 index1">
        <div class="container">
            <p class="about-public-title">{{$t('WhyEBC.screen2.title')}}</p>
            <p class="about-public-line"></p>
            <p class="about-public-desc">{{$t('WhyEBC.screen2.desc1')}}</p>
            <p class="about-public-desc">{{$t('WhyEBC.screen2.desc2')}}</p>
            <p class="about-public-desc">{{$t('WhyEBC.screen2.desc3')}}</p>
        </div>
    </div>

    <div class="about2 index2">
        <div class="container">
            <p class="about-public-title">{{$t('WhyEBC.screen3.title')}}</p>
            <p class="about-public-line" style="margin-bottom: 10px;"></p>
            <p class="about-public-desc-new">{{ $t('WhyEBC.screen3.desc') }}</p>
            <div class="row">
                <div class="col-md-4">
                    <img src="../assets/image/index/index-new-01.svg" alt="">
                    <p class="p2">{{$t('WhyEBC.screen3.desc-l1')}}</p>
                    <p class="p2">{{$t('WhyEBC.screen3.desc-l2')}}</p>
                    <p class="p2">{{$t('WhyEBC.screen3.desc-l3')}}</p>
                </div>
                <div class="col-md-4">
                    <img src="../assets/image/index/index-new-03.svg" alt="">
                    <p class="p2">{{$t('WhyEBC.screen3.desc-rr1')}}</p>
                    <p class="p2">{{$t('WhyEBC.screen3.desc-rr2')}}</p>
                    <p class="p2">{{$t('WhyEBC.screen3.desc-rr3')}}</p>
                </div>
                <div class="col-md-4">
                    <img src="../assets/image/index/index-new-02.svg" alt="">
                    <p class="p2">{{$t('WhyEBC.screen3.desc-r1')}}</p>
                    <p class="p2">{{$t('WhyEBC.screen3.desc-r2')}}</p>
                    <p class="p2">{{$t('WhyEBC.screen3.desc-r3')}}</p>
                </div>
            </div>
        </div>
    </div>


    <div class="about6  index3">
        <div class="container">
            <p class="about-public-title">{{$t('WhyEBC.screen4.title')}}</p>
            <p class="about-public-line"></p>
            <div class="row honor-list">
                <div class="swiper-wrapper slide-box">
                    <div class="col-md-4 swiper-slide" @click="jumPage('LEARN-647-43B')" :title="$t('WhyEBC.screen4.desc1')">
                        <img src="../assets/image/WhyEBC/about-11.png" alt="">
                        <p v-if="$t('WhyEBC.screen4.desc1')">{{$t('WhyEBC.screen4.desc1')}}</p>
                    </div>
                    <div class="col-md-4 swiper-slide" @click="jumPage('LEARN-647-43B')" :title="$t('WhyEBC.screen4.desc2')">
                        <img src="../assets/image/WhyEBC/about-22.png" alt="">
                        <p v-if="$t('WhyEBC.screen4.desc2')">{{$t('WhyEBC.screen4.desc2')}}</p>
                    </div>
                    <div class="col-md-4 swiper-slide" @click="jumPage('LEARN-2DC')" :title="$t('WhyEBC.screen4.desc4')">
                        <img src="../assets/image/WhyEBC/about-33.png" alt="">
                        <p v-if="$t('WhyEBC.screen4.desc4')">{{$t('WhyEBC.screen4.desc4')}}</p>
                    </div>
                    <div class="col-md-4 swiper-slide" @click="jumPage('LEARN-2DC')" :title="$t('WhyEBC.screen4.desc5')">
                        <img src="../assets/image/WhyEBC/about-44.png" alt="">
                        <p v-if="$t('WhyEBC.screen4.desc5')">{{$t('WhyEBC.screen4.desc5')}}</p>
                    </div>
                    <div class="col-md-4 swiper-slide" @click="jumPage('LEARN-2E5')" :title="$t('WhyEBC.screen4.desc3')">
                        <img src="../assets/image/WhyEBC/about-55.png" alt="">
                        <p v-if="$t('WhyEBC.screen4.desc3')">{{$t('WhyEBC.screen4.desc3')}}</p>
                    </div>
                    <div class="col-md-4 swiper-slide" @click="jumPage('LEARN-30-363')" :title="$t('WhyEBC.screen4.desc6')">
                        <img src="../assets/image/WhyEBC/about-66.png" alt="">
                        <p v-if="$t('WhyEBC.screen4.desc6')">{{$t('WhyEBC.screen4.desc6')}}</p>
                    </div>
                    <div class="col-md-4 swiper-slide" @click="jumPage('LEARN-559-34F')" :title="$t('WhyEBC.screen4.desc7')">
                        <img src="../assets/image/WhyEBC/about-77.png" alt="">
                        <p v-if="$t('WhyEBC.screen4.desc7')">{{$t('WhyEBC.screen4.desc7')}}</p>
                    </div>
                    <div class="col-md-4 swiper-slide" @click="jumPage('LEARN-486-3D7')" :title="$t('WhyEBC.screen4.desc8')">
                        <img src="../assets/image/WhyEBC/about-88.png" alt="">
                        <p v-if="$t('WhyEBC.screen4.desc8')">{{$t('WhyEBC.screen4.desc8')}}</p>
                    </div>
                </div>    
            </div>
        </div>
    </div>


    <div class="about3 index4">
        <div class="container">
            <p class="about-public-title">{{$t('WhyEBC.screen5.title')}}</p>
            <p class="about-public-line"></p>
            <div class="row">
                <div class="col-md-4">
                    <span>1</span>
                    <p class="title" v-html="$t('WhyEBC.screen5.list[0].title')"></p>
                    <p class="desc">{{$t('WhyEBC.screen5.list[0].desc')}}</p>
                </div>
                <div class="col-md-4">
                    <span>2</span>
                    <p class="title" v-html="$t('WhyEBC.screen5.list[1].title')"></p>
                    <p class="desc">{{$t('WhyEBC.screen5.list[1].desc')}}</p>
                </div>
                <div class="col-md-4">
                    <span>3</span>
                    <p class="title" v-html="$t('WhyEBC.screen5.list[2].title')"></p>
                    <p class="desc">{{$t('WhyEBC.screen5.list[2].desc')}}</p>
                </div>
                <div class="col-md-4">
                    <span>4</span>
                    <p class="title" v-html="$t('WhyEBC.screen5.list[3].title')"></p>
                    <p class="desc">{{$t('WhyEBC.screen5.list[3].desc')}}</p>
                </div>
                <div class="col-md-4">
                    <span>5</span>
                    <p class="title" v-html="$t('WhyEBC.screen5.list[4].title')"></p>
                    <p class="desc">{{$t('WhyEBC.screen5.list[4].desc')}}</p>
                </div>
                <div class="col-md-4">
                    <span>6</span>
                    <p class="title" v-html="$t('WhyEBC.screen5.list[5].title')"></p>
                    <p class="desc">{{$t('WhyEBC.screen5.list[5].desc')}}</p>
                </div>
            </div>
        </div>
    </div>



    <div class="about5  index5">
        <div class="container">
            <p class="about-public-title">{{$t('WhyEBC.screen6.title')}}</p>
            <p class="about-public-line"></p>
            <p class="about-public-desc" v-html="$t('WhyEBC.screen6.desc')"></p>
            <div class="row">
                <div class="col-md-4">
                    <img src="../assets/image/WhyEBC/about-icon-01.svg" alt="">
                    <p class="title">{{$t('WhyEBC.screen6.list[0].title')}}</p>
                    <p class="desc" v-html="$t('WhyEBC.screen6.list[0].desc')"></p>
                </div>
                <div class="col-md-4">
                    <img src="../assets/image/WhyEBC/about-icon-02.svg" alt="">
                    <p class="title">{{$t('WhyEBC.screen6.list[1].title')}}</p>
                    <p class="desc" v-html="$t('WhyEBC.screen6.list[1].desc')"></p>
                </div>
                <div class="col-md-4">
                    <img src="../assets/image/WhyEBC/about-icon-03.svg" alt="">
                    <p class="title">{{$t('WhyEBC.screen6.list[2].title')}}</p>
                    <p class="desc" v-html="$t('WhyEBC.screen6.list[2].desc')"></p>
                </div>
                <div class="col-md-4">
                    <img src="../assets/image/WhyEBC/about-icon-04.svg" alt="">
                    <p class="title">{{$t('WhyEBC.screen6.list[3].title')}}</p>
                    <p class="desc" v-html="$t('WhyEBC.screen6.list[3].desc')"></p>
                </div>
                <div class="col-md-4">
                    <img src="../assets/image/WhyEBC/about-icon-05.svg" alt="">
                    <p class="title">{{$t('WhyEBC.screen6.list[4].title')}}</p>
                    <p class="desc" v-html="$t('WhyEBC.screen6.list[4].desc')"></p>
                </div>
                <div class="col-md-4">
                    <img src="../assets/image/WhyEBC/about-icon-06.svg" alt="">
                    <p class="title">{{$t('WhyEBC.screen6.list[5].title')}}</p>
                    <p class="desc" v-html="$t('WhyEBC.screen6.list[5].desc')"></p>
                </div>
            </div>
        </div>
    </div>


    <div class="about4  index6">
        <div class="container">
            <p class="about-public-title">{{$t('WhyEBC.screen7.title')}}</p>
            <p class="about-public-line"></p>
            <div class="row">
                <img class="img-pc" src="../assets/image/WhyEBC/about-04-partner.png" alt="">
                <img class="img-m" src="../assets/image/WhyEBC/about-04-partner-m.png" alt="">
            </div>
        </div>
    </div>
    

    <div class="about7  index7">
        <div class="container">
            <p class="about-public-title">{{$t('WhyEBC.screen8.title')}}</p>
            <p class="about-public-line"></p>
            <div class="row">
                <div class="col-md-4">
                    <p>{{$t('WhyEBC.screen8.desc1')}}： <a href="mailto:mkt@ebc.com">mkt@ebc.com</a></p>
                </div>
                <div class="col-md-4">
                    <p>{{$t('WhyEBC.screen8.desc2')}}： <a href="mailto:careers@ebc.com">careers@ebc.com</a></p>
                </div>
                <div class="col-md-4">
                    <p>{{$t('WhyEBC.screen8.desc3')}}：<a href="mailto:cs@ebc.com">cs@ebc.com</a></p>
                </div>
            </div>
        </div>
    </div>
    
	<page-slogan></page-slogan>
	<div class="about-contact-us">
		<ss-contact-us mode="all"></ss-contact-us>
	</div>
	
				<new-footer></new-footer>
	</div>
</template>

<script>
  import 'swiper/css/swiper.css'
  import Swiper from 'swiper'
	import WOW from 'wowjs';
	export default {
		data() {
			return {
				seoInfo: "",
				pagIndex: 0,
				topNum: 0,
        swiper: null,
				num: 3
			}
		},
		metaInfo() {
			return {
				title: this.seoInfo.title || '',
				meta: [ // set meta
					{
						name: 'keyWords',
						content: this.seoInfo.keyWords || ''
					},
					{
						name: 'description',
						content: this.seoInfo.description || ''
					}
				]
			}
		},
		destroyed() {
			window.removeEventListener('scroll', this.scroll, false)
		},
		created() {
			this.$getSeoInfo({
				lang: this.$store.state.Lan,
				path: "/about-us"
			}).then(res => {
				this.seoInfo = res
			})
			$('html,body').animate({
				scrollTop: 0
			}, 0);
		},
		mounted() {
			this.topNum = $(".index1").offset().top
			let wow = new WOW.WOW({
				boxClass: 'wow',
				animateClass: 'animated',
				offset: 0,
				mobile: true,
				live: false
			});
			wow.init();
			window.addEventListener('scroll', this.scroll)
      if (!this.isMobile()) {
          this.swiper = new Swiper('.honor-list', {
          lazy: {
            loadPrevNext: true, // 默认false情况下swiper只加载当前slide的图片，其他图片不加载，设置为true后，swiper会提前加载下一个slide的图片
            loadPrevNextAmount: 2 // 默认为1，设置在延迟加载图片时，提前多少个slide
          },
          zoom: { // 开启缩放功能，双击slide会放大/缩小，并且在手机端可双指触摸缩放
            maxRatio: 3, // 默认3，设置缩放的最大放大比例，如果要在单个slide设置放大比例，可以在其上添加data-swiper-zoom="3"
            minRatio: 2, // 默认为1，最小缩放焦距（缩小倍率）
            toggle: true // 默认为true，是否允许双击缩放，为false时，不允许双击缩放，只允许手机端触摸缩放
          },
          navigation: { // 如果需要前进后退按钮
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
          scrollbar: { // 如果需要滚动条
            el: '.swiper-scrollbar',
            hide: true // 滚动条是否自动隐藏，默认false
          },
          slidesPerView: 4,
          spaceBetween: 60,
          centeredSlides: true,
          mousewheel: false, // 是否开启鼠标滚轮控制swiper切换 ，默认false
          direction: 'horizontal', // 滑动方向
          speed: 6000, // 切换速度，自动滑动开始到结束的时间
          grabCursor: false, // 悬浮时鼠标样式切换
          centerInsufficientSlides: true, // 当slide总数小于slidesPerView时，slide居中
          effect: 'slide', // slide的切换效果，默认为'slide'位移切换，'fade'淡入，'cube'方块，'coverflow'3d流，'flip'3d翻转，'cards'卡片式，'creative'创意性
          cubeEffect: { // cube效果参数
            slideShadows: false, // 是否开启slide投影，默认true
            shadow: false, // 是否开启投影，默认true
            shadowOffset: 20, // 投影距离。默认 20，单位px。
            shadowScale: 1 // 投影缩放比例。默认0.94。
          },
          // autoplay: true, // 启动自动切换，等同于以下设置
          autoplay: {
            delay: 0,
            disableOnInteraction: false, // 用户操作之后，是否禁止autoplay，默认true，操作包括触碰，拖动，点击pagination
            waitForTransition: true // 是否等待过渡完成，再开始自动切换的计时，默认true
          },
          loop: true // 循环模式选项
        })
        // 6.6.2之前的版本需要通过代码实现此功能；开启后，鼠标置于swiper时暂停自动切换，离开时回复自动切换
        this.swiper.el.onmouseover = function() { // 鼠标覆盖停止自动切换
          this.swiper.autoplay.stop()
        }
        this.swiper.el.onmouseout = function() { // 鼠标离开开始自动切换
          this.swiper.autoplay.start()
        }
      }else{
        this.swiper = new Swiper('.honor-list', {
          lazy: {
            loadPrevNext: true, // 默认false情况下swiper只加载当前slide的图片，其他图片不加载，设置为true后，swiper会提前加载下一个slide的图片
            loadPrevNextAmount: 2 // 默认为1，设置在延迟加载图片时，提前多少个slide
          },
          zoom: { // 开启缩放功能，双击slide会放大/缩小，并且在手机端可双指触摸缩放
            maxRatio: 3, // 默认3，设置缩放的最大放大比例，如果要在单个slide设置放大比例，可以在其上添加data-swiper-zoom="3"
            minRatio: 2, // 默认为1，最小缩放焦距（缩小倍率）
            toggle: true // 默认为true，是否允许双击缩放，为false时，不允许双击缩放，只允许手机端触摸缩放
          },
          navigation: { // 如果需要前进后退按钮
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
          scrollbar: { // 如果需要滚动条
            el: '.swiper-scrollbar',
            hide: true // 滚动条是否自动隐藏，默认false
          },
          slidesPerView: 1.6,
				  spaceBetween: 50,
				  centeredSlides: true,
          mousewheel: false, // 是否开启鼠标滚轮控制swiper切换 ，默认false
          direction: 'horizontal', // 滑动方向
          speed: 6000, // 切换速度，自动滑动开始到结束的时间
          grabCursor: false, // 悬浮时鼠标样式切换
          centerInsufficientSlides: true, // 当slide总数小于slidesPerView时，slide居中
          effect: 'slide', // slide的切换效果，默认为'slide'位移切换，'fade'淡入，'cube'方块，'coverflow'3d流，'flip'3d翻转，'cards'卡片式，'creative'创意性
          cubeEffect: { // cube效果参数
            slideShadows: false, // 是否开启slide投影，默认true
            shadow: false, // 是否开启投影，默认true
            shadowOffset: 20, // 投影距离。默认 20，单位px。
            shadowScale: 1 // 投影缩放比例。默认0.94。
          },
          // autoplay: true, // 启动自动切换，等同于以下设置
          autoplay: {
            delay: 0,
            disableOnInteraction: false, // 用户操作之后，是否禁止autoplay，默认true，操作包括触碰，拖动，点击pagination
            waitForTransition: true // 是否等待过渡完成，再开始自动切换的计时，默认true
          },
          loop: true // 循环模式选项
        })
        // 6.6.2之前的版本需要通过代码实现此功能；开启后，鼠标置于swiper时暂停自动切换，离开时回复自动切换
        this.swiper.el.onmouseover = function() { // 鼠标覆盖停止自动切换
          this.swiper.autoplay.stop()
        }
        this.swiper.el.onmouseout = function() { // 鼠标离开开始自动切换
          this.swiper.autoplay.start()
        }
      }
		},
		methods: {
			async scroll() {
				const elOffsetTop = document.getElementById('about-fth2').offsetTop
				const docScrollTop = document.documentElement.scrollTop

				if (docScrollTop <= ($(".index1").offset().top - 81)) {
					this.topNum = $(".index1").offset().top - docScrollTop
					this.pagIndex = 0
				} else {
					this.topNum = 76.5
				}

				// if(docScrollTop >= 480 && docScrollTop < $(".index1").offset().top-170){
				// 	this.pagIndex = 1
				// }

				if(docScrollTop >= $(".index1").offset().top-80 && docScrollTop < $(".index2").offset().top-170){
					this.pagIndex = 1
				}

				if(docScrollTop >= $(".index2").offset().top-170 && docScrollTop < $(".index3").offset().top-170){
					this.pagIndex = 2
				}

				if(docScrollTop >= $(".index3").offset().top-170 && docScrollTop < $(".index4").offset().top-170){
					this.pagIndex = 3
				}

				if(docScrollTop >= $(".index4").offset().top-170 && docScrollTop < $(".index5").offset().top-170){
					this.pagIndex = 4
				}

				if(docScrollTop >= $(".index5").offset().top-170 && docScrollTop < $(".index6").offset().top-170){
					this.pagIndex = 5
				}

				if(docScrollTop >= $(".index6").offset().top-170 && docScrollTop < $(".index7").offset().top-170){
					this.pagIndex = 6
				}

				if(docScrollTop >= $(".index7").offset().top-170 && docScrollTop < $(".contact-us").offset().top-170){
					this.pagIndex = 7
				}

				if(docScrollTop >= $(".contact-us").offset().top-170){
					this.pagIndex = 0
				}

			},
			changTab(idx) {
				this.tabIndex = idx
			},
			onClick(index) {
				let sectionTop = 150
				if(index == 1){
					sectionTop = 79
				}
				$('html,body').animate({
					scrollTop: $(".index" + index).offset().top - sectionTop
				}, 0);
			},
      isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				return flag;
			},
      jumPage(id) {
				this.$router.push({
					name: 'LearningCenterInfo',
					query: {
						id: id
					}
				})
			},
		}
	}
</script>

<style>
	@charset "UTF-8";
	.c-footer .fp-overflow{
		display: unset !important;
	}
@font-face{
    font-family: 'Baskerville Old Face';
    src : url('../assets/font/BaskervilleOldFace.ttf');
    font-style: normal;
    font-display: swap;
}
.about-fth1{
  background: url(../assets/image/WhyEBC/about-01-bg.png) 100%/auto 100% no-repeat;
  height: 500px;
  margin-top: 0px;
  text-align: center;
  padding-top: 188px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.about-fth1 .p1{
  font-size: 38px;
  color: #FFFFFF;
  font-weight: 700;
  letter-spacing: 4px;
  line-height: 66px;
}
.about-fth1 .p1 span{
  font-family: 'Baskerville Old Face';
  font-weight: normal;
  font-size: 48px;
  letter-spacing: 2px;
}
.about-fth1 .p2{
  font-size: 16px;
  color: #e6e6e6;
  letter-spacing: 2px;
  margin-top: 16px;
  width: 37%;
  margin: 0 auto;
  line-height: 30px;
}
.about-fth2-lock{
  position: fixed;
  right: 0;
  left: 0;
  top: 80px;
  z-index: 999;
}
.about-fth1 .p3{
  letter-spacing: 2px;
  opacity: 0.2;
  font-family: 'Baskerville Old Face';
  font-size: 46px;
  margin-top: 24px;
  color: #FFFFFF;
}
.about-fth1 img{
  margin-top: 16px;
  width: 300px;
  margin-bottom: 14px;
}
.about-fth2{
  background: #fff;
  box-shadow: 0px 2px 10px rgba(61, 1, 1, 0.08);
  height: 74px;
  text-align: center;
  padding-top: 30px;
  position: fixed;
				left: 0;
				right: 0;
				z-index: 10;
				top: 81px;
}
.about-fth2 a{
  padding: 8px 24px;
  margin: 0px 8px;
  border: 1px solid #4D0101;
  color: #4D0101;
  font-size: 16px;
}








.about-contact-us .fca7{
  background: url(../assets/image/WhyEBC/about-06-bg.png) 100%/auto 100% no-repeat;
  height: 880px;
  background-position: center center;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.rotate {
  transition: all 0.2s ease-in-out;
  transform: rotate(-90deg);
}
.rotate-leave{
  transition: all 0.2s ease-in-out;
  transform: rotate(+90deg);
}



.about-public-title{
    color: #3D0101;
    text-align: center;
    font-size: 38px;
    font-weight: 700;
    letter-spacing: 2px;
}
.about-public-line{
    width: 70px;
    border: 1px solid #AF8147;
    margin: 36px auto 46px;
}
.about-public-title2{
    color: #3D0101;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}
.about-public-desc{
    width: 70%;
    color: #666666;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 34px;
    margin: 18px auto 0;
    letter-spacing: 1px;
}


.about1{
  background: url(../assets/image/WhyEBC/about-02-bg.png) 100%/auto 100% no-repeat;
  height: 700px;
  margin-top: 0px;
  text-align: center;
  padding-top: 170px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.about2{
  background: url(../assets/image/WhyEBC/about-03-bg.png) 100%/auto 100% no-repeat;
  height: 660px;
  margin-top: 0px;
  text-align: center;
  padding-top: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.about2 .about-public-title{
  color: #ffffff;
}
.about2 .about-public-line{
  margin: 36px auto 70px;
}

.about2 img {
    width: 140px;
    height: auto;
    display: inline-block;
    margin-bottom: 14px;
}
.about2 .container{
  width: 1200px;
}
.about2 .p2 {
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #ffffff;
    margin-left: 20px;
    width: auto;
    line-height: 29px;
    opacity: 0.9;
}
.about2 .row div{
  text-align: center;
}

.about3{
  padding: 100px 0 50px;
  background: url(../assets/image/WhyEBC/about-10-bg.png) 100%/auto 100% no-repeat;
  height: auto;
  margin-top: 0px;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.about3 .row div{
  position: relative;
  text-align: center;
  margin: 80px 0 90px;
}
.about3 .row span{
  font-size: 270px;
  font-family: 'Baskerville Old Face';
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  opacity: 0.15;
  position: absolute;
  top: 42px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.about3 .row .title{
  color: #ffffff;
  font-weight: 900;
  letter-spacing: 1px;
  font-size: 48px;
}

.about3 .row .desc{
  color: rgba(255, 255, 255, 0.70);
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  margin-top: 20px;
  letter-spacing: 2px;
  padding: 0 8%;
}
.about3 .row .title i{
  font-size: 30px;
}

.about4{
  background: url(../assets/image/WhyEBC/about-04-bg.png) 100%/auto 100% no-repeat;
  height: 600px;
  margin-top: 0px;
  text-align: center;
  padding-top: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.about4 .about-public-title{
  color: #ffffff;
}

.about4 img {
    width: 60%;
    height: auto;
    margin: 0 auto;
}

.about5{
  padding: 100px 0 140px;
}

.about5 .about-public-line{
  margin-bottom: 30px;
}
.about5 .about-public-desc{
  margin-top: 0px;
  color: #949699;
  letter-spacing: 1px;
}
.about5 .about-public-desc span{
  color: #3D0101;
}
.about5 .row div{
  text-align: center;
}
.about5 .row img{
  width: 130px;
  opacity: 0.5;
}
.about5 .row div{
  margin: 80px 0 0;
}
.about5 .row .title{
  color: #2D0101;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 28px;
  margin: 20px 0 16px;
}
.about5 .row .desc{
  font-size: 16px;
  color: #666;
  line-height: 28px;
  letter-spacing: 1px;
  padding: 0 8%;
}


.about6{
  margin-top: 0px;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 90px;
}
.about6 .container{
  width: 1200px;
}
.about6 .about-public-title{
  color: #3D0101;
}
.about6 .row img{
  width: 260px;
}
.about6 .row p{
  color: #333333;
  font-size: 16px;
  line-height: 24px;
}
.about6 .row .img11 {
    width: 200px;
    margin: 35px auto 20px;
}
.about6 .row .img33 {
    width: 90px;
    margin: 22px auto 20px;
}
.about6 .row .img55 {
    width: 140px;
    margin: 35px auto 20px;
}
.about6 .row span{
    color: #3D0101;
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 10px;
    display: inline-block;
    line-height: 22px;
}

.about7{
  padding: 100px 0 140px;
}
.about7 .about-public-line{
  margin-bottom: 60px;
}
.about7 .container{
  width: 1100px;
}
.about7 .row div{
  text-align: center;
}
.about7 .row p{
  font-size: 18px;
  color: #666;
}
.about7 .row p a{
  color: #666;
}

.about-active {
	background-color: #4D0101;
	color: #ffffff !important;
}


.public-slogan{
    padding: 28px 0px;
    background: #F8F8F8;
    color: #3D0101;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    font-weight: 600;
}

.about4 .img-m{
  display: none;
}

.en-style .about-public-title{
  letter-spacing: 0px;
}
.en-style .about-public-desc{
  letter-spacing: 0px;
  width: 76%;
}
.en-style .about1{
  height: 730px;
}
.en-style .about2 .p2{
  letter-spacing: 0px;
}
.en-style .about3 .row .desc{
  letter-spacing: 0px;
}
.en-style .about5 .row .desc{
  letter-spacing: 0px;
  padding: 0 10%;
}
.en-style .about6 .row p{
  letter-spacing: 0px;
}
.en-style .about-contact-us .fca7 .form-label{
  letter-spacing: 0px;
}
.en-style .about-contact-us .fca7 .d1 .p1{
  letter-spacing: 0px;
}
.en-style .about-contact-us .fca7 .d1 .p3{
  letter-spacing: 0px;
}

.vi-style .about1{
  height: 780px;
}

.vi-style .about2 .p2{
  width: 68%;
  line-height: 25px;
}

.vi-style .about2 .container{
  width: 1240px;
}
.vi-style .about2 img{
  width: 146px;
}

.en-style .about7 .row p{
  line-height: 30px;
}

.about3 .about-public-title{
  color: #ffffff;
}

.about-contact-us .fca7 .form-box{
	background: linear-gradient(90deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.39) 100%);
}

.about6 .honor-list{
  overflow: hidden;
  padding-right: 22px;
  height: auto;
}

.about6 .honor-list .swiper-slide{
  cursor: pointer;
}

.about6 .row img{
  margin: 0px auto 30px;
}

.about6 .swiper-button-prev {
    left: -30px;
    background-image: url(../assets/image/WhyEBC/about-arrow-left.svg);
    width: 22px;
    height: 44px;
    background-size: 90%;
    background-repeat: no-repeat;
    top: 62%;
}

.about6 .swiper-button-next {
    right: -30px;
    background-image: url(../assets/image/WhyEBC/about-arrow-right.svg);
    width: 22px;
    height: 44px;
    background-size: 90%;
    background-repeat: no-repeat;
    top: 62%;
}
.about6 .container{
  position: relative;
}
.about6 .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after{
  content: "";
}
.about6 .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
  content: "";
}
.kr-style .about2 .container{
  width: 1260px;
  max-width: 1260px;
}

.honor-list .swiper-wrapper{
  -webkit-transition-timing-function: linear;
  -moz-transition-timing-function: linear;
  -ms-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}
.about-public-desc-new{
    width: 90%;
    color: #fff;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    margin: 30px auto 90px;
    letter-spacing: 0px;
    opacity: 0.9;
}



@media(min-width:2000px) {
    .fca1{
      height: 29vh;
      padding-top: 11vh;
    }
    .fca2 .container,.fca3 .container,.fca4 .container,.fca5 .container,.fca6 .container{
      max-width: 70%;
    }
}

@media(max-width:1600px) {
  .about-fth2-lock{
    top: 74.5px;
  }
  .vi-style .about-fth2 a{
    padding: 8px 10px;
  }
}

@media(max-width:1400px) {
    .about-fth2-lock{
      top: 74.5px;
    }
    .fca2 .d1{
      padding-left: 9%;
    }
    .about-fth1 {
        background: url(../assets/image/WhyEBC/about-01-bg.png) 100%/auto 100% no-repeat;
        height: 400px;
        margin-top: 0px;
        text-align: center;
        padding-top: 150px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
    .about-fth1 .p1{
      font-size: 34px;
      line-height: 56px;
    }
    .about-fth1 .p1 span{
      font-size: 38px;
    }
}

@media(max-width:1300px) {
    .fca2 p{
      width: 100%;
    }
    .about6 .swiper-button-prev {
      left: -5px;
    }
    .about6 .swiper-button-next {
      right: -5px;
    }
}
@media(max-width:1200px) {
  

}

@media(max-width:995px) {
  .kr-style .about2 .container{
    width: 100%;
    max-width: 100%;
  }
  .about2 .container{
    width: 100%;
  }
  .about6 .container{
    width: 100%;
  }
  .about7 .container{
    width: 100%;
  }
  .about-fth2{
    display: none;
  }
  .public-slogan{
        padding: 20px 20px;
        line-height: 28px;
    }
  .about-fth1 .p1 {
    padding: 0 5%;
  }
  .profile-name {
      font-size: 40px;
      top: 19.5%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  .about-fth1{
    background: url(../assets/image/WhyEBC/about-01-bg-m.png) 100%/auto 100% no-repeat;
    height: 500px;
    margin-top: 0px;
    text-align: center;
    padding-top: 146px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .about-fth1 .p3{
    font-size: 28px;
    opacity: 0.3;
  }
  .about-fth1 .p1{
    font-size: 30px;
  }

  .home-header .navbar-brand img{
    width: 120px;
    height: auto;
  }
    .fca1{
      background: url(../assets/image/WhyEBC/about-01-bg-m.png) 100%/auto 100% no-repeat;
      height: 280px;
      background-position: center center;
          padding-top: 118px;
    }
    .fca1 p{
        font-size: 28px;
    }
    .fca1 img {
      margin-top: 22px;
      width: 150px;
    }

    .contact-us {
        height: auto;
        padding-bottom: 64px
    }

    .contact-us .form-bg {
        width: 100%;
        display: block;
        margin: 0;
        border: none
    }

    .contact-us .form-box {
        height: auto;
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
        border: none;
        padding: 40px 8%;
        margin-top: -30px
    }

    .contact-us .form-box:after {
        display: none
    }

    .contact-us .form-box .form-btn {
        position: relative;
        left: 0;
        top: 0;
        margin-top: 60px
    }

    .contact-us .form-box .row {
        margin: 0
    }

    .contact-us .form-box .row>* {
        padding: 0
    }

    .contact-us .form-box .mt110 {
        margin-top: 43px
    }

    .contact-us .form-box .col-md-3 {
        margin-top: 12px;
        margin-right: 20px;
        width: 62px;
        height: 28px
    }

    .contact-us .form-box .select-label {
        height: 100%
    }

    .contact-us .form-box .form-label {
        font-size: 16px;
        margin-top: 32px
    }


   
    .about-contact-us .fca7 {
      background: url(../assets/image/WhyEBC/about-06-bg.png) 100%/auto 100% no-repeat;
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    .about-fth1 .p1{
      margin-top: 40px;
      line-height: 58px;
      font-size: 36px;
    }

    .en-style .about-fth1 .p1{
      font-size: 32px;
      padding: 0 10%;
      line-height: 44px;
    }

    .public-slogan {
        padding: 16px 0px;
        line-height: 28px;
        font-size: 14px;
    }

    .about-fth1 .p1{
      font-size: 28px;
      letter-spacing: 2px;
    }
    .about-fth1 .p1 span{
      font-size: 28px;
      letter-spacing: 1px;
      font-family: 'Noto Serif SC';
      font-weight: 700;
    }
    .about-public-title{
      letter-spacing: 1px;
      font-size: 30px;
    }
    .about1{
      background: none;
      height: auto;
      padding: 60px 0;
    }
    .about-public-desc{
      width: 90%;
      color: #999;
      font-size: 16px;
      line-height: 28px;
      text-align: left;
      margin-top: 10px;
    }
    .about-public-line{
      width: 50px;
      margin: 30px auto 30px;
    }
    .about2{
      background: url(../assets/image/WhyEBC/about-03-bg-m.png) 100%/auto 100% no-repeat;
      height: auto;
      margin-top: 0px;
      text-align: center;
      padding-top: 60px;
      padding-bottom: 10px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    .about2{
      text-align: center;
    }
    .about2 img{
      float: unset;
      width: 100px;
      margin-bottom: 12px;
    }
    .about2 .p2{
      float: unset;
      text-align: center;
      font-family: 'Noto Serif SC';
      line-height: 28px;
      margin-left: 0px;
      font-size: 14px;
    }
    .about2 .about-public-line{
      margin: 30px auto 40px;
    }
    .about2 .row div{
      margin-bottom: 50px;
    }
    .about3{
      padding: 60px 3% 30px;
    }
    .about3 .row div{
      width: 50%;
      margin: 60px 0 50px;
    }
    .about3 .row span{
      font-size: 210px;
      top: 38px;
    }
    .about3 .row .title{
      font-size: 34px;
    }
    .about3 .row .desc{
      padding: 0 6%;
      margin-top: 10px;
      line-height: 28px;
    }
    .about4 .img-pc{
      display: none;
    }
    .about4 .img-m{
      display: block;
    }
    .about4 img{
      width: 210px;
      margin-top: 10px;
    }
    .about4 {
        background: url(../assets/image/WhyEBC/about-04-bg-m.png) 100%/auto 100% no-repeat;
        height: 590px;
        margin-top: 0px;
        text-align: center;
        padding-top: 60px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
    .about5{
      padding: 60px 5% 70px;
    }
    .about5 .about-public-desc{
      text-align: center;
    }
    .about5 .row .desc{
      display: none;
    }
    .about5 .row div{
      width: 33.3%;
      margin: 40px 0 0;
      text-align: center;
    }
    .about5 .row img{
      width: 90%;
    }
    .about5 .row .title{
      margin: 10px 0 0px;
      font-size: 20px;
      color: #333333;
    }
    .about6 {
        height: auto;
        margin-top: 0px;
        text-align: center;
        padding-top: 60px;
        padding-bottom: 70px;
    }
    .about6 .row img{
      width: 186px;
    }
    .about6 .row p{
      margin-bottom: 26px;
    }
    .about7{
      padding: 60px 0 60px;
    }
    .about7 .row p{
      line-height: 40px;
      font-size: 16px;
    }
    .about7 .about-public-line{
      margin-bottom: 30px;
    }
    .en-style .about-fth1 .p1{
      padding: 0 3%;
      font-size: 28px;
    }
    .en-style .about-public-desc{
      width: 90%;
    }
    .en-style .about1{
      height: auto;
    }
    .en-style .about-public-title{
      line-height: 36px;
      font-size: 24px;
      padding: 0 5%;
    }
    .en-style .about2{
      height: auto;
    }
    .en-style .about3 .row .desc{
      padding: 0 3%;
      margin-top: 14px;
      line-height: 24px;
    }
    .en-style .about5 .row .title{
      letter-spacing: 0px;
      font-size: 16px;
      font-weight: normal;
      text-align: center;
    }
    .en-style .about5 .about-public-desc{
      width: 100%;
      line-height: 24px;
    }
    .en-style .about5{
      padding: 60px 3% 70px;
    }


    .vi-style .about1{
      height: auto;
    }

    .vi-style .about2 .p2{
      width: 68%;
      line-height: 25px;
    }

    .vi-style .about2 .container{
      width: 100%;
    }
    .vi-style .about2 img{
      width: 146px;
    }
    .vi-style .about2 .p2{
      width: 100%;
      padding: 0 6%;
    }
    .vi-style .about2 img{
      width: 100px;
    }
    .vi-style .about2{
      height: 680px;
    }
    .about3 .row .desc{
      font-size: 16px;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      margin-top: 16px;
    }

    .about6 .swiper-button-prev{
      display: none;
    }
    .about6 .swiper-button-next{
      display: none;
    }
    .about6 .swiper-slide{
      /* flex-shrink: unset; */
      height: auto;
    }
    .about6 .swiper-wrapper{
      /* display: block; */
    }
    .about6 .row img{
      width: 196px;
      margin-bottom: 16px;
    }
    .about6 .honor-list{
      height: auto;
      width: 100%;
    }
    .about6 .container{
      padding: 0;
    }
    .about6 .row img{
        width: 260px;
    }
    .about6 .row{
      margin: 0 auto;
    }
    .about6 .row p{
      margin-bottom: 0;
    }
    .about6 .row .img11,.about6 .row .img33,.about6 .row .img55{
        margin-top: 24px;
    }
    .about6 .row .img11{
        width: 185px;
    }
    .about6 .row .img55{
        width: 130px;
    }
    .about6{
      padding-bottom: 50px;
    }
    .about6 .row p{
      font-size: 16px;
    }
    .about-public-desc-new{
        width: 96%;
        color: #fff;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        margin: 30px auto 60px;
        letter-spacing: 0px;
        opacity: 0.9;
        line-height: 22px;
    }
}

@media(max-width:406px) {

    .about-contact-us .fca7 .d1 .p1{
      font-size: 26px;
      line-height: 40px;
      text-align: center;
      width: 90%;
    }
}
</style>