<template>
  <div class="ebcxmifi">
    <div class="ebcxmifi-head">
      <div class="container-xxl ebcxmifi-head__box">
        <div class="ebcxmifi-head__box__logo">
          <img src="@/assets/image/ebcxmifi/logo1.svg" alt="">
          <div class="logo-btn flex">
            <a class="flex-1" href="/"></a>
            <a class="flex-1" href="https://www.mifipedia.com"></a>
          </div>
        </div>
        <div class="ebcxmifi-head__box__btn flex-1">
          <a class="left-btn" href="https://client.ebccrm.com/signup/index?hl=zh">免费开户</a>
          <a class="right-btn" href="https://client.ebccrm.com/?hl=zh">登录</a>
        </div>
      </div>
    </div>

    <!-- <div class="ebcxmifi-next" id="menu">
			<img src="@/assets/image/ebcxmifi/next-icon.png" alt="">
		</div> -->
    <div id="fullpage" class="fullpage" ref="fullpage">
      <div class="section">
        <div class="fullpage-box1">
          <div class="container-xxl">
            <div class="fullpage-box1__h1 flex">
              <img src="@/assets/image/ebcxmifi/icon1.png" alt="">免费领取订单流工具<img src="@/assets/image/ebcxmifi/icon1.png"
                alt="">
            </div>
            <div class="fullpage-box1__h2 flex">
              助你夺回交易世界的主动权
            </div>
            <div class="fullpage-box1__h4 flex">
              <div class="tit">
                识别主力资金
              </div>
              <img src="@/assets/image/ebcxmifi/icon2.png" alt="">
              <div class="tit">
                跟庄交易
              </div>
              <img src="@/assets/image/ebcxmifi/icon2.png" alt="">
              <div class="tit">
                提高胜率
              </div>
            </div>
            <div class="fullpage-box1__line">

            </div>
            <div class="fullpage-box1__h3 flex">
              保持EBC账户余额大于$10000美金, 即可申请获得
            </div>
            <div class="fullpage-box1__ul flex">
              <div class="li-box flex-1 flex flex-column">
                <div class="li-box__img">
                  <img src="@/assets/image/ebcxmifi/item1.png" alt="">
                </div>
                <div class="li-box__name">
                  订单流分析工具个人版
                </div>
              </div>
              <div class="li-box flex-1 flex flex-column">
                <div class="li-box__img">
                  <img src="@/assets/image/ebcxmifi/item2.png" alt="">
                </div>
                <div class="li-box__name">
                  MIFI全套阶梯式课程
                </div>
              </div>
              <div class="li-box flex-1 flex flex-column">
                <div class="li-box__img">
                  <img src="@/assets/image/ebcxmifi/item3.png" alt="">
                </div>
                <div class="li-box__name">
                  MIFI每周实盘解读
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="fullpage-box2">
          <div class="container-xxl flex">
            <div class="flex-1 fullpage-box2__left">
              <div class="fullpage-box2__left__h3">
                订单流交易工具
              </div>
              <div class="fullpage-box2__left__info">
                如果你能知道市场上的大机构交易者在「何时何地」做出「何种」交易，你就可以相对准确地判断市场将往哪个方向移动。 <br><br>

                有别于传统时间、价格二维交易数据，订单流新增「实时买卖订单量」，把更多目光聚焦于「大机构市场参与者的交易行为」，通过分析盘口主力资金行为（吸筹派发等），实现「跟庄交易」，提高「交易胜率」。<br><br>

                高盛、瑞银等华尔街欧美专业机构必备交易工具，2019年《期货》杂志评为「最受欢迎交易方法」。
              </div>
              <div class="fullpage-box2__left__btn">
                <a class="btn-box" href="https://client.ebccrm.com/signup/index?hl=zh">
                  免费领取机构订单流工具
                </a>
              </div>
            </div>
            <div class="flex-1 fullpage-box2__right flex flex-column">
              <div class="fullpage-box2__right__pag">
                <div class="pag-num">
                  +40,000
                </div>
                <div class="pag-tit">
                  全球专业用户选择
                </div>
              </div>
              <div class="fullpage-box2__right__pag">
                <div class="pag-num">
                  NO.1
                </div>
                <div class="pag-tit">
                  对冲基金<br>
                  最热门交易工具
                </div>
              </div>
              <div class="fullpage-box2__right__pag">
                <div class="pag-num">
                  NO.1
                </div>
                <div class="pag-tit">
                  《期货》<br>
                  2019最受欢迎交易技术
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="fullpage-box3">
          <div class="container-xxl flex">
            <div class="fullpage-box3__left flex-1">
              <div class="fullpage-box3__left__h1">
                Order Flow<br />
                订单流主力足迹数据图
              </div>
              <div class="fullpage-box3__left__h2">
                个人版
              </div>
              <div class="fullpage-box3__left__btn">
                <a class="btn-box" href="https://client.ebccrm.com/signup/index?hl=zh">
                  免费领取机构订单流工具
                </a>
              </div>
              <div class="fullpage-box3__left__pag flex">
                <img src="@/assets/image/ebcxmifi/icon2.png" alt="">全新解读行情图表，微观展示市场主动买卖成交量
              </div>
              <div class="fullpage-box3__left__pag flex">
                <img src="@/assets/image/ebcxmifi/icon2.png" alt="">主力资金行为跃然于图表
              </div>
            </div>
            <div class="fullpage-box3__right flex-1">
              <img src="@/assets/image/ebcxmifi/box3-bg.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="fullpage-box4">
          <div class="container-xxl flex">
            <div class="fullpage-box4__left flex-1">
              <div class="fullpage-box4__left__h1">
                Tape Reading<br>
                盘口实时深度数据
              </div>
              <div class="fullpage-box4__left__h2">
                机构版
              </div>
              <div class="fullpage-box4__left__btn">
                <a href="https://client.ebccrm.com/signup/index?hl=zh" class="btn-box">
                  免费领取机构订单流工具
                </a>
              </div>
              <div class="fullpage-box4__left__pag flex">
                直连CME（芝加哥商品交易所），十档盘口实时Tick数据<br>
                让你更接近真实市场客观数据，及时捕捉盘口动态。
              </div>
            </div>
            <div class="fullpage-box4__right flex-1">
              <img src="@/assets/image/ebcxmifi/box4-bg.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="fullpage-box5">
          <div class="container-xxl flex">
            <div class="fullpage-box5__left flex-1">
              <div class="fullpage-box5__left__h1">
                Tape Reading<br>
                盘口实时深度数据
              </div>
              <div class="fullpage-box5__left__h2">
                机构版
              </div>
              <div class="fullpage-box5__left__btn">
                <a href="https://client.ebccrm.com/signup/index?hl=zh" class="btn-box">
                  免费领取机构订单流工具
                </a>
              </div>
              <div class="fullpage-box5__left__pag flex">
                直观展示市场供求关系，简易识别市场情绪
              </div>
            </div>
            <div class="fullpage-box5__right flex-1">
              <img src="@/assets/image/ebcxmifi/box5-bg.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <view class="fullpage-box6">
          <div class="container-xxl flex">
            <div class="fullpage-box6__left">
              <div class="fullpage-box6__left__h1">
                数据提供方
              </div>
              <div class="fullpage-box6__left__h2">
                CME 芝加哥商品交易所
              </div>
              <div class="fullpage-box6__left__info">
                作为全球最多元化的衍生工具市场龙头，CME每年平均经手30亿份合约，价值约1千兆美元。 CME为买卖双方提供市场，连接需要管理风险或愿意通过承担风险进行获利的个人、企业或机构。
                公司旗下交易所CME、CBOT、NYMEX和COMEX提供涵盖所有主要资产类别的范围最广的全球基准产品，包括基于利率、股票指数、货币、能源、农产品、金属、天气和房地产的期货和期权。为了向市场提供创新的管理解决方案，芝商所致力于不断添加已清算的场外产品和服务。
              </div>
            </div>
            <div class="fullpage-box6__right flex-1">
              <img src="@/assets/image/ebcxmifi/box6-bg.png" alt="">
            </div>
          </div>
        </view>
      </div>
      <div class="section">
        <view class="fullpage-box6">
          <div class="container-xxl flex">
            <div class="fullpage-box6__left">
              <div class="fullpage-box6__left__h1">
                软件提供商
              </div>
              <div class="fullpage-box6__left__h2">
                NinjaTrader
              </div>
              <div class="fullpage-box6__left__info">
                华尔街欧美地区最领先的金融类软件平台之一，专注于股票、期货以及货币交易。其第八代全新版本在兼备国内传统软件的基础功能之余，提供了更具深度与可拓展性的强大系统，更开放、更专业、更丰富、更自由。
              </div>
            </div>
            <div class="fullpage-box6__right flex-1">
              <img src="@/assets/image/ebcxmifi/box7-bg.png" alt="">
            </div>
          </div>
        </view>
      </div>
      <div class="section">
        <view class="fullpage-box6">
          <div class="container-xxl flex">
            <div class="fullpage-box6__left">
              <div class="fullpage-box6__left__h1">
                投教合作商
              </div>
              <div class="fullpage-box6__left__h2">
                MIFI市场微观数据结构研究所
              </div>
              <div class="fullpage-box6__left__info">
                微观市场数据结构研究所 Microdata Institute for Finance and Investment （MIFI）有近10年的研究经验，
                团队成员在大数据、高频交易、量化交易领域拥有二十多年的技术沉淀和研发经验。目前订单流工具的教学，全程由MIFI提供支持。
              </div>
              <div class="fullpage-box6__left__btn">
                <a href="https://client.ebccrm.com/signup/index?hl=zh" class="btn-box">免费领取机构订单流工具</a>
              </div>
            </div>
            <div class="fullpage-box6__right flex-1">
              <img src="@/assets/image/ebcxmifi/box8-bg.png" alt="">
            </div>
          </div>
        </view>
      </div>
    </div>
    <div class="ebcxmifi-foot">
      <div class="container-xxl">
        Copyright © 2022 EBC Financial Group (SVG) LLC. All Rights Reserved. | 每个认真交易的人，都值得被认真对待。
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    head() {
      return {
        script: [{
          src: '/fullpage.min.js'
        }]
      }
    },
    data() {
      return {
        state: false
      }
    },
    mounted() {
      if (this._isMobile()) {
        this.state = false
      } else {
        this.state = true
      }
      $(document).on('click', '#menu', function() {
        fullpage_api.moveSectionDown();
      });
    },
    created() {

    },
    methods: {
      _isMobile() {
        let flag = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
        return flag;
      },
    }
  }
</script>

<style lang="scss">
  .ebcxmifi {
    font-family: "Microsoft YaHei" !important;
    background-image: linear-gradient(to bottom, #3d0101, #331a1a);

    .fp-watermark {
      display: none !important;
    }

    &-head {
      position: fixed;
      z-index: 10000;
      top: 0;
      left: 0;
      right: 0;
      background-color: #3d0101;

      &__box {
        display: flex;
        align-items: center;
        height: 8.625rem;

        &__logo {
          width: 485.8px;
          height: 66.5px;
          position: relative;

          .logo-btn {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;

            a {
              height: 100%;
            }
          }

          .wap {
            display: none;
          }

          img {
            width: 100%;
          }
        }

        &__btn {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .right-btn {
            font-size: 18px;
            color: #fefefe;
            text-decoration: underline;
            margin-left: 50px;
            line-height: 41px;
          }

          .left-btn {
            display: block;
            border-radius: 8px;
            background-color: #5c0000;
            width: 114px;
            text-align: center;
            line-height: 36px;
            font-size: 18px;
            color: #fefefe;
          }
        }
      }
    }

    &-foot {
      // position: fixed;
      padding: 50px 0;
      z-index: 10000;
      bottom: 60px;
      left: 0;
      right: 0;
      font-size: 12px;
      color: #7e7e7e;
      text-align: center;
    }

    &-next {
      position: fixed;
      z-index: 10000;
      bottom: 60px;
      right: 60px;
      width: 40px;
      height: 40px;
      cursor: pointer;
      animation: myfirst 2s infinite
    }

    @keyframes myfirst {
      0% {
        transform: translate(0px, 0px);
        opacity: 1;
      }

      50% {
        transform: translate(0px, -10px);
        opacity: 0.8;
      }

      100% {
        transform: translate(0px, 0px);
        opacity: 1;
      }

    }

    .fullpage {
      .section {
        padding-top: 138px;
        height: 100% !important;

        &:nth-child(2) {
          background: url(../assets/image/ebcxmifi/box2-bg.png) no-repeat top right;
        }
      }

      &-box1 {
        padding: 50px 0;
        color: #fff;


        &__h1 {
          justify-content: center;
          color: #fefefe;
          font-weight: bold;
          font-size: 42px;
          letter-spacing: 3px;
          align-items: center;

          img {
            margin: 0 20px;
            width: 32px;
          }
        }

        &__h2 {
          color: #b7986e;
          font-weight: bold;
          justify-content: center;
          padding: 20px 0;
          font-size: 36px;
          letter-spacing: 3px;
        }

        &__h3 {
          justify-content: center;
          color: #fefefe;
          font-size: 26px;
        }

        &__h4 {
          justify-content: center;
          color: #b7986e;
          font-size: 26px;
          font-size: 22px;
          margin: 16px 0;
          align-items: center;

          img {
            margin: 0 30px;
          }
        }

        &__line {
          width: 33px;
          height: 3px;
          margin: 20px auto;
          background-color: #5a5a5a;
        }

        &__ul {
          padding: 60px 150px;

          .li-box {
            align-items: center;
            justify-content: center;

            &:nth-child(2) {
              padding: 0 40px;
            }

            &__img {
              width: 300px;
              height: 300px;

              img {
                max-width: 100%;
              }
            }

            &__name {
              height: 56px;
              padding: 0 20px;
              text-align: center;
              line-height: 56px;
              background-color: #5c0000;
              color: #fefefe;
              font-size: 30px;
              border-radius: 5px;
              margin-top: 20px;
              white-space: nowrap;
            }
          }
        }
      }

      &-box2 {

        &__left {
          margin-top: 60px;
          background-color: rgba(#5c0000, 0.2);
          padding: 45px 72px 55px 61px;

          &__h3 {
            color: #fefefe;
            font-size: 24px;
            font-weight: bold;
          }

          &__info {
            padding: 40px 0;
            text-align: left;
            color: #b7986e;
            line-height: 1.5;
            font-size: 24px;
          }

          &__btn {
            .btn-box {
              background-color: #5c0000;
              font-size: 19px;
              color: #fefefe;
              height: 40px;
              display: block;
              width: 256px;
              text-align: center;
              line-height: 40px;
            }
          }
        }

        &__right {
          align-items: flex-end;

          &__pag {
            background-color: rgba(#5c0000, 0.4);
            width: 400px;
            height: 217px;
            margin-bottom: 25px;
            display: flex;
            padding-left: 60px;
            flex-direction: column;
            justify-content: center;


            &:nth-child(2) {
              margin-right: -70px;
            }

            .pag-num {
              font-size: 69px;
              color: #fff;
              font-family: Bahnschrift;
              position: relative;

              &:before {
                content: "";
                position: absolute;
                top: -20px;
                left: 0;
                width: 70px;
                height: 70px;
                border-radius: 70px;
                background-color: #b7986e;
                z-index: -1;
              }
            }

            .pag-tit {
              color: #efefef;
              font-size: 16px;
              line-height: 1.5;
              padding-top: 10px;
            }
          }
        }

      }

      &-box3 {
        &__left {
          padding-top: 160px;

          &__h1 {
            font-size: 60px;
            font-weight: bold;
            line-height: 1.33;
            letter-spacing: 1.2px;
            color: #fefefe;
          }

          &__h2 {
            font-size: 60px;
            font-weight: bold;
            line-height: 1.33;
            letter-spacing: 1.2px;
            color: #b7986e;
            padding: 20px 0;
          }

          &__btn {
            margin-bottom: 80px;

            .btn-box {
              background-color: #5c0000;
              width: 256px;
              height: 40px;
              font-size: 19px;
              text-align: center;
              color: #fefefe;
              line-height: 40px;
              display: block;
            }
          }

          &__pag {
            align-items: center;
            color: #7e7e7e;
            font-size: 22px;
            margin-bottom: 14px;

            img {
              margin-right: 10px;
            }
          }
        }
      }

      &-box4 {
        &__left {
          padding-top: 160px;

          &__h1 {
            font-size: 60px;
            font-weight: bold;
            line-height: 1.33;
            letter-spacing: 1.2px;
            color: #fefefe;
          }

          &__h2 {
            font-size: 60px;
            font-weight: bold;
            line-height: 1.33;
            letter-spacing: 1.2px;
            color: #b7986e;
            padding: 20px 0;
          }

          &__btn {
            margin-bottom: 80px;

            .btn-box {
              background-color: #5c0000;
              width: 256px;
              height: 40px;
              font-size: 19px;
              text-align: center;
              color: #fefefe;
              line-height: 40px;
              display: block;
            }
          }

          &__pag {
            align-items: center;
            color: #7e7e7e;
            font-size: 22px;
            line-height: 1.5;
          }
        }

        &__right {
          text-align: right;

          img {
            max-width: 80%;
          }
        }
      }

      &-box5 {
        &__left {
          padding-top: 160px;

          &__h1 {
            font-size: 60px;
            font-weight: bold;
            line-height: 1.33;
            letter-spacing: 1.2px;
            color: #fefefe;
          }

          &__h2 {
            font-size: 60px;
            font-weight: bold;
            line-height: 1.33;
            letter-spacing: 1.2px;
            color: #b7986e;
            padding: 20px 0;
          }

          &__btn {
            margin-bottom: 80px;

            .btn-box {
              background-color: #5c0000;
              width: 256px;
              height: 40px;
              font-size: 19px;
              text-align: center;
              color: #fefefe;
              line-height: 40px;
              display: block;
            }
          }

          &__pag {
            align-items: center;
            color: #7e7e7e;
            font-size: 22px;
            line-height: 1.5;
          }
        }

        &__right {
          padding-top: 60px;
        }
      }

      &-box6 {
        &__left {
          padding-top: 160px;

          &__h1 {
            font-size: 36px;
            color: #b7986e;
          }

          &__h2 {
            font-size: 36px;
            font-weight: bold;
            color: #efefef;
            padding: 20px 0 40px;
          }

          &__info {
            max-width: 480px;
            font-size: 18px;
            color: #7e7e7e;
            line-height: 1.67;
          }

          &__btn {
            margin-top: 80px;

            .btn-box {
              background-color: #5c0000;
              width: 256px;
              height: 40px;
              font-size: 19px;
              text-align: center;
              color: #fefefe;
              line-height: 40px;
              display: block;
            }
          }

          &__pag {
            align-items: center;
            color: #7e7e7e;
            font-size: 22px;
            line-height: 1.5;
          }
        }

        &__right {
          padding-top: 60px;
        }
      }
    }
  }

  @media (max-width: 500px) {
    .ebcxmifi {
      &-head {
        background-color: #3d0101;
        padding: 20px 0;

        &__box {
          display: flex;
          align-items: center;
          height: inherit;

          &__logo {
            width: inherit;
            height: inherit;
            padding-right: 10px;

            .pc {
              display: none;
            }

            .wap {
              display: block;
            }

            img {
              width: 100%;
            }
          }

          &__btn {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .right-btn {
              font-size: 12px;
              color: #fefefe;
              text-decoration: underline;
              margin-left: 10px;
              white-space: nowrap;
            }

            .left-btn {
              display: block;
              border-radius: 3px;
              background-color: #5c0000;
              width: 72px;
              height: 23px;
              text-align: center;
              line-height: 25px;
              font-size: 12px;
              color: #fefefe;
            }
          }
        }
      }

      &-foot {
        line-height: 1.5;
      }

      &-next {
        display: none;
      }

      .fullpage {
        .section {
          padding-top: 0;
          height: auto !important;

          &:nth-child(1) {
            padding-top: 108px;
          }

          &:nth-child(2) {
            // background: url(../assets/image/ebcxmifi/box2-bg.png) no-repeat top right;
          }
        }

        &-box1 {
          padding: 0px 0;
          color: #fff;

          &__h1 {

            font-size: 24px;
            letter-spacing: 2px;
            flex-wrap: nowrap;

            img {
              margin: 0 10px;
              width: 16px;
            }
          }

          &__h2 {
            font-size: 20px;
          }

          &__h3 {
            justify-content: center;
            color: #fefefe;
            font-size: 18px;
            line-height: 1.2;
          }

          &__h4 {
            justify-content: center;
            color: #b7986e;
            font-size: 18px;
            margin: 16px 0;
            align-items: center;

            img {
              margin: 0 10px;
            }
          }

          &__line {
            width: 33px;
            height: 3px;
            margin: 20px auto;
            background-color: #5a5a5a;
          }

          &__ul {
            padding: 40px 10px;
            flex-direction: column;
            align-items: center;

            .li-box {
              align-items: center;
              justify-content: center;
              margin-bottom: 20px;
              width: 100%;

              &:nth-child(2) {
                padding: 0 40px;
              }

              &__img {
                width: 200px;
                height: 200px;

                img {
                  max-width: 100%;
                }
              }

              &__name {
                height: 36px;
                padding: 0 20px;
                text-align: center;
                line-height: 36px;
                background-color: #5c0000;
                color: #fefefe;
                font-size: 24px;
                border-radius: 5px;
                white-space: nowrap;
              }
            }
          }
        }

        &-box2 {
          .container-xxl {
            flex-direction: column;
          }

          &__left {
            margin: 10px auto 0;
            background-color: rgba(#5c0000, 0.2);
            width: 98%;
            padding: 20px 10px;

            &__h3 {
              color: #fefefe;
              font-size: 24px;
              font-weight: bold;
            }

            &__info {
              padding: 20px 0;
              text-align: left;
              color: #b7986e;
              line-height: 1.5;
              font-size: 18px;
            }

            &__btn {
              .btn-box {
                background-color: #5c0000;
                font-size: 19px;
                color: #fefefe;
                height: 40px;
                display: block;
                width: 256px;
                text-align: center;
                line-height: 40px;
              }
            }
          }

          &__right {
            align-items: flex-end;
            width: 100%;
            box-sizing: border-box;

            &__pag {
              background-color: rgba(#5c0000, 0.4);
              width: 100%;
              height: 117px;
              margin-bottom: 25px;
              display: flex;
              padding-left: 60px;
              flex-direction: column;
              justify-content: center;

              &:nth-child(2) {
                margin-right: 0;
              }

              .pag-num {
                font-size: 32px;
                color: #fff;
                font-family: Bahnschrift;

                &:before {
                  content: "";
                  position: absolute;
                  top: -10px;
                  left: 0;
                  width: 30px;
                  height: 30px;
                  border-radius: 30px;
                  background-color: #b7986e;
                  z-index: -1;
                }
              }

              .pag-tit {
                color: #efefef;
                font-size: 16px;
                line-height: 1.5;
                padding-top: 10px;
              }
            }
          }

        }

        &-box3 {
          .container-xxl {
            flex-direction: column;
          }

          &__left {
            padding-top: 0;
            width: 100%;
            text-align: center;

            &__h1 {
              font-size: 32px;
              font-weight: bold;
              line-height: 1.33;
              letter-spacing: 1.2px;
              color: #fefefe;
            }

            &__h2 {
              font-size: 32px;
              font-weight: bold;
              line-height: 1.33;
              letter-spacing: 1.2px;
              color: #b7986e;
              padding: 20px 0;
            }

            &__btn {
              margin-bottom: 40px;

              .btn-box {
                background-color: #5c0000;
                width: 256px;
                height: 40px;
                font-size: 19px;
                text-align: center;
                color: #fefefe;
                line-height: 40px;
                display: block;
                margin: auto;
              }
            }

            &__pag {
              align-items: center;
              color: #7e7e7e;
              font-size: 14px;
              margin-bottom: 14px;

              img {
                margin-right: 10px;
              }
            }
          }

          &__right {
            width: 100%;
            text-align: center;

            img {
              max-width: 80%;
            }
          }
        }

        &-box4 {
          .container-xxl {
            flex-direction: column;
          }

          &__left {
            padding-top: 40px;
            width: 100%;
            text-align: center;

            &__h1 {
              font-size: 32px;
              font-weight: bold;
              line-height: 1.33;
              letter-spacing: 1.2px;
              color: #fefefe;
            }

            &__h2 {
              font-size: 32px;
              font-weight: bold;
              line-height: 1.33;
              letter-spacing: 1.2px;
              color: #b7986e;
              padding: 20px 0;
            }

            &__btn {
              margin-bottom: 40px;

              .btn-box {
                background-color: #5c0000;
                width: 256px;
                height: 40px;
                font-size: 19px;
                text-align: center;
                color: #fefefe;
                line-height: 40px;
                display: block;
                margin: auto;
              }
            }

            &__pag {
              align-items: center;
              color: #7e7e7e;
              font-size: 14px;
              line-height: 1.5;
              text-align: center;
            }
          }

          &__right {
            text-align: center;
            width: 100%;

            img {
              max-width: 100%;
            }
          }
        }

        &-box5 {
          .container-xxl {
            flex-direction: column;
          }

          &__left {
            padding-top: 40px;
            width: 100%;
            text-align: center;

            &__h1 {
              font-size: 32px;
              font-weight: bold;
              line-height: 1.33;
              letter-spacing: 1.2px;
              color: #fefefe;
            }

            &__h2 {
              font-size: 32px;
              font-weight: bold;
              line-height: 1.33;
              letter-spacing: 1.2px;
              color: #b7986e;
              padding: 20px 0;
            }

            &__btn {
              margin-bottom: 40px;

              .btn-box {
                background-color: #5c0000;
                width: 256px;
                height: 40px;
                font-size: 19px;
                text-align: center;
                color: #fefefe;
                line-height: 40px;
                display: block;
                margin: auto;
              }
            }

            &__pag {
              align-items: center;
              color: #7e7e7e;
              font-size: 14px;
              line-height: 1.5;
              justify-content: center;
            }
          }

          &__right {
            padding-top: 60px;
            width: 100%;
            text-align: center;

            img {
              max-width: 80%;
            }
          }
        }

        &-box6 {
          .container-xxl {
            flex-direction: column;
          }

          &__left {
            padding-top: 40px;
            width: 100%;

            &__h1 {
              font-size: 24px;
              color: #b7986e;
            }

            &__h2 {
              font-size: 24px;
              font-weight: bold;
              color: #efefef;
              padding: 20px 0 40px;
            }

            &__info {
              max-width: 100%;
              font-size: 14px;
              color: #7e7e7e;
              line-height: 1.67;
            }

            &__btn {
              margin-top: 40px;

              .btn-box {
                background-color: #5c0000;
                width: 256px;
                height: 40px;
                font-size: 19px;
                text-align: center;
                color: #fefefe;
                line-height: 40px;
                display: block;
                margin: auto;
              }
            }

            &__pag {
              align-items: center;
              color: #7e7e7e;
              font-size: 14px;
              line-height: 1.5;
            }
          }

          &__right {
            padding-top: 40px;
            width: 100%;
          }
        }
      }

    }
  }
</style>
