<template>
	<div class="trading-instruments" :class="$t('Dividend.box-name')">
		<ss-header mode="Home" bg="rgb(38, 5, 5)"></ss-header>
		<div class="tools-t-sub1">
                    <h1 class="p1">{{$t('Tools.h1-title')}}</h1>
                    <img src="../assets/image/TradingAccount/fth1-line.png" alt="">
                    <p class="p2">{{$t('Tools.screen0.title')}}</p>
                    <a href="https://client.ebccrm.com/signup/index" class="btn">{{$t('Tools.public-btn-text')}}</a>
                </div>

                <div class="tools-ash tools1">
                    <div class="container">
                        <p class="tools-public-title">{{$t('Tools.screen1.title')}}</p>
                        <p class="tools-public-line"></p>
                        <p class="tools-public-desc">{{$t('Tools.screen1.desc')}}</p>
                        <div class="row">
                            <img src="../assets/image/TradingInstruments/invalid-name.png" alt="">
                        </div>
                        <a class="public-link2" href="https://client.ebccrm.com/signup/index">{{$t('Tools.public-btn-text')}}<img class="arrow1" src="../assets/image/dividend/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/dividend/fca12.png" alt=""></a>
                    </div>
                </div>

                <div class="tools-white tools2">
                    <div class="container">
                        <p class="tools-public-title">{{$t('Tools.screen2.title')}}</p>
                        <p class="tools-public-line"></p>
                        <p class="tools-public-desc">{{$t('Tools.screen2.desc')}}</p>
                        <div class="row">
                            <img src="../assets/image/TradingInstruments/macd.png" alt="">
                        </div>
                        <a class="public-link2" href="https://client.ebccrm.com/signup/index">{{$t('Tools.public-btn-text')}}<img class="arrow1" src="../assets/image/dividend/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/dividend/fca12.png" alt=""></a>
                    </div>
                </div>

                <div class="tools-ash tools3">
                    <div class="container">
                        <p class="tools-public-title">{{$t('Tools.screen3.title')}}</p>
                        <p class="tools-public-line"></p>
                        <p class="tools-public-desc">{{$t('Tools.screen3.desc')}}</p>
                        <div class="row">
							<img :src="require(`../assets/image/TradingInstruments/mtd${$t('Tools.imgUrlLang')}.png`)" alt="">
                        </div>
                        <a class="public-link2" href="https://client.ebccrm.com/signup/index">{{$t('Tools.public-btn-text')}}<img class="arrow1" src="../assets/image/dividend/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/dividend/fca12.png" alt=""></a>
                    </div>
                </div>

                <div class="tools-white tools4">
                    <div class="container">
                        <p class="tools-public-title">{{$t('Tools.screen4.title')}}</p>
                        <p class="tools-public-line"></p>
                        <p class="tools-public-desc">{{$t('Tools.screen4.desc')}}</p>
                        <div class="row">
                            <img src="../assets/image/TradingInstruments/invalid-name.png" alt="">
                        </div>
                        <a class="public-link2" href="https://client.ebccrm.com/signup/index">{{$t('Tools.public-btn-text')}}<img class="arrow1" src="../assets/image/dividend/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/dividend/fca12.png" alt=""></a>
                    </div>
                </div>



                <div class="tools-ash tools5">
                    <div class="container">
                        <p class="tools-public-title">{{$t('Tools.screen5.title')}}</p>
                        <p class="tools-public-line"></p>
                        <p class="tools-public-desc">{{$t('Tools.screen5.desc')}}</p>
                        <div class="row">
							<img :src="require(`../assets/image/TradingInstruments/yjpc${$t('Tools.imgUrlLang')}.png`)" alt="">
                        </div>
                        <a class="public-link2" href="https://client.ebccrm.com/signup/index">{{$t('Tools.public-btn-text')}}<img class="arrow1" src="../assets/image/dividend/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/dividend/fca12.png" alt=""></a>
                    </div>
                </div>

                <div class="tools-white tools6">
                    <div class="container">
                        <p class="tools-public-title">{{$t('Tools.screen6.title')}}</p>
                        <p class="tools-public-line"></p>
                        <p class="tools-public-desc">{{$t('Tools.screen6.desc')}}</p>
                        <div class="row">
							<img :src="require(`../assets/image/TradingInstruments/jzjk${$t('Tools.imgUrlLang')}.png`)" alt="">
                        </div>
                        <a class="public-link2" href="https://client.ebccrm.com/signup/index">{{$t('Tools.public-btn-text')}}<img class="arrow1" src="../assets/image/dividend/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/dividend/fca12.png" alt=""></a>
                    </div>
                </div>


                <div class="tools-ash tools7">
                    <div class="container">
                        <p class="tools-public-title">{{$t('Tools.screen7.title')}}</p>
                        <p class="tools-public-line"></p>
                        <p class="tools-public-desc">{{$t('Tools.screen7.desc')}}</p>
                        <div class="row">
							<img :src="require(`../assets/image/TradingInstruments/dsxd${$t('Tools.imgUrlLang')}.png`)" alt="">
                        </div>
                        <a class="public-link2" href="https://client.ebccrm.com/signup/index">{{$t('Tools.public-btn-text')}}<img class="arrow1" src="../assets/image/dividend/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/dividend/fca12.png" alt=""></a>
                    </div>
                </div>



                <page-slogan></page-slogan>
				<new-footer></new-footer>
	</div>
</template>

<script>
export default {
    data() {
    	return {
    		seoInfo: ""
    	}
    },
    metaInfo() {
    	return {
    		title: this.seoInfo.title || '',
    		meta: [ // set meta
    			{
    				name: 'keyWords',
    				content: this.seoInfo.keyWords || ''
    			},
    			{
    				name: 'description',
    				content: this.seoInfo.description || ''
    			}
    		]
    	}
    },
    created() {
    	this.$getSeoInfo({
    		lang: this.$store.state.Lan,
    		path: "/tools"
    	}).then(res => {
    		this.seoInfo = res
    	})
    
    }
}
</script>

<style>
	.tools-t-sub1{
  background: url(../assets/image/TradingInstruments/tools-bg.jpg) 100%/auto 100% no-repeat;
  height: 360px;
  margin-top: 0px;
  text-align: center;
  padding-top: 126px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.tools-t-sub1 img {
    margin-top: 26px;
    width: 330px;
    margin-bottom: 14px;
}
.tools-t-sub1 .p1{
    color: #FFF;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    margin-top: 19px;
}
.line{
    width: 80px;
    border: 1px solid #D9D9D9;
    margin: 60px auto 50px;
}
.tools-t-sub1 .p2{
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    width: 666px;
    margin: 0 auto;
    line-height: 36px;
    opacity: 0.9;
}
.tools-t-sub1 .btn{
    display: block;
    width: max-content;
    padding: 10px 56px;
    height: 46px;
    font-size: 16px;
    border-radius: 2px;
    background: #3D0101;
    color: #AF8147;
    margin: 28px auto;
}
.tools-t-sub1 .btn:hover{
    color: #3D0101;
    background: #AF8147;
}

.tools-public-title{
    color: #3D0101;
    text-align: center;
    font-size: 34px;
    font-weight: 700;
}
.tools-public-line{
    width: 70px;
    border: 1px solid #AF8147;
    margin: 46px auto 36px;
}
.tools-public-title2{
    color: #3D0101;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}
.tools-public-desc{
    width: 54%;
    color: #666666;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    margin: 20px auto 0;
}


.tools-t-sub2{
    background: #F8F8F8;
    padding: 80px 0;
}
.tools-t-sub2 .row{
    background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
    margin: 46px auto;
    padding: 50px 10% 50px 17%;
    width: 1000px;
}
.tools-t-sub2 .row div{
    margin-top: 10px;
}
.tools-t-sub2 .row img{
    width: 30px;
    position: relative;
    top: 7px;
}
.tools-t-sub2 .row p{
    color: #080E1C;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    display: inline;
    line-height: 46px;
    margin-left: 16px;
}


.public-btn{
    display: block;
    width: max-content;
    padding: 13px 40px;
    height: 44px;
    font-size: 16px;
    border-radius: 2px;
    color: #3D0101;
    margin: 50px auto 0px;
    text-align: center;
    border: 1px solid #3D0101;
    border-radius: 2px;
}
.public-btn:hover{
    color: #3D0101;
    background: #AF8147;
    border: 1px solid #AF8147;
}

.btn-box{
    width: max-content;
    margin: auto;
}


.tools-t-sub3{
    background: #ffffff;
    padding: 90px 0 160px;
}
.tools-t-sub3 .row{
    margin: 54px auto;
    width: 1200px;
}
.tools-t-sub3 .row div{
    text-align: center;
}
.tools-t-sub3 .row img{
    width: 65px;
    position: relative;
    top: 26px;
}
.tools-t-sub3 .row img{
    width: 90px;
}
.tools-t-sub3 .row .title{
    color: #080E1C;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 48px;
    margin-top: 22px;
}
.tools-t-sub3 .row .desc{
    color: #080E1C;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
    padding: 0 10%;
}


.tools-t-sub4{
    background: #F8F8F8;
    padding: 80px 0;
}
.tools-t-sub4 img{
    display: block;
    margin: 40px auto;
    width: 60%;
    background: #fff;
    padding: 30px;
}
.tools-t-sub4 .tools-public-desc{
    width: 60%;
    text-align: left;
}


.tools-t-sub5{
    background: #FFFFFF;
    padding: 80px 0;
}
.tools-t-sub5 .tools-t-sub5-img{
    display: block;
    margin: 40px auto;
    width: 1000px;
}
.tools-t-sub5 .tools-public-desc{
    width: 500px;
}
.tools-t-sub5-content{
    position: relative;
    width: 1000px;
    margin: 0 auto;
}
.tools-t-sub5-bottom{
    position: absolute;
    bottom: 0px;
    height: 80px;
    background: rgba(29, 1, 1, 0.75);
    width: 1000px;
    text-align: center;
}
.tools-t-sub5-bottom p{
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline;
    padding: 0 60px;
    line-height: 74px;
}
.tools-t-sub5-bottom img{
    width: 7px;
    margin-left: 10px;
}
.tools-t-sub5-bottom span{
    font-size: 26px;
    font-weight: bold;
}
.public-slogan{
    padding: 28px 0px;
    background: #F8F8F8;
    color: #3D0101;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    font-weight: 600;
}

.tools-t-subtner-box-en .tools-t-sub2 .row{
    padding: 50px 0% 26px 22%;
}
.tools-t-subtner-box-en .tools-t-sub2 .row div{
    width: 100%;
}
.tools-t-subtner-box-en .tools-t-sub1 .p1{
    line-height: 52px;
    margin-top: 60px;
}
.tools-t-subtner-box-en .tools-public-desc{
    width: 750px;
}

.tools-t-subtner-box-en .tools-t-sub3 .row p{
    margin-left: 10px;
    display: block;
    line-height: 26px;
    margin-top: 30px;
}
.tools-t-subtner-box-en .tools-t-sub1 .p2{
    letter-spacing: 0px;
    width: 718px;
    line-height: 30px;
}
.tools-t-subtner-box-en .tools-t-sub2 .row p{
    letter-spacing: 0px;
}
.tools-t-sub1 .btn-ug{
    width: 190px;
}


.tools-t-sub5 .container{
    max-width: 1100px;
}
.tools-t-sub5 .row div{
    text-align: center;
    margin-top: 30px;
    margin-bottom: 40px;
}
.tools-t-sub5 .row img{
    width: 80%;
}
.tools-t-sub5 .row p{
    color: #666666;
    font-size: 16px;
    line-height: 26px;
    width: 90%;
    margin: 30px auto 0;
}

.tools-t-sub6{
    background: #F8F8F8;
    padding: 80px 0;
}
.tools-t-sub6 .container{
    max-width: 1100px;
}
.tools-t-sub6 .row div{
    background: #fff;
    width: auto;
    margin: 10px 2% 20px;
    height: 44px;
    padding: 14px 20px 14px 46px;
    position: relative;
    border-radius: 3px;
}
.tools-t-sub6 .row div img{
    width: 18px;
    position: absolute;
    top: 13px;
    left: 20px;
}
.tools-t-sub6 .row div p{
    float: left;
    font-size: 16px;
}
.tools-t-sub1 .p1 br{
    display: none;
}

.en-style .tools-t-sub1 .p2{
    letter-spacing: 0px;
}
.en-style .tools-t-sub1 .p1{
    margin-top: 80px;
}
.vi-box .tools-t-sub1 .p1{
    margin-top: 40px;
}




.public-link{
    background: #3d0101;
    /* padding: 11px 28px; */
    color: #fff;
    font-size: 16px;
    letter-spacing: 1px;
    display: block;
    width: max-content;
    padding: 15px 29px;
    float: left;
    margin-top: 20px;
}
.public-link img {
    width: 7px;
    margin-left: 12px;
}
.public-link:hover{
  color: #ffffff;
  background: #AF8147;
}

.public-link2{
    padding: 14px 28px;
    color: #3d0101;
    font-size: 16px;
    border: 1px solid #3d0101;
    margin-right: 12px;
    letter-spacing: 1px;
    display: block;
    width: max-content;
    float: left;
    margin-top: 20px;
}
.public-link2 img {
    width: 7px;
    margin-left: 12px;
}
.public-link2:hover{
  color: #ffffff;
  background: #3d0101;
  border: 1px solid #3d0101;
}
.arrow2{
  display: none!important;
}
.public-link2:hover .arrow1{
  display: none!important;
}
.public-link2:hover .arrow2{
  display: inline-block!important;
}


.product {
    width: 1000px;
    margin: 80px auto;
}

.product .accordion-header {
    cursor: pointer
}

.product .table-list {
    width: 100%;
    border: none;
    position: relative;
    flex-direction: inherit;
    background: #949699;
}

.product .table-list .flex-1 {
    font-weight: 700;
    line-height: 40px;
    color: #ffffff;
    font-size: 14px;
    padding: 2px 12px;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    cursor: pointer;
    font-family: auto;
    text-align: center;
}

.product .table-list .flex-1.active {
    background-color: #3D0101;
    color: #ffffff;
}

.product-box__nav {
    /* margin-bottom: 4px; */
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    color: #666666;
    padding: 0 12px;
    background-color: #F8F8F8;
    padding: 4px 0;
}

.product-box__nav div {
    line-height: 40px;
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: auto;
    text-align: center;
}

.product-box__accordion-item {
    background: #f7f7f7;
    border: none
}

.product-box__accordion-item .accordion-button {
    padding: 10px;
    font-size: 14px;
    background: #ffffff;
    color: #333
}

.product-box__accordion-item .accordion-body-head {
    width: 100%;
    padding: 12px 0;
    background-color: #949699;
    color: #f7f7f7
}

.product-box__accordion-item .accordion-body-head div {
    flex: 1
}

.product-box__accordion-header {
    margin-bottom: 0
}

.product-box .accordion-body-tr {
    width: 100%;
    padding: 12px 0;
    font-family: auto;
}

.product-box .accordion-body-tr div {
    flex: 1;
    text-align: center;
}

.product-box .accordion-body-tr div:nth-of-type(2) {
    color: #48ba13
}

.product-box .accordion-body-tr .red {
    color: #cc3221 !important
}

.product-box .accordion-body-tr div:nth-of-type(5) {
    color: #48ba13
}

.product .look-all {
    display: flex;
    justify-content: center;
    color: #8b8b8b;
    padding: 20px 0;
    font-size: 16px;
}

.product .look-all span {
    padding-top: 5px;
    border-top: 1px solid #8b8b8b
}

.product .c-underline__top:after {
    /* background: #7d1614;
    height: 4px */
}

.accordion-body{
    font-size: 14px;
    font-family: auto;
    background: #f8f8f8;
}




.rotate .accordion-button:after{
    transform: rotate(180deg);
}



.en-style2 .tools-t-sub1 .p2{
    letter-spacing: 0px;
}
.en-style2 .tools-t-sub3 .row .title{
    letter-spacing: 0px;
    line-height: 26px;
    margin-top: 32px;
    margin-bottom: 14px;
}
.en-style2 .tools-t-sub3 .row .desc{
    letter-spacing: 0px;
}
.en-style2 .public-link2{
    letter-spacing: 0px;
}
.en-style2 .public-link{
    letter-spacing: 0px;
}

.tools-t-sub1 .btn{
    display: none;
}

.account1{
    padding: 80px 0 170px;
    background: #f8f8f8;
}
.account1 .container{
    width: 950px;
    text-align: center;
}
.account1 .row .title{
    font-size: 28px;
    color: #080E1C;
    font-weight: 700;
    margin-bottom: 30px;
    letter-spacing: 1px;
}
.account1 .row .title2{
    color: #3D0101;
}
.account1 .row .desc1,.account1 .row .desc2,.account1 .row .desc3{
    font-size: 17px;
    line-height: 50px;
    letter-spacing: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.account1 .row .div1{
    margin-top: 60px;
}
.account1 .row .desc1{
    color: #949699;
}
.account1 .row .desc2{
    color: #080E1C;
}
.account1 .row .desc3{
    color: #3D0101;
}
.account1 .public-link2{
    margin-right: 0px;
    margin-top: 40px;
}
.tools-ash{
    padding: 100px 0 80px;
    background: #f8f8f8;
}

.tools-ash .row img{
    width: 80%;
    margin: 40px auto 40px;
}

.tools-ash .tools-public-desc{
    color: #949699;
    font-size: 18px;
}

.tools-ash .public-link2{
    float: unset;
    margin: 0 auto;
}

.tools-white{
    padding: 100px 0 80px;
}

.tools-white .row img{
    width: 80%;
    margin: 40px auto 40px;
}

.tools-white .tools-public-desc{
    color: #949699;
    font-size: 18px;
}

.tools-white .public-link2{
    float: unset;
    margin: 0 auto;
}

.en-style2 .tools-t-sub1 .p2{
    letter-spacing: 0px;
}
.en-style2 .account1 .row .desc1, .en-style2 .account1 .row .desc2, .en-style2 .account1 .row .desc3{
    letter-spacing: 0px;
}
.en-style2 .account1 .row .title{
    letter-spacing: 0px;
    font-size: 26px;
}
.en-style2 .tools-public-desc{
    width: 80%;
}
.en-style2 .tools-public-title{
    padding: 0 10%;
    line-height: 38px;
}

.tools3 .row img{
    width: 46%;
}

.tools5 .row img{
    width: 50%;
}

.tools6 .row img{
    width: 70%;
}

.tools7 .row img{
    width: 70%;
}


@media(max-width:1400px) {
    .tools-t-sub1 .p1{
        font-size: 34px;
    }
    .tools-t-sub1 .p2{
        line-height: 32px;
        font-size: 16px;
        width: 600px;
    }
    .tools-t-sub4 img{
        width: 700px;
    }
    .tools-t-sub5 .tools-t-sub5-img{
        width: 700px;
    }
    .tools-t-sub5-bottom{
        width: 700px;
        height: 68px;
    }
    .tools-t-sub5-content{
        width: 700px;
    }
    .tools-t-sub5-bottom p{
        line-height: 60px;
    }
    .tools-t-subtner-box-en .tools-t-sub1{
        height: 650px;
    }
}


@media(max-width:995px) {
    .tools-t-sub1 .p1 br{
        display: block !important;
    }
    .tools-t-sub2 .row{
        width: 90%;
        padding: 20px 0% 0px 13%;
        margin: 30px auto;
    }
    .tools-t-sub2 .row p{
        font-size: 14px;
    }
    .tools-t-sub2 .row div{
        margin-top: 0px;
    }
    .tools-t-sub3 .row{
        width: 100%;
        padding: 19px 0% 0px 0%;
        margin: 10px auto 30px;
        background: #ffffff;
    }
    .tools-t-sub3 .row div{
        width: 50%;
        margin-bottom: 30px;
    }
    .tools-t-sub3 .row img{
        width: 90px;
    }
    .public-link2{
        padding: 14px 18px;
        margin-top: 0px;
        text-align: center;
        width: 100%;
    }
    .public-link{
        padding: 15px 19px;
        margin-top: 0px;
        width: 100%;
        text-align: center;
    }
    .tools-t-sub3 .row .title{
        
    }
    .tools-t-sub3 .row .desc{
        font-size: 15px;
        padding: 0 0%;
        line-height: 26px;
    }
    .tools-t-sub5-content{
        width: 100%;
    }
    .tools-t-sub5 .tools-t-sub5-img{
        width: 100%;
    }
    .tools-t-sub5-bottom{
        width: 100%;
        height: 46px;
    }
    .tools-t-sub5-bottom p{
        line-height: 42px;
        font-size: 14px;
        padding: 0px 20px;
    }
    .tools-t-sub5-bottom span{
        font-size: 18px;
    }
    .tools-t-sub5-bottom img{
        width: 6px;
        margin-left: 6px;
    }
    .tools-t-sub1 .p2{
        width: 100%;
        padding: 24px 8%;
        font-size: 16px;
        line-height: 30px;
    }
    .line{
        width: 62px;
        border: 1px solid #D9D9D9;
        margin: 26px auto 0px;
    }
    .tools-t-sub1 .p1{
        margin-top: 20px;
        line-height: 58px;
        font-size: 34px;
    }
    .tools-t-sub1 .btn{
        margin: 14px auto;
    }
    .tools-public-desc{
        width: 90%;
        font-size: 15px;
        line-height: 24px;
    }
    .tools-t-sub4 .tools-public-desc,.tools-t-sub5 .tools-public-desc{
        width: 90%;
    }
    .tools-t-sub2,.tools-t-sub3,.tools-t-sub4,.tools-t-sub5{
        padding: 56px 0;
    }
    .tools-public-line{
        width: 50px;
        margin: 40px auto 0px;
    }
    .tools-public-title{
        font-size: 26px;
    }
    .tools-t-sub2 .row img{
        width: 28px;
    }
    .tools-t-sub4 img{
        width: 90%;
    }
    .public-slogan{
        padding: 20px 20px;
        line-height: 28px;
    }
    .tools-t-subtner-box-en .tools-t-sub2 .row{
        padding: 20px 0% 0px 3%;
    }
    .tools-t-subtner-box-en .tools-t-sub2 .row div{
        width: 100%;
        margin-top: 14px;
    }
    .tools-t-subtner-box-en .tools-t-sub2 .row div p{
        line-height: 30px;
    }
    .tools-t-subtner-box-en .tools-t-sub1 .p1{
        line-height: 44px;
        font-size: 28px;
        width: 90%;
        margin: 20px auto 0;
    }
    .tools-public-title2{
        line-height: 26px;
    }
    .tools-t-subtner-box-en .tools-t-sub5-bottom p{
        font-size: 12px;
        letter-spacing: 0px;
        padding: 0px 8px;
    }
    .tools-t-subtner-box-en .tools-t-sub5-bottom span{
        font-size: 16px;
    }
    .tools-t-subtner-box-en .tools-public-desc{
        width: 90%;
    }
    .tools-t-subtner-box-en .tools-t-sub1 .p2{
        width: 100%;
        font-size: 14px;
        line-height: 26px;
    }
    .tools-t-subtner-box-en .tools-t-sub2 .row{
        width: 100%;
    }
    .tools-t-subtner-box-en .tools-t-sub2 .row img{
        width: 24px;
    }
    .tools-t-subtner-box-en .tools-t-sub1 .p1{
        font-size: 26px;
        line-height: 34px;
    }
    .tools-t-subtner-box-en .tools-public-title{
        font-size: 22px;
        line-height: 32px;
    }
    .tools-t-subtner-box-en .public-slogan{
        padding: 16px 0px;
        line-height: 28px;
        font-size: 13px;
    }
    .tools-t-subtner-box-en .tools-t-sub2 .row div p{
        line-height: 30px;
        margin-left: 6px;
    }
    .tools-t-subtner-box-en .tools-t-sub2 .row div{
        text-align: center;
    }
    .tools-t-subtner-box-en .tools-t-sub3 .row p {
        margin-left: 8px;
        display: block;
        line-height: 18px;
        margin-top: 8px;
        font-size: 12px;
    }
    .public-slogan {
        padding: 16px 0px;
        line-height: 28px;
        font-size: 13px;
    }
    .tools-t-sub1 .p1 br{
        display: block;
    }
    .tools-t-sub4 img{
        padding: 0;
        background: none;
        margin: 0 auto;
        width: 96%;
    }
    .public-btn{
        margin: 40px auto 0px;
    }
    .tools-t-sub4 .tools-public-desc{
        margin-top: 30px;
    }
    .tools-t-sub5 .row div{
        width: 50%;
        margin-bottom: 0px;
    }
    .tools-t-sub5 .row p{
        font-size: 14px;
        width: 82%;
        margin: 20px auto 0;
        line-height: 22px;
    }
    .tools-t-sub5 .row img{
        width: 88%;
    }
    .tools-t-sub6 .row div p{
        font-size: 14px;
    }
    .tools-t-sub6 .row div{
        margin-bottom: 6px;
        margin-left: 4%;
        padding: 16px 10px 14px 36px;
    }
    .tools-t-sub6 .row div img{
        top: 13px;
        left: 12px;
    }
    .tools-t-sub6{
        padding-bottom: 50px;
    }
    .en-style .tools-t-sub6 .row div{
        width: 92%;
        line-height: 22px;
        height: auto;
        padding: 14px 10px 12px 36px;
    }
    .en-style .tools-t-sub6 .row div p{
        font-size: 14px;
    }
    .en-style .tools-t-sub6 .row div img{
        top: 18px;
        left: 12px;
        width: 13px;
    }
    .en-style .tools-public-title{
        font-size: 23px;
    }
    .en-style .tools-t-sub1 .p1{
        line-height: 46px;
        font-size: 30px;
        margin-top: 55px;
    }
    .product{
        width: 100%;
    }
    .product .table-list .flex-1:nth-child(4),.product .table-list .flex-1:nth-child(5){
        display: none;
    }
    .accordion-body{
        padding: 0;
    }
    .product-box__nav{
        font-size: 12px;
    }
    .product-box__accordion-item .accordion-button{
        font-size: 12px;
    }
    .accordion-body{
        font-size: 12px;
    }
    .product .table-list .flex-1{
        font-size: 13px;
    }
    .product .accordion-button:after{
        display: none;
    }
    .product-box__accordion-item .accordion-button{
        padding: 8px 0;
    }
    .en-style2 .public-link2{
        float: unset;
        margin-bottom: 16px;
    }
    .en-style2 .public-link{
        float: unset;
    }
    .en-style2 .tools-t-sub3 .row .title{
        line-height: 24px;
    }
    .en-style2 .tools-t-sub3 .row .desc{
        margin-top: 14px;
    }
    .en-style2 .product{
        margin-top: 30px;
    }
    .en-style2 .tools-public-title{
        padding: 0 8%;
        line-height: 32px;
    }
    .en-style2 .product{
        margin-bottom: 30px;
    }
    .tools-t-sub1 .btn{
        display: block;
    }
    .tools-t-sub1 {
        background: url(../assets/image/TradingInstruments/tools-bg-m.jpg) 100%/auto 100% no-repeat;
        height: 480px;
        margin-top: 0px;
        text-align: center;
        padding-top: 110px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
    .tools-t-sub1 .p1{
        font-size: 27px;
    }
    .account1{
        padding: 50px 2% 50px;
        background: #ffffff;
    }
    .account1 .container{
        width: 100%;
    }
    .account1 .row div{
        width: 33.3%;
    }
    .account1 .row .title{
        font-size: 17px;
        letter-spacing: 0px;
        white-space: nowrap;
        max-width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 20px;
    }
    .account1 .row .desc1, .account1 .row .desc2, .account1 .row .desc3{
        font-size: 14px;
        line-height: 20px;
        margin-top: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        letter-spacing: 0px;
    }
    .account1 .row .div1 {
        margin-top: 22px;
        text-align: right;
    }
    .account1 .btn-box{
        display: none;
    }
    .tools-t-sub3{
        background: #f8f8f8;
    }
    .tools-t-sub3 .row{
        background: #f8f8f8;
    }
    .tools-t-sub3 .row div{
        width: 100%;
    }
    .public-link, .public-link2{
        width: 70%;
        text-align: center;
        margin: 20px auto 10px;
        float: unset;
    }
    .btn-box{
        width: 100%;
    }
    .tools-ash{
        background: #ffffff;
        padding: 60px 3%;
    }
    .tools-ash .tools-public-desc{
        width: 100% !important;
        font-size: 16px;
        line-height: 24px;
    }
    .tools-ash .row img{
        width: 100%;
        margin: 20px auto 30px;
    }
    .tools-white{
        padding: 60px 3%;
        background: #f8f8f8;
    }
    .tools-white .tools-public-desc{
        width: 100% !important;
        font-size: 16px;
        line-height: 24px;
    }
    .tools-white .row img{
        width: 100%;
    }
    
    .tools-public-line{
        margin: 30px auto 30px;
    }
    .tools-t-sub3 .tools-public-line{
        margin-bottom: 0px;
    }
    .en-style2 .tools-t-sub1 .p2{
        padding-top: 14px;
    }
    .en-style2 .account1 .row .title{
        font-size: 16px;
    }
    .en-style2 .tools-public-title{
        font-size: 24px;
    }
    .en-style2 .tools-t-sub3 .row .desc{
        padding: 0 10%;
    }
}
@media(max-width:400px) {
    .tools-t-sub6 .row div p{
        font-size: 13px;
    }
    .tools-t-sub6 .row div{
        margin-bottom: 6px;
        margin-left: 4%;
        padding: 14px 10px 12px 34px;
        height: 38px;
    }
    .tools-t-sub6 .row div img{
        top: 13px;
        left: 12px;
        width: 14px;
    }
    .en-style .tools-t-sub6 .row div{
        width: 92%;
        line-height: 22px;
        height: auto;
    }
    .en-style .tools-t-sub6 .row div p{
        font-size: 14px;
    }
    .account1 .row .title{
        font-size: 16px;
    }
}

@media(max-width:390px) {
    .account1 .row .desc1, .account1 .row .desc2, .account1 .row .desc3{
        font-size: 13px;
    }
}
</style>
