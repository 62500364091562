<template>
<div class="cn-S2">
	<ss-header mode="Home" bg="rgb(38, 5, 5)"></ss-header>

	<div class="winS2-d1">
        <img class="winS2-bg-pc" src="../assets/image/winS2/winS2-bg-cn.jpg" alt="" draggable="false">
        <img class="winS2-bg-m" src="../assets/image/winS2/winS2-bg-cn-m.jpg" alt="" draggable="false">
        <div class="countdown">
            <p class="title">开赛倒计时</p>
            <ul>
                <li><p id="days">{{ days }}</p><span>DAYS</span></li>
                <li><p id="hours">{{ hours }}</p><span>HOURS</span></li>
                <li><p id="minutes">{{ minutes }}</p><span>MUNUTES</span></li>
                <li><p id="seconds">{{ seconds }}</p><span>SECONDS</span></li>
            </ul>
        </div>
    </div>

    <div class="winS2-d2" id="winS2-d2" :style="{top:topNum + 'px'}">
        <a href="javascript:;" id="transmit1" @click="onClick(1)" class="win-sub-nav win-sub-nav-js" :class="{'winS2-active':pagIndex == 1}">大赛简介</a>
        <a href="javascript:;" id="transmit2" @click="onClick(2)" class="win-sub-nav win-sub-nav-js" :class="{'winS2-active':pagIndex == 2}">奖项设置</a>
        <a href="javascript:;" id="transmit3" @click="onClick(3)" class="win-sub-nav win-sub-nav-js" :class="{'winS2-active':pagIndex == 3}">实时赛况</a>
        <a href="javascript:;" id="transmit4" @click="onClick(4)" class="win-sub-nav win-sub-nav-js" :class="{'winS2-active':pagIndex == 4}">参赛须知</a>
        <a href="javascript:;" id="transmit5" @click="onClick(5)" class="win-sub-nav win-sub-nav-js" :class="{'winS2-active':pagIndex == 5}">大赛新闻</a>
        <a href="javascript:;" id="transmit6" @click="onClick(6)" class="win-sub-nav win-sub-nav-js" :class="{'winS2-active':pagIndex == 6}">往届回顾</a>
        <a href="{$register}" rel="nofollow" target="_blank" id="transmit7" class="win-sub-nav win-sub-nav7">立即参赛<img src="../assets/image/winS2/win-s2-icon3.svg" alt=""></a>
    </div>

    <div class="winS2-d3 index1">
        <div class="container">
            <div class="row r1">
                <div class="col-md-5 d1">
                    <img src="../assets/image/winS2/win-s2-2.png" alt="">
                </div>
                <div class="col-md-5 d2">
                    <img src="../assets/image/winS2/win-s2-icon4.svg" alt="">
                    <p>
                        交易，让我们内心同频震动；<br>
                        竞逐，让我们为之热血沸腾。<br>
                        一颗认真交易的赤诚之心，让我们从庸碌的日常中跳出去；<br>
                        一场与全球交易者同屏的竞争，完成我们脑海中关于英雄梦的勾勒。<br><br>

                        EBC全球百万美金交易大赛Ⅱ 重磅回归。<br>
                        以业内奖金最高，门槛最低的诚意，邀你来书写传奇；<br>
                        以交易之王的桂冠，为认真交易的你加冕；<br>
                        以百万美金大奖，见证你举世无双的交易智慧。<br><br>

                        无须犹豫，你就是被选中的那一个。<br>
                        不管你是交易大师，还是刚入场的新人，都只管全力以赴，<br>
                        只因为EBC，会倾力让世界看见你的交易才华。
                    </p>
                </div>
                <div class="col-md-1"></div>
            </div>
            <div class="row r2">
                <div class="col-md-4 li-r2">
                    <div>
                        <img src="../assets/image/winS2/win-s2-icon2.svg" alt="">
                        <p>奖金最高</p>
                        <span>冠军奖励20万美金现金，或100万美金交易账户！</span>
                    </div>
                </div>
                <div class="col-md-4 li-r2">
                    <div>
                        <img src="../assets/image/winS2/win-s2-icon2.svg" alt="">
                        <p>复制冠军</p>
                        <span>百万奖金得主为你操盘，免费复制冠军收益！</span>
                    </div>
                </div>
                <div class="col-md-4 li-r2">
                    <div>
                        <img src="../assets/image/winS2/win-s2-icon2.svg" alt="">
                        <p>公开透明</p>
                        <span>观摩参赛者每笔订单，学习冠军交易技巧！</span>
                    </div>
                </div>
            </div>
            <img class="knight" src="../assets/image/winS2/win-s2-2.png" alt="">
        </div>
    </div>

    <div class="winS2-d4 index2">
        <p class="win-title">奖项设置</p>
        <div class="container">
            <div class="row awards-list">
                <div class="swiper-wrapper slide-box">
                    <div class="awards1 swiper-slide">
                        <p class="p1">冠军</p>
                        <p class="p2">$1,000,000</p>
                        <p class="p3">账户 或 </p>
                        <p class="p2">$200,000</p>
                        <p class="p3">现金</p>
                    </div>
                    <div class="awards2 swiper-slide">
                        <p class="p1">亚军</p>
                        <p class="p2">$30,000</p>
                        <p class="p3">现金<br>（第二名）</p>
                    </div>
                    <div class="awards3 swiper-slide">
                        <p class="p1">季军</p>
                        <p class="p2">$10,000</p>
                        <p class="p3">现金<br>（第三至第五名）</p>
                    </div>
                    <div class="awards4 swiper-slide">
                        <p class="p1">殿军</p>
                        <p class="p2">$5,000</p>
                        <p class="p3">现金<br>（第六至第十名）</p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="winS2-d5 index3">
        <img class="win-title-img" src="../assets/image/winS2/win-s2-3.png" alt="">
        <p class="win-title">实时赛况</p>
        <div class="container">
            <div class="row">
                <div class="winS2-page3">
                        <table class="list">
                            <thead>
                                <tr>
                                    <th style="border-radius: 10px 0 0 0;">
                                        <div>排名</div>
                                    </th>
                                    <th>
                                        <div>昵称</div>
                                    </th>
                                    <th>
                                        <div>MT4</div>
                                    </th>
                                    <th>
                                        <div>净值</div>
                                    </th>
                                    <th>
                                        <div>收益表现</div>
                                    </th>
                                    <th style="border-radius: 0 10px 0 0;" width="160">
                                        <div>查看</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(itx,idx) in infoData" :key="idx" :class="{'active':(idx+1)%2 == 0}">
							<td>
								{{idx+1}}
							</td>
							<td v-if="lang == 'en_US'">{{itx.name}}</td>
							<td v-else>{{itx.nickName}}</td>
							<td>{{itx.mtAccount}}</td>
							<td>{{itx.equity}}</td>
							<td>{{itx.profit}}</td>
							<td>
								<a href="https://ebcfinsns.co" target="_blank"
									v-if="lang == 'zh_CN' || lang == 'zh_TW'">查看</a>
								<a href="https://ebcfinsns.co" target="_blank" v-else-if="lang === 'weiwuer'">پايدا
									ئەھۋالى</a>
								<a href="https://ebcfinsns.co" target="_blank" v-else>Check</a>
							</td>
						</tr>
                            </tbody>
                        </table>
                        <div class="tips flex">
                            *该页面仅展示收益表现排名前20的交易账号，每小时同步更新。
                        </div>
                    </div>
                </div>
            </div>
    </div>


    <div class="winS2-d6 index4">
        <p class="win-title">参赛须知</p>
        <div class="container">
            <div class="row">
                <p class="notice">
                    1. 活动时间为 2024年1月xx日00:00:00至2024年x月xx日23:59:59(GMT+2)；<br>
                    2. 参赛不设资金门槛，不限参赛者账号数量，每人限得一个奖项，取名下最优成绩为排名依据；<br>
                    3. 为确保公开公正，活动期间新开交易账号并注册交易社区发布信号即可参赛，但无须开放跟随；<br>
                    4. 大赛结果根据活动期间参赛账号的收益排名，收益表现参考取值=浮动盈亏+已平仓盈亏；<br>
                    5. 账号类型仅限STD与PRO，PAMM账户无法参赛；<br>
                    6. 冠军若选择100万美金交易账户奖励，可亏损最大数额为20万美金；<br>
                    7. 为保证比赛公平，如遇服务器硬件故障，报价商发送错误报价等非可控因素产生的订单，均视为无效订单；<br>
                    8. 任何滥用报价以及影响比赛公平的套利行为都将被取消比赛资格；<br>
                    9. 本活动最终解释权归EBC所有。
                </p>
                <div class="page-screen-3">
                    <div class="problem-box">
                        <div id="accordionExample" class="accordion">
                            <div id="accordionFlushExample" class="accordion accordion-flush">
                                <div class="accordion-item" v-for="(item, index) in $t('CashInAndOut.screen3.faqList')"
							:key="index">
							<h2 class="accordion-header" :id="'flush-heading' + index">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
									:data-bs-target="'#flush-collapse' + index" aria-expanded="false"
									:aria-controls="'flush-collapse' + index">
									{{index + 1}}. {{item.title}}
								</button>
							</h2>
							<div :id="'flush-collapse' + index" class="accordion-collapse collapse"
								:aria-labelledby="'flush-heading' + index" data-bs-parent="#accordionFlushExample">
								<div class="accordion-body" v-html="item.desc"></div>
							</div>
						</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="winS2-d7 index5">
        <p class="win-title">大赛新闻</p>
        <div class="container" style="position: relative;">
            <div class="row news-list">
                <div class="swiper-wrapper slide-box">
                        <div class="news-li swiper-slide" v-for="(item, index) in newData" :key="index" v-if="item.tips != '' && index < 4"
						@click="jumPage(item)">
                            <div class="thumbnail">
                                <a @click="jumPage(item)"><img :src="item.logo" :alt="item.tit"></a>
                                <div class="caption">
                                    <h3><a @click="jumPage(item)">{{item.tit}}</a></h3>
                                    <span>{{item.time}}</span>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
        </div>
    </div>


    <div class="winS2-d8 index6">
        <p class="win-title">往届回顾</p>
        <div class="container" style="position: relative;">
            <div class="row news-list">
                <div class="swiper-wrapper slide-box">
                        <div class="news-li swiper-slide">
                            <div class="thumbnail">
                                <a href="/zh-cn/win" class="video-news">
                                    <img src="../assets/image/winS2/win-s2-8.png" alt="往期回顾：第一期交易大赛颁奖典礼">
                                </a>
                                <div class="caption">
                                    <h3><a href="/zh-cn/win">往期回顾：第一期交易大赛颁奖典礼</a></h3>
                                </div>
                            </div>
                            <a href="/zh-cn/win" class="svg-play-button"></a>
                        </div>
                        <div class="news-li swiper-slide">
                            <div class="thumbnail">
                                <a href="/zh-cn/win" class="video-news">
                                    <img src="../assets/image/winS2/win-s2-9.png" alt="往期回顾：第一期交易大赛数据释放">
                                </a>
                                <div class="caption">
                                    <h3><a href="/zh-cn/win">往期回顾：第一期交易大赛数据释放</a></h3>
                                </div>
                            </div>
                            <a href="/zh-cn/win" class="svg-play-button"></a>
                        </div>
                        <div class="news-li swiper-slide">
                            <div class="thumbnail">
                                <a href="/zh-cn/win"><img src="../assets/image/winS2/win-s2-10.png" alt="往期回顾：第一期交易大赛选手巡礼"></a>
                                <div class="caption">
                                    <h3><a href="/zh-cn/win">往期回顾：第一期交易大赛选手巡礼</a></h3>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
        </div>
    </div>


    <div class="winS2-footer">
        <span class="span1">每个认真交易的人，都值得被认真对待</span>
        <span class="span2">Where your goals begin.</span>
        <a href="{$register}" rel="nofollow" target="_blank">即刻参赛<img src="../assets/image/winS2/win-s2-icon3.svg" alt=""></a>
    </div>
</div>
</template>

<script>
    import 'swiper/css/swiper.css'
    import Swiper from 'swiper'
	import {
		mapState,
	} from 'vuex'
	export default {
		head() {
			return {
				title: this.title1,
				meta: [ // set meta
					{
						name: 'keyWords',
						content: 'EBC,EBC平台,EBC官网,EBC金融官网,EBC开户,EBC金融'
					},
					{
						name: 'description',
						content: 'EBC金融官网是由EBC金融集团EBC Financial Group成立的,主要提供,EBC金融,EBC开户交易服务,只需一个EBC综合平台账户,即可交易多种差价合约（CFD）:衍生品、美股、数字货币、期货指数、贵金属和能源等.'
					}
				]
			}
		},
		data() {
			return {
				infoData: "",
				newData: "",
                pagIndex: 0,
                topNum: null,
                swiper: null,
                num: 3,
                startDate: new Date("2024-01-15T00:00:00").getTime(),
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0
			}
		},
		computed: {
			...mapState({
				lang: state => state.Lan,
			})
		},
        destroyed() {
			window.removeEventListener('scroll', this.scroll, false)
		},
		created() {
			this.getData()
			this.getNewData()
            $('html,body').animate({
				scrollTop: 0
			}, 0);
		},
        mounted(){
           window.addEventListener('scroll', this.scroll)
           setTimeout(() => {
            if (!this.isMobile()) {
                this.swiper = new Swiper('.winS2-d7 .row', {
					navigation: { // 如果需要前进后退按钮
						nextEl: '.winS2-d7 .swiper-button-next',
						prevEl: '.winS2-d7 .swiper-button-prev'
					},
					slidesPerView: 3,
                    spaceBetween: 25,
					autoplay: true, // 启动自动切换，等同于以下设置
					loop: true // 循环模式选项
                })
            }else{
                this.swiper = new Swiper('.winS2-d7 .row', {
					slidesPerView: 1.15,
                    spaceBetween: 20,
					freeMode: true
                })
            }
            }, 5000)
            
            if (!this.isMobile()) {
                this.swiper = new Swiper('.winS2-d8 .row', {
					navigation: { // 如果需要前进后退按钮
						nextEl: '.winS2-d8 .swiper-button-next',
						prevEl: '.winS2-d8 .swiper-button-prev'
					},
					slidesPerView: 3,
                    spaceBetween: 25,
					autoplay: true, // 启动自动切换，等同于以下设置
					loop: true // 循环模式选项
                })
            }else{
                this.swiper = new Swiper('.winS2-d8 .row', {
					slidesPerView: 1.15,
                    spaceBetween: 20,
					freeMode: true
                })
                this.swiper = new Swiper('.winS2-d4 .row', {
					slidesPerView: 1.15,
                    spaceBetween: 20,
					freeMode: true
                })
            }
            this.updateCountdown();
            setInterval(this.updateCountdown, 1000);
        },
		methods: {
			getNewData() {
				let parms;
				if (this.lang === 'zh_CN') {
					parms = 'zh_CN'
				} else {
					parms = 'en_US'
				}
				this.$http("/ebc/api/getLearnCategory/run", {
					lang: parms,
				}).then(res => {
					this.newData = res.data.data[4].info
				})
			},
			getData() {
				$.ajax({
					type: "POST",
					url: "https://socialtradingwebapi.eappservice.com/api/tradingRank",
					contentType: "application/json",
					dataType: "json",
					data: "{}",
					success: (res) => {
						this.infoData = res.data
					}
				})
			},
            async scroll() {
                this.topNum = $(".index1").offset().top
				const docScrollTop = document.documentElement.scrollTop

				if (docScrollTop <= ($(".index1").offset().top - 76.5)) {
					this.topNum = $(".index1").offset().top - docScrollTop
					this.pagIndex = 0
				} else {
					this.topNum = 76.5
				}

				// if(docScrollTop >= 480 && docScrollTop < $(".index1").offset().top-170){
				// 	this.pagIndex = 1
				// }

				if(docScrollTop >= $(".index1").offset().top-76.5 && docScrollTop < $(".index2").offset().top-170){
					this.pagIndex = 1
				}

				if(docScrollTop >= $(".index2").offset().top-170 && docScrollTop < $(".index3").offset().top-170){
					this.pagIndex = 2
				}

				if(docScrollTop >= $(".index3").offset().top-170 && docScrollTop < $(".index4").offset().top-170){
					this.pagIndex = 3
				}

				if(docScrollTop >= $(".index4").offset().top-170 && docScrollTop < $(".index5").offset().top-170){
					this.pagIndex = 4
				}

				if(docScrollTop >= $(".index5").offset().top-170 && docScrollTop < $(".index6").offset().top-170){
					this.pagIndex = 5
				}

				if(docScrollTop >= $(".index6").offset().top-300){
					this.pagIndex = 6
				}

                

			},
			jumPage(idx) {
				this.$router.push({
					name: 'LearningCenterInfo',
					query: {
						id: idx.id,
						lang: this.$store.state.Lan
					}
				})
			},
            onClick(index) {
				let sectionTop = 150
				if(index == 1){
					sectionTop = 76.5
				}
				$('html,body').animate({
					scrollTop: $(".index" + index).offset().top - sectionTop
				}, 0);
			},
            isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				return flag;
			},
            updateCountdown() {
                var now = new Date().getTime();
                this.timeDifference = this.startDate - now;

                if (this.timeDifference <= 0) {
                    this.timeDifference = 0;
                }

                this.days = Math.floor(this.timeDifference / (1000 * 60 * 60 * 24));
                this.hours = Math.floor((this.timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                this.minutes = Math.floor((this.timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                this.seconds = Math.floor((this.timeDifference % (1000 * 60)) / 1000);
            }
		}
	}

</script>

<style>
	@charset "UTF-8";
@font-face{
    font-family: 'Abril Fatface';
    src : url('../assets/font/AbrilFatface-Regular.ttf');
    font-style: normal;
    font-display: swap;
}
.winS2-d1{
      height: auto;
      margin-top: 0px;
      text-align: center;
      padding-top: 0px;
      position: relative;
}
.winS2-d1 .d1-bg{
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    width: 100%;
}
.winS2-d1 .p1{
    color: #ffcd8e;
    font-size: 56px;
    letter-spacing: 10px;
    font-family: 'Noto Serif SC';
    font-weight: 700;
    margin-top: 356px;
    text-align: center;
}
.winS2-d1 .p2{
    text-align: center;
    background: url(../assets/image/winS2/win-s2-7.png) 100%/auto 100% no-repeat;
    height: 70px;
    text-align: center;
    padding-top: 18px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: max-content;
    margin: 120px auto 0;
}
.winS2-d1 .p2 span{
    font-family: 'Noto Serif SC';
    font-weight: 700;
    font-size: 32px;
    color: #ffffff;
    letter-spacing: 7px;
    margin-left: 36px;
    margin-right: 30px;
}
.winS2-d1 .countdown{
    width: 305px;
    height: 125px;
    border-radius: 5px;
    border: 1px solid #710D0D;
    background: #470101;
    box-shadow: 4.86px 7.432px 1.143px -3.144px rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(2.8584794998168945px);
    margin: 34px auto;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
}
.winS2-d1 .countdown .title{
    letter-spacing: 12px;
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-family: 'Noto Serif SC';
    font-weight: 700;
    margin-top: 20px;
    padding-left: 14px;
}
.winS2-d1 .countdown ul li{
    border-radius: 6.593px;
    background: #2F0000;
    width: 21.3%;
    height: 60px;
    float: left;
    margin-left: 3%;
    margin-top: 14px;
}
.winS2-d1 .countdown ul li p{
    font-family: 'Abril Fatface';
    color: #FFF;
    font-size: 32px;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 7px;
}
.winS2-d1 .countdown ul li span{
    color: #AF8147;
    text-align: center;
    font-family: Poppins;
    font-size: 9px;
    display: block;
}



.winS2-d2{
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(61, 1, 1, 0.08);
  height: 76px;
  text-align: center;
  padding-top: 30px;
  position: fixed;
    left: 0;
    right: 0;
    z-index: 10;
}
.winS2-bg-pc{
    margin-top: -26px;
}
.winS2-bg-m{
    display: none;
}
.winS2-d2 a{
    padding: 8px 24px;
    margin: 0px 8px;
    border: 1px solid #4D0101;
    color: #4D0101;
    font-size: 16px;
    font-family: 'Noto Serif SC';
}
.winS2-d2 a:hover{
    background: #AF8147;
    color: #fff;
}
.winS2-d2 .win-sub-nav7{
    border: 1px solid #AF8147 !important;
    color: #fff !important;
    background: #AF8147 !important;
}
.winS2-d2 .win-sub-nav7 img{
    margin-left: 10px;
}
.winS2-d2 a:hover{
    background: #470101;
}

.winS2-d3{
    padding-bottom: 60px;
    padding-top: 80px;
}
.winS2-d3 .r1{
    margin-top: 40px;
}
.winS2-d3 .r1 .d1{
    text-align: center;
    margin-left: 5%;
}
.winS2-d3 .r1 .d1 img{
    width: 87%;
}
.winS2-d3 .r1 .d2{
    text-align: center;
    margin-top: 96px;
}
.winS2-d3 .r1 .d2 p{
    color: #621915;
    text-align: center;
    font-family: 'Noto Serif SC';
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 1px;
    margin-top: 30px;
}
.winS2-d3 .r1 .d2 img{
    width: 20px;
}
.winS2-d3 .r2{
    margin-top: 60px;
    padding: 0 6%;
}

.winS2-d3 .r2 .li-r2 div{
    border: 1px dashed #949699;
    border-radius: 5px;
    padding: 24px;
    text-align: center;
    margin: 0 auto;
}
.winS2-d3 .r2 .li-r2 p{
    color: #3D0101;
    font-size: 18px;
    font-weight: 700;
    margin-top: 16px;
    margin-bottom: 16px;
    font-family: 'Noto Serif SC';
    letter-spacing: 3px;
}
.winS2-d3 .r2 .li-r2 span{
    color: #666;
    font-size: 14px;
    font-family: 'Noto Serif SC';
    line-height: 21px;
}



.winS2-d4{
    text-align: center;
    background: url(../assets/image/winS2/win-s2-4.png) 100%/auto 100% no-repeat;
    height: auto;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 110px;
    padding-bottom: 100px;
}
.winS2-d4 .win-title{
    color: #ffffff;
    text-align: center;
    font-family: 'Noto Serif SC';
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 2px;
    margin-bottom: 70px;
}
.winS2-d4 .row{
    padding: 0 9%;
}
.winS2-d4 .awards1{
    width: 28%;
    height: auto;
    margin: 0 1.5%;
    padding: 30px;
    border-radius: 5px;
    border: 1px solid #E0BA84;
    background: linear-gradient(150deg, #FFDCAA 1.97%, #FEDFB4 40.36%, #8B6C48 92.97%);
    box-shadow: 4px 4px 8px -3.144px rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(2.8584794998168945px);
}
.winS2-d4 .awards2{
    width: 20%;
    height: auto;
    margin: 30px 1.5% 0;
    padding: 30px;
    border-radius: 5px;
    border: 1px solid #C7C5C0;
    background: linear-gradient(150deg, #F0EFEE 1.97%, #DCDBDA 36.57%, #9A9896 92.97%);
    box-shadow: 4px 4px 8px -3.144px rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(2.8584794998168945px);
}
.winS2-d4 .awards3{
    width: 20%;
    height: auto;
    margin: 50px 1.5% 0;
    padding: 30px;
    border-radius: 5px;
    border: 1px solid #C7C5C0;
    background: linear-gradient(150deg, #F0EFEE 1.97%, #DCDBDA 36.57%, #9A9896 92.97%);
    box-shadow: 4px 4px 8px -3.144px rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(2.8584794998168945px);
}
.winS2-d4 .awards4{
    width: 20%;
    height: auto;
    margin: 70px 1.5% 0;
    padding: 30px;
    border-radius: 5px;
    border: 1px solid #C7C5C0;
    background: linear-gradient(150deg, #F0EFEE 1.97%, #DCDBDA 36.57%, #9A9896 92.97%);
    box-shadow: 4px 4px 8px -3.144px rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(2.8584794998168945px);
}

.winS2-d4 .awards1 .p1,.winS2-d4 .awards2 .p1,.winS2-d4 .awards3 .p1,.winS2-d4 .awards4 .p1{
    background: url(../assets/image/winS2/win-s2-icon6.svg) 100%/auto 100% no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 80px;
    height: 80px;
    line-height: 80px;
    margin: 20px auto 26px;
}

.winS2-d4 .awards1 .p3{
    color: #3D0101;
}


.winS2-d4 div .p1{
    color: #6D0101;
    font-family: 'Noto Serif SC';
    font-size: 24px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
}
.winS2-d4 div .p2{
    font-family: 'Abril Fatface';
    font-size: 36px;
    color: #8D0101;
    line-height: 40px;

}
.winS2-d4 div .p3{
    font-family: 'Noto Serif SC';
    color: #333;
    letter-spacing: 1px;
    font-size: 14px;
    line-height: 20px;
}



.winS2-page3 {
    padding: 28px 30px 0;
    background-size: 100% 100%;
    padding-bottom: 20px;
    margin-bottom: 70px;
}

.winS2-page3 .list {
    width: 100%;
    text-align: center;
    border-radius: 10px;
}


.winS2-page3 .list th {
    background: #ffffff;
    color: #000000;
    line-height: 72px;
    font-size: 18px;
    font-weight: 700;
}

.winS2-page3 .list td {
    color: #292D32;
    line-height: 66px;
    font-size: 16px;
    font-family: auto;
    font-weight: 500;
}

.winS2-active {
	background-color: #4D0101;
	color: #ffffff !important;
}


.winS2-page3 .list td:first-child {
    min-width: 50px;
    color: #3D0101;
}

.winS2-page3 .list td a {
    color: #AF8147;
    padding: 8px 28px;
    border: 1px solid #AF8147;
    border-radius: 5px;
    font-size: 14px;
}
.winS2-page3 .list td a:hover{
    background: #AF8147;
    color: #ffffff;
}


.winS2-page3 .tips {
    color: #B5B7C0;
    line-height: 61px;
    font-size: 14px;
    width: 100%;
    background: #fff;
    padding-left: 40px;
    padding-bottom: 10px;
    font-family: auto;
    border-radius: 0px 0 10px 10px;
}

.winS2-page3 .tips .ps {
    color: #e7c48f
}

.winS2-d5{
    background: #F3F3F3;
    padding-top: 70px;
}

.winS2-d5 .win-title{
    color: #3D0101;
    text-align: center;
    font-family: 'Noto Serif SC';
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 2px;
    margin-bottom: 0px;
}
.winS2-d5 .win-title-img{
    margin: 0px auto 30px;
    display: block;
    width: 180px;
}
.winS2-page3 .list tr{
    border-bottom: 1px solid #e7e7e7;
}
.winS2-page3 .list tr{
    background: #ffffff;
}
.winS2-page3 .list tr:nth-child(-n+3){
    background: #FEFAF6;
}
.winS2-d5 .row{
    padding: 20px 7%;
}
.winS2-page3 .list td:nth-child(5) {
    color: #1b7d14;
}

.winS2-d6{
    text-align: center;
    background: url(../assets/image/winS2/win-s2-6.png) 100%/auto 100% no-repeat;
    height: auto;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 110px;
    padding-bottom: 80px;
}

.winS2-d6 .win-title{
    color: #ffffff;
    text-align: center;
    font-family: 'Noto Serif SC';
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 2px;
    margin-bottom: 0px;
}
.winS2-d6 .row{
    padding: 0 18%;
}
.winS2-d6 .notice{
    color: #c2c2c2;
    font-family: 'Noto Serif SC';
    font-size: 16px;
    line-height: 34px;
    letter-spacing: 1px;
    text-align: left;
    margin-top: 40px;
    margin-bottom: 40px;
}


.winS2-d6 .page-screen-3 .problem-box .accordion {
    padding: 0px 10px 0px
}

.winS2-d6 .page-screen-3 .problem-box .accordion-item {
    border-radius: 4px 4px 4px 4px;
    overflow: hidden;
    border: 0 solid #d9d9d9;
    margin-bottom: 12px;
}


.winS2-d6 .page-screen-3 .problem-box .accordion-button {
    font-size: 16px;
    line-height: 1.5;
    color: #621915;
}


.winS2-d6 .page-screen-3 .problem-box .accordion .cont-info {
    display: none
}

.winS2-d6 .page-screen-3 .problem-box .accordion .cont-info.active {
    display: block
}

.winS2-d6 .page-screen-3 .problem-box .accordion .accordion-body {
    line-height: 24px;
    font-size: 16px;
    color: #585858;
    text-align: left;
    padding-top: 6px;
}


.winS2-d6 .page-screen-3 .problem-box .accordion-item {
    border-bottom: 1px solid #e7e7e7;
}

.rotate .accordion-button:after{
    transform: rotate(180deg);
}
.winS2-d6 .page-screen-3 .problem-box #accordionExample{
    max-height: 260px;
    overflow-y: auto;
    border-radius: 10px;
    position: relative;
}


.winS2-d6 .page-screen-3 .problem-box #accordionExample::-webkit-scrollbar {
  width: 8px; /* 设置滚动条宽度 */
}

.winS2-d6 .page-screen-3 .problem-box #accordionExample::-webkit-scrollbar-thumb {
  background-color: #b1b1b1; /* 设置滚动条拖动部分的颜色 */
  border-radius: 6px; /* 设置滚动条拖动部分的圆角 */
}

.winS2-d6 .page-screen-3 .problem-box #accordionExample::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* 设置滚动条轨道的颜色 */
}

.winS2-d6 .page-screen-3{
    background: #fff;
    border-radius: 10px;
    padding-top: 18px;
    padding-bottom: 20px;
}

.winS2-d7 .container{
    width: 1100px;
}
.winS2-d7 .news-li{
    padding: 0;
    padding-bottom: 20px;
    border-radius: 5px;
    margin-bottom: 30px;
    background-color: #fff;
    height: auto;
    cursor: pointer;
}
.winS2-d7 .news-li img{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transform: none;
    object-position: left;
    width: 100%;
    height: auto;
}
.winS2-d7 .news-li h3 a{
    margin: 14px 16px 10px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    display: block;
    color: #333;
    overflow:hidden; 
    text-overflow:ellipsis;
    display:-webkit-box; 
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2; 
    text-align: left;
}
.winS2-d7 .news-li p{
    margin: 0 16px 10px;
    color: #666;
    font-size: 16px;
    line-height: 1.5;
    font-family: auto;
    overflow:hidden; 
    text-overflow:ellipsis;
    display:-webkit-box; 
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2; 
    text-align: left;
}
.winS2-d7 .news-li span{
    padding: 0 16px 10px;
    color: #666;
    font-size: 14px;
    text-align: left;
}
.winS2-d7 .news-list{
    overflow: hidden;
    margin-top: 50px;
    text-align: left;
}
.winS2-d7 .swiper-wrapper{
    padding: 0;
}
.winS2-d7 .swiper-button-prev {
    left: -52px;
    background-image: url(../assets/image/winS2/winS2-prev1.svg);
    width: 22px;
    height: 44px;
    background-size: 90%;
    background-repeat: no-repeat;
    top: 45%;
}

.winS2-d7 .swiper-button-next {
    right: -52px;
    background-image: url(../assets/image/winS2/winS2-next1.svg);
    width: 22px;
    height: 44px;
    background-size: 90%;
    background-repeat: no-repeat;
    top: 46%;
}
.swiper-button-next:after, .swiper-button-prev:after {
    content: "";
}
.winS2-d7{
    text-align: center;
    background: url(../assets/image/winS2/win-s2-5.png) 100%/auto 100% no-repeat;
    height: auto;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 130px;
    padding-bottom: 130px;
}

.winS2-d7 .win-title{
    color: #3D0101;
    text-align: center;
    font-family: 'Noto Serif SC';
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 2px;
    margin-bottom: 0px;
}


.winS2-d8{
    text-align: center;
    background: #200000;;
    height: 610px;
    padding-top: 110px;
}

.winS2-d8 .win-title{
    color: #ffffff;
    text-align: center;
    font-family: 'Noto Serif SC';
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 2px;
    margin-bottom: 0px;
}
.winS2-d8 .container{
    width: 1100px;
}
.winS2-d8 .news-li{
    padding: 0;
    padding-bottom: 20px;
    border-radius: 5px;
    margin-bottom: 30px;
    background-color: #3D0101;
    height: auto;
    cursor: pointer;
}
.winS2-d8 .news-li img{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transform: none;
    object-position: left;
    width: 100%;
    height: auto;
}
.winS2-d8 .news-li h3 a{
    margin: 16px 16px 6px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    display: block;
    color: #fff;
    overflow:hidden; 
    text-overflow:ellipsis;
    display:-webkit-box; 
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2; 
    text-align: left;
}
.winS2-d8 .news-li p{
    margin: 0 16px 10px;
    color: #666;
    font-size: 16px;
    line-height: 1.5;
    font-family: auto;
    overflow:hidden; 
    text-overflow:ellipsis;
    display:-webkit-box; 
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2; 
    text-align: left;
}
.winS2-d8 .news-li span{
    padding: 0 16px 10px;
    color: #666;
    font-size: 14px;
    text-align: left;
}
.winS2-d8 .news-list{
    overflow: hidden;
    margin-top: 50px;
    text-align: left;
}
.winS2-d8 .swiper-wrapper{
    padding: 0;
}
.winS2-d8 .swiper-button-prev {
    left: -52px;
    background-image: url(../assets/image/winS2/winS2-prev2.svg);
    width: 22px;
    height: 44px;
    background-size: 90%;
    background-repeat: no-repeat;
    top: 45%;
}

.winS2-d8 .swiper-button-next {
    right: -52px;
    background-image: url(../assets/image/winS2/winS2-next2.svg);
    width: 22px;
    height: 44px;
    background-size: 90%;
    background-repeat: no-repeat;
    top: 46%;
}
.winS2-d8 .svg-play-button{
    position: absolute;
    top: 40%;
    left: 50%;
    width: 60px;
    height: 60px;
    background-image: url(../assets/image/winS2/play.svg);
    background-size: contain;
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 99;
}


.winS2-footer{
    width: 100%;
    height: 80px;
    background: #000;
    text-align: center;
    /*position: fixed;
    bottom: 0;*/
}
.winS2-footer .span1{
    color: #AF8147;
    letter-spacing: 2px;
    font-size: 16px;
    font-family: 'Noto Serif SC';
    line-height: 80px;
}
.winS2-footer .span2{
    color: #AF8147;
    letter-spacing: 1px;
    font-size: 16px;
    font-family: 'Noto Serif SC';
    line-height: 80px;
    margin-left: 40px;
    float: none;
}
.winS2-footer a{
    padding: 8px 24px;
    margin: 0px 12px;
    border-radius: 5px;
    font-size: 16px;
    font-family: 'Noto Serif SC';
    border: 1px solid #AF8147;
    color: #fff;
    background: #AF8147;
    margin-left: 40px;
}
.winS2-footer a img {
    margin-left: 10px;
}

.winS2-d3 .knight{
    display: none;
}

.winS2-d2-lock{
    position: fixed;
    right: 0;
    left: 0;
    top: 77px;
    z-index: 999;
}


/*.winS2-d2{
    position: fixed;
    right: 0;
    left: 0;
    top: 77px;
    z-index: 999;
}*/


.en-S2 .winS2-d1 .countdown .title{
    padding-left: 0px;
    letter-spacing: 2px;
}

.en-S2 .winS2-d3 .r1 .d1 img{
    width: 76%;
}
.en-S2 .winS2-d3 .r1 .d2{
    margin-top: 155px;
}

.en-S2 .winS2-d3 .r1 .d2 p{
    letter-spacing: 0px;
    font-size: 16px;
    line-height: 38px;
}

.en-S2 .winS2-d3 .r2 .li-r2 p{
    letter-spacing: 0px;
}
.en-S2 .winS2-d3 .r2 .li-r2 span{
    font-size: 15px;
    line-height: 23px;
}
.en-S2 .winS2-d4 .awards1 .p1, .en-S2 .winS2-d4 .awards2 .p1, .en-S2 .winS2-d4 .awards3 .p1, .en-S2 .winS2-d4 .awards4 .p1{
    background: none;
    width: auto;
    height: auto;
    line-height: 30px;
    font-size: 26px;
    margin-bottom: 18px;
}
.en-S2 .winS2-d4 div .p3{
    letter-spacing: 0px;
}
.en-S2 .winS2-d4 div .p2{
    font-size: 30px;
}
.en-S2 .winS2-d4 .win-title,.en-S2 .winS2-d5 .win-title,.en-S2 .winS2-d6 .win-title,.en-S2 .winS2-d7 .win-title,.en-S2 .winS2-d8 .win-title{
    letter-spacing: 0px;
}   
.en-S2 .winS2-d4{
    height: auto;
}
.en-S2 .winS2-d6 .notice{
    letter-spacing: 0px;
}
.en-S2 .winS2-d6 .page-screen-3 .problem-box #accordionExample{
    max-height: 240px;
}
.en-S2 .winS2-d8 .news-li h3 a{
    font-size: 16px;
    font-weight: 400;
    margin: 16px 16px 0px;
}
.en-S2 .winS2-footer .span1,.en-S2 .winS2-footer .span2{
    letter-spacing: 1px;
}
.jp-S2 .winS2-d3 .r1 .d2{
    margin-top: 56px;
}
.jp-S2 .winS2-d4 .awards1 .p1,.jp-S2 .winS2-d4 .awards2 .p1, .jp-S2 .winS2-d4 .awards3 .p1, .jp-S2 .winS2-d4 .awards4 .p1{
    background: none;
    width: auto;
    height: auto;
    line-height: 30px;
    font-size: 26px;
    margin-bottom: 18px;
}
.jp-S2 .winS2-d4 div .p2{
    font-size: 30px;
}

.th-S2 .winS2-d3 .r1 .d2{
    margin-top: 26px;
}
.th-S2 .winS2-d3 .r1 .d1 img{
    width: 89%;
}
.th-S2 .winS2-d3 .r2 .li-r2 span{
    font-size: 14px;
    line-height: 21px;
}
.winS2-join-now{
    display: block;
    width: 230px;
    height: 80px;
    z-index: 99;
    position: absolute;
    bottom: 23%;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
}
.FAQ-win-title{
    color: #ffffff;
    text-align: center;
    font-family: 'Noto Serif SC';
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: 10px;
}


@media(max-width:1700px) {
    .winS2-d1 .p1{
        margin-top: 320px;
        font-size: 48px;
    }
}

@media(max-width:1600px) {
  .winS2-d2-lock{
    top: 74.5px;
  }
  .winS2-d1 .countdown{
        bottom: 8px;
    }
}

@media(max-width:1500px) {
    .winS2-d1 .countdown{
        bottom: 2px;
    }
    .en-S2 .winS2-d4{
        height: auto;
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .en-S2 .winS2-d4 .awards1 .p1, .en-S2 .winS2-d4 .awards2 .p1, .en-S2 .winS2-d4 .awards3 .p1, .en-S2 .winS2-d4 .awards4 .p1{
        font-size: 22px;
    }
    .en-S2 .winS2-d4 div .p2{
        font-size: 28px;
    }
}

@media(max-width:1400px) {
    .winS2-join-now{
        height: 60px;
    }
    .winS2-d1 .p1{
        margin-top: 250px;
        font-size: 46px;
    }
    .winS2-d1 .p2 span{
        font-size: 26px;
    }
    .winS2-d1 .p2{
        height: 62px;
        margin: 106px auto 0;
    }
    .winS2-d1 .countdown{
        bottom: 2px;
        width: 285px;
        height: 105px;
    }
    .winS2-d1 .countdown .title{
        font-size: 18px;
        margin-top: 14px;
    }
    .winS2-d1 .countdown ul li{
        height: 52px;
        margin-top: 11px;
    }
    .winS2-d1 .countdown ul li p{
        font-size: 24px;
        margin-top: 6px;
    }
    .winS2-d1 .countdown ul li span{
        font-size: 8px;
    }
    .winS2-d3 .r1 .d2{
        margin-top: 20px;
    }
    .winS2-d6 .row{
        padding: 0 14%;
    }
    .winS2-d7 .container{
        width: 900px;
    }
    .winS2-d8{
        height: 570px;
    }
    .winS2-d8 .container{
        width: 900px;
    }
    .winS2-d4{
        height: auto;
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .winS2-d6{
        padding-top: 110px;
    }
    .winS2-d8{
        padding-top: 100px;
    }
    .jp-S2 .winS2-d3 .r1 .d1 img{
        width: 97%;
        margin-top: 40px;
    }
    .en-S2 .winS2-d3 .r1 .d2{
        margin-top: 80px;
    }
}


@media(max-width:1300px) {
    .winS2-d1 .p1{
        margin-top: 214px;
        font-size: 38px;
    }
    .winS2-d1 .p2 span{
        font-size: 22px;
    }
    .winS2-d1 .p2 img{
        width: 18px;
    }
    .winS2-d1 .p2{
        height: 62px;
        margin: 82px auto 0;
    }
    .winS2-d1 .countdown{
        margin-top: 22px;
    }
}


@media(max-width:996px) {
    .winS2-d3 {
        padding-top: 0;
    }
    .winS2-d2{
        display: none;
    }
    .winS2-d7 .container{
        width: 100%;
    }
    .winS2-d8 .container{
        width: 100%;
    }
    .swiper-button-prev,.swiper-button-next{
        display: none;
    }
    .winS2-d3 .r1 .d1{
        margin-left: 0;
    }
    .winS2-d4 .awards-list{
        overflow: hidden;
    }
    .winS2-d4 .awards-list .swiper-wrapper{
        margin-right: 40px;
    }
    .winS2-d1{
      height: auto;
      margin-top: 0px;
      text-align: center;
      padding-top: 0px;
      position: relative;
      margin-top: -60px;
    }
    .winS2-bg-pc{
        display: none;
    }
    .winS2-bg-m{
        display: block;
    }
    .winS2-d1 .p1 {
        margin-top: 110px;
        font-size: 36px;
        padding: 0 10%;
        letter-spacing: 3px;
        line-height: 50px;
    }
    .winS2-d1 .p2{
        height: 56px;
        margin: 24px auto 0;
    }
    .winS2-d1 .p2 span{
        font-size: 18px;
        letter-spacing: 4px;
        margin-left: 12px;
        margin-right: 12px;
    }
    .winS2-d1 .p2 img{
        width: 14px;
    }
    
    .winS2-d1 .countdown{
        border-radius: 10px;
        border: 2px solid #710D0D;
        background: #470101;
        box-shadow: 2.72px 15.86px 18.29px -9.29px rgba(0, 0, 0, 0.50);
        backdrop-filter: blur(5.716958999633789px);
        width: 77%;
        height: 123px;
        bottom: 4px;
    }
    .winS2-d1 .countdown ul li{
        height: 66px;
    }
    .winS2-d1 .countdown ul li p{
        font-size: 32px;
    }
    .winS2-d3 .r1{
        margin-top: 30px;
    }
    .winS2-d3 .r1 .d1{
        display: none;
    }
    .winS2-d3 .r1 .d2 p{
        padding: 0 5%;
    }
    .winS2-d3 .r2{
        padding: 0 2%;
        padding-left: 50%;
    }
    .winS2-d3 .r2 .li-r2 div{
        margin-bottom: 20px;
        padding: 16px;
    }
    .winS2-d3 .r2 .li-r2 p{
        margin-top: 12px;
        margin-bottom: 12px;
    }
    .winS2-d3 .r2 .li-r2 div img{
        width: 26px;
    }
    .winS2-d3 .knight{
        display: block;
        position: absolute;
        bottom: 86px;
        width: 74%;
        left: -21%;
    }
    .winS2-d3{
        position: relative;
    }
    .winS2-d3{
        padding-bottom: 50px;
    }
    .winS2-page3 .list th:nth-child(3) {
        display: none;
    }
    .winS2-page3 .list td:nth-child(3) {
        display: none;
    }
    .winS2-page3{
        padding-top: 30px;
        padding-left: 0;
        padding-right: 0;
    }
    .winS2-d5 .row{
        padding: 0 4%;
    }
    .winS2-page3 .list td a{
        padding: 4px 14px;
        font-size: 13px;
    }
    .winS2-page3 .list th{
        font-size: 15px;
        line-height: 56px;
        font-size: 14px;
    }
    .winS2-page3 .list td{
        font-size: 14px;
        line-height: 54px;
        font-size: 13px;
    }
    .winS2-page3 .list th:nth-child(6){
        width: 20%;
    }
    .winS2-page3 .list th:nth-child(1){
        width: 10%;
    }
    .winS2-d4 .win-title,.winS2-d5 .win-title,.winS2-d6 .win-title,.winS2-d7 .win-title,.winS2-d8 .win-title{
        font-size: 28px;
    }
    .winS2-d4{
        height: auto;
        padding-top: 100px;
        padding-bottom: 90px;
    }
    .winS2-d4 .row{
        padding: 0 10% 0 12%;
    }
    .winS2-page3 .tips{
        line-height: 24px;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 12px;
    }
    .winS2-page3{
        margin-bottom: 40px;
    }
    .winS2-d6{
        padding-top: 90px;
        height: auto;
        padding-bottom: 50px;
    }
    .winS2-d6 .row{
        padding: 0 7%;
    }
    .winS2-d6 .notice{
        line-height: 28px;
        letter-spacing: 0px;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 30px;
    }
    .winS2-d6 .page-screen-3{
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .winS2-d6 .page-screen-3 .problem-box .accordion .accordion-body{
        padding-left: 0;
        padding-right: 0;
    }
    .winS2-d6 .page-screen-3 .problem-box .accordion-button{
        padding-left: 0;
        padding-right: 0;
    }
    .winS2-d6 .page-screen-3 .problem-box #accordionExample{
        max-height: 200px;
    }
    .winS2-d7 {
        height: 580px;
        padding-top: 90px;
    }
    .winS2-d7 .row {
        padding: 0 8% 0 7%;
    }
    .winS2-d8 {
        height: 540px;
        padding-top: 90px;
    }
    .winS2-d8 .row {
        padding: 0 8% 0 7%;
    }
    .winS2-footer{
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .winS2-footer .span1,.winS2-footer .span2{
        display: block;
        line-height: 20px;
        font-size: 14px;
        letter-spacing: 1px;
        margin-left: 0;
    }
    .winS2-footer .span2{
        margin-top: 2px;
    }
    .winS2-footer a{
        display: block;
        width: max-content;
        margin: 0 auto;
        padding: 12px 24px;
        margin-top: 12px;
    }
    .winS2-d7 .news-li h3 a,.winS2-d8 .news-li h3 a{
        font-size: 18px;
    }


    .en-S2 .winS2-d3 .r2 .li-r2 p{
        letter-spacing: 0px;
        line-height: 20px;
        font-size: 16px;
    }
    .en-S2 .winS2-d3 .r2 .li-r2 span{
        font-size: 14px;
        line-height: 20px;
    }
    .en-S2 .winS2-d3 .knight{
        bottom: 99px;
        width: 83%;
        left: -26%;
    }
    .en-S2 .winS2-d4{
        padding-bottom: 110px;
        height: auto;
    }
    .en-S2 .winS2-d4 .win-title, .en-S2 .winS2-d5 .win-title, .en-S2 .winS2-d6 .win-title, .en-S2 .winS2-d7 .win-title, .en-S2 .winS2-d8 .win-title{
        font-size: 24px;
    }
    .en-S2 .winS2-page3 .list td a{
        border: none;
        padding: 0;
    }
    .en-S2 .winS2-page3 .list td{
        font-size: 12px;
        line-height: 16px;
        padding: 10px 0;
    }
    .en-S2 .winS2-page3 .list th{
        font-size: 14px;
        padding: 10px 6px;
        line-height: 16px;
    }
    .en-S2 .winS2-d6{
        padding-top: 60px;
    }
    .jp-S2 .winS2-d3 .r1 .d2{
        margin-top: 16px;
    }
    .en-S2 .winS2-d3 .r1 .d2{
        margin-top: 20px;
    }
    .en-S2 .winS2-d3 .r1 .d2 p{
        font-size: 15px;
        line-height: 32px;
    }


    .jp-S2 .winS2-d4 .win-title, .jp-S2 .winS2-d5 .win-title, .jp-S2 .winS2-d6 .win-title, .jp-S2 .winS2-d7 .win-title, .jp-S2 .winS2-d8 .win-title{
        font-size: 24px;
    }
    .winS2-page3 .list td a{
        border: none;
        padding: 0;
    }
    .winS2-page3 .list td{
        font-size: 12px;
        line-height: 16px;
        padding: 10px 0;
    }
    .winS2-page3 .list th{
        font-size: 14px;
        padding: 10px;
        line-height: 16px;
    }
    .jp-S2 .winS2-d6{
        padding-top: 60px;
    }
    .jp-S2 .winS2-d3 .r1 .d2{
        margin-top: 16px;
    }
    .jp-S2 .winS2-d3 .r1 .d2{
        margin-top: 20px;
    }
    .jp-S2 .winS2-d3 .r1 .d2 p{
        font-size: 15px;
        line-height: 32px;
    }
    .winS2-d5{
        padding-top: 50px;
    }
    .winS2-join-now {
        height: 48px;
        bottom: 27%;
        width: 38%;
    }
    .FAQ-win-title{
        font-size: 22px;
        line-height: 30px;
    }
    .en-S2 .winS2-d4 .win-title, .en-S2 .winS2-d5 .win-title, .en-S2 .winS2-d6 .win-title, .en-S2 .winS2-d7 .win-title, .en-S2 .winS2-d8 .win-title{
        font-size: 22px;
        line-height: 30px;
        padding: 0 3%;
    }
    .winS2-d6 .notice{
        margin-top: 24px;
    }
}

@media(max-width:420px){
}

</style>

