<template>
	<div class="TC">
		<div class="content TC-head">
			<router-link to="/"><img src="../assets/image/TC/logo.png" alt="" class="logo"></router-link>
			<div class="TC-head__jb">
				<img src="../assets/image/TC/tc-7.png" alt="">
			</div>
			<div class="TC-head__h1" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
				<img src="../assets/image/TC/tc-10.png" alt="">
			</div>
			<div class="TC-head__h1" v-else-if="lang === 'weiwuer'">
				<img src="../assets/image/TC/tc-15.png" alt="">
			</div>
			<div class="TC-head__h1" v-else>
				<img src="../assets/image/TC/tc-11.png" alt="">
			</div>
			<div class="TC-head__h1 wap" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
				<img src="../assets/image/TC/tc-13.png" alt="">
			</div>
			<div class="TC-head__h1 wap" v-else-if="lang === 'weiwuer'">
				<img src="../assets/image/TC/tc-16.png" alt="">
			</div>
			<div class="TC-head__h1 wap" v-else>
				<img src="../assets/image/TC/tc-11.png" alt="">
			</div>
			<div class="TC-head__tips">
				<div class="tips-box" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
					悬赏百万美金，寻找交易之王
				</div>
				<div class="tips-box" v-else-if="lang === 'weiwuer'">
					بىر مىليون ئامېرىكا دوللارمۇكاپات، سودا شاھىنى ئىزدەش
				</div>
				<div class="tips-box" v-else>
					Million dollar reward for the king of trading
				</div>
			</div>
			<div class="TC-head__btn">
				<a class="btn-box" href="https://client.ebccrm.com/signup/index" target="_blank"
					v-if="lang == 'zh_CN' || lang == 'zh_TW'">
					立即报名
				</a>
				<a class="btn-box" href="https://client.ebccrm.com/signup/index" target="_blank"
					v-else-if="lang === 'weiwuer'">
					دەرھال تىزىملىتىڭ.
				</a>
				<a class="btn-box" href="https://client.ebccrm.com/signup/index" target="_blank" v-else>
					Register now
				</a>
			</div>
		</div>
		<div class="TC-title">
			<div class="content">
				<div class="flex tit" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
					<img src="../assets/image/TC/tc-8.png" alt="">
					瓜分百万美金 <img src="../assets/image/TC/tc-8.png" alt="">
				</div>
				<div class="flex tit" v-else-if="lang === 'weiwuer'">
					<img src="../assets/image/TC/tc-8.png" alt="">
					بىر مىليون ئامېرىكا دوللىرىغا ئىېرشىۋىلىڭ. <img src="../assets/image/TC/tc-8.png" alt="">
				</div>
				<div class="flex tit" v-else>
					<img src="../assets/image/TC/tc-8.png" alt="">
					$1,000,000 Reward <img src="../assets/image/TC/tc-8.png" alt="">
				</div>
			</div>
		</div>
		<div class="TC-screen">
			<div class="content">
				<img src="../assets/image/TC/tc-9.png" alt="" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
				<img src="../assets/image/TC/tc-14.png" alt="" v-else-if="lang === 'weiwuer'">
				<img src="../assets/image/TC/tc-1.png" alt="" v-else>
				<div class="TC-screen__btn flex flex-ajcenter">
					<div class="TC-screen__btn__top">
						<div class="text" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							抢夺200,000美金现金
						</div>
						<div class="text" v-else-if="lang === 'weiwuer'">
							200000 ئامېرىكا دوللىرى نەق پۇلىغا ئىىرشىڭ
						</div>
						<div class="text" v-else>
							Compete now for your chance at $1,000,000！
						</div>
					</div>
					<a href="https://client.ebccrm.com/signup/index" class="TC-screen__btn__bot" target="_blank"
						v-if="lang == 'zh_CN' || lang == 'zh_TW'">
						立即报名>
					</a>
					<a href="https://client.ebccrm.com/signup/index" class="TC-screen__btn__bot" target="_blank"
						v-else-if="lang === 'weiwuer'">
						دەرھال تىزىملىتىڭ.
					</a>
					<a href="https://client.ebccrm.com/signup/index" class="TC-screen__btn__bot" target="_blank" v-else>
						Register now>
					</a>
				</div>
			</div>
		</div>
		<div class="TC-screen1">
			<div class="content">
				<div class="TC-screen1__box">
					<div class="box-li" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
						<img src="../assets/image/TC/tc-3.png" alt="">
						活动时间: 2023年1月30日00:00:00至2023年4月30日23:59:59（GMT+2）
					</div>
					<div class="box-li" v-else-if="lang === 'weiwuer'">
						<img src="../assets/image/TC/tc-3.png" alt="">
						پائالىيەت ۋاقتى: 2023-يىلى 2-ئاينىڭ 5-كۈنى 00: 00 دىن 2023-يىلى 4-ئاينىڭ 30-كۈنى سائەت 23: 59:
						59 غىچە (GMT
						+ 2)
					</div>
					<div class="box-li" v-else>
						<img src="../assets/image/TC/tc-3.png" alt="">
						Competition period: January 30, 2023, 00:00:00 to April 30, 2023 23:59:59 (GMT+2).
					</div>
					<div class="box-li" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
						<img src="../assets/image/TC/tc-4.png" alt="">
						参赛对象: 活动期间新开交易账号并注册交易社区发布信号，<p>且入金超过$500，不限参赛者账号数量</p>
					</div>
					<div class="box-li" v-else-if="lang === 'weiwuer'">
						<img src="../assets/image/TC/tc-4.png" alt="">
						مۇسابىقىگە قاتنىشىدىغان ئوبيېكتلار: پائالىيەت مەزگىلىدە يېڭىدىن ئېچىلغان ھەمدە سودا مەھەللىسىدە
						تىزىمغا
						ئېلىپ سىگنال تارقتشى، ھەمدە كىرگەن پۇل 500 $ دىن ئېششى كېرەك ، مۇسابىقىگە قاتناشقۇچىلارنىڭ
						ھېسابات نومۇرى
						سانى چەكلەنمەيدۇ.
					</div>
					<div class="box-li" v-else>
						<img src="../assets/image/TC/tc-4.png" alt="">
						Competition details: <br>
						1.Open new EBC trading account. <br>
						2.During the competition period, competitors must link their accounts to the EBC trading
						community and release trading signals. <br>
						3.Deposit at least $500 to activate (no limit on the number of accounts).
					</div>
				</div>
			</div>
		</div>
		<div class="TC-info content">
			<div class="TC-info__box flex flex-ajcenter">
				<div class="TC-info__box__title flex flex-ajcenter" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
					<img src="../assets/image/TC/tc-6.png" alt="">实时排名<img src="../assets/image/TC/tc-6.png" alt="">
				</div>
				<div class="TC-info__box__title flex flex-ajcenter" v-else-if="lang === 'weiwuer'">
					<img src="../assets/image/TC/tc-6.png" alt="">ۋاقىتلىق رەت تەرتىپى<img
						src="../assets/image/TC/tc-6.png" alt="">
				</div>
				<div class="TC-info__box__title flex flex-ajcenter" v-else>
					<img src="../assets/image/TC/tc-6.png" alt="">Current Ranking<img src="../assets/image/TC/tc-6.png"
						alt="">
				</div>
			</div>
			<!-- <div v-if="timeState">
        <div class="TC-info__timer flex" v-if="lang != 'zh_CN' && lang != 'zh_TW'">
          Countdown to the start of the competition: {{timeDate.day}}Days {{timeDate.hour}}Hours
          {{timeDate.minute}}Minutes {{timeDate.second}}seconds
        </div>
        <div class="TC-info__timer flex" v-else>
          距离比赛开始还有{{timeDate.day}}天{{timeDate.hour}}小时{{timeDate.minute}}分{{timeDate.second}}秒
        </div>
      </div> -->
			<div class="TC-info__list">
				<table class="list">
					<thead>
						<tr>
							<th></th>
							<th>
								<div v-if="lang == 'zh_CN' || lang == 'zh_TW'">昵称</div>
								<div v-else-if="lang === 'weiwuer'">ئىسىمى</div>
								<div v-else>Competitor Name</div>
							</th>
							<th>
								<div v-if="lang == 'zh_CN' || lang == 'zh_TW'">MT4</div>
								<div v-else-if="lang === 'weiwuer'">MT4</div>
								<div v-else>Account Number</div>
							</th>
							<th>
								<div v-if="lang == 'zh_CN' || lang == 'zh_TW'">净值</div>
								<div v-else-if="lang === 'weiwuer'">ساپ قىممەت</div>
								<div v-else>Net Value</div>
							</th>
							<th>
								<div v-if="lang == 'zh_CN' || lang == 'zh_TW'">总入金</div>
								<div v-else-if="lang === 'weiwuer'">ئومۇمىي كىرگۈزگەن مەبلەخ</div>
								<div v-else>Total Deposit</div>
							</th>
							<th>
								<div v-if="lang == 'zh_CN' || lang == 'zh_TW'">收益率</div>
								<div v-else-if="lang === 'weiwuer'">پايدا ئەھۋالى</div>
								<div v-else>Yield</div>
							</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(itx,idx) in infoData" :key="idx" :class="{'active':(idx+1)%2 == 0}">
							<td v-if="idx < 3" :class="">
							</td>
							<td v-else>
								{{idx+1}}
							</td>
							<td v-if="lang == 'en_US'">{{itx.name}}</td>
							<td v-else>{{itx.nickName}}</td>
							<td>{{itx.mtAccount}}</td>
							<td>{{itx.equity}}</td>
							<td>{{itx.totalDeposit}}</td>
							<td>{{(itx.yields*100).toFixed(2)}}%</td>
							<td>
								<a href="https://ebcfinsns.co" target="_blank"
									v-if="lang == 'zh_CN' || lang == 'zh_TW'">查看</a>
								<a href="https://ebcfinsns.co" target="_blank" v-else-if="lang === 'weiwuer'">پايدا
									ئەھۋالى</a>
								<a href="https://ebcfinsns.co" target="_blank" v-else>Check</a>
							</td>
						</tr>
					</tbody>
				</table>
				<div class="tips" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
					* 根据活动期间参赛账号的收益表现排名，收益表现参考取值=净值/账号总入金。<br>
					* 参赛者收益表现排名每小时更新。
				</div>
				<div class="tips" v-else-if="lang === 'weiwuer'">
					پائالىيەت مەزگىلىدە مۇسابىقگە قاتناشقان ھىسابنىڭ پايدا رەت تەرتىپىگە ئاساسەن ، پايدا ئېلىش
					ئىپادىسنىڭ
					پايدىلىنىش قىممىتى= ساپ قىممەت /ھىسابنىڭ ئومۇمىي كىرىم سوممىسى .<br>
					ۋاقىتلىق رەت تەرتىپى (ھەر سائەتتە يېڭىلىنىدۇ)
				</div>
				<div class="tips" v-else>
					* Ranked according to the yield of competing accounts during the competition period. Yield = Net
					Value / Total Deposit.<br>
					* Ranking updated every hour.
				</div>
				<div class="btns flex flex-ajcenter">
					<a href="https://client.ebccrm.com/signup/index" class="btns-btn flex flex-ajcenter" target="_blank"
						v-if="lang == 'zh_CN' || lang == 'zh_TW'">即刻参赛</a>
					<a href="https://client.ebccrm.com/signup/index" class="btns-btn flex flex-ajcenter" target="_blank"
						v-else-if="lang === 'weiwuer'">دەرھال مۇسابىقىگە قاتنىشايلى</a>
					<a href="https://client.ebccrm.com/signup/index" class="btns-btn flex flex-ajcenter" target="_blank"
						v-else>Join Now</a>
				</div>
			</div>
		</div>
		<div class="TC-foot content" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
			<div class="TC-foot__title">
				活动细则
			</div>
			<div class="TC-foot__desc">
				1. 账号类型仅限 STD 与 PRO， PAMM账户无法参赛。<br />
				2. 为了公开公正参赛账号必须在交易社区 Https://www.ebcfinsns.co 注册成为信号方以便展示交易记录，但无须开放跟随。<br />
				3. 每位参赛者可不限交易账号数量参赛，取最优成绩为排名依据。<br />
				4. 最终排名以2023年4月30日23:59:59（ GMT+2）官网排名为准。<br />
				5. 冠军若选择100万美金交易账户奖励，可最大亏损幅度为20万美金。<br />
				6. 为保证比赛公平，如遇服务器硬件故障，报价商发送错误报价等非可控因素产生的订单，均视为无效订单。<br />
				7. EBC有权取消违规账户的参赛资格。<br />
				8. EBC拥有规则最终解释权。
			</div>
		</div>
		<div class="TC-foot content" v-else-if="lang === 'weiwuer'">
			<div class="TC-foot__title">
				پائالىيەتنىڭ تەپسىلىي قائىدىسى:
			</div>
			<div class="TC-foot__desc">
				1. ھېسابات نومۇرى تۈرى STDۋەPROبىلەنلا چەكلىنىدۇ. PAMM ھېساباتى مۇسابىقىگە قاتناشتۇرۇلمايدۇ<br />
				2. مۇسابىقىگە ئادىل قاتنىشىش ئۈچۈن چوقۇم سودا مەھەللىسىدە تىزىملىتىش كېرەك. Https://www.ebcfinsns.co
				سودا
				خاتىرىسىنى نامايان قىلىشقا قولايلىق بولسۇن ئۈچۈن ، تىزىمغا ئالدۇرۇپ سىگنال بەرگۈچى تەرەپكە تىزىملىتىڭ ،
				لېكىن
				ئوچۇق-ئاشكارا ئەگىشىشنى تاللىمساقمۇ بۇلىدۇ.<br />
				3. ھەر بىر مۇسابىقىگە قاتناشقۇچى سودا ھېسابات نومۇرى سانى بىلەن چەكلەنمەيدۇ ، ئەڭ ياخشى نەتىجىنى رەتكە
				تۇرغۇزۇش
				ئاساسى قىلىنىدۇ.<br />
				4. ئاخىرقى رەت تەرتىپى 2023-يىلى 4-ئاينىڭ 30-كۈنى 23: 59: 59 (GMT + 2 )رەت تەرتىپى تور بەتتە ئىلان
				قىلنغنى
				ئۆلچەم قىلىنىدۇ.<br />
				5. چېمپىيون بىر مىليون دوللار نەق پۇل ھېسابىنى تاللىسا ، ئەڭ چوڭ زىيان 200 مىڭ ئامېرىكا دوللىرى
				بولىدۇ.<br />
				6. مۇسابىقىنىڭ ئادىل بولۇشىغا كاپالەتلىك قىلىش ئۈچۈن ، ئەگەر مۇلازىمېتىر قاتتىق دېتالى كاشىلىغا يولۇقۇپ
				، باھا
				مەلۇم قىلغۇچى تەرەپنىڭ خاتا باھا مەلۇم قىلىش قاتارلىق كونترول قىلغىلى بولمايدىغان ئامىللاردىن كېلىپ
				چىققان زاكاز
				تالونى ئىناۋەتسىز دەپ قارىلىدۇ.<br />
				7. EBC قائىدىگە خىلاپ ھېسابلارنىڭ مۇسابىقىگە قاتنىشىش سالاھىيىتىنى بىكار قىلىشقا ھوقۇقلۇق.<br />
				8. EBC قائىدىنى ئاخىرقى چۈشەندۈرۈش ھوقۇقىغا ئىگە.
			</div>
		</div>
		<div class="TC-foot content" v-else>
			<div class="TC-foot__title">
				Competition terms:
			</div>
			<div class="TC-foot__desc">
				1.This competition is only available to new STD and PRO accounts. PAMM accounts are ineligible.<br />
				2.To ensure transparency and honesty, accounts must register as signal providers in the EBC trading
				community（https://www.ebcfinsns.co).<br />
				3.Competitors may open as many trading accounts as they wish, but only the best single account will be
				used for the final ranking. <br />
				4.Ranking will be finalized at 23:59:59 (GMT+2) on April 30, 2023, based on the final results posted on
				the official website ranking page.<br />
				5.Should the Champion select the $1,000,000 cash account, their maximum allowed loss is $200,000.<br />
				6.In order to ensure the fairness of competition, orders generated by uncontrollable factors such as
				server hardware failure and/or bidder errors will be disqualified. <br />
				7.EBC reserves the right to disqualify any offending account from participation in the competition.
				<br />
				8.EBC has the final right to interpret these rules.
			</div>
		</div>
		<client-only>
			<noscript>
				<a href="https://www.livechatinc.com/chat-with/12340599/" rel="nofollow"> Chat with us </a>, powered by
				<a href="https://www.livechatinc.com/?welcome" rel="noopener nofollow " target="_blank ">LiveChat</a>
			</noscript>
		</client-only>
		<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K4P4CHP" height="0" width="0"
			style="display:none;visibility:hidden"></iframe>
	</div>
</template>

<script>
	if (process.browser) {
		var {
			WOW
		} = require('wowjs')
	}
	import {
		mapState,
	} from 'vuex'
	export default {
		head() {
			return {
				title: this.title1,
				meta: [ // set meta
					{
						name: 'keyWords',
						content: 'EBC,EBC平台,EBC官网,EBC金融官网,EBC开户,EBC金融'
					},
					{
						name: 'description',
						content: 'EBC金融官网是由EBC金融集团EBC Financial Group成立的,主要提供,EBC金融,EBC开户交易服务,只需一个EBC综合平台账户,即可交易多种差价合约（CFD）:衍生品、美股、数字货币、期货指数、贵金属和能源等.'
					}
				]
			}
		},
		data() {
			return {
				endTimestamp: '1675008000',
				timeDate: {
					day: '00',
					hour: '00',
					minute: '00',
				},
				timeState: true,
				infoData: "",
				newData: ""
			}
		},
		computed: {
			...mapState({
				lang: state => state.Lan,
			}),
		},
		mounted() {
			window.dataLayer = window.dataLayer || [];

			// function gtag() {
			// 	dataLayer.push(arguments);
			// }
			// gtag('js', new Date());
			// gtag('config', 'G-TJ431PDHZX');
			window.__lc = window.__lc || {};
			window.__lc.license = 12340599;
			(function(n, t, c) {
				function i(n) {
					return e._h ? e._h.apply(null, n) : e._q.push(n)
				}
				var e = {
					_q: [],
					_h: null,
					_v: "2.0",
					on: function() {
						i(["on", c.call(arguments)])
					},
					once: function() {
						i(["once", c.call(arguments)])
					},
					off: function() {
						i(["off", c.call(arguments)])
					},
					get: function() {
						if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
						return i(["get", c.call(arguments)])
					},
					call: function() {
						i(["call", c.call(arguments)])
					},
					init: function() {
						var n = t.createElement("script");
						n.async = !0, n.type = "text/javascript", n.src =
							"https://cdn.livechatinc.com/tracking.js", t.head.appendChild(n)
					}
				};
				!n.__lc.asyncInit && e.init(), n.LiveChatWidget = n.LiveChatWidget || e
			}(window, document, [].slice))
			this.$nextTick(() => {
				if (process.browser) { // 在页面mounted生命周期里面 根据环境实例化WOW
					(function(w, d, s, l, i) {
						w[l] = w[l] || [];
						w[l].push({
							'gtm.start': new Date().getTime(),
							event: 'gtm.js'
						});
						var f = d.getElementsByTagName(s)[0],
							j = d.createElement(s),
							dl = l != 'dataLayer' ? '&l=' + l : '';
						j.async = true;
						j.src =
							'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
						f.parentNode.insertBefore(j, f);
					})(window, document, 'script', 'dataLayer', 'GTM-K4P4CHP')
				}
			});
		},
		created() {
			// this.formatTime()
			this.getData()
			this.getNewData()
		},
		methods: {
			getNewData() {
				let parms;
				if (this.lang === 'zh_CN') {
					parms = 'zh_CN'
				} else {
					parms = 'en_US'
				}
				this.$http("/ebc/api/getLearnCategory/run", {
					lang: parms,
				}).then(res => {
					this.newData = res.data.data
				})
			},
			getData() {
				$.ajax({
					type: "POST",
					url: "https://socialtradingwebapi.eappservice.com/api/profit_stats",
					contentType: "application/json",
					dataType: "json",
					data: "{}",
					success: (res) => {
						this.infoData = res.data
					}
				})
			},
			formatTime() {
				let dTime = this.getCurrentZoneTime(2)
				if (parseInt(dTime / 1000) > this.endTimestamp) {
					this.timeState = false
					return
				}
				const $time = this.endTimestamp - parseInt(dTime / 1000)
				this.timeDate = {
					day: Math.floor($time / (3600 * 24)),
					hour: this.zeroFill(Math.floor(($time % (3600 * 24)) / 3600)),
					minute: this.zeroFill(Math.floor((($time % (3600 * 24)) % 3600) / 60)),
					second: this.zeroFill(Math.floor($time % 60))
				}
				setTimeout(() => {
					this.formatTime()
				}, 1000)
			},
			getCurrentZoneTime(zone) {
				var timezone = zone; //目标时区
				var offset_GMT = new Date().getTimezoneOffset(); // 本地时间和格林威治的时间差，单位为分钟
				var nowDate = new Date().getTime(); // 本地时间距 1970 年 1 月 1 日午夜（GMT 时间）之间的毫秒数
				var targetDate = new Date(nowDate + offset_GMT * 60 * 1000 + timezone * 60 * 60 * 1000); //当前东八区的时间
				var current = targetDate.getTime(); //当前时区时间戳
				return current
			},
			zeroFill(num, fixed = 1) {
				const arr = []
				arr.length = fixed
				arr.fill(9)
				return num > arr.join() ? String(num) : '0' + num
			}
		}
	}

</script>

<style lang="scss" scoped>
	.TC {
		background: url(../assets/image/TC/bg.png)no-repeat top center #3a0603;
		background-size: 100%;

		.content {
			max-width: 1200px;
			margin: auto;
			position: relative;
		}

		&-head {
			background: url(../assets/image/TC/tc-7.png)no-repeat top right;
			background-size: 55%;
			padding-bottom: 300px;

			.logo {
				margin: 100px 0 80px 0px;
			}

			&__jb {
				display: none;
			}

			&__h1 {
				&.wap {
					display: none;
				}

				img {
					width: 40%;
				}
			}

			&__tips {
				font-size: 28px;
				font-weight: 700;
				color: rgba(248, 227, 159, 1);
				padding: 20px 0;

				.tips-box {
					padding: 20px 0px;
					display: inline-block;
					border-radius: 5px;
					position: relative;

					&::after {
						content: "";
						background: url(../assets/image/TC/tc-12.png)no-repeat top center;
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						height: 2px;
						background-size: 100%;
					}

					&::before {
						content: "";
						background: url(../assets/image/TC/tc-12.png)no-repeat top center;
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						height: 2px;
						background-size: 100%;
					}
				}
			}

			&__btn {

				.btn-box {
					background: rgba(251, 230, 162, 1);
					min-width: 182.93px;
					padding: 0 10px;
					height: 46.09px;
					text-align: center;
					line-height: 46.09px;
					display: inline-block;
					font-size: 22px;
					font-weight: 700;
					color: rgba(118, 10, 3, 1);
					border-radius: 24px;
				}
			}

		}

		&-title {
			height: 94px;
			margin-top: -180px;
			background: linear-gradient(90deg, rgba(61, 2, 2, 0.6) 0%, rgba(69, 6, 4, 0) 100%);
			background-blend-mode: soft-light;
			z-index: 10;
			position: relative;

			.tit {
				height: 94px;
				align-items: center;
				font-size: 32px;
				font-weight: 700;
				letter-spacing: 0px;
				line-height: 0px;
				color: #f8e39f;

				img {
					margin: 0 10px;
					width: 26px;
					height: 26px;
				}
			}
		}

		&-screen {
			text-align: center;
			margin-top: -100px;
			z-index: 10;
			position: relative;

			img {
				max-width: 80%;
				margin: auto;
			}

			&__btn {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;

				&__top {
					min-width: 358px;
					height: 70px;
					padding: 0 20px;
					background: url(../assets/image/TC/tc-2.png)no-repeat;
					background-size: 100% 100%;
					text-align: center;
					margin-bottom: 5px;
					position: relative;
					margin-right: 10px;

					.text {
						line-height: 80px;
						font-size: 24px;
						font-weight: 700;
						letter-spacing: 0.86px;
						color: rgba(248, 226, 160, 1);
						padding-right: 20px;
					}

				}

				&__bot {
					min-width: 202px;
					height: 70px;
					background: url(../assets/image/TC/btn.png)no-repeat;
					background-size: 100% 100%;
					color: #861809;
					font-size: 28px;
					font-weight: 700;
					padding: 0 20px;
					display: flex;
					margin-top: 8px;
					align-items: center;
					justify-content: center;
				}
			}
		}

		&-screen1 {
			margin: 50px 0 60px;
			display: flex;

			&__box {
				border: 2px dashed rgba(247, 226, 160, 0.5);
				border-radius: 32px;
				padding: 0 30px 20px;

				.box-li {
					margin-top: 20px;
					font-size: 18px;
					line-height: 28px;
					font-weight: 500;
					letter-spacing: 0.22px;
					color: rgba(247, 226, 160, 1);
					display: flex;
					align-items: center;

					img {
						width: 24px;
						height: 23px;
						margin-right: 10px;
					}

					p {
						display: inline;
						background: rgba(117, 0, 0, 0.8);
						color: #fff;
						padding: 4px 8px;
						line-height: 24px;
					}
				}
			}
		}

		&-info {
			background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(252, 246, 238, 1) 100%);
			border-radius: 20px;

			&__box {
				&__title {
					height: 100px;
					font-size: 36px;
					color: rgba(156, 4, 4, 1);
					font-weight: bold;
					min-width: 498px;
					background: url(../assets/image/TC/tc-5.png)no-repeat;
					background-size: 100% 100%;

					img {
						margin: 0 10px;
						width: 31px;
						height: 31px;
					}
				}
			}

			&__timer {
				justify-content: center;
				margin-top: 40px;
				color: #3B0104;
				font-size: 24px;
				font-weight: bold;
			}

			&__list {
				padding: 40px 50px 26px;

				.list {
					width: 100%;
					border-radius: 10px;
					text-align: center;

					tbody {
						tr {
							&:nth-child(1) {
								td {
									&:nth-child(1) {
										background: url(../assets/image/TC/img-0.png)no-repeat rgba(255, 241, 226, 1) center center;
									}
								}
							}

							&:nth-child(2) {
								td {
									&:nth-child(1) {
										background: url(../assets/image/TC/img-1.png)no-repeat rgba(254, 225, 191, 1) center center;
									}
								}
							}

							&:nth-child(3) {
								td {
									&:nth-child(1) {
										background: url(../assets/image/TC/img-2.png)no-repeat rgba(255, 241, 226, 1) center center;
									}
								}
							}
						}
					}

					th {
						background: linear-gradient(180deg, rgba(198, 156, 109, 1) 0%, rgba(230, 182, 129, 1) 100%);
						color: rgba(61, 21, 0, 1);
						line-height: 50px;
						font-size: 18px;
						font-weight: 500;

						&:nth-child(1) {
							border-radius: 10px 0 0 0;
						}

						&:nth-child(7) {
							border-radius: 0 10px 0 0;
						}
					}

					td {
						background: rgba(255, 241, 226, 1);
						color: rgba(61, 21, 0, 1);
						line-height: 78px;
						font-size: 18px;
						font-weight: 500;
						color: rgba(125, 22, 20, 1);

						&:nth-child(6) {
							color: rgba(27, 125, 20, 1);
						}

						&:nth-child(1) {
							min-width: 50px;
							color: rgba(61, 21, 0, 1);
						}
					}

					.active {
						td {
							background: rgba(254, 225, 191, 1);
						}
					}

					tr {
						&:last-child {
							td {
								&:nth-child(1) {
									border-radius: 0 0 0px 10px;
								}

								&:nth-child(7) {
									border-radius: 0 0 10px 0px;
								}
							}
						}
					}
				}

				.tips {
					margin-top: 20px;
					font-size: 20px;
					color: rgba(102, 102, 102, 1);
					line-height: 30px;
					padding: 20px 0;
					border-top: 2px solid rgba(198, 156, 108, 0.5);
				}

				.btns {
					margin-bottom: 40px;

					&-btn {
						min-width: 500px;
						height: 64px;
						border-radius: 41px;
						background: linear-gradient(180deg, rgba(166, 36, 27, 1) 0%, rgba(107, 21, 21, 1) 70%);
						font-size: 24px;
						font-weight: 700;
						letter-spacing: 2.36px;
						color: rgba(248, 226, 160, 1);
					}
				}
			}
		}

		&-foot {
			padding: 46px 0 46px 50px;
			color: rgba(248, 226, 160, 1);
			line-height: 36px;

			&__title {
				font-size: 25px;
				font-weight: bold;
				margin-bottom: 10px;
			}

			&__desc {
				font-size: 18px;
			}
		}

		@media (max-width: 500px) {
			.content {
				max-width: 100%;
				margin: auto;
				position: relative;
			}

			&-head {
				background: none;
				background-size: 40%;
				padding: 0 10px 20px;

				&__jb {
					text-align: center;
					display: block;

					img {
						width: 60%;
					}
				}

				.logo {
					margin: 20px 0 0px 0px;
					max-width: 50%;
				}

				&__h1 {
					text-align: center;
					margin-top: -60px;
					display: none;

					&.wap {
						display: block;
					}

					img {
						width: 60%;
					}
				}

				&__tips {
					font-size: 18px;
					text-align: center;
					padding: 20px 0;

					.tips-box {
						padding: 10px;
						display: inline-block;
					}
				}

				&__btn {
					padding: 0 0 0 0px;
					text-align: center;
				}
			}

			&-title {
				height: 94px;
				margin-top: 0px;
				background: linear-gradient(90deg, rgba(61, 2, 2, 0.6) 0%, rgba(69, 6, 4, 0) 100%);
				background-blend-mode: soft-light;
				z-index: 10;
				position: relative;

				.tit {
					height: 70px;
					align-items: center;
					font-size: 24px;
					color: #f8e39f;
					justify-content: center;
					text-align: center;

					img {
						margin: 0 10px;
						width: 26px;
						height: 26px;
					}
				}
			}

			&-screen {
				text-align: center;
				margin-top: 0px;
				z-index: 10;
				position: relative;
				padding: 0 10px;

				img {
					max-width: 100%;
					margin: auto;
				}

				&__btn {
					position: relative;
					bottom: 0;
					left: 0;
					right: 0;

					&__top {
						min-width: 178px;
						height: 60px;
						background: url(../assets/image/TC/tc-2.png)no-repeat;
						background-size: 100% 100%;
						text-align: center;
						margin-bottom: 5px;
						position: relative;
						padding: 0 20px;

						.text {
							font-size: 14px;
							line-height: 70px;
							letter-spacing: 0.86px;
							color: rgba(248, 226, 160, 1);
						}
					}

					&__bot {
						min-width: 100px;
						height: 52px;
						background: url(../assets/image/TC/btn.png)no-repeat;
						background-size: 100% 100%;
						color: #861809;
						font-size: 16px;
						font-weight: 700;
						letter-spacing: 2px;
					}
				}
			}

			&-screen1 {
				margin: 20px 0;
				display: flex;
				padding: 0 10px;

				&__box {
					border: 2px dashed rgba(247, 226, 160, 0.5);
					border-radius: 16px;
					padding: 0 10px 20px;

					.box-li {
						margin-top: 10px;
						font-size: 14px;
						line-height: 30px;
						font-weight: 500;
						letter-spacing: 0.22px;
						color: rgba(247, 226, 160, 1);
						display: block;
						align-items: center;

						img {
							width: 24px;
							height: 23px;
							margin-right: 5px;
						}
					}
				}
			}

			&-info {
				border-radius: 20px;
				padding: 0 5px;
				max-width: 96% !important;

				&__box {
					&__title {
						height: 50px;
						font-size: 24px;
						color: rgba(156, 4, 4, 1);
						font-weight: bold;
						min-width: 80%;
						background: url(../assets/image/TC/tc-5.png)no-repeat;
						background-size: 100% 100%;

						img {
							margin: 0 10px;
							width: 18px;
							height: 18px;
						}
					}
				}

				&__timer {
					margin-top: 20px;
					font-size: 16px;
					line-height: 1.2;
				}

				&__list {
					padding: 20px 0px 2px;

					.list {
						width: 100%;
						border-radius: 10px;
						text-align: center;

						tr {
							width: 100%;
						}

						tbody {
							tr {
								&:nth-child(1) {
									td {
										&:nth-child(1) {
											background-size: 40%;
										}
									}
								}

								&:nth-child(2) {
									td {
										&:nth-child(1) {
											background-size: 40%;
										}
									}
								}

								&:nth-child(3) {
									td {
										&:nth-child(1) {
											background-size: 40%;
										}
									}
								}
							}
						}

						th {
							background: linear-gradient(180deg, rgba(198, 156, 109, 1) 0%, rgba(230, 182, 129, 1) 100%);
							color: rgba(61, 21, 0, 1);
							line-height: 28px;
							font-size: 14px;
							font-weight: 500;

							&:nth-child(1) {
								border-radius: 10px 0 0 0;
							}

							&:nth-child(5) {
								display: none;
							}

							&:nth-child(6) {
								border-radius: 0 10px 0 0;
							}

							&:nth-child(7) {
								display: none;
							}
						}

						td {
							background: rgba(255, 241, 226, 1);
							color: rgba(61, 21, 0, 1);
							line-height: 38px;
							font-size: 12px;
							font-weight: 500;
							white-space: nowrap;

							img {
								width: 19px;
							}

							&:nth-child(6) {
								color: rgba(27, 125, 20, 1);
							}

							&:nth-child(5) {
								display: none;
							}

							&:nth-child(1) {
								min-width: 30px;
								color: rgba(61, 21, 0, 1);
							}

							&:nth-child(7) {
								display: none;
							}
						}

						.active {
							td {
								background: rgba(254, 225, 191, 1);
							}
						}

						tr {
							&:last-child {
								td {
									&:nth-child(1) {
										border-radius: 0 0 0px 10px;
									}

									&:nth-child(6) {
										border-radius: 0 0 10px 0px;
									}

									&:nth-child(7) {
										border-radius: 0 0 10px 0px;
									}
								}
							}
						}
					}

					.tips {
						font-size: 14px;
						line-height: 20px;
						padding: 20px 0;
					}

					.btns {
						margin-bottom: 20px;

						&-btn {
							min-width: 100%;
							height: 52px;
							border-radius: 41px;
							background: linear-gradient(180deg, rgba(171, 25, 15, 1) 0%, rgba(226, 3, 2, 1) 100%);
							font-size: 22px;
							font-weight: 700;
							letter-spacing: 2.36px;
							color: rgba(248, 226, 160, 1);
							text-align: center;
						}
					}
				}
			}

			&-foot {
				padding: 20px 10px;
				line-height: 28px;

				&__title {
					font-size: 18px;
					font-weight: bold;
				}

				&__desc {
					font-size: 14px;
				}
			}

		}

	}

</style>

