<template>
	<div class="pay">
		<div class="content">
			<div class="pay-head flex align-center">
				<router-link to="/" class="flex-1"><img src="../assets/image/pay/logo.svg" alt=""
						class="logo"></router-link>
				<router-link to="/" class="pay-head__url">
					www.ebcfin.com
				</router-link>
			</div>
			<div class="pay-pages1">
				<div class="pay-pages1__title">
					<div class="pay-pages1__title__h1" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
						交易领薪计划
					</div>
					<div class="pay-pages1__title__h1" v-else-if="lang === 'weiwuer'">
						مائاش پىلانى
					</div>
					<div class="pay-pages1__title__h1" v-else>
						trading rewards plan
					</div>
					<div class="pay-pages1__title__h2" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
						我们全程买单，只为寻找传奇
					</div>
					<div class="pay-pages1__title__h2" v-else-if="lang === 'weiwuer'">
						بىزنىڭ پۈتكۈل جەريانىدىكى چىقىم قىلىش سەۋەبىمىز مۆجىزە ئىزدەش ئۈچۈندۇر
					</div>
					<div class="pay-pages1__title__h2" v-else>
						We cover all the expenses in seek of talents.
					</div>
				</div>
				<div class="pay-pages1__desc flex align-center" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
					<div class="iconfont">
						&#xe653;
					</div>
					即刻发起交易信号
				</div>
				<div class="pay-pages1__desc flex align-center" v-else-if="lang === 'weiwuer'">
					<div class="iconfont">
						&#xe653;
					</div>
					دەرھال سودا سىگنالى تارقىتىش.
				</div>
				<div class="pay-pages1__desc flex align-center" v-else>
					<div class="iconfont">
						&#xe653;
					</div>
					initiate a trading signal immediately
				</div>
				<div class="pay-pages1__desc flex align-center" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
					<div class="iconfont">
						&#xe653;
					</div>
					无限奖励月月拿
				</div>
				<div class="pay-pages1__desc flex align-center" v-else-if="lang === 'weiwuer'">
					<div class="iconfont">
						&#xe653;
					</div>
					ھەر ئايدا چەكسىز مۇكاپات ئېلىش.
				</div>
				<div class="pay-pages1__desc flex align-center" v-else>
					<div class="iconfont">
						&#xe653;
					</div>
					unlimited monthly rewards
				</div>
				<div class="pay-pages1__desc flex align-center" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
					<div class="iconfont">
						&#xe653;
					</div>
					优享12个专属大礼
				</div>
				<div class="pay-pages1__desc flex align-center" v-else-if="lang === 'weiwuer'">
					<div class="iconfont">
						&#xe653;
					</div>
					12تۈرلۈك مەخسۇس چوڭ سوۋغاتتىن بەھرىمان بۇلۇش
				</div>
				<div class="pay-pages1__desc flex align-center" v-else>
					<div class="iconfont">
						&#xe653;
					</div>
					12 exlcusive bonuses
				</div>
				<div class="pay-pages1__btn">
					<a href="https://www.ebcfinsns.co" target="_blank"
						v-if="lang == 'zh_CN' || lang == 'zh_TW'">即刻加入</a>
					<a href="https://www.ebcfinsns.co" target="_blank" v-else-if="lang === 'weiwuer'">دەرھال
						قاتنشىش</a>
					<a href="https://www.ebcfinsns.co" target="_blank" v-else>Join Now</a>
				</div>
			</div>
			<div class="pay-pages2" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
				交易员奖励计划正式开启
			</div>
			<div class="pay-pages2" v-else-if="lang === 'weiwuer'">
				سودا ماھىرلىرىنى مۇكاپاتلاش پىلانى رەسمىي باشلاندى
			</div>
			<div class="pay-pages2" v-else>
				trading rewards plan starts
			</div>
			<div class="pay-pages3">
				<div class="pay-pages3__ul flex">
					<div class="pay-pages3__ul__li flex-1">
						<div class="li-top">
							<div class="li-top__head flex">
								<div class="name flex-1" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
									奖励
								</div>
								<div class="name flex-1" v-else-if="lang === 'weiwuer'">
									مۇكاپات
								</div>
								<div class="name flex-1" v-else>
									reward
								</div>
								<div class="sum" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
									$100/月
								</div>
								<div class="sum" v-else-if="lang === 'weiwuer'">
									$100/ئاي
								</div>
								<div class="sum" v-else>
									$100/month
								</div>
								<div class="iconfont ">
									&#xe652;
								</div>
							</div>
							<div class="li-top__img">
								<img src="../assets/image/pay/pay-9.png" alt="" class="img">
							</div>
							<div class="li-top__desc flex">
								<div class="flex-1" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
									管理资金
								</div>
								<div class="flex-1" v-else-if="lang === 'weiwuer'">
									مەبلەغنى باشقۇرۇش
								</div>
								<div class="flex-1" v-else>
									AUM
								</div>
								<div>
									>$20,000
								</div>
							</div>
							<div class="li-top__desc flex">
								<div class="flex-1" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
									跟随客户量
								</div>
								<div class="flex-1" v-else-if="lang === 'weiwuer'">
									خېرىدارلارغا ئەگىشىش مىقدارى
								</div>
								<div class="flex-1" v-else>
									number of follwing traders
								</div>
								<div>
									≥10
								</div>
							</div>
						</div>
						<div class="li-title flex flex-ajcenter">
							<div class="iconfont">
								&#xe653;
							</div>
							<div class="li-title__name" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								新锐交易员
							</div>
							<div class="li-title__name ug" v-else-if="lang === 'weiwuer'">
								يۇلتۇز ماھر
							</div>
							<div class="li-title__name" v-else>
								beginning
							</div>
							<div class="iconfont">
								&#xe653;
							</div>
						</div>
					</div>
					<div class="pay-pages3__ul__li flex-1">
						<div class="li-top">
							<div class="li-top__head flex">
								<div class="name flex-1" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
									奖励
								</div>
								<div class="name flex-1" v-else-if="lang === 'weiwuer'">
									مۇكاپات
								</div>
								<div class="name flex-1" v-else>
									Awards
								</div>
								<div class="sum" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
									$300/月
								</div>
								<div class="sum" v-else-if="lang === 'weiwuer'">
									$300/ئاي
								</div>
								<div class="sum" v-else>
									$300/Monthly
								</div>
								<div class="iconfont ">
									&#xe652;
								</div>
							</div>
							<div class="li-top__img">
								<img src="../assets/image/pay/pay-8.png" alt="" class="img">
							</div>
							<div class="li-top__desc flex">
								<div class="flex-1" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
									管理资金
								</div>
								<div class="flex-1" v-else-if="lang === 'weiwuer'">
									مەبلەغنى باشقۇرۇش
								</div>
								<div class="flex-1" v-else>
									Following Funds
								</div>
								<div>
									>$50,000
								</div>
							</div>
							<div class="li-top__desc flex">
								<div class="flex-1" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
									跟随客户量
								</div>
								<div class="flex-1" v-else-if="lang === 'weiwuer'">
									خېرىدارلارغا ئەگىشىش مىقدارى
								</div>
								<div class="flex-1" v-else>
									Following Number of Users
								</div>
								<div>
									>30
								</div>
							</div>
						</div>
						<div class="li-title flex flex-ajcenter">
							<div class="iconfont">
								&#xe653;
							</div>
							<div class="li-title__name" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								精英交易员
							</div>
							<div class="li-title__name" v-else-if="lang === 'weiwuer'">
								قالتىس ماھىر
							</div>
							<div class="li-title__name" v-else>
								good
							</div>
							<div class="iconfont">
								&#xe653;
							</div>
						</div>
					</div>
					<div class="pay-pages3__ul__li flex-1">
						<div class="li-top">
							<div class="li-top__head flex">
								<div class="name flex-1" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
									奖励
								</div>
								<div class="name flex-1" v-else-if="lang === 'weiwuer'">
									مۇكاپات
								</div>
								<div class="name flex-1" v-else>
									Awards
								</div>
								<div class="sum" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
									$1000/月
								</div>
								<div class="sum" v-else-if="lang === 'weiwuer'">
									$1000/ئاي
								</div>
								<div class="sum" v-else>
									$1000/Monthly
								</div>
								<div class="iconfont">
									&#xe652;
								</div>
							</div>
							<div class="li-top__img">
								<img src="../assets/image/pay/pay-7.png" alt="" class="img">
							</div>
							<div class="li-top__desc flex">
								<div class="flex-1" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
									管理资金
								</div>
								<div class="flex-1" v-else-if="lang === 'weiwuer'">
									مەبلەغنى باشقۇرۇش
								</div>
								<div class="flex-1" v-else>
									Following Funds
								</div>
								<div>
									>$100,000
								</div>
							</div>
							<div class="li-top__desc flex">
								<div class="flex-1" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
									跟随客户量
								</div>
								<div class="flex-1" v-else-if="lang === 'weiwuer'">
									خېرىدارلارغا ئەگىشىش مىقدارى
								</div>
								<div class="flex-1" v-else>
									Following Number of Users
								</div>
								<div>
									>50
								</div>
							</div>
						</div>
						<div class="li-title flex flex-ajcenter">
							<div class="iconfont">
								&#xe653;
							</div>
							<div class="li-title__name" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								冠军交易员
							</div>
							<div class="li-title__name" v-else-if="lang === 'weiwuer'">
								چىمپيون ماھىر
							</div>
							<div class="li-title__name" v-else>
								excellent
							</div>
							<div class="iconfont">
								&#xe653;
							</div>
						</div>
					</div>
					<div class="pay-pages3__ul__li flex-1">
						<div class="li-top">
							<div class="li-top__head flex">
								<div class="name flex-1" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
									奖励
								</div>
								<div class="name flex-1" v-else-if="lang === 'weiwuer'">
									مۇكاپات
								</div>
								<div class="name flex-1" v-else>
									Awards
								</div>
								<div class="sum" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
									$5000/月
								</div>
								<div class="sum" v-else-if="lang === 'weiwuer'">
									$5000/ئاي
								</div>
								<div class="sum" v-else>
									$5000/Monthly
								</div>
								<div class="iconfont">
									&#xe652;
								</div>
							</div>
							<div class="li-top__img">
								<img src="../assets/image/pay/pay-6.png" alt="" class="img">
							</div>
							<div class="li-top__desc flex">
								<div class="flex-1" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
									管理资金
								</div>
								<div class="flex-1" v-else-if="lang === 'weiwuer'">
									مەبلەغنى باشقۇرۇش
								</div>
								<div class="flex-1" v-else>
									Following Funds
								</div>
								<div>
									>$500,000
								</div>
							</div>
							<div class="li-top__desc flex">
								<div class="flex-1" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
									跟随客户量
								</div>
								<div class="flex-1" v-else-if="lang === 'weiwuer'">
									خېرىدارلارغا ئەگىشىش مىقدارى
								</div>
								<div class="flex-1" v-else>
									Following Number of Users
								</div>
								<div>
									>100
								</div>
							</div>
						</div>
						<div class="li-title flex flex-ajcenter">
							<div class="iconfont">
								&#xe653;
							</div>
							<div class="li-title__name" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								明星交易员
							</div>
							<div class="li-title__name" v-else-if="lang === 'weiwuer'">
								چولپان ماھىر
							</div>
							<div class="li-title__name" v-else>
								Star
							</div>
							<div class="iconfont">
								&#xe653;
							</div>
						</div>
					</div>
					<div class="pay-pages3__ul__li flex-1">
						<div class="li-top">
							<div class="li-top__head flex">
								<div class="name flex-1" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
									奖励
								</div>
								<div class="name flex-1" v-else-if="lang === 'weiwuer'">
									مۇكاپات
								</div>
								<div class="name flex-1" v-else>
									Awards
								</div>
								<div class="sum" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
									$10000/月
								</div>
								<div class="sum" v-else-if="lang === 'weiwuer'">
									$10000/ئاي
								</div>
								<div class="sum" v-else>
									$10000/Monthly
								</div>
								<div class="iconfont">
									&#xe652;
								</div>
							</div>
							<div class="li-top__tips" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								+1%管理资金/年（按月付）
							</div>
							<div class="li-top__tips" v-else-if="lang === 'weiwuer'">
								+% 1 مەبلەغنى يىل بويىچە باشقۇرۇش (ئاي بويىچە چىقىم قىلىش)
							</div>
							<div class="li-top__tips" v-else>
								1% AUM per annum (paid monthly)
							</div>
							<div class="li-top__img">
								<img src="../assets/image/pay/pay-5.png" alt="" class="img">
							</div>
							<div class="li-top__desc flex">
								<div class="flex-1" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
									管理资金
								</div>
								<div class="flex-1" v-else-if="lang === 'weiwuer'">
									مەبلەغنى باشقۇرۇش
								</div>
								<div class="flex-1" v-else>
									Following Funds
								</div>
								<div>
									>$1,000,000
								</div>
							</div>
							<div class="li-top__desc flex">
								<div class="flex-1" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
									跟随客户量
								</div>
								<div class="flex-1" v-else-if="lang === 'weiwuer'">
									خېرىدارلارغا ئەگىشىش مىقدارى
								</div>
								<div class="flex-1" v-else>
									Following Number of Users
								</div>
								<div>
									>200
								</div>
							</div>
						</div>
						<div class="li-title flex flex-ajcenter">
							<div class="iconfont">
								&#xe653;
							</div>
							<div class="li-title__name" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								传奇交易员
							</div>
							<div class="li-title__name" v-else-if="lang === 'weiwuer'">
								مۆجىزە ماھىر
							</div>
							<div class="li-title__name" v-else>
								legend
							</div>
							<div class="iconfont">
								&#xe653;
							</div>
						</div>
					</div>

				</div>
			</div>
			<div class="pay-pages6">
				<div class="pay-pages6__li flex">
					<div class="pay-pages6__li__left flex flex-column flex-ajcenter">
						<div class="left-top">
							<div class="left-top__sum">
								$10000
							</div>
							<div class="left-top__dw" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								/月
							</div>
							<div class="left-top__dw" v-else-if="lang === 'weiwuer'">
								/ئاي
							</div>
							<div class="left-top__dw" v-else>
								/Monthly
							</div>
						</div>
						<div class="left-add">
							+
						</div>
						<div class="left-top">
							<div class="left-top__tips" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								1%管理资金
								<div class="left-top__tips__desc">
									/年(按月付)
								</div>
							</div>
							<div class="left-top__tips" v-else-if="lang === 'weiwuer'">
								'+% 1 مەبلەغنى يىل بويىچە باشقۇرۇش
								<div class="left-top__tips__desc">
									يىل (ئاي بويىچە تۆلىنىدۇ)
								</div>
							</div>
							<div class="left-top__tips" v-else>
								+1% following funds
								<div class="left-top__tips__desc">
									/year (paid monthly)
								</div>
							</div>
						</div>
						<div class="left-bot" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							奖励
						</div>
						<div class="left-bot" v-else-if="lang === 'weiwuer'">
							مۇكاپات
						</div>
						<div class="left-bot" v-else>
							Awards
						</div>
					</div>
					<div class="pay-pages6__li__info flex">
						<div class="info-pag flex align-center">
							<img src="../assets/image/pay/ico01.png" alt="">
							<div class="desc" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								管理资金 >$1,000,000
							</div>
							<div class="desc" v-else-if="lang === 'weiwuer'">
								مەبلەغنى باشقۇرۇش >$1,000,000
							</div>
							<div class="desc" v-else>
								Following Funds >$1,000,000
							</div>
						</div>
						<div class="info-pag flex align-center">
							<img src="../assets/image/pay/ico02.png" alt="">
							<div class="desc" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								跟随客户量 >200
							</div>
							<div class="desc" v-else-if="lang === 'weiwuer'">
								خېرىدارلارغا ئەگىشىش مىقدارى >200
							</div>
							<div class="desc" v-else>
								Following Number of Users >200
							</div>
						</div>
					</div>
					<div class="pay-pages6__li__name flex-1 flex flex-ajcenter"
						v-if="lang == 'zh_CN' || lang == 'zh_TW'">
						传奇
					</div>
					<div class="pay-pages6__li__name flex-1 flex flex-ajcenter" v-else-if="lang === 'weiwuer'">
						مۆجىزە
					</div>
					<div class="pay-pages6__li__name flex-1 flex flex-ajcenter" v-else>
						Legend
					</div>
				</div>
				<div class="pay-pages6__li flex">
					<div class="pay-pages6__li__left flex flex-column flex-ajcenter">
						<div class="left-top">
							<div class="left-top__sum">
								$5000
							</div>
							<div class="left-top__dw" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								/月
							</div>
							<div class="left-top__dw" v-else-if="lang === 'weiwuer'">
								/ئاي
							</div>
							<div class="left-top__dw" v-else>
								/Monthly
							</div>
						</div>
						<div class="left-bot" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							奖励
						</div>
						<div class="left-bot" v-else-if="lang === 'weiwuer'">
							مۇكاپات
						</div>
						<div class="left-bot" v-else>
							Awards
						</div>
					</div>
					<div class="pay-pages6__li__info flex">
						<div class="info-pag flex align-center">
							<img src="../assets/image/pay/ico01.png" alt="">
							<div class="desc" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								管理资金 >$500,000
							</div>
							<div class="desc" v-else-if="lang === 'weiwuer'">
								مەبلەغنى باشقۇرۇش >$500,000
							</div>
							<div class="desc" v-else>
								Following Funds >$500,000
							</div>
						</div>
						<div class="info-pag flex align-center">
							<img src="../assets/image/pay/ico02.png" alt="">
							<div class="desc" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								跟随客户量 >100
							</div>
							<div class="desc" v-else-if="lang === 'weiwuer'">
								خېرىدارلارغا ئەگىشىش مىقدارى >100
							</div>
							<div class="desc" v-else>
								Following Number of Users >100
							</div>
						</div>
					</div>
					<div class="pay-pages6__li__name flex-1 flex flex-ajcenter"
						v-if="lang == 'zh_CN' || lang == 'zh_TW'">
						明星
					</div>
					<div class="pay-pages6__li__name flex-1 flex flex-ajcenter" v-else-if="lang === 'weiwuer'">
						چولپان
					</div>
					<div class="pay-pages6__li__name flex-1 flex flex-ajcenter" v-else>
						Star
					</div>
				</div>
				<div class="pay-pages6__li flex">
					<div class="pay-pages6__li__left flex flex-column flex-ajcenter">
						<div class="left-top">
							<div class="left-top__sum">
								$1000
							</div>
							<div class="left-top__dw" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								/月
							</div>
							<div class="left-top__dw" v-else-if="lang === 'weiwuer'">
								/ئاي
							</div>
							<div class="left-top__dw" v-else>
								/Monthly
							</div>
						</div>
						<div class="left-bot" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							奖励
						</div>
						<div class="left-bot" v-else-if="lang === 'weiwuer'">
							مۇكاپات
						</div>
						<div class="left-bot" v-else>
							Awards
						</div>
					</div>
					<div class="pay-pages6__li__info flex">
						<div class="info-pag flex align-center">
							<img src="../assets/image/pay/ico01.png" alt="">
							<div class="desc" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								管理资金 >$100,000
							</div>
							<div class="desc" v-else-if="lang === 'weiwuer'">
								مەبلەغنى باشقۇرۇش >$100,000
							</div>
							<div class="desc" v-else>
								Following Funds >$100,000
							</div>
						</div>
						<div class="info-pag flex align-center">
							<img src="../assets/image/pay/ico02.png" alt="">
							<div class="desc" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								跟随客户量 >50
							</div>
							<div class="desc" v-else-if="lang === 'weiwuer'">
								خېرىدارلارغا ئەگىشىش مىقدارى >50
							</div>
							<div class="desc" v-else>
								Following Number of Users >50
							</div>
						</div>
					</div>
					<div class="pay-pages6__li__name flex-1 flex flex-ajcenter"
						v-if="lang == 'zh_CN' || lang == 'zh_TW'">
						冠军
					</div>
					<div class="pay-pages6__li__name flex-1 flex flex-ajcenter" v-else-if="lang === 'weiwuer'">
						چېمپىيون
					</div>
					<div class="pay-pages6__li__name flex-1 flex flex-ajcenter" v-else>
						Champion
					</div>
				</div>
				<div class="pay-pages6__li flex">
					<div class="pay-pages6__li__left flex flex-column flex-ajcenter">
						<div class="left-top">
							<div class="left-top__sum">
								$300
							</div>
							<div class="left-top__dw" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								/月
							</div>
							<div class="left-top__dw" v-else-if="lang === 'weiwuer'">
								/ئاي
							</div>
							<div class="left-top__dw" v-else>
								/Monthly
							</div>
						</div>
						<div class="left-bot" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							奖励
						</div>
						<div class="left-bot" v-else-if="lang === 'weiwuer'">
							مۇكاپات
						</div>
						<div class="left-bot" v-else>
							Awards
						</div>
					</div>
					<div class="pay-pages6__li__info flex">
						<div class="info-pag flex align-center">
							<img src="../assets/image/pay/ico01.png" alt="">
							<div class="desc" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								管理资金 >$50,000
							</div>
							<div class="desc" v-else-if="lang === 'weiwuer'">
								مەبلەغنى باشقۇرۇش >$50,000
							</div>
							<div class="desc" v-else>
								Following Funds >$50,000
							</div>
						</div>
						<div class="info-pag flex align-center">
							<img src="../assets/image/pay/ico02.png" alt="">
							<div class="desc" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								跟随客户量 >30
							</div>
							<div class="desc" v-else-if="lang === 'weiwuer'">
								خېرىدارلارغا ئەگىشىش مىقدارى >30
							</div>
							<div class="desc" v-else>
								Following Number of Users >30
							</div>
						</div>
					</div>
					<div class="pay-pages6__li__name flex-1 flex flex-ajcenter"
						v-if="lang == 'zh_CN' || lang == 'zh_TW'">
						精英
					</div>
					<div class="pay-pages6__li__name flex-1 flex flex-ajcenter" v-else-if="lang === 'weiwuer'">
						قالتىس
					</div>
					<div class="pay-pages6__li__name flex-1 flex flex-ajcenter" v-else>
						Elite
					</div>
				</div>
				<div class="pay-pages6__li flex">
					<div class="pay-pages6__li__left flex flex-column flex-ajcenter">
						<div class="left-top">
							<div class="left-top__sum">
								$100
							</div>
							<div class="left-top__dw" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								/月
							</div>
							<div class="left-top__dw" v-else-if="lang === 'weiwuer'">
								/ئاي
							</div>
							<div class="left-top__dw" v-else>
								/Monthly
							</div>
						</div>
						<div class="left-bot" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							奖励
						</div>
						<div class="left-bot" v-else-if="lang === 'weiwuer'">
							مۇكاپات
						</div>
						<div class="left-bot" v-else>
							Awards
						</div>
					</div>
					<div class="pay-pages6__li__info flex">
						<div class="info-pag flex align-center">
							<img src="../assets/image/pay/ico01.png" alt="">
							<div class="desc" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								管理资金 >$20,000
							</div>
							<div class="desc" v-else-if="lang === 'weiwuer'">
								مەبلەغنى باشقۇرۇش >$20,000
							</div>
							<div class="desc" v-else>
								Following Funds >$20,000
							</div>
						</div>
						<div class="info-pag flex align-center">
							<img src="../assets/image/pay/ico02.png" alt="">
							<div class="desc" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
								跟随客户量 ≥10
							</div>
							<div class="desc" v-else-if="lang === 'weiwuer'">
								خېرىدارلارغا ئەگىشىش مىقدارى ≥10
							</div>
							<div class="desc" v-else>
								Following Number of Users ≥10
							</div>
						</div>
					</div>
					<div class="pay-pages6__li__name flex-1 flex flex-ajcenter"
						v-if="lang == 'zh_CN' || lang == 'zh_TW'">
						新锐
					</div>
					<div class="pay-pages6__li__name flex-1 flex flex-ajcenter" v-else-if="lang === 'weiwuer'">
						يۇلتۇز
					</div>
					<div class="pay-pages6__li__name flex-1 flex flex-ajcenter" v-else>
						Rising-Star
					</div>
				</div>
			</div>
			<div class="pay-pages4" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
				增值服务
			</div>
			<div class="pay-pages4" v-else-if="lang === 'weiwuer'">
				قوشۇمچە مۇلازىمەتلەر
			</div>
			<div class="pay-pages4" v-else>
				value-added services
			</div>
			<div class="pay-pages5">
				<div class="pay-pages5__menu flex">
					<div class="pay-pages5__menu__pag flex-1 flex-ajcenter flex-column">
						<div class="pag-tit" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							增值服务
						</div>
						<div class="pag-tit" v-else-if="lang === 'weiwuer'">
							قوشۇمچە مۇلازىمەتلەر
						</div>
						<div class="pag-tit" v-else>
							value-added services
						</div>
						<div class="pag-tips" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							（按等级划分）
						</div>
						<div class="pag-tips" v-else-if="lang === 'weiwuer'">
							(دەرىجىسى بويىچە ئايرىش)
						</div>
						<div class="pag-tips" v-else>
							(ranking system)
						</div>
					</div>
					<div class="pay-pages5__menu__pag flex-1 flex flex-ajcenter">
						<div class="pag-img">
							<img src="../assets/image/pay/icon-1.png" alt="" class="img">
						</div>
						<div class="pag-tit" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							<div class="pag-tit__h1">
								精英
							</div>
							<div class="pag-tit__h2">
								交易员
							</div>
						</div>
						<div class="pag-tit" v-else-if="lang === 'weiwuer'">
							<div class="pag-tit__h1">
								قالتىس
							</div>
							<div class="pag-tit__h2">
								سودا ماھىرى
							</div>
						</div>
						<div class="pag-tit" v-else>
							<div class="pag-tit__h1">
								Elite
							</div>
							<div class="pag-tit__h2">
								Traders
							</div>
						</div>
					</div>
					<div class="pay-pages5__menu__pag flex-1 flex flex-ajcenter">
						<div class="pag-img">
							<img src="../assets/image/pay/icon-2.png" alt="" class="img">
						</div>
						<div class="pag-tit" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							<div class="pag-tit__h1">
								冠军
							</div>
							<div class="pag-tit__h2">
								交易员
							</div>
						</div>
						<div class="pag-tit" v-else-if="lang === 'weiwuer'">
							<div class="pag-tit__h1">
								چېمپىيون
							</div>
							<div class="pag-tit__h2">
								سودا ماھىرى
							</div>
						</div>
						<div class="pag-tit" v-else>
							<div class="pag-tit__h1">
								Champion
							</div>
							<div class="pag-tit__h2">
								Traders
							</div>
						</div>
					</div>
					<div class="pay-pages5__menu__pag flex-1 flex flex-ajcenter">
						<div class="pag-img">
							<img src="../assets/image/pay/icon-3.png" alt="" class="img">
						</div>
						<div class="pag-tit" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							<div class="pag-tit__h1">
								明星
							</div>
							<div class="pag-tit__h2">
								交易员
							</div>
						</div>
						<div class="pag-tit" v-else-if="lang === 'weiwuer'">
							<div class="pag-tit__h1">
								چولپان
							</div>
							<div class="pag-tit__h2">
								سودا ماھىرى
							</div>
						</div>
						<div class="pag-tit" v-else>
							<div class="pag-tit__h1">
								Star
							</div>
							<div class="pag-tit__h2">
								Traders
							</div>
						</div>
					</div>
					<div class="pay-pages5__menu__pag flex-1 flex flex-ajcenter">
						<div class="pag-img">
							<img src="../assets/image/pay/icon-4.png" alt="" class="img">
						</div>
						<div class="pag-tit" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							<div class="pag-tit__h1">
								传奇
							</div>
							<div class="pag-tit__h2">
								交易员
							</div>
						</div>
						<div class="pag-tit" v-else-if="lang === 'weiwuer'">
							<div class="pag-tit__h1">
								مۆجىزە
							</div>
							<div class="pag-tit__h2">
								سودا ماھىرى
							</div>
						</div>
						<div class="pag-tit" v-else>
							<div class="pag-tit__h1">
								Legend
							</div>
							<div class="pag-tit__h2">
								Traders
							</div>
						</div>
					</div>
				</div>
				<div class="pay-pages5__list flex">
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="tit" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							5×24小时<br />优先客服
						</div>
						<div class="tit" v-else-if="lang === 'weiwuer'">
							5 × 24 سائەت خېرىدارلارغا مۇلازىمەت قىلىش
						</div>
						<div class="tit" v-else>
							5x24 customer service
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
				</div>
				<div class="pay-pages5__list flex">
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="tit" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							参与EBC<br />品牌方营销互动
						</div>
						<div class="tit" v-else-if="lang === 'weiwuer'">
							EBC تەرەپ ئۆز-ئارا مۇزاكىرلىشش پائالىيىتىگە قاتنىشىش
						</div>
						<div class="tit" v-else>
							involvement in EBC marketing campaign
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
				</div>
				<div class="pay-pages5__list flex">
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="tit" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							VPS赞助
						</div>
						<div class="tit" v-else-if="lang === 'weiwuer'">
							VPS سوۋغا قىلش
						</div>
						<div class="tit" v-else>
							VPS sponsorship
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="heng">

						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
				</div>
				<div class="pay-pages5__list flex">
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="tit" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							金融投资类<br />书籍赞助
						</div>
						<div class="tit" v-else-if="lang === 'weiwuer'">
							پۇل مۇئامىلە مەبلەغ سېلىش تۈرىدىكى كىتاب ماتىرياللار بىلەن تەمىنلەش
						</div>
						<div class="tit" v-else>
							investing books sponsorship
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="heng">

						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
				</div>
				<div class="pay-pages5__list flex">
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="tit" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							订单流软件<br />一年使用权
						</div>
						<div class="tit" v-else-if="lang === 'weiwuer'">
							زاكاز ئېقىم يۇمشاق دېتال بىر يىللىق ئىشلىتىش ھوقۇقى
						</div>
						<div class="tit" v-else>
							one-year subscription of order flow trading software
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="heng">

						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
				</div>
				<div class="pay-pages5__list flex">
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="tit" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							EBC定制<br />大礼包
						</div>
						<div class="tit" v-else-if="lang === 'weiwuer'">
							EBC زاكاز قىلىنغان چوڭ سوۋغات
						</div>
						<div class="tit" v-else>
							customized rewards package
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="heng">

						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
				</div>
				<div class="pay-pages5__list flex">
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="tit" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							信号置顶展示
						</div>
						<div class="tit" v-else-if="lang === 'weiwuer'">
							باش بەتتە سېگىنالنى نامايەندە قىلش
						</div>
						<div class="tit" v-else>
							signal displayed at the top
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="heng">

						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="heng">

						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
				</div>
				<div class="pay-pages5__list flex">
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="tit" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							受邀参观<br />海外办公室
						</div>
						<div class="tit" v-else-if="lang === 'weiwuer'">
							چەت ئەلدىكى ئىشخانىنى ئېكسكۇرسىيە قىلىشقا تەكلىپ قىلىندۇ
						</div>
						<div class="tit" v-else>
							a visit to EBC office overseas
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="heng">

						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="heng">

						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
				</div>
				<div class="pay-pages5__list flex">
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="tit" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							媒体资源推送
						</div>
						<div class="tit" v-else-if="lang === 'weiwuer'">
							ئاخبارات ۋاستىلىرى ئېقىملىرى بىلەن تەمىنلەش
						</div>
						<div class="tit" v-else>
							shared media resoruces
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="heng">

						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="heng">

						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="heng">

						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
				</div>
				<div class="pay-pages5__list flex">
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="tit" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							品牌战略合作
						</div>
						<div class="tit" v-else-if="lang === 'weiwuer'">
							ماركا تەرەپ بىلەن ئىستراتېگىيەلىك ھەمكارلىق ئورنىتش
						</div>
						<div class="tit" v-else>
							co-branding
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="heng">

						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="heng">

						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="heng">

						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
				</div>
				<div class="pay-pages5__list flex">
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="tit" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
							海外基金产品<br />发行顾问服务
						</div>
						<div class="tit" v-else-if="lang === 'weiwuer'">
							چەت ئەل فوند مەھسۇلاتلىرىنى تارقىتىش مەسلىھەتچىلىك مۇلازىمىتى
						</div>
						<div class="tit" v-else>
							offshore fund launch advisory service
						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="heng">

						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="heng">

						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="heng">

						</div>
					</div>
					<div class="pay-pages5__list__li flex-1 flex-ajcenter">
						<div class="iconfont">
							&#xe664;
						</div>
					</div>
				</div>

			</div>
			<div class="pay-pages7">
				<div class="pay-pages7__title" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
					活动细则
				</div>
				<div class="pay-pages7__title" v-else-if="lang === 'weiwuer'">
					پائالىيەتنىڭ تەپسىلىي قائىدىسى:
				</div>
				<div class="pay-pages7__title" v-else>
					rules
				</div>
				<div class="pay-pages7__desc" v-if="lang == 'zh_CN' || lang == 'zh_TW'">
					1.EBC账户成为信号方，账户资金可算入管理资金奖励数值；<br />
					2.1个MT4账户最多允许同时跟单10个信号方账户，单个信号跟随账户金额=跟随账户资金/跟随信号数；<br />
					3.账户起始跟随净值>=500USD,方可纳为有效跟随账户。自然月内每个交易日满足最低达标，方可激活交易员奖励。次月第一周计算并发放交易员奖励，取上月较低级别奖励；<br />
					4.所有奖励发到EBC后台钱包，获奖者自行提取，请参赛者提前注册；<br />
					5.活动最终解释权归主办方EBC所有。
				</div>
				<div class="pay-pages7__desc" v-else-if="lang === 'weiwuer'">
					1. ئۈنۈملۈك ئەگەشمە ھېسابات: ھېسابات باشلىنىش ئوتتۇرىچە ساپ قىممىتى 500USD دىن چوڭ ياكى تەڭ بولىدۇ.
					EBCھېساباتى سىگنال بەرگۈچى تەرەپ بولۇپ ، ھېسابتىكى مەبلەغ باشقۇرۇش مەبلىغىنىڭ مۇكاپات سانلىق
					قىممىتىگە
					كىرگۈزۈلىدۇ.<br />
					2. ھەر بىر ئەگەشكەن ھېسابتىكى سوممىنى ھېسابلاش ئۇسۇلى: ئىسچوتتىكى مەبلەغ ئەگىشىش سانى MT4ھېساباتى
					بىرلا
					ۋاقىتتا 10 سىگنال تەرەپ ئەڭ كۆپ بولغاندا ھېساباتىغا ئەگشەلايدۇ<br />
					3. تەبىئىي ئاي ئىچىدە ھەر بىر سودا كۈنىدە ئەڭ تۆۋەن ئۆلچەمگە يەتكەندىلا ، ئاندىن سودا خادىملىرىنى
					مۇكاپاتلاشقا
					بولىدۇ. كېيىنكى ئاينىڭ ئەڭ ئاخىرقى كۈنى سودا خادىملىرىغا بېرىلىدىغان مۇكاپاتنى ھېسابلاپ ۋە تارقىتىپ
					، شۇ
					ئايلىق تۆۋەن دەرىجىلىك مۇكاپاتنى ئالىدۇ.<br />
					4. بارلىق مۇكاپات EBC ئارقا سۇپىغا يوللىنىپ ، مۇكاپاتقا ئېرىشكۈچىلەر بەھىرلىنەلەيدۇ ، مۇسابىقىگە
					قاتناشقۇچىلار
					ئالدىن تىزىمغا ئالدۇرۇڭ.<br />
					5. پائالىيەتنىڭ ئەڭ ئاخىرقى چۈشەندۈرۈش ھوقۇقى ئۇيۇشتۇرغۇچى تەرەپ EBC نىڭ ئىگىدارلىقىدا بولىدۇ.
				</div>
				<div class="pay-pages7__desc" v-else>
					1.EBC account is a signal source.Fund of a signal soruce is included in followers' AUM base
					calculation.<br />
					2.One MT4 account can follow up to 10 signal sourecs. Followers' AUM for every signal is calculated
					as
					followers' AUM divided by the number of signals followed. <br />
					3.A minium of $500 principle is required for a valid follower account.
					You must meet the threshold every trading day in a calendar month to be rewarded.
					Rewards will be distributed in the first week of the following month based on the lowest level
					reached in the
					current month.
					<br />
					4.All rewards will be distributed to EBC e-wallet which requires registration ahead.<br />
					5.All rights reserved.
				</div>
			</div>
		</div>
		<noscript>
			<a href="https://www.livechatinc.com/chat-with/12340599/" rel="nofollow"> Chat with us </a>, powered by
			<a href="https://www.livechatinc.com/?welcome" rel="noopener nofollow " target="_blank ">LiveChat</a>
		</noscript>
	</div>
</template>

<script>
	import {
		mapState,
	} from 'vuex'
	export default {
		head() {
			return {
				title: this.lang === 'en_US' ? "EBC Financial Group|Trade & get paid" : 'EBC Financial Group|英国澳洲双持牌券商',
				meta: [ // set meta
					{
						name: 'keyWords',
						content: 'EBC,EBC平台,EBC官网,EBC金融官网,EBC开户,EBC金融'
					},
					{
						name: 'description',
						content: 'EBC金融官网是由EBC金融集团EBC Financial Group成立的,主要提供,EBC金融,EBC开户交易服务,只需一个EBC综合平台账户,即可交易多种差价合约（CFD）:衍生品、美股、数字货币、期货指数、贵金属和能源等.'
					}
				]
			}
		},
		data() {
			return {
				
			}
		},
		computed: {
			...mapState({
				lang: state => state.Lan,
			}),
		},
		mounted() {
			window.dataLayer = window.dataLayer || [];

			// function gtag() {
			// 	dataLayer.push(arguments);
			// }
			// gtag('js', new Date());
			// gtag('config', 'G-TJ431PDHZX');
			window.__lc = window.__lc || {};
			window.__lc.license = 12340599;
			(function(n, t, c) {
				function i(n) {
					return e._h ? e._h.apply(null, n) : e._q.push(n)
				}
				var e = {
					_q: [],
					_h: null,
					_v: "2.0",
					on: function() {
						i(["on", c.call(arguments)])
					},
					once: function() {
						i(["once", c.call(arguments)])
					},
					off: function() {
						i(["off", c.call(arguments)])
					},
					get: function() {
						if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
						return i(["get", c.call(arguments)])
					},
					call: function() {
						i(["call", c.call(arguments)])
					},
					init: function() {
						var n = t.createElement("script");
						n.async = !0, n.type = "text/javascript", n.src =
							"https://cdn.livechatinc.com/tracking.js", t.head.appendChild(n)
					}
				};
				!n.__lc.asyncInit && e.init(), n.LiveChatWidget = n.LiveChatWidget || e
			}(window, document, [].slice))
			let nowDate = new Date();
		},
		created() {},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
	.pay {
		background: url(../assets/image/pay/bg.png)no-repeat top left #3d0101;
		background-size: 80%;
		padding-bottom: 70px;

		.content {
			max-width: 1200px;
			margin: auto;
		}

		&-head {
			padding: 20px 0;

			&__url {
				color: rgba(224, 172, 94, 1);
				font-size: 20px;
				font-weight: 400;
				letter-spacing: 2px;
			}
		}

		&-pages1 {
			background: url(../assets/image/pay/pay-4.png)no-repeat bottom right;
			padding-bottom: 140px;
			background-size: 55%;

			&__title {
				line-height: 1.2;
				padding: 60px 0;
				font-family: monospace;

				&__h1 {
					font-size: 72px;
					font-weight: bold;
					color: #f6dd8c;
				}

				&__h2 {
					font-size: 62px;
					color: #f6dd8c;
					letter-spacing: -2px;
				}
			}

			&__desc {
				padding-left: 10px;
				font-size: 28px;
				color: rgba(250, 220, 137, 1);
				line-height: 2.25;

				.iconfont {
					margin-right: 20px;
				}
			}

			&__btn {
				padding: 50px 0 10px 10px;

				a {
					height: 90px;
					min-width: 340px;
					background: url(../assets/image/pay/pay-3.png)no-repeat;
					background-size: 100% 100%;
					font-size: 44px;
					font-weight: 700;
					color: #3d0101;
					display: inline-block;
					text-align: center;
					line-height: 90px;
				}
			}
		}

		&-pages2 {
			font-size: 40px;
			font-weight: bold;
			letter-spacing: 1px;
			color: #fadc89;
			font-family: monospace;
		}

		&-pages3 {
			padding: 100px 0 80px;
			background: url(../assets/image/pay/pay-2.png)no-repeat left 20px;

			&__ul {
				align-items: flex-end;
				padding: 0 10px 0 20px;

				&__li {
					margin-right: 10px;

					&:nth-child(3) {
						.li-top__img {
							padding: 50px 0 20px;
						}
					}

					&:nth-child(4) {
						.li-top__img {
							padding: 50px 0 20px;
						}
					}

					.li-top {
						background-color: #510703;
						padding: 10px;

						&__head {
							background: #850505;
							height: 48px;
							align-items: center;
							padding: 0 10px;
							position: relative;

							.name {
								color: #fffddf;
								font-size: 16px;
							}

							.sum {
								font-size: 16px;
								font-weight: 700;
								color: #fffddf;
							}

							.iconfont {
								position: absolute;
								top: 0;
								right: 0;
								color: #510703;
								font-size: 18px;
							}
						}

						&__tips {
							background: #850505;
							color: #fffddf;
							text-align: center;
							font-size: 14px;
							font-weight: 700;
							padding-bottom: 5px;
						}

						&__img {
							text-align: center;
							padding: 20px 0;
						}

						&__desc {
							color: #fffddf;
							font-size: 16px;
							line-height: 1.5;
							align-items: center;
						}
					}

					.li-title {
						color: #fff;
						font-size: 21px;
						line-height: 1.75;

						&__name {
							font-weight: 900;
							padding: 0 6px;
							white-space: nowrap;
						}
					}
				}
			}
		}

		&-pages4 {
			font-size: 40px;
			font-weight: bold;
			letter-spacing: 1px;
			color: #fadc89;
			font-family: monospace;
			padding-bottom: 40px;
			padding-top: 40px;
			background: url(../assets/image/pay/pay-1.png)no-repeat right top;
			background-size: 147px;
		}

		&-pages5 {
			background-color: #fff;
			overflow: hidden;

			&__menu {
				background: #ebebeb;
				box-shadow: 0px 0px 10.01px 0px #7d5146;
				padding: 10px;

				&__pag {
					background-color: #cec4c1;
					min-height: 100px;
					width: 20%;

					&:nth-child(2) {
						background-color: #907776;
					}

					&:nth-child(3) {
						background-color: #80605f;

						.pag-tit {
							color: #fff;
						}
					}

					&:nth-child(4) {
						background-color: #5d3332;

						.pag-tit {
							color: #fff;
						}
					}

					&:nth-child(5) {
						background-color: #3a0603;

						.pag-tit {
							color: #fff;
						}
					}

					.pag-tit {
						font-size: 28px;
						font-weight: 700;
						color: #3d0202;
						line-height: 1;
						text-align: center;

						&__h2 {
							font-size: 22px;
						}
					}

					.pag-img {
						.img {
							width: 80%;
						}
					}

					.pag-tips {
						font-size: 20px;
						font-weight: 700;
						color: #3d0202;
					}
				}
			}

			&__list {
				background-color: #fff;
				padding: 0 10px;

				&__li {
					min-height: 100px;
					margin-right: 4px;

					&:nth-child(1) {
						background-color: #fcfbfa;
					}

					&:nth-child(2) {
						background-color: #f7f4f3;
					}

					&:nth-child(3) {
						background-color: #f5f1f0;
					}

					&:nth-child(4) {
						background-color: #f1edec;
					}

					&:nth-child(5) {
						background-color: #ede9e7;
						margin-right: 0px;
					}

					.tit {
						font-size: 22px;
						color: #3d0202;
						text-align: center;
						line-height: 1.5;
					}

					.iconfont {
						font-size: 30px;
						color: #3d0202;
					}

					.heng {
						width: 24px;
						height: 6px;
						background: #d1c3c2;
					}
				}
			}
		}

		&-pages6 {
			display: none;
		}

		&-pages7 {
			padding-top: 40px;
			color: #d9b282;
			line-height: 1.75;
			font-weight: 400;
			font-size: 14px;

			&__title {
				font-size: 16px
			}
		}

		@media (max-width: 500px) {
			&-head {
				padding: 10px;

				&__url {
					font-size: 15px;
				}

				.logo {
					width: 70%;
				}
			}

			&-pages1 {
				background: url(../assets/image/pay/pay-4.png)no-repeat bottom right;
				background-size: 60%;
				padding: 10px 10px 40px;

				&__title {
					line-height: 1.5;
					padding: 10px 0;
					font-family: monospace;

					&__h1 {
						font-size: 42px;
						font-weight: bold;
						color: #f6dd8c;
					}

					&__h2 {
						font-size: 32px;
						color: #f6dd8c;
						letter-spacing: -2px;
					}
				}

				&__desc {
					padding-left: 10px;
					font-size: 16px;
					color: rgba(250, 220, 137, 1);
					line-height: 1.75;

					.iconfont {
						margin-right: 10px;
					}
				}

				&__btn {
					padding: 30px 0 10px 10px;

					a {
						height: 45px;
						min-width: 170px;
						font-size: 24px;
						line-height: 45px;
						white-space: nowrap;
						padding: 0 10px;
					}
				}
			}

			&-pages2 {
				font-size: 24px;
				letter-spacing: 0px;
				text-align: center;
			}

			&-pages3 {
				display: none;
			}

			&-pages4 {
				font-size: 24px;
				letter-spacing: 0px;
				text-align: center;
				padding-bottom: 20px;
				padding-top: 40px;
				background: url(../assets/image/pay/pay-1.png)no-repeat right 25px;
				background-size: 72px;
			}

			&-pages5 {
				margin: 0px 5px;

				&__menu {
					padding: 2px;

					&__pag {
						min-height: 60px;

						.pag-tit {
							font-size: 14px;

							&__h2 {
								font-size: 12px;
							}
						}

						.pag-img {
							width: 30%;

							.img {
								width: 100%;
							}
						}

						.pag-tips {
							font-size: 12px;
						}
					}
				}

				&__list {
					background-color: #fff;
					padding: 0 2px;

					&__li {
						min-height: 60px;
						margin-right: 2px;

						&:nth-child(5) {
							background-color: #ede9e7;
							margin-right: 0px;
						}

						.tit {
							font-size: 12px;
							color: #3d0202;
							text-align: center;
							line-height: 1.2;
						}

						.iconfont {
							font-size: 20px;
							color: #3d0202;
						}

						.heng {
							width: 12px;
							height: 4px;
							background: #d1c3c2;
						}
					}
				}
			}

			&-pages7 {
				padding: 40px 10px 0;
			}

			&-pages6 {
				display: block;
				padding: 40px 10px 0;

				&__li {
					background: url(../assets/image/pay/bg01.png)no-repeat;
					background-size: 100% 100%;
					padding-top: 18px;
					height: 100px;
					margin-bottom: 5px;

					&:nth-child(1) {
						padding-top: 10px;
						height: 120px;

						.left-top {
							color: #d0bda3;
						}
					}

					&:nth-child(2) {
						background: url(../assets/image/pay/bg02.png)no-repeat;
						background-size: 100% 100%;
					}

					&:nth-child(3) {
						background: url(../assets/image/pay/bg03.png)no-repeat;
						background-size: 100% 100%;
					}

					&:nth-child(4) {
						background: url(../assets/image/pay/bg04.png)no-repeat;
						background-size: 100% 100%;
					}

					&:nth-child(5) {
						background: url(../assets/image/pay/bg05.png)no-repeat;
						background-size: 100% 100%;
					}

					&__left {
						background-color: #700e06;
						width: 32%;
						height: 100%;

						.left-top {
							display: flex;
							align-items: baseline;
							color: #fff;

							&__sum {
								font-size: 16px;
								font-weight: bold;
							}

							&__tips {
								font-size: 14px;
								position: relative;

								&__desc {
									position: absolute;
									bottom: -180%;
									right: -90%;
									font-size: 10px;
									background-color: rgba(#fff, 0.5);
									padding: 4px 6px;
									border-radius: 0 10px 10px 10px;
									color: #d3c0a6;
								}
							}
						}

						.left-add {
							color: #d0bda3;
							font-size: 16px;
							font-weight: bold;
							padding-bottom: 5px;
						}

						.left-bot {
							color: rgba(250, 220, 137, 1);
							font-size: 10px;
							margin-top: 5px;
							padding: 5px 20px;
							background-color: rgba(#333, 0.3);
							border-radius: 4px;
						}
					}

					&__info {
						height: 100%;
						flex-direction: column;
						color: #fff;
						font-size: 12px;
						justify-content: center;
						line-height: 1.5;
						padding-left: 10px;

						img {
							width: 9px;
							height: 11px;
							margin-right: 4px;
						}
					}

					&__name {
						height: 100%;
						color: #d9d9d8;
						font-size: 20px;
						font-weight: bold;
					}
				}
			}
		}
	}
</style>