<template>
    <div>
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100..700&display=swap" rel="stylesheet">
        <ss-header mode="Home" bg="rgb(38, 5, 5)"></ss-header>
        <div class="MT5" :class="[$t('MT5.box-class-name'), $t('MT5.lang')]">
            <div class="mt-section1">
                <div class="mt-section1-bg">
                    <img class="mt-section1-top-bg" src="../assets/image/mt/mt5_bg2.png" />
                    <div class="mt-section1-content">
                        <div class="title">
                            MetaTrader 5
                        </div>
                        <div class="subtitle">
                            {{ $t('MT5.MetaTrader 5 (MT5) 是新一代多资产交易平台') }}
                        </div>
                        <div class="list">
                            <div class="li">
                                <img class="icon" src="../assets/image/mt/icon_star.svg" />
                                {{ $t('MT5.提供市场深度信息') }}
                            </div>
                            <div class="li">
                                <img class="icon" src="../assets/image/mt/icon_star.svg" />
                                {{ $t('MT5.支持6种挂单交易类型') }}
                            </div>
                            <div class="li">
                                <img class="icon" src="../assets/image/mt/icon_star.svg" />
                                {{ $t('MT5.内置经济日历') }}
                            </div>
                        </div>
                        <a :href="$store.state.khUrl" rel="nofollow">
                            {{ $t('MT5.立即注册') }}
                            <img class="icon-right" src="../assets/image/mt/icon_right.svg" alt="">
                            <img class="icon-right-hover" src="../assets/image/mt/icon_right_hover.svg" alt="">
                        </a>
                    </div>
                    <img class="mt-section1-bottom-bg" src="../assets/image/mt/mt5_bg1.png" />
                </div>



            </div>
            <div class="page-block">
                <div class="mt-section2">
                    <div class="title">
                        {{ $t('MT5.MetaTrader 5下载') }}
                    </div>
                    <div class="version-list">
                        <div class="version-item">
                            <div class="version-item-img">
                                <img class="img1" src="../assets/image/mt/mt_1.png" alt="">
                            </div>
                            <div class="version-item-title">
                                {{ $t('MT5.MT5移动版') }}
                            </div>
                            <div class="version-item-subtitle">
                                {{ $t('MT5.随时随地进行交易') }}
                            </div>
                            <div class="download-links">
                                <a rel="nofollow" target="_blank"
                                    href="https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/metatrader5.apk"
                                    class="download-btn">
                                    <img class="icon" src="../assets/image/mt/icon_android.svg" />
                                    {{ $t('MT5.下载Android版') }}
                                    <img class="icon" src="../assets/image/mt/icon_download.svg" />
                                </a>

                                <a rel="nofollow" target="_blank"
                                    href="https://download.mql5.com/cdn/mobile/mt5/ios?server=EBCFinancialGroupKY-Live"
                                    class="download-btn">
                                    <img class="icon" src="../assets/image/mt/icon_ios.svg" />
                                    {{ $t('MT5.下载IOS版') }}
                                    <img class="icon" src="../assets/image/mt/icon_download.svg" />
                                </a>

                                <a rel="nofollow" target="_blank"
                                    href="https://download.mql5.com/cdn/mobile/mt5/ios?server=EBCFinancialGroupKY-Live"
                                    class="download-btn">
                                    <img class="icon" src="../assets/image/mt/icon_ios.svg" />
                                    {{ $t('MT5.下载iPad版') }}
                                    <img class="icon" src="../assets/image/mt/icon_download.svg" />
                                </a>
                            </div>

                        </div>
                        <div class="version-item">
                            <div class="version-item-img">
                                <img class="img2" src="../assets/image/mt/mt_2.png" alt="">
                            </div>
                            <div class="version-item-title">
                                {{ $t('MT5.MT5桌面版') }}
                            </div>
                            <div class="version-item-subtitle">
                                {{ $t('MT5.全面的交易工具和高级图表分析') }}
                            </div>
                            <div class="download-links">
                                <a rel="nofollow" target="_blank"
                                    href="https://download.mql5.com/cdn/web/ebc.financial.group/mt5/ebcfinancialgroupky5setup.exe"
                                    class="download-btn">
                                    <img class="icon" src="../assets/image/mt/icon_window.svg" />
                                    {{ $t('MT5.下载Windows版') }}
                                    <img class="icon" src="../assets/image/mt/icon_download.svg" />
                                </a>
                                <a rel="nofollow" target="_blank"
                                    href="https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.pkg.zip"
                                    class="download-btn">
                                    <img class="icon" src="../assets/image/mt/icon_ios.svg" />
                                    {{ $t('MT5.下载MacOS版') }}
                                    <img class="icon" src="../assets/image/mt/icon_download.svg" />
                                </a>
                            </div>
                        </div>
                        <div class="version-item">
                            <div class="version-item-img">
                                <img class="img3" src="../assets/image/mt/mt_3.png" alt="">
                            </div>
                            <div class="version-item-title">
                                {{ $t('MT5.MT5网页版') }}
                            </div>
                            <div class="version-item-subtitle">
                                {{ $t('MT5.无需下载') }}
                            </div>
                            <div class="download-links">
                                <!-- target="_blank" -->
                                <a rel="nofollow" href="javascript:void(0)" class="download-btn disabled">
                                    <img class="icon" src="../assets/image/mt/icon_ie.svg" />
                                    {{ $t('MT5.打开网页版') }}
                                    <img class="icon" src="../assets/image/mt/icon_link.svg" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="mt-section3">
                <div class="title">
                    {{ $t('MT5.MT4与MT5的区别') }}
                </div>
                <div class="subtitle">
                    {{ $t('MT5.MT4和MT5均由MetaQuotes开发') }}
                </div>
                <div class="compare-table">
                    <div class="th">
                        <div class="td">{{ $t('MT5.项目') }}</div>
                        <div class="td">MT4</div>
                        <div class="td">MT5</div>
                    </div>
                    <div class="tr">
                        <div class="td">{{ $t('MT5.发布时间') }}</div>
                        <div class="td">{{ $t('MT5.2005年') }}</div>
                        <div class="td">{{ $t('MT5.2010年') }}</div>
                    </div>
                    <div class="tr">
                        <div class="td">{{ $t('MT5.交易市场') }}</div>
                        <div class="td">{{ $t('MT5.主要是外汇') }}</div>
                        <div class="td">{{ $t('MT5.包括股票') }}</div>
                    </div>
                    <div class="tr">
                        <div class="td">{{ $t('MT5.挂单类型') }}</div>
                        <div class="td">{{ $t('MT5.4种') }}</div>
                        <div class="td">{{ $t('MT5.6种') }}</div>
                    </div>
                    <div class="tr">
                        <div class="td">{{ $t('MT5.时间框架') }}</div>
                        <div class="td">{{ $t('MT5.9种') }}</div>
                        <div class="td">{{ $t('MT5.21种') }}</div>
                    </div>
                    <div class="tr">
                        <div class="td">{{ $t('MT5.技术指标') }}</div>
                        <div class="td">{{ $t('MT5.30个') }}</div>
                        <div class="td">{{ $t('MT5.38个') }}</div>
                    </div>
                    <div class="tr">
                        <div class="td">{{ $t('MT5.市场深度') }}</div>
                        <div class="td">{{ $t('MT5.不支持') }}</div>
                        <div class="td">{{ $t('MT5.支持') }}</div>
                    </div>
                    <div class="tr">
                        <div class="td">{{ $t('MT5.财经日历') }}</div>
                        <div class="td">{{ $t('MT5.不支持') }}</div>
                        <div class="td">{{ $t('MT5.内置') }}</div>
                    </div>
                    <div class="tr">
                        <div class="td">{{ $t('MT5.操作界面') }}</div>
                        <div class="td">{{ $t('MT5.更直观简单') }}</div>
                        <div class="td">{{ $t('MT5.更加现代化灵活') }}</div>
                    </div>
                </div>
                <a :href="$store.state.khUrl" rel="nofollow">{{ $t('MT5.立即注册') }}</a>
            </div>
        </div>
        <new-footer></new-footer>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    }
}
</script>
<style>
@charset "UTF-8";

.c-footer .fp-overflow {
    display: unset !important;
}

body {
    background: #fff;
}

.mt-section1-top-bg,
.mt-section1-bottom-bg {
    display: none;
}

.mt-section1 {
    position: relative;
    margin-top: 71px;
    height: auto;
    background: linear-gradient(0deg, #030201 0%, #055CAC 100%);
    width: 100%;
    color: #fff;
}

.MT4 .mt-section1 {
    background: linear-gradient(0deg, #030201 0%, #9C571D 100%);
}

.mt-section1-bg {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 387px;
    padding: 40px 0;
    background: url(../assets/image/mt/mt5_bg.png) no-repeat;
    background-position: center center;
    background-size: contain;
    /* //cover; */
    /* max-width: 1920px; */
    max-width: 1440px;
    margin: 0 auto;
}

.MT4 .mt-section1-bg {
    background: url(../assets/image/mt/mt4_bg.png) no-repeat;
    background-position: center center;
    background-size: contain;
}

.MT4 .ar .mt-section1-bg {
    background: url(../assets/image/mt/mt4_bg_r.png) no-repeat;
    background-position: center center;
    background-size: contain;
}

.ar .mt-section1-bg {
    background: url(../assets/image/mt/mt5_bg_r.png) no-repeat;
    background-position: center center;
    background-size: contain;
}

.mt-section1-left-bg {
    width: 217.801px;
    height: 220px;
    background: url(../assets/image/mt/mt5_bg1.png);
    background-position: center center;
    background-size: cover;
    position: absolute;
    top: 13.9%;
    left: 5.41%;
    z-index: 1;
}


.mt-section1-right-bg {
    width: 441.499px;
    height: 271.413px;
    background: url(../assets/image/mt/mt5_bg2.png);
    background-position: center center;
    background-size: cover;
    position: absolute;
    top: 18.94%;
    right: 12.29%;
    z-index: 1;
}

.mt-section1-content {
    /* margin: 0 15.97%; */
    margin: 0 12%;
    z-index: 2;
    /* flex-shrink: 0; */
}

.mt-section1-content .title {
    font-family: "Source Han Sans CN", "Noto Sans SC", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    margin-bottom: 0;
    width: 300px;
}

.mt-section1-content .subtitle {
    font-family: "Source Han Sans CN", "Noto Sans SC", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 150%;
    max-width: 34.44%;
    min-width: 496px;
    color: rgba(255, 255, 255, .8);
    margin-bottom: 12px;
}

.mt-section1-content .list .li {
    font-family: "Source Han Sans CN", "Noto Sans SC", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
    /* max-width: 34.44%;
	min-width: 496px; */
    /* flex-wrap: wrap; */
    min-width: 575px;
    max-width: 47.52%;
}

.mt-section1-content .list .li .icon {
    display: inline-block;
    width: 14px;
    margin-right: 8px;
    flex-shrink: 0;
    margin-top: 2px;
}

.ar .mt-section1-content .list .li .icon {
    margin-right: 0;
    margin-left: 8px;
}

.mt-section1-content a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 48px;
    margin-top: 20px;
    border-radius: 4px;
    background: #fff;
    color: #5D0101;
    font-family: "Source Han Sans CN", "Noto Sans SC", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    gap: 4px;
}

.ru .mt-section1-content a {
    width: 240px;
}

.mt-section1-content a:hover {
    background: #AF8147;
    color: #fff;
}


.mt-section1-content a img {
    margin-top: 3px;
}

.mt-section1-content a .icon-right {
    display: inline-block;
}

.mt-section1-content a:hover .icon-right {
    display: none;
}

.mt-section1-content a .icon-right-hover {
    display: none;
}

.mt-section1-content a:hover .icon-right-hover {
    display: inline-block;
}

.right-hover {
    display: none;
    visibility: hidden;
}


.page-block {
    width: 100%;
    background-color: #f8f8f8;
    padding: 112px 64px;
}

.mt-section2 .title,
.mt-section3 .title {
    color: #333;
    text-align: center;
    font-family: "Source Han Sans CN", "Noto Sans SC", sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

.version-list {
    display: flex;
    margin: 80px auto 0;
    gap: 28px;
    max-width: 1312px;
    width: 100%;
}

.version-item {
    padding: 40px 24px;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    width: 33.33%;
}

.version-item-img {
    display: flex;
    justify-content: center;
}

.version-item-img .img1 {
    /* height: 255px; */
    width: 88.54%;
    height: auto;
}

.version-item-img .img2 {
    width: 97.13%;
    height: auto;
}

.version-item-img .img3 {
    width: 68.25%;
    height: auto;
}


.version-item-title {
    margin-top: 32px;
    text-align: center;
    font-family: "Source Han Sans CN", "Noto Sans SC", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;

}

.en-box .version-item-title {
    font-size: 24px;
    min-height: 67px;
}

.version-item-subtitle {
    margin-top: 16px;
    text-align: center;
    font-family: "Source Han Sans CN", "Noto Sans SC", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    min-height: 72px;
}

.en-box .version-item-subtitle {
    font-size: 14px;
    min-height: 96px;
}

.download-links {
    margin-top: 32px;
    min-height: 184px;
}

.download-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    width: 100%;
    padding: 12px 28px;
    border-radius: 4px;
    background: #5D0101;
}

.download-btn+.download-btn {
    margin-top: 20px;
}

.version-list a {
    color: #fff;
    font-family: "Source Han Sans CN", "Noto Sans SC", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.en-box .version-list a {
    font-size: 16px;
    /* padding: 0 15px; */
}

.download-links a .icon:first-child {
    padding-right: 5px;
}

.download-links a .icon:last-child {
    padding-left: 5px;
}

.version-list a:hover {
    background: #410101
}

.version-list a.disabled:hover {
    background: grey;
    cursor: not-allowed;
}

.mt-section3 .title {
    margin: 0 auto;
    margin-top: 60px;
    max-width: 1200px;
    padding: 0 30px;
}

.mt-section3 .subtitle {
    margin: 24px auto 0;
    color: #666;
    text-align: center;
    font-family: "Source Han Sans CN", "Noto Sans SC", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 350;
    line-height: 120%;
    max-width: 1200px;
    padding: 0 30px;
    /* 27px */
}

.mt-section3 .compare-table {
    margin: 24px auto 0;
    width: 708px;
}

.mt-section3 .compare-table .th,
.mt-section3 .compare-table .tr {
    display: flex;
    min-height: 32px;
    padding: 12px 0;
    align-items: center;
}

.mt-section3 .compare-table .td {
    width: 33.3%;
    padding-left: 52px;
    padding-right: 10px;
}

.mt-section3 .compare-table .th {
    background: #949699;
    color: #FFF;
    font-family: "Source Han Sans CN", "Noto Sans SC", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.mt-section3 .compare-table .tr {
    color: #333;
    font-family: "Source Han Sans CN", "Noto Sans SC", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
}

.mt-section3 .compare-table .tr .td:first-child {
    font-weight: 350;
}

.mt-section3 .compare-table .tr:nth-child(2n) {
    background: #FFFDFA;
}

.mt-section3 .compare-table .tr:nth-child(2n+1) {
    background: #F1F5F9;
}

.mt-section3 a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 244px;
    height: 48px;
    margin: 72px auto 140px;
    border-radius: 4px;
    background: #5D0101;
    color: #fff;
    font-family: "Source Han Sans CN", "Noto Sans SC", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    gap: 4px;

}

.mt-section3 a:hover {
    background: #410101
}

@media(max-width:1440px) {
    .en-box .version-item-title {
        font-size: 22px;
        min-height: 67px;
    }

    .es .version-item-title {
        min-height: 93px;
    }

    .en-box .version-list a {
        font-size: 13px;
        padding: 12px 15px;
    }

    .version-list a {
        font-size: 15px;
    }

    .en-box .version-item-subtitle {
        font-size: 14px;
        min-height: 120px;
    }

    .mt-section1-content .subtitle {
        min-width: 0;
        max-width: 496px;
    }

    .mt-section1-content .list .li {
        min-width: 0;
        max-width: 510px;
    }
}

@media(max-width:1200px) {
    .en-box .version-item-title {
        font-size: 20px;
        min-height: 67px;
    }

    .download-links img {
        width: 24px;
        height: 24px;
    }

    .es .version-item-title {
        min-height: 93px;
    }

    .en-box .version-list a {
        font-size: 11px;
        padding: 12px 8px;
    }

    .download-links a .icon:first-child {
        padding-right: 5px;
    }

    .download-links a .icon:last-child {
        padding-left: 5px;
    }

    .mt-section1-content .subtitle {
        min-width: 0;
        max-width: 420px;
    }

    .mt-section1-content .list .li {
        min-width: 0;
        max-width: 420px;
    }
}

@media(max-width:996px) {

    .mt-section1 {
        margin-top: 51px;
    }

    .mt-section1-bg {
        display: flex;
        flex-direction: column;
        padding: 80px 30px;
        background: none;
    }

    .MT4 .mt-section1-bg {
        background: none;
    }

    .MT4 .ar .mt-section1-bg {
        background: none;
    }

    .ar .mt-section1-bg {
        background: none;
    }

    .mt-section1-top-bg {
        display: block;
        width: 100%;
        max-width: 630px;
        height: auto;
    }

    .mt-section1-bottom-bg {
        display: block;
        position: absolute;
        bottom: 73px;
        width: 58.13%;
        max-width: 400px;
    }

    .mt-section1-content {
        position: relative;
        margin: 44px auto 0;
        width: 100%;
    }

    .mt-section1-content .title {
        width: 100%;
        text-align: center;
        margin-bottom: 16px;
        font-size: 42px;
        line-height: 120%;
    }

    .mt-section1-content .subtitle {
        width: 100%;
        text-align: center;
        max-width: none;
        min-width: inherit;
        margin-bottom: 40px;
    }

    .mt-section1-content .list .li {
        width: 100%;
        text-align: left;
        max-width: none;
        min-width: inherit;
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 150%;
    }

    .mt-section1-content .list .li .icon {
        margin-top: 5px;
    }

    .page-block {
        padding: 80px 10px 72px;
    }

    .mt-section2 .title,
    .mt-section3 .title {
        font-size: 35px;
    }

    .mt-section3 .compare-table {
        width: 100%;
        margin: 48px auto 0;
        padding: 30px;
    }

    .mt-section1-content a,
    .ru .mt-section1-content a {
        margin: 64px 6px 0;
        width: auto;
    }


    .version-list {
        flex-wrap: wrap;
        margin-top: 68px;
        gap: 32px;
    }

    .version-item {
        width: 100%;
        padding: 44px 20px;
    }

    .version-item-title {
        margin-top: 27px;
        font-size: 22px;
    }

    .en-box .version-item-title,
    .es .version-item-title {
        min-height: 0;
    }

    .version-item-subtitle,
    .en-box .version-item-subtitle {
        margin-top: 13px;
        font-size: 14px;
        min-height: 0;
    }

    .download-links {
        margin-top: 27px;
        min-height: 0;
    }

    .version-list a,
    .en-box .version-list a {
        font-size: 13px;
        padding: 12px 28px;
    }


    .download-links img {
        width: 23px;
        height: 23px;
    }

    .download-btn+.download-btn {
        margin-top: 17px;
    }

    .mt-section3 .title {
        margin-top: 80px;

    }

    .mt-section3 .subtitle {
        font-size: 16px;
        line-height: 150%;

    }

    .mt-section3 .compare-table .td {
        padding-left: 25px;
        padding-right: 7px;
    }

    .mt-section3 .compare-table .th {
        font-size: 12px;
    }

    .mt-section3 .compare-table .tr {
        font-size: 11px;
    }

    .mt-section3 a {
        width: auto;
        margin: 48px 36px 80px;
    }

    .version-item-img .img1,
    .version-item-img .img2,
    .version-item-img .img3 {
        max-height: 216px;
        width: auto;
    }
}
</style>