<template>
  <div class="managing-your-portfolio" :class="$t('ManagingYourPortfolio.box-name')">
	<ss-header mode="Home" bg="rgb(38, 5, 5)"></ss-header>
    <div class="tp-fth1">
        <p class="p1" v-html="$t('ManagingYourPortfolio.screen0.title')"></p>
        <img src="../assets/image/ManagingYourPortfolio/fth1-line.png" alt="">
        <p class="line"></p>
        <p class="p2">{{$t('ManagingYourPortfolio.screen0.desc')}}</p>
        <router-link to="/about-us" class="btn">{{$t('ManagingYourPortfolio.screen0.btn')}}</router-link>
    </div>

    <div class="tp-fth2" id="tp-fth2"  :style="{top:topNum + 'px'}">
        <a href="javascript:;" id="transmit1" class="sub-nav" @click="onClick(1)" :class="{'tp-active':pagIndex == 1}">{{$t('ManagingYourPortfolio.screen1.link1')}}</a>
        <a href="javascript:;" id="transmit2" class="sub-nav" @click="onClick(2)" :class="{'tp-active':pagIndex == 2}">{{$t('ManagingYourPortfolio.screen1.link2')}}</a>
        <a href="javascript:;" id="transmit3" class="sub-nav" @click="onClick(3)" :class="{'tp-active':pagIndex == 3}">{{$t('ManagingYourPortfolio.screen1.link3')}}</a>
        <a href="javascript:;" id="transmit4" class="sub-nav" @click="onClick(4)" :class="{'tp-active':pagIndex == 4}">{{$t('ManagingYourPortfolio.screen1.link4')}}</a>
    </div>

    <div class="tp-section0 tp-section trade1" id="section1">
        <div class="container">
            <div class="row">
                <div class="col-md-8 d1">
                    <p class="title cn-title1 m-title">{{$t('ManagingYourPortfolio.screen2.title')}}</p>
                    <p class="line m-line"></p>
                    <div class="row">
                        <div>
                            <img :src="require(`../assets/image/ManagingYourPortfolio/${$t('ManagingYourPortfolio.screen2.imgUrl')}.svg`)" alt="">
                            <p>{{$t('ManagingYourPortfolio.screen2.text1')}}</p>
                        </div>
                        <div>
                            <img src="../assets/image/ManagingYourPortfolio/trade-02.svg" alt="">
                            <p>{{$t('ManagingYourPortfolio.screen2.text2')}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 d2">
                    <p class="title cn-title1 pc-title">{{$t('ManagingYourPortfolio.screen2.title')}}</p>
                    <p class="line pc-line"></p>
                    <a class="tp-public-link2" :href="'https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/EBC-PRODUCT-GUIDEpdf_' + $t('CFooters.pdfLang') +'.pdf'" target="_blank">{{$t('ManagingYourPortfolio.public-btn-text1')}}<img class="arrow1" src="../assets/image/ManagingYourPortfolio/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/ManagingYourPortfolio/fca12.png" alt=""></a>
                </div>
            </div>
        </div>
    </div>

    <div class="tp-section1 tp-section trade2">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <p class="title cn-title1 pc-title" v-html="$t('ManagingYourPortfolio.screen2.title2')"></p>
                    <p class="line pc-line"></p>
                    <p class="desc pc-desc" style="width: 82%;" v-html="$t('ManagingYourPortfolio.screen2.desc2')"></p>
                    <router-link class="tp-public-link2 pc-btn" to="/AllProducts">{{$t('ManagingYourPortfolio.public-btn-text2')}}<img class="arrow1" src="../assets/image/ManagingYourPortfolio/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/ManagingYourPortfolio/fca12.png" alt=""></router-link>
                </div>
                <div class="col-md-6 market s1-d2 tp-section1-s1-d2">
                    <p class="title cn-title1 m-title" v-html="$t('ManagingYourPortfolio.screen2.title2')"></p>
                    <p class="line m-line"></p>
                    <p class="desc m-desc" style="width: 82%;">{{$t('ManagingYourPortfolio.screen2.desc2')}}</p>
                    <ul>
                        <li id="EURUSD">
										<span class="name">EURUSD</span>
										<span class="float"
											:class="{'red': forex.EURUSD.percentage < 0,'green':forex.EURUSD.percentage > 0}">{{forex.EURUSD.num}}</span>
										<p class="bid">Bid</p>
										<p class="bid-price green" :class="{'red':forex.EURUSD.percentage < 0}">
											{{tear(forex.EURUSD.bid_price)[0] || '--'}}<span>.{{tear(forex.EURUSD.bid_price)[1] || '--'}}</span>
										</p>
										<p class="spread"><span class="s1">Spread</span> &nbsp;&nbsp;&nbsp; <span
												class="s2">{{forex.EURUSD.chadian || '--'}}</span></p>
										<p class="line"></p>
										<p class="ask">Ask</p>
										<p class="ask-price green" :class="{'red':forex.EURUSD.percentage < 0}">
											{{tear(forex.EURUSD.ask_price)[0] || '--'}}<span>.{{tear(forex.EURUSD.ask_price)[1] || '--'}}</span>
										</p>
										<a class="buy" href="$store.state.khUrl">BUY</a>
										<a class="sell" href="$store.state.khUrl">SELL</a>
									</li>
									<li id="USDJPY">
										<span class="name">USDJPY</span>
										<span class="float"
											:class="{'red': forex.USDJPY.percentage < 0,'green':forex.USDJPY.percentage > 0}">{{forex.USDJPY.num}}</span>
										<p class="bid">Bid</p>
										<p class="bid-price green" :class="{'red':forex.USDJPY.percentage < 0}">
											{{tear(forex.USDJPY.bid_price)[0] || '--'}}<span>.{{tear(forex.USDJPY.bid_price)[1] || '--'}}</span>
										</p>
										<p class="spread"><span class="s1">Spread</span> &nbsp;&nbsp;&nbsp; <span
												class="s2">{{forex.USDJPY.chadian || '--'}}</span></p>
										<p class="line"></p>
										<p class="ask">Ask</p>
										<p class="ask-price green" :class="{'red':forex.USDJPY.percentage < 0}">
											{{tear(forex.USDJPY.ask_price)[0] || '--'}}<span>.{{tear(forex.USDJPY.ask_price)[1] || '--'}}</span>
										</p>
										<a class="buy" :href="$store.state.khUrl">BUY</a>
										<a class="sell" :href="$store.state.khUrl">SELL</a>
									</li>
									<li id="AUDUSD">
										<span class="name">AUDUSD</span>
										<span class="float"
											:class="{'red': forex.AUDUSD.percentage < 0,'green':forex.AUDUSD.percentage > 0}">{{forex.AUDUSD.num}}</span>
										<p class="bid">Bid</p>
										<p class="bid-price green" :class="{'red':forex.AUDUSD.percentage < 0}">
											{{tear(forex.AUDUSD.bid_price)[0] || '--'}}<span>.{{tear(forex.AUDUSD.bid_price)[1] || '--'}}</span>
										</p>
										<p class="spread"><span class="s1">Spread</span> &nbsp;&nbsp;&nbsp; <span
												class="s2">{{forex.AUDUSD.chadian || '--'}}</span></p>
										<p class="line"></p>
										<p class="ask">Ask</p>
										<p class="ask-price green" :class="{'red':forex.AUDUSD.percentage < 0}">
											{{tear(forex.AUDUSD.ask_price)[0] || '--'}}<span>.{{tear(forex.AUDUSD.ask_price)[1] || '--'}}</span>
										</p>
										<a class="buy" href="$store.state.khUrl">BUY</a>
										<a class="sell" href="$store.state.khUrl">SELL</a>
									</li>
                    </ul>

                </div>
                <router-link class="tp-public-link2 m-btn" to="/AllProducts">{{$t('ManagingYourPortfolio.public-btn-text2')}}<img class="arrow1" src="../assets/image/ManagingYourPortfolio/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/ManagingYourPortfolio/fca12.png" alt=""></router-link>
            </div>
        </div>
    </div>



    <div class="tp-section0 tp-section tp-section3 trade3" id="section2">
        <div class="container">
            <div class="row">
                <div class="col-md-8 d1">
                    <p class="title cn-title1 m-title">{{$t('ManagingYourPortfolio.screen3.title')}}</p>
                    <p class="line m-line"></p>
                    <div class="row">
                        <div>
                            <img src="../assets/image/ManagingYourPortfolio/trade-03.svg" alt="">
                            <p>{{$t('ManagingYourPortfolio.screen3.text1')}}</p>
                        </div>
                        <div>
                            <img src="../assets/image/ManagingYourPortfolio/trade-04.svg" alt="">
                            <p>{{$t('ManagingYourPortfolio.screen3.text2')}}</p>
                        </div>
                        <div>
                            <img src="../assets/image/ManagingYourPortfolio/trade-05.svg" alt="">
                            <p>{{$t('ManagingYourPortfolio.screen3.text3')}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 d2">
                    <p class="title cn-title1 pc-title">{{$t('ManagingYourPortfolio.screen3.title')}}</p>
                    <p class="line pc-line"></p>
                    <a class="tp-public-link2" :href="'https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/EBC-PRODUCT-GUIDEpdf_' + $t('CFooters.pdfLang') +'.pdf'" target="_blank">{{$t('ManagingYourPortfolio.public-btn-text1')}}<img class="arrow1" src="../assets/image/ManagingYourPortfolio/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/ManagingYourPortfolio/fca12.png" alt=""></a>
                </div>
            </div>
        </div>
    </div>


    <div class="tp-section1 tp-section trade4">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <p class="title cn-title1 pc-title">{{$t('ManagingYourPortfolio.screen3.title2')}}</p>
                    <p class="line pc-line"></p>
                    <p class="desc pc-desc" style="width: 82%;">{{$t('ManagingYourPortfolio.screen3.desc2')}}</p>
                    <router-link class="tp-public-link2 pc-btn" to="/AllProducts">{{$t('ManagingYourPortfolio.public-btn-text2')}}<img class="arrow1" src="../assets/image/ManagingYourPortfolio/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/ManagingYourPortfolio/fca12.png" alt=""></router-link>
                </div>
                <div class="col-md-6 market s1-d2">
                    <p class="title cn-title1 m-title">{{$t('ManagingYourPortfolio.screen3.title2')}}</p>
                    <p class="line m-line"></p>
                    <p class="desc m-desc" style="width: 82%;">{{$t('ManagingYourPortfolio.screen3.desc2')}}</p>
                    <ul>
									<li id="XAUUSD">
										<span class="name">XAUUSD</span>
										<span class="float"
											:class="{'red': forex.XAUUSD.percentage < 0,'green':forex.XAUUSD.percentage > 0}">{{forex.XAUUSD.num}}</span>
										<p class="bid">Bid</p>
										<p class="bid-price green" :class="{'red':forex.XAUUSD.percentage < 0}">
											{{tear(forex.XAUUSD.bid_price)[0] || '--'}}<span>.{{tear(forex.XAUUSD.bid_price)[1] || '--'}}</span>
										</p>
										<p class="spread"><span class="s1">Spread</span> &nbsp;&nbsp;&nbsp; <span
												class="s2">{{forex.XAUUSD.chadian || '--'}}</span></p>
										<p class="line"></p>
										<p class="ask">Ask</p>
										<p class="ask-price green" :class="{'red':forex.XAUUSD.percentage < 0}">
											{{tear(forex.XAUUSD.ask_price)[0] || '--'}}<span>.{{tear(forex.XAUUSD.ask_price)[1] || '--'}}</span>
										</p>
										<a class="buy" href="$store.state.khUrl">BUY</a>
										<a class="sell" href="$store.state.khUrl">SELL</a>
									</li>
									<li id="XAGUSD">
										<span class="name">XAGUSD</span>
										<span class="float"
											:class="{'red': forex.XAGUSD.percentage < 0,'green':forex.XAGUSD.percentage > 0}">{{forex.XAGUSD.num}}</span>
										<p class="bid">Bid</p>
										<p class="bid-price green" :class="{'red':forex.XAGUSD.percentage < 0}">
											{{tear(forex.XAGUSD.bid_price)[0] || '--'}}<span>.{{tear(forex.XAGUSD.bid_price)[1] || '--'}}</span>
										</p>
										<p class="spread"><span class="s1">Spread</span> &nbsp;&nbsp;&nbsp; <span
												class="s2">{{forex.XAGUSD.chadian || '--'}}</span></p>
										<p class="line"></p>
										<p class="ask">Ask</p>
										<p class="ask-price green" :class="{'red':forex.XAGUSD.percentage < 0}">
											{{tear(forex.XAGUSD.ask_price)[0] || '--'}}<span>.{{tear(forex.XAGUSD.ask_price)[1] || '--'}}</span>
										</p>
										<a class="buy" href="$store.state.khUrl">BUY</a>
										<a class="sell" href="$store.state.khUrl">SELL</a>
									</li>
									<li id="XTIUSD">
										<span class="name">XTIUSD</span>
										<span class="float"
											:class="{'red': forex.XTIUSD.percentage < 0,'green':forex.XTIUSD.percentage > 0}">{{forex.XTIUSD.num}}</span>
										<p class="bid">Bid</p>
										<p class="bid-price green" :class="{'red':forex.XTIUSD.percentage < 0}">
											{{tear(forex.XTIUSD.bid_price)[0] || '--'}}<span>.{{tear(forex.XTIUSD.bid_price)[1] || '--'}}</span>
										</p>
										<p class="spread"><span class="s1">Spread</span> &nbsp;&nbsp;&nbsp; <span
												class="s2">{{forex.XTIUSD.chadian || '--'}}</span></p>
										<p class="line"></p>
										<p class="ask">Ask</p>
										<p class="ask-price green" :class="{'red':forex.XTIUSD.percentage < 0}">
											{{tear(forex.XTIUSD.ask_price)[0] || '--'}}<span>.{{tear(forex.XTIUSD.ask_price)[1] || '--'}}</span>
										</p>
										<a class="buy" :href="$store.state.khUrl">BUY</a>
										<a class="sell" :href="$store.state.khUrl">SELL</a>
									</li>
                    </ul>
                </div>
                <router-link class="tp-public-link2 m-btn" to="/AllProducts">{{$t('ManagingYourPortfolio.public-btn-text2')}}<img class="arrow1" src="../assets/image/ManagingYourPortfolio/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/ManagingYourPortfolio/fca12.png" alt=""></router-link>
            </div>
        </div>
    </div>



    <div class="tp-section0 tp-section tp-section4 trade5" id="section3">
        <div class="container">
            <div class="row">
                <div class="col-md-8 d1">
                    <p class="title cn-title1 m-title">{{$t('ManagingYourPortfolio.screen4.title')}}</p>
                    <p class="line m-line"></p>
                    <div class="row">
                        <div>
                            <img src="../assets/image/ManagingYourPortfolio/trade-06.svg" alt="">
                            <p>{{$t('ManagingYourPortfolio.screen4.text1')}}</p>
                        </div>
                        <div>
                            <img src="../assets/image/ManagingYourPortfolio/trade-07.svg" alt="">
                            <p>{{$t('ManagingYourPortfolio.screen4.text2')}}</p>
                        </div>
                        <div>
                            <img src="../assets/image/ManagingYourPortfolio/trade-08.svg" alt="">
                            <p>{{$t('ManagingYourPortfolio.screen4.text3')}}</p>
                        </div>
                        <div>
                            <img src="../assets/image/ManagingYourPortfolio/trade-09.svg" alt="">
                            <p>{{$t('ManagingYourPortfolio.screen4.text4')}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 d2">
                    <p class="title cn-title1 pc-title">{{$t('ManagingYourPortfolio.screen4.title')}}</p>
                    <p class="line pc-line"></p>
                    <a class="tp-public-link2" :href="'https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/EBC-PRODUCT-GUIDEpdf_' + $t('CFooters.pdfLang') +'.pdf'" target="_blank">{{$t('ManagingYourPortfolio.public-btn-text1')}}<img class="arrow1" src="../assets/image/ManagingYourPortfolio/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/ManagingYourPortfolio/fca12.png" alt=""></a>
                </div>
            </div>
        </div>
    </div>



    <div class="tp-section1 tp-section tp-section5 trade6">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <p class="title cn-title1 pc-title">{{$t('ManagingYourPortfolio.screen4.title2')}}</p>
                    <p class="line pc-line"></p>
                    <router-link class="tp-public-link2 pc-btn" to="/AllProducts">{{$t('ManagingYourPortfolio.public-btn-text2')}}<img class="arrow1" src="../assets/image/ManagingYourPortfolio/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/ManagingYourPortfolio/fca12.png" alt=""></router-link>
                </div>
                <div class="col-md-6 market s1-d2">
                    <p class="title cn-title1 m-title">{{$t('ManagingYourPortfolio.screen4.title2')}}</p>
                    <p class="line m-line"></p>
                    <ul>
                        <li id="US30USD">
										<span class="name">US30USD</span>
										<p class="bid">Bid</p>
										<p class="bid-price green" :class="{'red':forex.US30USD.percentage < 0}">
											{{tear(forex.US30USD.bid_price)[0] || '--'}}<span>.{{tear(forex.US30USD.bid_price)[1] || '--'}}</span>
										</p>
										<p class="spread"><span class="s1">Spread</span> &nbsp;&nbsp;&nbsp; <span
												class="s2">{{forex.US30USD.chadian || '--'}}</span></p>
										<p class="line"></p>
										<p class="ask">Ask</p>
										<p class="ask-price green" :class="{'red':forex.US30USD.percentage < 0}">
											{{tear(forex.US30USD.ask_price)[0] || '--'}}<span>.{{tear(forex.US30USD.ask_price)[1] || '--'}}</span>
										</p>
										<a class="buy" :href="$store.state.khUrl">BUY</a>
										<a class="sell" :href="$store.state.khUrl">SELL</a>
									</li>
									<li id="SPXUSD">
										<span class="name">SPXUSD</span>
										<p class="bid">Bid</p>
										<p class="bid-price green" :class="{'red':forex.SPXUSD.percentage < 0}">
											{{tear(forex.SPXUSD.bid_price)[0] || '--'}}<span>.{{tear(forex.SPXUSD.bid_price)[1] || '--'}}</span>
										</p>
										<p class="spread"><span class="s1">Spread</span> &nbsp;&nbsp;&nbsp; <span
												class="s2">{{forex.SPXUSD.chadian || '--'}}</span></p>
										<p class="line"></p>
										<p class="ask">Ask</p>
										<p class="ask-price green" :class="{'red':forex.SPXUSD.percentage < 0}">
											{{tear(forex.SPXUSD.ask_price)[0] || '--'}}<span>.{{tear(forex.SPXUSD.ask_price)[1] || '--'}}</span>
										</p>
										<a class="buy" :href="$store.state.khUrl">BUY</a>
										<a class="sell" :href="$store.state.khUrl">SELL</a>
									</li>
									<li id="NASUSD">
										<span class="name">NASUSD</span>
										<p class="bid">Bid</p>
										<p class="bid-price green" :class="{'red':forex.NASUSD.percentage < 0}">
											{{tear(forex.NASUSD.bid_price)[0] || '--'}}<span>.{{tear(forex.NASUSD.bid_price)[1] || '--'}}</span>
										</p>
										<p class="spread"><span class="s1">Spread</span> &nbsp;&nbsp;&nbsp; <span
												class="s2">{{forex.NASUSD.chadian || '--'}}</span></p>
										<p class="line"></p>
										<p class="ask">Ask</p>
										<p class="ask-price green" :class="{'red':forex.NASUSD.percentage < 0}">
											{{tear(forex.NASUSD.ask_price)[0] || '--'}}<span>.{{tear(forex.NASUSD.ask_price)[1] || '--'}}</span>
										</p>
										<a class="buy" :href="$store.state.khUrl">BUY</a>
										<a class="sell" :href="$store.state.khUrl">SELL</a>
									</li>
                    </ul>
                </div>
                <router-link class="tp-public-link2 m-btn" to="/AllProducts">{{$t('ManagingYourPortfolio.public-btn-text2')}}<img class="arrow1" src="../assets/image/ManagingYourPortfolio/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/ManagingYourPortfolio/fca12.png" alt=""></router-link>
            </div>
        </div>
    </div>



    <div class="tp-section0 tp-section tp-section6 trade7" id="section4">
        <div class="container">
            <div class="row">
                <div class="col-md-9 d1">
                    <p class="title cn-title1 m-title">{{$t('ManagingYourPortfolio.screen5.title')}}</p>
                    <p class="line m-line"></p>
                    <div class="row">
                        <div>
                            <img src="../assets/image/ManagingYourPortfolio/trade-10.svg" alt="">
                            <p v-html="$t('ManagingYourPortfolio.screen5.text1')"></p>
                        </div>
                        <div>
                            <img src="../assets/image/ManagingYourPortfolio/trade-10.svg" alt="">
                            <p v-html="$t('ManagingYourPortfolio.screen5.text2')"></p>
                        </div>
                        <div>
                            <img src="../assets/image/ManagingYourPortfolio/trade-10.svg" alt="">
                            <p v-html="$t('ManagingYourPortfolio.screen5.text3')"></p>
                        </div>
                        <div>
                            <img src="../assets/image/ManagingYourPortfolio/trade-10.svg" alt="">
                            <p v-html="$t('ManagingYourPortfolio.screen5.text4')"></p>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 d2">
                    <p class="title cn-title1 pc-title">{{$t('ManagingYourPortfolio.screen5.title')}}</p>
                    <p class="line pc-line"></p>
                    <a class="tp-public-link2" :href="'https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/EBC-PRODUCT-GUIDEpdf_' + $t('CFooters.pdfLang') +'.pdf'" target="_blank">{{$t('ManagingYourPortfolio.public-btn-text1')}}<img class="arrow1" src="../assets/image/ManagingYourPortfolio/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/ManagingYourPortfolio/fca12.png" alt=""></a>
                </div>
            </div>
        </div>
    </div>


    <div class="tp-section1 tp-section tp-section7 trade8">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <p class="title cn-title1 pc-title">{{$t('ManagingYourPortfolio.screen5.title2')}}</p>
                    <p class="line pc-line"></p>
                    <router-link class="tp-public-link2 pc-btn" to="/AllProducts">{{$t('ManagingYourPortfolio.public-btn-text2')}}<img class="arrow1" src="../assets/image/ManagingYourPortfolio/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/ManagingYourPortfolio/fca12.png" alt=""></router-link>
                </div>
                <div class="col-md-6 market s1-d2">
                    <p class="title cn-title1 m-title">{{$t('ManagingYourPortfolio.screen5.title2')}}</p>
                    <p class="line m-line"></p>
                    <ul>
                        <li id="EURUSD">
										<span class="name">EURUSD</span>
										<span class="float"
											:class="{'red': forex.EURUSD.percentage < 0,'green':forex.EURUSD.percentage > 0}">{{forex.EURUSD.num}}</span>
										<p class="bid">Bid</p>
										<p class="bid-price green" :class="{'red':forex.EURUSD.percentage < 0}">
											{{tear(forex.EURUSD.bid_price)[0] || '--'}}<span>.{{tear(forex.EURUSD.bid_price)[1] || '--'}}</span>
										</p>
										<p class="spread"><span class="s1">Spread</span> &nbsp;&nbsp;&nbsp; <span
												class="s2">{{forex.EURUSD.chadian || '--'}}</span></p>
										<p class="line"></p>
										<p class="ask">Ask</p>
										<p class="ask-price green" :class="{'red':forex.EURUSD.percentage < 0}">
											{{tear(forex.EURUSD.ask_price)[0] || '--'}}<span>.{{tear(forex.EURUSD.ask_price)[1] || '--'}}</span>
										</p>
										<a class="buy" :href="$store.state.khUrl">BUY</a>
										<a class="sell" :href="$store.state.khUrl">SELL</a>
									</li>
									<li id="XAUUSD">
										<span class="name">XAUUSD</span>
										<span class="float"
											:class="{'red': forex.XAUUSD.percentage < 0,'green':forex.XAUUSD.percentage > 0}">{{forex.XAUUSD.num}}</span>
										<p class="bid">Bid</p>
										<p class="bid-price green" :class="{'red':forex.XAUUSD.percentage < 0}">
											{{tear(forex.XAUUSD.bid_price)[0] || '--'}}<span>.{{tear(forex.XAUUSD.bid_price)[1] || '--'}}</span>
										</p>
										<p class="spread"><span class="s1">Spread</span> &nbsp;&nbsp;&nbsp; <span
												class="s2">{{forex.XAUUSD.chadian || '--'}}</span></p>
										<p class="line"></p>
										<p class="ask">Ask</p>
										<p class="ask-price green" :class="{'red':forex.XAUUSD.percentage < 0}">
											{{tear(forex.XAUUSD.ask_price)[0] || '--'}}<span>.{{tear(forex.XAUUSD.ask_price)[1] || '--'}}</span>
										</p>
										<a class="buy" :href="$store.state.khUrl">BUY</a>
										<a class="sell" :href="$store.state.khUrl">SELL</a>
									</li>
									<li id="USDJPY">
										<span class="name">USDJPY</span>
										<span class="float"
											:class="{'red': forex.USDJPY.percentage < 0,'green':forex.USDJPY.percentage > 0}">{{forex.USDJPY.num}}</span>
										<p class="bid">Bid</p>
										<p class="bid-price green" :class="{'red':forex.USDJPY.percentage < 0}">
											{{tear(forex.USDJPY.bid_price)[0] || '--'}}<span>.{{tear(forex.USDJPY.bid_price)[1] || '--'}}</span>
										</p>
										<p class="spread"><span class="s1">Spread</span> &nbsp;&nbsp;&nbsp; <span
												class="s2">{{forex.USDJPY.chadian || '--'}}</span></p>
										<p class="line"></p>
										<p class="ask">Ask</p>
										<p class="ask-price green" :class="{'red':forex.USDJPY.percentage < 0}">
											{{tear(forex.USDJPY.ask_price)[0] || '--'}}<span>.{{tear(forex.USDJPY.ask_price)[1] || '--'}}</span>
										</p>
										<a class="buy" :href="$store.state.khUrl">BUY</a>
										<a class="sell" :href="$store.state.khUrl">SELL</a>
									</li>
                    </ul>
                </div>
                <router-link class="tp-public-link2 m-btn" to="/AllProducts">{{$t('ManagingYourPortfolio.public-btn-text2')}}<img class="arrow1" src="../assets/image/ManagingYourPortfolio/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/ManagingYourPortfolio/fca12.png" alt=""></router-link>
            </div>
        </div>
    </div>
    <ss-contact-us mode="all"></ss-contact-us>
    <new-footer></new-footer>
  </div>
</template>

<script>
	import axios from 'axios'
	import md5 from 'js-md5';
	import {
		mapState
	} from "vuex";
	export default {
		data() {
			return {
				ws: {},
				khUrl: 'https://client.ebccrm.com/signup/index',
				forex: {
					"XAUUSD": {
						chadian: "",
						bid_price: "0.00",
						ask_price: "0.00",
						num: "",
						percentage: 0
					},
					"EURUSD": {
						chadian: "",
						bid_price: "0.00",
						ask_price: "0.00",
						num: "",
						percentage: 0
					},
					"USDJPY": {
						chadian: "",
						bid_price: "0.00",
						ask_price: "0.00",
						num: "",
						percentage: 0
					},
					"AUDUSD": {
						chadian: "",
						bid_price: "0.00",
						ask_price: "0.00",
						num: "",
						percentage: 0
					},
					"XAGUSD": {
						chadian: "",
						bid_price: "0.00",
						ask_price: "0.00",
						num: "",
						percentage: 0
					},
					"XTIUSD": {
						chadian: "",
						bid_price: "0.00",
						ask_price: "0.00",
						num: "",
						percentage: 0
					},
					"US30USD": {
						chadian: "",
						bid_price: "0.00",
						ask_price: "0.00",
						num: "",
						percentage: 0
					},
					"SPXUSD": {
						chadian: "",
						bid_price: "0.00",
						ask_price: "0.00",
						num: "",
						percentage: 0
					},
					"NASUSD": {
						chadian: "",
						bid_price: "0.00",
						ask_price: "0.00",
						num: "",
						percentage: 0
					},
				},
				XAGUSDask: "",
				EURUSDask: "",
				USDJPYask: "",
				AUDUSDask: "",
				XAGUSDask: "",
				XTIUSDask: "",
				US30USDask: "",
				SPXUSDask: "",
				NASUSDask: "",
				newData: [],
				time: "",
				selList: [],
				seoInfo: "",
				pagIndex: 0,
				topNum: 360,
			}
		},
		metaInfo() {
			return {
				title: this.seoInfo.title || '',
				meta: [ // set meta
					{
						name: 'keyWords',
						content: this.seoInfo.keyWords || ''
					},
					{
						name: 'description',
						content: this.seoInfo.description || ''
					}
				]
			}
		},
		computed: {
			...mapState({
				Lan: (state) => state.Lan,
			}),
		},
		mounted() {
			window.addEventListener('scroll', this.scroll)
		},
		destroyed() {
			window.removeEventListener('scroll', this.scroll, false)
		},
		created() {
			this.$getSeoInfo({
				lang: this.$store.state.Lan,
				path: "/tradingproduct"
			}).then(res=>{
				this.seoInfo = res
			})
			this.getPortfolio()
			let par = localStorage.getItem('advert')
			let lang = localStorage.getItem('language')
			let parms;
			if(lang === 'ct'){
				parms = 'tw'
			}else if(lang === 'ja'){
				parms = 'jp'
			}else if(lang === 'vi'){
				parms = 'zh'
			}else{
				parms = lang
			}
			par = JSON.parse(par)
			if (par != null) {
				this.khUrl = this.khUrl + '?utm_source=' + par.utm_source + '&utm_medium=' + par.utm_medium +
					'&utm_campaign=' + par.utm_campaign + '&utm_term=' + par.utm_term + '&utm_content=' + par.utm_content + '&hl=' + parms
			}else{
				this.khUrl = this.khUrl + '?hl=' + parms
			}
			$('html,body').animate({
				scrollTop: 0
			}, 0);
		},
		methods: {
			async scroll() {
				const elOffsetTop = document.getElementById('tp-fth2').offsetTop
				const docScrollTop = document.documentElement.scrollTop

				if (docScrollTop <= 279) {
					this.topNum = 360 - docScrollTop
					this.pagIndex = 0
				} else {
					this.topNum = 76.5
				}

				if(docScrollTop >= 281 && docScrollTop < $(".trade3").offset().top-170){
					this.pagIndex = 1
				}

				if(docScrollTop >= $(".trade3").offset().top-170 && docScrollTop < $(".trade5").offset().top-170){
					this.pagIndex = 2
				}

				if(docScrollTop >= $(".trade5").offset().top-170 && docScrollTop < $(".trade7").offset().top-170){
					this.pagIndex = 3
				}

				if(docScrollTop >= $(".trade7").offset().top-170 && docScrollTop < $(".contact-us").offset().top-170){
					this.pagIndex = 4
				}

				if(docScrollTop >= $(".contact-us").offset().top-170){
					this.pagIndex = 0
				}

			},
			handleClick(index) {
				const a = this.selList.findIndex(o => o === index)
				if (a === -1) {
					this.selList.push(index)
				} else {
					this.selList.splice(a, 1)
				}

			},
			changTab(idx) {
				this.tabIndex = idx
			},
			getCount(idx1, idx2) {
				const r = /^\+?[1-9][0-9]*$/; // 正整数
				// 如果判断不符合正则，则不是正整数不能提交
				let num = 0;
				let a1 = 0
				let a2 = 0
				if (!r.test(idx1)) {
					a1 = idx1.toString().split(".")[1].length;
				}
				if (!r.test(idx2)) {
					a2 = idx2.toString().split(".")[1].length;
				}
				if (a1 > a2) {
					if (a1 === 1) {
						num = idx1 * 10 - idx2 * 10
					} else if (a1 === 2) {
						num = idx1 * 100 - idx2 * 100
					} else if (a1 === 3) {
						num = idx1 * 1000 - idx2 * 1000
					} else if (a1 === 4) {
						num = idx1 * 10000 - idx2 * 10000
					} else if (a1 === 5) {
						num = idx1 * 100000 - idx2 * 100000
					}
				} else {
					if (a2 === 1) {
						num = idx1 * 10 - idx2 * 10
					} else if (a2 === 2) {
						num = idx1 * 100 - idx2 * 100
					} else if (a2 === 3) {
						num = idx1 * 1000 - idx2 * 1000
					} else if (a2 === 4) {
						num = idx1 * 10000 - idx2 * 10000
					} else if (a2 === 5) {
						num = idx1 * 100000 - idx2 * 100000
					}
				}
				return num.toFixed(0);
			},
			onClick(index) {
				let sectionTop = 160
				if(index == 1){
					sectionTop = 79
				}
				$('html,body').animate({
					scrollTop: $("#section" + index).offset().top - sectionTop
				}, 0);
			},
			getPortfolio() {
				let time = new Date().getMilliseconds()
				this.$http('/ebc/api/getToken/run', {
					date: time,
					CheckSum: md5(time + "YISENCE")
				}).then(res => {
					let url = "wss://pricecloud.eappservice.com/ws?token=" + res.data.data
					this.init(url)
				})
			},
			tear(item) {
				let data = JSON.stringify(item)
				return data.split('.')
			},
			init(url) {
				this.ws = new WebSocket(url)

				//监听是否连接成功
				this.ws.onopen = () => {
					//连接成功则发送一个数据
					this.ws.send('连接成功');
				}

				//接听服务器发回的信息并处理展示
				this.ws.onmessage = (data) => {
					if (data.data == 'Ws connected success！' || data.data == '连接成功') return
					let info = JSON.parse(data.data)
					if (info.tick.length == 0) return
					info.tick.forEach(o => {
						o.chadian = this.getCount(o.ask_price * 1, o.bid_price * 1)
					})
					if (info.symbol == 'XAUUSD') {
						this.forex.XAUUSD = info.tick[0]
						let float = info.tick[0].ask_price - this.XAUUSDask
						this.XAUUSDask = info.tick[0].ask_price
						float = Math.floor(float * 100) / 100
						this.forex.XAUUSD.percentage = float
						if (float == 0) {
							this.forex.XAUUSD.num = '0.00'
						} else {
							if (float < 0) {
								this.forex.XAUUSD.num = float + '%'
							} else {
								this.forex.XAUUSD.num = "+" + float + '%'
							}
						}
					} else if (info.symbol == 'EURUSD') {
						this.forex.EURUSD = info.tick[0]
						let float = info.tick[0].ask_price - this.EURUSDask
						this.EURUSDask = info.tick[0].ask_price
						float = Math.floor(float * 100) / 100
						this.forex.EURUSD.percentage = float
						if (float == 0) {
							this.forex.EURUSD.num = '0.00'
						} else {
							if (float < 0) {
								this.forex.EURUSD.num = float + '%'
							} else {
								this.forex.EURUSD.num = "+" + float + '%'
							}
						}
					} else if (info.symbol == 'USDJPY') {
						this.forex.USDJPY = info.tick[0]
						let float = info.tick[0].ask_price - this.USDJPYask
						this.USDJPYask = info.tick[0].ask_price
						float = Math.floor(float * 100) / 100
						this.forex.USDJPY.percentage = float
						if (float == 0) {
							this.forex.USDJPY.num = '0.00'
						} else {
							if (float < 0) {
								this.forex.USDJPY.num = float + '%'
							} else {
								this.forex.USDJPY.num = "+" + float + '%'
							}
						}
					} else if (info.symbol == 'AUDUSD') {
						this.forex.AUDUSD = info.tick[0]
						let float = info.tick[0].ask_price - this.AUDUSDask
						this.AUDUSDask = info.tick[0].ask_price
						float = Math.floor(float * 100) / 100
						this.forex.AUDUSD.percentage = float
						if (float == 0) {
							this.forex.AUDUSD.num = '0.00'
						} else {
							if (float < 0) {
								this.forex.AUDUSD.num = float + '%'
							} else {
								this.forex.AUDUSD.num = "+" + float + '%'
							}
						}
					} else if (info.symbol == 'XAGUSD') {
						this.forex.XAGUSD = info.tick[0]
						let float = info.tick[0].ask_price - this.XAGUSDask
						this.XAGUSDask = info.tick[0].ask_price
						float = Math.floor(float * 100) / 100
						this.forex.XAGUSD.percentage = float
						if (float == 0) {
							this.forex.XAGUSD.num = '0.00'
						} else {
							if (float < 0) {
								this.forex.XAGUSD.num = float + '%'
							} else {
								this.forex.XAGUSD.num = "+" + float + '%'
							}
						}
					} else if (info.symbol == 'XTIUSD') {
						this.forex.XTIUSD = info.tick[0]
						let float = info.tick[0].ask_price - this.XTIUSDask
						this.XTIUSDask = info.tick[0].ask_price
						float = Math.floor(float * 100) / 100
						this.forex.XTIUSD.percentage = float
						if (float == 0) {
							this.forex.XTIUSD.num = '0.00'
						} else {
							if (float < 0) {
								this.forex.XTIUSD.num = float + '%'
							} else {
								this.forex.XTIUSD.num = "+" + float + '%'
							}
						}
					} else if (info.symbol == 'U30USD') {
						this.forex.US30USD = info.tick[0]
						let float = info.tick[0].ask_price - this.US30USDask
						this.US30USDask = info.tick[0].ask_price
						float = Math.floor(float * 100) / 100
						this.forex.US30USD.percentage = float
						if (float == 0) {
							this.forex.US30USD.num = '0.00'
						} else {
							if (float < 0) {
								this.forex.US30USD.num = float + '%'
							} else {
								this.forex.US30USD.num = "+" + float + '%'
							}
						}
					} else if (info.symbol == 'SPXUSD') {
						this.forex.SPXUSD = info.tick[0]
						let float = info.tick[0].ask_price - this.SPXUSDask
						this.SPXUSDask = info.tick[0].ask_price
						float = Math.floor(float * 100) / 100
						this.forex.SPXUSD.percentage = float
						if (float == 0) {
							this.forex.SPXUSD.num = '0.00'
						} else {
							if (float < 0) {
								this.forex.SPXUSD.num = float + '%'
							} else {
								this.forex.SPXUSD.num = "+" + float + '%'
							}
						}
					} else if (info.symbol == 'NASUSD') {
						this.forex.NASUSD = info.tick[0]
						let float = info.tick[0].ask_price - this.NASUSDask
						this.NASUSDask = info.tick[0].ask_price
						float = Math.floor(float * 100) / 100
						this.forex.NASUSD.percentage = float
						if (float == 0) {
							this.forex.NASUSD.num = '0.00'
						} else {
							if (float < 0) {
								this.forex.NASUSD.num = float + '%'
							} else {
								this.forex.NASUSD.num = "+" + float + '%'
							}
						}
					}
				}

				//监听连接关闭事件
				this.ws.onclose = () => {
					//监听整个过程中websocket的状态
					console.log('ws连接状态：' + this.ws.readyState);
				}

				//监听并处理error事件
				this.ws.onerror = function(error) {
					console.log(error);
				}
			},
		},
	}
</script>

<style>
	@charset "UTF-8";
.tp-fth1{
  background: url(../assets/image/ManagingYourPortfolio/trade-bg.png) 100%/auto 100% no-repeat;
  height: 360px;
  margin-top: 0px;
  text-align: center;
  padding-top: 140px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.tp-fth1 .p1{
  font-size: 38px;
  color: #FFFFFF;
  font-weight: 700;
  letter-spacing: 4px;
}
.tp-fth1 .p2{
  font-size: 16px;
  color: #e6e6e6;
  letter-spacing: 2px;
  margin-top: 16px;
  width: 37%;
  margin: 0 auto;
  line-height: 30px;
}
.tp-fth1 .p3{
  letter-spacing: 2px;
  opacity: 0.2;
  font-family: 'Baskerville Old Face';
  font-size: 46px;
  margin-top: 24px;
  color: #FFFFFF;
}
.tp-fth1 img{
  margin-top: 26px;
  width: 300px;
  margin-bottom: 14px;
}
.tp-fth2{
  background: #fff;
  box-shadow: 0px 2px 10px rgba(61, 1, 1, 0.08);
  height: 76px;
  text-align: center;
  padding-top: 30px;
  position: fixed;
				left: 0;
				right: 0;
				z-index: 10;
				top: 81px;
}
.tp-fth2 a{
  padding: 8px 24px;
  margin: 0px 12px;
  border: 1px solid #4D0101;
  color: #4D0101;
  font-size: 16px;
}
/*.tp-fth2 a:hover{
  color: #ffffff;
  background: #3D0101;
}*/
.tp-public-title{
  font-size: 38px;
  font-weight: 600;
  color: #3D0101;
  text-align: center;
  letter-spacing: 2px;
  margin-top: 70px;
  margin-bottom: 60px;
}
.public-line{
    width: 60px;
    border: 1px solid #949699;
    margin: 30px auto;
}
.tp-public-link{
    background: #3d0101;
    /* padding: 11px 28px; */
    color: #fff;
    font-size: 16px;
    letter-spacing: 1px;
    display: block;
    width: max-content;
    padding: 15px 29px;
    float: left;
}
.tp-public-link img {
    width: 7px;
    margin-left: 12px;
}
.tp-public-link:hover{
  color: #ffffff;
  background: #AF8147;
}

.tp-public-link2{
    padding: 14px 28px;
    color: #3d0101;
    font-size: 16px;
    border: 1px solid #3d0101;
    margin-right: 12px;
    letter-spacing: 1px;
    display: block;
    width: max-content;
    float: left;
}
.tp-public-link2 img {
    width: 7px;
    margin-left: 12px;
}
.tp-public-link2:hover{
  color: #ffffff;
  background: #3d0101;
  border: 1px solid #3d0101;
}
.arrow2{
  display: none!important;
}
.tp-public-link2:hover .arrow1{
  display: none!important;
}
.tp-public-link2:hover .arrow2{
  display: inline-block!important;
}
.link{
  text-align: center;
  margin-top: 70px;
}
.tp-active {
	background-color: #4D0101;
	color: #ffffff !important;
}
.tp-fth3 { 
}
.tp-fth3 .row{
    background: linear-gradient(90deg, rgba(148, 150, 153, 0) 0%, rgba(148, 150, 153, 0.06) 92.82%);
    padding: 110px 0 90px;
    margin-top: 55px;
}
.tp-fth3 .row div{
  position: relative;
  text-align: center;
  width: 20%;
}
.tp-fth3 .p1{
  font-size: 230px;
  font-family: 'Baskerville Old Face';
  font-weight: 400;
  text-align: center;
  color: #3D0101;
  opacity: 0.1;
  position: absolute;
  top: 48px;  
  left: 50%;  
  transform: translate(-50%, -50%);  
}
.tp-fth3 .p2{
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  letter-spacing: 2px;
  color: #3d0101;
  margin-top: 82px;
}
.tp-fth3 .p3{
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  margin-top: 20px;
}
.tp-fth4{
  margin-top: 150px;
}
.tp-fth4 .r1{
  margin-top: 55px;
}
.tp-fth4 .row .list{
  text-align: center;
  background: linear-gradient(0deg, rgba(148, 150, 153, 0.06) 0%, rgba(148, 150, 153, 0.02) 100%);
  padding: 30px;
  margin: 0 1.5%;
  width: 22%;
}
.tp-fth4 .row .list img{
  width: 45%;
  border-radius: 50%;
}
.tp-fth4 .row .list .p1{
  font-size: 18px;
  color: #000000;
  margin-top: 12px;
}
.tp-fth4 .row .list .p2{
  color: #949699;
  font-size: 14px;
  margin-top: 8px;
}
.tp-fth4 .row .list .p3{
  color: #8D0101;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-top: 18px;
}
.tp-fth4 .row .list .p4{
  color: #949699;
  font-size: 14px;
  margin-top: 6px;
}
.tp-fth4 .row .list .sp1{
  color: #3D0101;
  font-size: 18px;
  font-weight: 600;
}
.tp-fth4 .row .list .sp2{
  color: #949699;
  font-size: 14px;
  margin-top: 12px;
}
.tp-fth4 .r2{
  margin-top: 30px;
}
.tp-fth4 .r2 div{
  padding: 0;
}
.tp-fth4 .list .p5{
  margin-top: 50px;
  margin-bottom: 18px;
}
.tp-fth4 .list .p5 span{
  font-size: 14px;
  color: #3D0101;
  margin-right: 36px;
}
.tp-fth4 .list .p5 a{
  padding: 8px 20px;
  color: #3D0101;
  background: #AF8147;
  font-size: 16px;
  border-radius: 2px;
}
.tp-fth4 .list .p5 a:hover{
  color: #AF8147;
  background: #3D0101;
}

.tp-fth5{
  margin-top: 150px;
}
.tp-fth5 .row{
  margin-top: 60px;
}
.tp-fth5 .row div{
  background: linear-gradient(0deg, rgba(148, 150, 153, 0.06) 0%, rgba(148, 150, 153, 0.02) 100%);
  margin-left: 1.2%;
  margin-right: 1.2%;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  height: auto;
  width: 17.5%;
}
.tp-fth5 .row .p1{
  font-size: 18px;
  color: #3D0101;
  font-weight: 700;
}
.tp-fth5 .row .chess{
  width: 36%;
  margin-top: 40px;
}
.tp-fth5 .row .p2{
 display: inline-block;
 padding: 0px 16px;
 margin-top: 30px;
 width: 100%;
}
.tp-fth5 .row .p2 .s1{
  font-size: 14px;
  color: #3D0101;
  float: left;
  font-weight: 400;
}
.tp-fth5 .row .p2 .s2{
  float: right;
  color: #3D0101;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 700;
}
.tp-fth5 .row .p3{
 display: inline-block;
 padding: 0px 16px;
 margin-top: 14px;
 width: 100%;
}
.tp-fth5 .row .p3 .s1{
  font-size: 14px;
  color: #3D0101;
  float: left;
  font-weight: 400;
}
.tp-fth5 .row .p3 .s2{
  float: right;
  color: #3D0101;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 700;
}
.tp-fth5 .row .p4{
  color: #AF8147;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 700;
  margin-top: 30px;
}
.tp-fth5 .row .p4 img{
  width: 12px;
}
.tp-fth5 .row div .tip1{
  font-size: 13px;
  margin-top: 16px;
}
.tp-fth5 .row div:nth-child(1){
  margin-top: 100px;
}
.tp-fth5 .row div:nth-child(2){
  margin-top: 80px;
}
.tp-fth5 .row div:nth-child(3){
  margin-top: 60px;
}
.tp-fth5 .row div:nth-child(4){
  margin-top: 40px;
}
.tp-fth5 .row div:nth-child(5){
  margin-top: 0px;
  border: 2px solid #8D0101;
}
.tp-fth5 .row div:nth-child(5) .p1,.tp-fth5 .row div:nth-child(5) .tip1,.tp-fth5 .row div:nth-child(5) .p4{
  color: #8D0101;
}
.tp-fth5 .row div:nth-child(1) .chess{
  width: 30%;
}
.tp-fth5 .row div:nth-child(2) .chess{
  width: 27%;
  margin-bottom: 8px;
  margin-top: 44px;
}
.tp-fth5 .row div:nth-child(3) .chess{
    width: 34%;
    margin-top: 44px;
    margin-bottom: 15px;
}
.tp-fth5 .row div:nth-child(4) .chess{
    width: 39%;
    margin-top: 46px;
    margin-bottom: 26px;

}
.tp-fth5 .row div:nth-child(5) .chess{
  width: 35%;
  margin-top: 23px;
}
.tp-fth6{
  margin-top: 160px;
  margin-bottom: 160px;
}
.tp-fth6 .row{
  margin-top: 80px;
}
.tp-fth6 table{
  width: 88%;
  text-align: center;
  margin: 0 auto;
}
.tp-fth6 table thead th{
  color: #000000;
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  height: 82px;
  line-height: 82px;
}
.tp-fth6 table thead th img{
  width: 37%;
  position: absolute;
  left: 31%;
  top: -18px;
}
.tp-fth6 table thead th:nth-child(1){
  width: 13%;
}
.tp-fth6 table tbody tr{
  height: 50px;
  line-height: 50px;
}
.tp-fth6 table tbody tr td{
  position: relative;
}
.tp-fth6 table tbody tr .img1{
  width: 12%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.tp-fth6 table tbody tr .img2{
  width: 10%;
  left: 49%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.tp-fth6 table tbody tr:nth-child(odd){
  background: rgba(148, 150, 153, 0.04);
}
.tp-fth6 table tbody tr:nth-child(even){
  height: 70px;
  line-height: 70px;
}
.tp-fth6 table tbody td{
  color: #3D0101;
  font-size: 16px;
  font-weight: 700;
}
.tp-fth6 table tbody tr:last-child td:first-child{
  line-height: 30px;
}
.tp-fth6 table th:last-child{
  color: #8D0101;
}
.reward-list-m{
  display: none;
}
.tp-fth6 table thead th br{
  display: none;
}
.tp-fth2-lock{
  position: fixed;
  right: 0;
  left: 0;
  top: 80px;
  z-index: 999;
}
.profile-name{
  font-size: 40px;
  color: #ffffff;
  position: absolute;
  top: 19%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#transmit5 img{
  width: 7px;
    margin-left: 10px;
}

 .trade1 .tp-public-link2, .trade3 .tp-public-link2{
  margin-bottom: 16px;
 }









.form-control {
    color: #fff;
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid #ffffff;
    border-radius: 0px;
}
#verify{
  width: 92%;
}
.rotate {
  transition: all 0.2s ease-in-out;
  transform: rotate(-90deg);
}
.rotate-leave{
  transition: all 0.2s ease-in-out;
  transform: rotate(+90deg);
}




.tp-section1{
    padding: 30px 0px 120px;
}
.tp-section .bottom {
    background: rgba(45, 1, 1, 0.7);
    height: 150px;
    position: absolute;
    bottom: 70px;
    width: 100%;
    padding-top: 28px;
}
.tp-section .bottom div{
    text-align: center;
}
.tp-section .bottom img{
    width: 20%;
}
.tp-section .bottom .col-md-3:nth-child(1) img{
    width: 28%;
    margin-top: 4px;
}
.tp-section .bottom .col-md-3:nth-child(4) img{
    width: 26%;
    margin-top: 14px;
}
.tp-section .bottom p{
    color: #8D9095;
    font-size: 16px;
    margin-top: 12px;
}
.tp-section .bottom div{
    height: 64px;
}
.tp-section .title{
    color: #3D0101;
    font-size: 36px;
    line-height: 56px;
    margin-top: 0px;
    font-weight: 700;
    letter-spacing: 1px;
}
.tp-section .desc{
    color: #999;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 30px;
}
.tp-section .line{
    width: 60px;
    border: 1px solid #AF8147;
    margin: 40px 0 44px;
}
.tp-section .learn-more{
    display: inline-block;
    padding: 12px 30px;
    border: 1px #fff solid;
    font-size: 14px;
    color: #ffffff;
    margin-top: 46px;
}
.tp-section .learn-more:hover{
    border: 1px #AF8147 solid;
    background: #AF8147;
    color: #3D0101;
}
.tp-section1 .market li{
    float: left;
    background-color: rgba(0,0,0,0.9);
    width: 31%;
    margin: 0 1%;
    padding: 20px 3%;
    position: relative;
    border-radius: 4px;
}
.tp-section1 .market li .name{
    color: #AF8147;
    font-size: 16px;
}
.tp-section1 .market li .float{
    float: right;
    font-size: 14px;
    font-family: auto;
    color: #949699;
}
.tp-section1 .market li .bid,.ask{
    color: #949699;
    margin-top: 20px;
    font-size: 14px;
}
.tp-section1 .market li .bid-price,.ask-price{
    font-size: 26px;
    font-family: auto;
    margin-top: 12px;
    letter-spacing: 1px;
}
.tp-section1 .market li .bid-price span,.ask-price span{
    font-size: 36px;
}
.tp-section1 .market li .green{
    color: #00B427;
}
.tp-section1 .market li .red{
    color: #FF1120;
}
.tp-section1 .market li .spread{
    width: 80%;
    height: 26px;
    background-color: #292A2C;
    margin: 20px auto 0;
    position: relative;
    z-index: 9;
    text-align: center;
}
.tp-section1 .market li .spread .s1{
    font-size: 12px;
    color: #BABCBF;
    line-height: 26px;
}
.tp-section1 .market li .spread .s2{
    color: #e0e0e0;
    font-size: 14px;
    font-family: auto;
    line-height: 26px;
}
.tp-section1 .market li .line{
    position: absolute;
    width: 81%;
    top: 112px;
    border: 1px solid #2B2C2D;
}
.tp-section1 .market li a{
    background-color: #2B2C2D;
    padding: 10px 23px;
    float: left;
    border-radius: 16px;
    margin-top: 18px;
    font-size: 12px;
}
.tp-section1 .market li .buy{
    color: #00B427;
}
.tp-section1 .market li .sell{
    float: right;
    color: #FF1120;
}

.tp-section0{
  padding: 80px 0;
}

.tp-section0 .d1{
  background: linear-gradient(90deg, rgba(148, 150, 153, 0.06) 0%, rgba(148, 150, 153, 0.00) 100%);
  padding: 140px 100px;
}
.tp-section0 .d2{
  padding: 140px 0 0 60px;
}

.tp-section0 .d1 .row div{
  text-align: center;
  width: 50%;
}
.tp-section0 .d1 .row div img{
    
}
.tp-section0 .d1 .row div p{
  color: #080E1C;
  font-size: 16px;
  margin-top: 18px;
  letter-spacing: 1px;
  line-height: 26px;
}
.tp-section0 .line{
  margin: 40px 0 50px;
}

.tp-section .s1-d2{
  margin-top: 6px;
}


.tp-section3 .d1 .row div{
  width: 33.3%;
}
.tp-section3 .d1{
  padding: 140px 50px 140px 60px;
}
.tp-section3{
  padding-top: 0px;
}
.tp-section .title{
  width: 80%;
}


.tp-section4 .d1{
  padding: 140px 40px 140px 50px;
}
.tp-section4 .d1 .row div{
  width: 25%;
}
.tp-section4 .d1 .row div img{
  width: 122px;
}




.tp-section5 .line{
  margin: 40px 0 20px;
}

.tp-section6 .d1 .row div img{
  width: 50px;
}

.tp-section6 .d1 .row div{
  width: 25%;
}
.tp-section6 .d1{
   padding: 140px 40px 140px 50px;
}
.tp-section6 .d1 .row div p{
  line-height: 24px;
  margin-top: 16px;
}

.tp-section4{
  padding-top: 0px;
}

.tp-section6{
  padding-top: 0px;
}

.tp-section4 .tp-public-link,.tp-section6 .tp-public-link{
  margin-top: 16px;
}

.tp-section .tp-section1-s1-d2{
  margin-top: 26px;
}

.tp-section4 .d2{
  padding-top: 120px;
  padding-left: 100px;
}
.tp-section6 .d2{
  padding-top: 90px;
  padding-left: 100px;
}
.tp-section5 .tp-public-link2{
  margin-top: 26px;
}
.tp-fth1 .line{
    display: none;
}
.tp-fth1 .btn{
  display: none;
}

.tp-fth1 .p1 br{
  display: none;
}
.m-title,.m-line,.m-desc,.m-btn{
  display: none;
}

.trade5 .title,.trade7 .title{
  width: 100%;
}
.trade7 .d1 .row div p{
  padding: 0 8%;
}

.en-style .tp-fth1 .p1{
  letter-spacing: 0px;
}
.en-style .tp-fth1 .p2{
  letter-spacing: 0px;
}
.en-style .tp-section .title{
  letter-spacing: 0px;
}
.en-style .tp-section0 .d1 .row div p{
  letter-spacing: 0px;
  padding: 0 3%;
}
.en-style .tp-public-link2,.en-style .tp-public-link{
  letter-spacing: 0px;
}
.en-style .tp-section .title{
  width: 92%;
}
.en-style .tp-section6 .d2{
  padding-left: 70px;
}
.en-style .tp-section4 .d2{
  padding-left: 140px;
  padding-top: 100px;
}
.en-style .tp-section4 .d1{
  padding-right: 0px;
}




@media(min-width:2100px) {
  .fca7 .d1 .p1{
    font-size: 44px;
    margin-top: 55%;
    width: 82%;
  }
  .fca7 .form-box{
      right: 22%;
      top: 22%;
    }
}


@media(min-width:2000px) {
    .fca1{
      height: 29vh;
      padding-top: 11vh;
    }
    .fca7 .form-box{
      right: 18%;
      top: 22%;
    }
    .fca2 .container,.fca3 .container,.fca4 .container,.fca5 .container,.fca6 .container{
      max-width: 70%;
    }
    .c-footer .container-xxl{
      max-width: 70%;
    }
}

@media(max-width:1600px) {
  .tp-fth2-lock{
    top: 74.5px;
  }
  .tp-section4 .d2{
    padding-left: 60px;
  }
  .tp-section6 .d2{
    padding-left: 60px;
  }
}

@media(max-width:1400px) {
    .tp-fth2-lock{
      top: 74.5px;
    }
    .fca2 .d1{
      padding-left: 9%;
    }
    .profile-name {
      font-size: 38px;
      top: 17.5%;
    }
    .tp-section1 .market li a{
      padding: 10px 18px;
    }
    .tp-section6 .d1 .row div p{
      font-size: 16px;
      line-height: 24px;
      padding: 0 5%;
    }
    .tp-section .title{
      font-size: 34px;
    }
    .tp-section3 .tp-public-link{
      padding: 15px 22px;
    }
    .tp-section3 .tp-public-link2{
      padding: 14px 22px;
    }
    .tp-section0 .d1 .row div img{
      width: 130px;
    }
    .tp-section0 .d2{
      padding-top: 130px;
    }
    .tp-section4 .d2{
      padding-top: 100px;
    }
    .tp-section6 .d2{
      padding-top: 70px;
    }
    .tp-section6 .d1 .row div img{
          width: 50px;
    }
    .tp-section4 .d1 .row div img{
        width: 110px;
    }
    .tp-section4 .d2{
      padding-left: 80px;
    }
    .tp-section6 .d2{
      padding-left: 40px;
    }
    .en-style .tp-public-link2{
        padding: 14px 15px;
    }
    .en-style .tp-public-link{
        padding: 15px 16px;
    }
}

@media(max-width:1300px) {
    .fca3 p{
      margin-top: 100px;
    }
    .fca3 span{
      margin: 0px 52px 0 52px;
    }
    .fca2 p{
      width: 100%;
    }
    .fca3 .d2{
      padding-left: 2%;
    }
    .tp-section3 .d1 .row div img{
          width: 132px;
    }
    .tp-section3 .d2{
      padding-top: 120px;
    }
}

@media(max-width:1200px) {
  .fca3 .img1{
      width: 180px
  }
  .fca3 .img2{
      width: 190px;
      margin-top: 16px;
  }
  .fca3 span{
      margin: 0px 44px 0 40px;
  }

}

@media(max-width:995px) {
  .tp-fth1 .p1 {
    padding: 0 5%;
  }
  .tp-fth4 .row .list img{
    width: 100px;
    height: 100px;
  }
  .profile-name {
      font-size: 40px;
      top: 19.5%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  .tp-fth1{
    background: url(../assets/image/ManagingYourPortfolio/trade-bg-m.png) 100%/auto 100% no-repeat;
    height: 650px;
    margin-top: 0px;
    text-align: center;
    padding-top: 126px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .tp-fth1 img{
    display: none;
  }

  .tp-fth1 .p3{
    font-size: 28px;
    opacity: 0.3;
  }
  .tp-fth1 .p1{
    font-size: 30px;
  }
  .tp-fth2{
    display: none;
  }
  .tp-public-title{
    font-size: 26px;
    margin-bottom: 20px;
  }
  .tp-public-title:nth-child(1){
    margin-top: 40px;
  }
  .tp-fth3 .row{
    background: none;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
  }
  .link{
    margin-top: 50px;
  }
  .tp-fth3 .row div{
    margin: 22px 0;
  }
  .tp-fth3 .row div:nth-child(1){
    width: 100%;
  }
  .tp-fth3 .row div:nth-child(2),.tp-fth3 .row div:nth-child(3),.tp-fth3 .row div:nth-child(4),.tp-fth3 .row div:nth-child(5){
    width: 50%;
  }
  .tp-fth3 .p1{
    font-size: 150px;
  }
  .tp-fth3 .p2{
    font-size: 22px;
    line-height: 33px;
    margin-top: 61px;
  }
  .tp-fth3 .p3{
    line-height: 24px;
    font-size: 14px;
    margin-top: 14px;
  }
  .tp-fth4 {
    margin-top: 100px;
  }
  .tp-fth4 .r1{
    overflow: hidden;
    margin-top: 40px;
    padding: 0px 38px 0 0;
  }
  .tp-fth4 .r2 .col-md-4{
    width: 33%;
  }
  .tp-fth5{
    margin-top: 100px;
  }
  .tp-fth5 .row div{
    width: 93%;
    margin: 0 auto;
  }
  .tp-fth5 .row{
    margin-top: 0px;
  }
  .tp-fth5 .row div .col-sm-6{
    width: 50%;
  }
  .tp-fth5 .row div .sr-row{
    padding: 0;
    margin: 0;
    background: none;
    margin-top: 0px!important;
  }
  .tp-fth5 .row div .sr-row div{
    background: none;
    padding: 0;
    margin: 0;
  }
  .tp-fth5 .row .p4{
    margin-top: 10px;
  }
  .tp-fth5 .row .chess{
    margin-top: 30px !important;
  }
  .tp-fth5 .row .p1{
    margin-top: 10px;
  }
  .tp-fth5 .row .p2{
    margin-top: 50px;
  }
  .tp-fth5 .row .p2 .s1{
    font-size: 16px;
  }
  .tp-fth5 .row .p2 .s2{
    font-size: 16px;
  }
  .reward-list-pc{
    display: none;
  }
  .reward-list-m{
    display: block;
  }
  .tp-fth5 .row div:nth-child(1){
    margin-top: 20px;
  }
  .tp-fth5 .row div:nth-child(2){
    margin-top: 20px;
  }
  .tp-fth5 .row div:nth-child(3){
    margin-top: 20px;
  }
  .tp-fth5 .row div:nth-child(4){
    margin-top: 20px;
  }
  .tp-fth5 .row div:nth-child(5){
    margin-top: 20px;
  }
  .reward-list-m div{
    background: linear-gradient(0deg, rgba(148, 150, 153, 0.06) 0%, rgba(148, 150, 153, 0.02) 100%);
  }
  .reward-list-m .sr-row div:nth-child(1){
    width: 40% !important;
  }
  .reward-list-m .sr-row div:nth-child(2){
    width: 60% !important;
  }
  .tp-fth5 .row div .tip1{
    font-size: 14px;
    line-height: 20px;
    margin-top: 4px;
  }
  .tp-fth5 .row div:nth-child(5) .p1{
    margin-top: 60px;
  }
  .reward-list-m .col-md-2{
    padding-top: 18px!important;
    padding-bottom: 18px!important;
  }
  .tp-fth6 table thead th{
    font-size: 14px;
    line-height: 18px;
  }
  .tp-fth6 table thead th br{
    display: block;
  }
  .tp-fth6 table thead th img {
    width: 76%;
    left: 11%;
    top: -15px;
  }
  .tp-fth6 table thead th:nth-child(1){
    width: 26%;
  }
  .tp-fth6 table tbody td{
    font-size: 13px;
    line-height: 10px;
  }
  .tp-fth6 table{
    width: 92%;
  }
  .tp-fth6 table thead th{
    height: 56px;
  }
  .tp-fth6 table thead th:nth-child(1) div{
    position: relative;
    top: 6%;
  }
  .tp-fth6 table tbody tr .img1{
    width: 40%;
  }
  .tp-fth6 table tbody tr .img2{
    width: 33%;
    left: 45%;
  }
  .tp-fth6 table tbody td {
    font-size: 13px;
    line-height: 50px;
  }
  .tp-fth6 table tbody tr:nth-child(even) td{
    line-height: 60px;
  }
  .tp-fth6 table tbody tr:nth-child(even) {
    height: 60px;
    line-height: 60px;
  }
  .tp-fth6 table tbody tr:last-child td:first-child {
    line-height: 18px;
    padding-top: 7px;
  }
  .tp-fth6{
    margin-top: 100px;
    margin-bottom: 80px;
  }
  .tp-fth6 .row {
    margin-top: 50px;
  }
  .tp-fth5 .row .p2{
    margin-top: 40px;
  }
  .tp-fth5 .row .p2 .s1{
    font-size: 14px;
  }
  .tp-fth5 .row .p2 .s2{
    font-size: 14px;
  }
  .tp-fth5 .row div:nth-child(1) .chess,.tp-fth5 .row div:nth-child(2) .chess, .tp-fth5 .row div:nth-child(3) .chess, .tp-fth5 .row div:nth-child(4) .chess, .tp-fth5 .row div:nth-child(5) .chess{
    margin-bottom: 4px;
  }
  .tp-fth5 .row div:nth-child(5) .p1{
    margin-top: 54px;
  }
  .tp-fth5 .sr-row{
    width: 100% !important;
  }

  .tp-fth4 .row .list .p3{
    font-size: 26px;
  }
  .tp-fth4 .row .list .sp1{
    font-size: 16px;
  }
  .tp-fth4 .row .list .sp2{
    font-size: 12px;
    margin-top: 6px;
  }

  .fca3 .d2{
    padding-left: 8%;
  }
  .home-header .navbar-brand img{
    width: 120px;
    height: auto;
  }
    .fca1{
      background: url(../assets/image/ManagingYourPortfolio/fca1-m.jpg) 100%/auto 100% no-repeat;
      height: 280px;
      background-position: center center;
          padding-top: 118px;
    }
    .fca1 p{
        font-size: 28px;
    }
    .fca1 img {
      margin-top: 22px;
      width: 150px;
    }
    .fca2{
      margin-top: 35px;
      margin-bottom: 66px;
    }
    .fca2 .d1{
      padding: 46px 0px;
      padding-left: 10%;
    }
    .fca2 .img1{
      margin-top: 4px;
      width: 32%;
    }
    .fca2 .img2{
      width: 18%;
      margin-top: 2px;
    }
    .fca2 span{
      margin: 0px 18% 0 8%;
      height: 58px;
    }
    .fca2 p{
      width: 100%;
      padding: 0px 6%;
      margin-top: 22px;
    }

    .fca3{
      margin-top: 35px;
    }
    .fca3 .d1{
      padding: 46px 0px;
      padding-left: 10%;
    }
    .fca3 .img1{
      margin-top: 4px;
      width: 32%;
    }
    .fca3 .img2{
      width: 43%;
      margin-top: 6px;
    }
    .fca3 span{
      margin: 0px 8% 0 8%;
      height: 58px;
    }
    .fca3 p{
      width: 100%;
      padding: 0px 6% 0 0%;
      margin-top: 22px;
    }
    .fca4{
      margin: 20px 0;
    }
    .fca4 .title{
      font-size: 22px;
      line-height: 40px;
      padding: 0 6%;
      margin-bottom: 26px;
    }
    .fca4 .row div{
      margin: 20px 0;
      text-align: left;
    }
    .fca4 .row div img{
      float: left;
      width: 50px;
      margin-left: 4%;
      margin-top: 12px;
    }
    .fca4 .row div p{
      float: left;
      margin-top: 0px;
      width: 64%;
      margin-left: 12%;
    }
    .fca4 .row div p br{
      display: none;
    }
    .fca4 .row{
      padding: 16px 7%;
    }



    .fca5{
      margin: 80px 0 10px;
    }
    .fca5 .title{
      font-size: 22px;
      line-height: 40px;
      padding: 0 6%;
      margin-bottom: 26px;
    }
    .fca5 .r1 div{
      margin: 20px 0;
      text-align: left;
    }
    .fca5 .r1 div img{
      float: left;
      width: 23%;
      margin-left: 8%;
      margin-top: 12px;
    }
    .fca5 .r1 div p{
      float: left;
      margin-top: 11%;
      width: 60%;
      margin-left: 8%;
      font-size: 18px;
    }
    .fca5 .r1 div p br{
      display: none;
    }
    .fca5 .r1{
      padding: 16px 7%;
    }
    .fca5 .r2 .c1{
      text-align: center;
      padding-right: 7px;
      margin-bottom: 54px;
    }
    .fca5 .r2 .c2{
      text-align: center;
      padding-right: 11px;
    }
    .fca5 .r2{
      margin-top: 46px;
    }

    .fca6{
      margin: 100px 0 10px;
    }
    .fca6 .title{
      font-size: 22px;
      line-height: 40px;
      padding: 0 10%;
      margin-bottom: 26px;
    }
    .fca6 .row{
      padding: 0px 8%;
      background: none;
    }
    .fca6 .row div .p2{
      padding-left: 0px;
      margin-bottom: 50px;
    }

    .contact-us {
        height: auto;
        padding-bottom: 64px
    }

    .contact-us .form-bg {
        width: 100%;
        display: block;
        margin: 0;
        border: none
    }

    .contact-us .form-box {
        height: auto;
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
        border: none;
        padding: 40px 8%;
        margin-top: -30px
    }

    .contact-us .form-box:after {
        display: none
    }

    .contact-us .form-box .form-btn {
        position: relative;
        left: 0;
        top: 0;
        margin-top: 60px
    }

    .contact-us .form-box .row {
        margin: 0
    }

    .contact-us .form-box .row>* {
        padding: 0
    }

    .contact-us .form-box .mt110 {
        margin-top: 43px
    }

    .contact-us .form-box .col-md-3 {
        margin-top: 12px;
        margin-right: 20px;
        width: 62px;
        height: 28px
    }

    .contact-us .form-box .select-label {
        height: 100%
    }

    .contact-us .form-box .form-label {
        font-size: 16px;
        margin-top: 32px
    }

   
    #verify {
      width: 100%;
    }
    .contact-us .form-box .select-label{
      height: 34px;
      width: 80px;
    }
    .contact-us .form-box .col-md-3{
      margin-right: 40px;
    }
    .contact-us .form-box .form-btn{
        margin-top: 36px;
    }
    .contact-us .form-box{
      padding-bottom: 80px;
    }
    .tp-fth1 .p1{
      margin-top: 40px;
      line-height: 58px;
      font-size: 36px;
    }
    .tp-fth1 .line{
      width: 62px;
      border: 1px solid #D9D9D9;
      margin: 26px auto 0px;
      display: block;
    }
    .tp-fth1 .p2{
      width: 100%;
      padding: 8%;
      font-size: 16px;
      line-height: 26px;
    }
    .tp-fth1 .btn{
      display: block;
      width: 56%;
      padding: 10px 40px;
      height: 46px;
      font-size: 16px;
      border-radius: 2px;
      background: #3D0101;
      color: #AF8147;
      margin: 14px auto;
    }
    .m-title,.m-line,.m-desc,.m-btn{
      display: block;
    }
    .pc-title,.pc-line,.pc-desc,.pc-btn{
      display: none;
    }
    .tp-fth1 .p1 br{
      display: block;
    }
    .tp-section0 .d1{
      background: none;
    }
    .trade1{
      background: #f8f8f8;
      padding: 50px 5%;
    }
    .trade1 .d1{
      padding: 0px;
    }
    .tp-section .title{
      text-align: center;
      width: 100%;
    }
    .tp-section .line{
      margin: 26px auto 30px;
      width: 46px;
    }
    .tp-section .title{
      font-size: 28px;
    }

    .tp-public-link,.tp-public-link2{
      width: 70%;
      text-align: center;
      margin: 20px auto 10px;
      float: unset;
    }

    .trade1 .d2{
      padding: 0;
    }
    .trade1 .d1{
      margin-bottom: 40px;
    }
    .trade1 .d1 .row div img{
      width: 110px;
    }
    .tp-section .market li:nth-child(3){
        display: none;
    }
    .tp-section .market li {
      width: 48%;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    .tp-section .title{
      line-height: 42px;
    }
    .tp-section .tp-section1-s1-d2{
      margin-top: 0px;
    }
    .trade2{
      padding: 50px 5%;;
    }
    .tp-section .desc{
      width: 100% !important;
      font-size: 16px;
      line-height: 24px;
    }
    .tp-section .market li .line{
        width: 88%;
        top: 130.5px;
    }
    .tp-section .market li{
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .trade2 .market{
      margin-bottom: 20px;
    }
    .trade3{
      background: #f8f8f8;
      padding: 50px 5%;
    }
    .trade3 .d1{
      padding: 0;
      margin-bottom: 30px;
    }
    .trade3 .d1 .row div{
      margin: 20px 0;
    }
    .trade3 .d1 .row div:nth-child(1){
        width: 100%;
    }
    .trade3 .d1 .row div:nth-child(2),.trade3 .d1 .row div:nth-child(3){
        width: 50%;
    }
    .tp-section .d2{
      padding: 0;
    }
    .tp-section3 .d1 .row div img{
      width: 122px;
    }
    .trade4{
      padding: 50px 5%;
    }
    .trade4 .m-desc{
      text-align: center;
    }
    .trade4 .market{
      margin-bottom: 20px;
    }
    .trade5{
      padding: 50px 5%;
      background: #f8f8f8;
    }
    .trade5 .d1{
      padding: 0;
      margin-bottom: 30px;
    }
    .trade5 .d1 .row div{
      width: 50%;
      margin: 30px 0;
    }
    .trade5 .tp-public-link2{
      margin-top: 0px;
    }

    .trade6{
      padding: 50px 5%;
    }
    .trade6 .d1{
      padding: 0;
      margin-bottom: 30px;
    }
    .trade6 .d1 .row div{
      width: 50%;
      margin: 30px 0;
    }
    .trade6 .tp-public-link2{
      margin-top: 40px;
    }

    .trade7{
      padding: 50px 5%;
      background: #f8f8f8;
    }
    .trade7 .d1{
      padding: 0;
      margin-bottom: 30px;
    }
    .trade7 .d1 .row div{
      width: 50%;
      margin: 30px 0;
    }
    .trade7 .tp-public-link2{
      margin-top: 0px;
    }

    .trade8{
      padding: 50px 5%;
    }
    .trade8 .d1{
      padding: 0;
      margin-bottom: 30px;
    }
    .trade8 .d1 .row div{
      width: 50%;
      margin: 30px 0;
    }
    .trade8 .tp-public-link2{
      margin-top: 40px;
    }
    .en-style .tp-fth1 .p1{
      font-size: 32px;
      padding: 0 10%;
      line-height: 44px;
    }
    .en-style .tp-section .title{
      width: 100%;
      font-size: 26px;
      line-height: 34px;
    }
    .en-style .tp-section4 .d2{
      padding: 0;
    }
    .en-style .tp-section6 .d2{
      padding: 0;
    }
}

</style>
