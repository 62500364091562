<template>
	<div class="never">
		<div class="never-bg">
			<img src="../assets/image/never/bg.png" alt="">
		</div>
		<div class="never-head">
			<div class="head-box flex">
				<div class="head-box__logo">
					<router-link to="/"><img src="../assets/image/No1/Group-19.png"
							title=" EBC官网地址 https://www.ebcfin.com" class="img"></router-link>
				</div>
				<div class="head-box__tit flex-1">
					<router-link to="/" class="btns">www.ebcfin.com</router-link>
				</div>
				<div class="head-box__btn flex">
					<a class="left-btn" href="https://client.ebccrm.com/signup/index?hl=zh">免费开户</a>
					<a class="right-btn" href="https://client.ebccrm.com/?hl=zh">登录</a>
				</div>
			</div>
		</div>
		<div class="never-h1">
			<div class="never-h1__tit">
				<img src="../assets/image/never/f-1.svg" alt="">
			</div>

			<div class="never-h1__name flex">
				<p class="text">我们日夜兼程，只为</p>
				<p>【每个认真交易的人，都值得被认真对待】</p>
			</div>
			<div class="never-h1__info flex">

				<div class="info-box">
					<div class="info-box__h3">
						尊享权益
					</div>
					<div class="info-box__h1">
						优享免息账户
					</div>
					<div class="info-box__h2">
						所有用户交易六大主要货币对<br />
						免除隔夜利息
					</div>
					<div class="info-box__time">
						「 2023年8月31日截止 」
					</div>
					<a class="info-box__btn flex"
						href="https://client.ebccrm.com/signup/index?utm_source=landingpagewin2022&amp;utm_medium=banner&amp;utm_campaign=ebc">
					</a>
				</div>
				<div class="info-bg flex-1">
					<img src="../assets/image/LandingPage/c-2.png" alt="" class="d-none d-md-block">
					<img src="../assets/image/LandingPage/c-4.png" alt="" class="d-block d-md-none">
				</div>
			</div>
			<div class="never-h1__tit4">
				免息产品包括
			</div>
			<div class="never-h1__img5">
				<img src="../assets/image/LandingPage/c-1.svg" alt="">
			</div>

		</div>
		<div class="never-swiper">

			<div class="box2-line">
				<img src="../assets/image/never/icon.png" alt="">
			</div>
			<div class="never-swiper__box2">
				<div class="box2-top flex">
					<div class="box2-top__li flex-1 flex flex-column">
						<div class="img">
							<img src="../assets/image/never/zj-icon.png" alt="">
						</div>
						<div class="tit">
							资金安全
						</div>
					</div>
					<div class="box2-top__li flex-1 flex flex-column">
						<div class="img">
							<img src="../assets/image/never/aq-icon.png" alt="">
						</div>
						<div class="tit">
							点差优势
						</div>
					</div>
				</div>
				<div class="box2-bot flex">
					<div class="box2-bot__li flex-1">
						<div class="text">
							资金托管于世界顶级银行，受到严格监管。
						</div>
						<div class="text">
							多种安全入金和便捷的提款方式
						</div>
					</div>
					<div class="box2-bot__li flex-1">
						<div class="text">
							遵守 BBBO(Best Bid Best Offer)交易规则
						</div>
						<div class="text">
							将最优报价呈现给客户
						</div>
					</div>
				</div>
			</div>
			<div class="never-swiper__box1 d-none d-md-block">
				<div class="box1-btn">
					<a class="btn"
						href="https://client.ebccrm.com/signup/index?utm_source=landingpagewin2022&amp;utm_medium=banner&amp;utm_campaign=ebc">
						马上领取
					</a>
				</div>
			</div>
			<div class="box2-line d-none d-md-block">
				<img src="../assets/image/never/icon.png" alt="">
			</div>
			<div class="never-swiper__box3">
				<div class="box3-pag flex">
					<div class="box3-pag__left flex flex-column">
						<div class="img">
							<img src="../assets/image/never/aq-icon.png" alt="">
						</div>
						<div class="tit">
							资金安全
						</div>
					</div>
					<div class="box3-pag__right flex-1">
						<div class="text">
							资金托管于世界顶级银行，受到严格监管。
						</div>
						<div class="text">
							多种安全入金和便捷的提款方式
						</div>
					</div>
				</div>
				<div class="box3-pag flex">
					<div class="box3-pag__left flex flex-column">
						<div class="img">
							<img src="../assets/image/never/zj-icon.png" alt="">
						</div>
						<div class="tit">
							点差优势
						</div>
					</div>
					<div class="box3-pag__right flex-1">
						<div class="text">
							遵守 BBBO(Best Bid Best Offer)交易规则
						</div>
						<div class="text">
							将最优报价呈现给客户
						</div>
					</div>
				</div>
				<div class="never-swiper__box1">
					<div class="box1-btn">
						<a class="btn"
							href="https://client.ebccrm.com/signup/index?utm_source=landingpagewin2022&amp;utm_medium=banner&amp;utm_campaign=ebc">
							马上领取
						</a>
					</div>
				</div>
				<div class="box3-line">
					<img src="../assets/image/never/icon.png" alt="">
				</div>
			</div>
			<div class="never-swiper__box1">
				<div class="box1-tit flex">
					<img src="../assets/image/No1/Path-10196.png" alt="">
					<div class="name flex-1">
						活动条款：
					</div>
				</div>
				<div class="box1-info">
					<div class="tit1 flex">
						<div class="tit1-num">
							1.
						</div>
						<div class="tit1-desc flex-1">
							免息产品包括：EUR/USD、GBP/USD、AUD/USD、USD/CHF、USD/CAD、NZD/USD。
						</div>
					</div>
					<div class="tit1 flex">
						<div class="tit1-num">
							2.
						</div>
						<div class="tit1-desc flex-1">
							免息活动：隔夜利率为负值时，EBC将为您免除此部分利息；隔夜利息为正值时， 您仍可获得该部分利息。
						</div>
					</div>
					<div class="tit1 flex">
						<div class="tit1-num">
							3.
						</div>
						<div class="tit1-desc flex-1">
							参与此次活动的用户不得同时参与EBC其他活动。
						</div>
					</div>
					<div class="tit1 flex">
						<div class="tit1-num">
							4.
						</div>
						<div class="tit1-desc flex-1">
							本次活动最终解释权归EBC所有，EBC有权随时终止。
						</div>
					</div>
					<div class="tit1 flex">
						<div class="tit1-num">
							5.
						</div>
						<div class="tit1-desc flex-1">
							优享免息账户活动2023年8月31日截止。
						</div>
					</div>
				</div>
			</div>

		</div>
		<div class="never-tips flex flex-column">
			<div class="text">
				详情请咨询官网客服或您的专属顾问
			</div>
			<div class="text">
				EBC英澳权威双监管，致力协助机构和专业投资者成功
			</div>
		</div>
		<div class="never-country flex flex-column">
			<div class="never-country__ul flex">
				<div class="never-country__ul__li flex-1 flex">
					<div class="img">
						<img src="../assets/image/never/gq1.png" alt="">
					</div>
					<div class="info flex flex-column">
						<div class="text">
							EBC Financial Group (UK) Ltd
						</div>
						<div class="text">
							英国金融行为监管局(FCA)授权监管
						</div>
						<div class="text">
							监管号: 927552
						</div>
					</div>
				</div>
				<div class="never-country__ul__li flex-1 flex">
					<div class="img">
						<img src="../assets/image/never/gq2.png" alt="">
					</div>
					<div class="info flex flex-column">
						<div class="text">
							EBC Financial Group (Australia) Pty Ltd
						</div>
						<div class="text">
							澳大利亚证券和投资委员会(ASIC)授权监管
						</div>
						<div class="text">
							监管号: 500991
						</div>
					</div>
				</div>
			</div>
			<div class="never-country__line">
				<img src="../assets/image/never/icon.png" alt="">
			</div>
		</div>
		<div class="never-footer flex flex-column">
			<div class="never-footer-top">
				唯有时间,让您,更了解我
			</div>
			<div class="never-footer-bot">
				Let’s Build The Future
			</div>
		</div>
	</div>
</template>
<!-- Google tag (gtag.js) -->
<!-- <script async src="https://www.googletagmanager.com/gtag/js?id=G-TJ431PDHZX"></script>
<script>
	window.dataLayer = window.dataLayer || [];

	function gtag() {
		dataLayer.push(arguments);
	}
	gtag('js', new Date());
	gtag('config', 'G-TJ431PDHZX');
</script> -->
<script>
	if (process.browser) {
		var {
			WOW
		} = require('wowjs')
	}
	export default {
		metaInfo() {
			return {
				title: 'Free Swap | EBC Financial Group',
				meta: [ // set meta
					{
						name: 'keyWords',
						content: 'EBC,EBC平台,EBC官网,EBC金融官网,EBC开户,EBC金融'
					},
					{
						name: 'description',
						content: 'EBC金融官网是由EBC金融集团EBC Financial Group成立的,主要提供,EBC金融,EBC开户交易服务,只需一个EBC综合平台账户,即可交易多种差价合约（CFD）:衍生品、美股、数字货币、期货指数、贵金属和能源等.'
					}
				]
			}
		},
		data() {
			return {
				current: 0,
				endTimestamp: '1654012799',
				timeDate: {
					day: '000',
					hour: '00',
					minute: '00',
					second: '00'
				},
			}
		},
		mounted() {
			this.$nextTick(() => {
				if (process.browser) { // 在页面mounted生命周期里面 根据环境实例化WOW
					new WOW({
						animateClass: 'animate__animated'
					}).init()
				}
			});
		},
		created() {

		},
		methods: {
			formatTime() {
				const $time = this.endTimestamp - parseInt((+new Date()) / 1000)
				this.timeDate = {
					day: this.zeroFill(Math.floor($time / (3600 * 24)), 2),
					hour: this.zeroFill(Math.floor(($time % (3600 * 24)) / 3600)),
					minute: this.zeroFill(Math.floor((($time % (3600 * 24)) % 3600) / 60)),
					second: this.zeroFill(Math.floor($time % 60))
				}
				setTimeout(() => {
					this.formatTime()
				}, 1000)
			},
			changSw() {
				if (this.current === 0) {
					this.current = 1
				} else if (this.current === 1) {
					this.current = 2
				} else {
					this.current = 0
				}
				setTimeout(() => {
					this.changSw()
				}, 6000)
			},
			zeroFill(num, fixed = 1) {
				const arr = []
				arr.length = fixed
				arr.fill(9)
				return num > arr.join() ? String(num) : '0' + num
			},
			chang(idx) {
				this.current = idx;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.never {
		font-family: 'PingFang SC', 'Helvetica Neue', 'Microsoft YaHei UI', 'Microsoft YaHei', 'Noto Sans CJK SC', Sathu, EucrosiaUPC, Arial, Helvetica, sans-serif;
		background: linear-gradient(to bottom, #3B0909, #921111);
		position: relative;

		&-bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 720px;
		}

		&-head {
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			background-color: rgba(84, 0, 0, 1);
			padding: 15px 0;
			z-index: 1000;

			.head-box {
				max-width: 1080px;
				margin: auto;
				align-items: center;

				&__logo {}

				&__tit {
					text-align: center;

					.btns {
						color: #a97c50;
						font-family: Times New Roman, Georgia, serif;
						font-weight: 700;
						font-size: 22px;
						line-height: 70px;
					}
				}

				&__btn {
					align-items: center;

					.left-btn {
						display: block;
						color: #c49a6c;
						border-radius: 5px;
						font-size: 20px;
						font-family: Noto Sans SC, Helvetica, Arial, Lucida, sans-serif;
						background-color: #760006;
						padding: 0.3em 1em;
						line-height: 1.7em;
					}

					.right-btn {
						margin-left: 20px;
						font-size: 20px;
						font-family: Noto Sans SC, Helvetica, Arial, Lucida, sans-serif !important;
						color: #e3e3e3;
						padding: 0.3em 1em;
						text-decoration: underline
					}
				}
			}
		}

		&-swiper {
			margin: 40px auto;
			max-width: 1080px;
			position: relative;

			&__box1 {
				margin-bottom: 60px;

				.box1-tit {
					margin-top: 60px;
					align-items: center;

					.name {
						font-size: 42px;
						color: #F7BF83;
						padding-left: 10px;
					}

					img {
						width: 32px;
					}
				}

				.box1-info {
					text-align: center;
					padding: 40px 0;
					letter-spacing: 6px;

					.tit {
						color: #fff;
						padding-bottom: 20px;
						font-size: 32px;
						font-weight: 600;
					}

					.tit1 {
						color: #fff;
						padding-bottom: 40px;
						font-size: 24px;
						font-weight: 400;
						line-height: 48px;
						text-align: left;

						&-num {
							text-indent: 10px;
							padding-right: 5px;
						}
					}

					.ps {
						color: #F7BF83;
						padding-bottom: 60px;
						font-size: 26px;
						font-weight: 400;
					}
				}

				.box1-btn {
					text-align: center;

					.btn {
						cursor: pointer;
						width: 476px;
						height: 108px;
						color: rgba(0, 0, 0, 1);
						font-size: 36px;
						line-height: 78px;
						background: url(../assets/image/never/f-3.svg)no-repeat top left;
						background-size: 100% 100%;
					}
				}
			}

			&__box3 {
				display: none;
			}

			&__box2 {
				margin: 80px 0 60px 0;

				.box2-top {
					&__li {
						align-items: center;

						.img {
							height: 200px;

							img {
								height: 100%;
							}
						}

						.tit {
							padding: 50px 0;
							color: #fff;
							font-size: 32px;
							letter-spacing: 6px;
						}
					}
				}

				.box2-bot {
					padding: 0px 0 50px;

					&__li {
						color: #fff;
						text-align: center;
						font-size: 18px;
						letter-spacing: 4px;
						font-weight: 400;
						line-height: 32px;
					}
				}
			}

		}

		&-h1 {
			margin: 60px auto;
			max-width: 1080px;
			padding-top: 140px;

			&__info {
				position: relative;
				margin-top: 40px;
				z-index: 1;
				align-items: center;

				.info-box {
					&__h3 {
						font-size: 24px;
						font-weight: bold;
						color: #fff;
						letter-spacing: 16px;
					}

					&__btn {
						height: 60px;
						color: #450505;
						width: 200px;
						justify-content: center;
						background: url(../assets/image/LandingPage/btn.svg)no-repeat top center;
						background-size: 100% 100%;
					}

					&__h1 {
						font-size: 48px;
						font-weight: bold;
						color: #fff;
						letter-spacing: 10px;
						padding: 20px 0;
					}

					&__h2 {
						color: #F7DE97;
						font-size: 44px;
						font-weight: 700;
						letter-spacing: 3px;
						line-height: 1.5;
					}

					&__time {
						color: #fff;
						font-size: 28px;
						letter-spacing: 4px;
						margin: 40px 0;
						line-height: 50px;
						background: url(../assets/image/LandingPage/c-3.svg)no-repeat top center;
						background-size: 100% 100%;
						display: inline-block;
					}
				}

				.info-bg {
					img {
						max-width: 100%;
					}
				}
			}

			&__tit {
				text-align: center;

				img {
					width: 70%;
				}
			}

			&__card {
				text-align: center;
				padding: 80px 0;

				img {
					width: 30%;
				}
			}

			&__tit1 {
				color: rgba(229, 192, 127, 1);
				font-size: 48px;
				text-align: center;
				padding-bottom: 40px;
			}

			&__tit2 {
				color: #fff;
				font-size: 48px;
				text-align: center;
				padding-bottom: 30px;
			}

			&__tit3 {
				color: rgba(229, 192, 127, 1);
				font-size: 48px;
				text-align: center;
				padding-bottom: 30px;
			}

			&__tit4 {
				color: rgba(229, 192, 127, 1);
				font-size: 48px;
				text-align: center;
				padding-bottom: 40px;
				padding-top: 80px;
			}

			&__img5 {
				text-align: center;
				position: relative;
				z-index: 10;

				img {
					width: 70%;
				}
			}

			&__name {
				justify-content: center;
				font-size: 32px;
				padding-top: 30px;
				padding-bottom: 50px;

				.text {
					color: #fff;
				}

				p {
					color: #F7DE97;
				}
			}
		}


		&-tips {
			margin: 0px auto 140px;
			max-width: 1080px;
			align-items: center;
			font-size: 32px;
			font-weight: 500;
			color: #fff;
			line-height: 62px;
		}

		&-country {
			margin: auto;
			max-width: 1080px;

			&__ul {
				padding-bottom: 60px;
				width: 100%;

				&__li {
					align-items: center;
					color: #ffffff;
					font-size: 20px;

					.img {
						width: 126px;
						height: 76px;
						margin-right: 10px;
					}

					.info {
						line-height: 27px;
					}
				}
			}
		}

		&-footer {
			padding: 60px 0;
			margin: auto;
			max-width: 1080px;
			align-items: center;
			font-size: 28px;
			color: #fff;
			letter-spacing: 2px;

			&-bot {
				color: #F7BF83;
				padding-top: 14px;
				font-size: 32px;
				letter-spacing: 2px;
			}
		}


		@media (max-width: 500px) {
			&-head {
				.head-box {
					padding: 0 10px;

					&__logo {
						flex: 1;

						img {
							width: 90%;
						}
					}

					&__tit {
						display: none;
					}

					&__btn {
						flex: 1;
						justify-content: right;

						.right-btn {
							margin-left: 0;
							font-size: 14px;
						}

						.left-btn {
							padding: 0.3em 0.6em;
							font-size: 14px;
						}
					}
				}
			}

			&-bg {
				width: 100%;
				bottom: 0;
				top: initial;
			}

			&-swiper {
				margin: 20px 10px;
				position: relative;

				&__box1 {
					margin-bottom: 20px;
				}

				&__dost {
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					display: flex;
					justify-content: center;

					&__pag {
						width: 16px;
						height: 16px;
						background-color: #AB2B2B;
						border-radius: 8px;
						cursor: pointer;

						&.active {
							background-color: #F06969;
						}

						&:nth-child(2) {
							margin: 0 50px;
						}
					}
				}

				&__box1 {
					.box1-tit {
						margin-top: 0px;
						align-items: center;

						.name {
							font-size: 24px;
							color: #F7BF83;
						}

						img {
							width: 20px;
							display: none;
						}
					}

					.box1-info {
						text-align: center;
						padding: 20px 0 0;
						letter-spacing: 0px;

						.tit {
							color: #fff;
							padding-bottom: 20px;
							font-size: 20px;
							font-weight: 600;
						}

						.tit1 {
							padding-bottom: 10px;
							font-size: 14px;
							font-weight: 400;
							line-height: 28px;

						}

						.ps {
							color: #F7BF83;
							padding-bottom: 20px;
							font-size: 18px;
							font-weight: 400;
							line-height: 28px;
						}
					}

					.box1-btn {
						text-align: center;

						.btn {
							cursor: pointer;
							width: 250px;
							height: 60px;
							background: url(../assets/image/never/f-3.svg)no-repeat top left;
							background-size: 100% 100%;
							font-size: 18px;
							line-height: 39px;
						}
					}
				}

				&__box2 {
					display: none;

					.box2-top {
						&__li {
							align-items: center;

							.img {
								height: 100px;

								img {
									height: 100%;
								}
							}

							.tit {
								padding: 50px 0;
								color: #fff;
								font-size: 24px;
								letter-spacing: 6px;
							}
						}
					}

					.box2-bot {
						padding: 50px 0;

						&__li {
							color: #fff;
							text-align: center;
							font-size: 18px;
							letter-spacing: 4px;
							font-weight: 400;
							line-height: 32px;
						}
					}
				}

				&__box3 {
					display: flex;
					margin: 60px 0 0px;
					flex-direction: column;

					.box3-pag {
						align-items: center;
						flex-direction: column;
						width: 100%;
						margin-bottom: 40px;

						&__left {
							width: 100%;
							align-items: center;
							justify-content: center;

							.img {
								text-align: center;

								img {
									width: 50%;
								}
							}

							.tit {
								color: #F7BF83;
								font-size: 32px;
								padding: 30px 0;
							}
						}

						&__right {
							color: #fff;
							font-size: 18px;
							line-height: 32px;
							width: 100%;
							text-align: center;
						}
					}

					.box3-line {
						margin: 20px 0;
					}
				}
			}

			&-h1 {
				margin: 40px 10px;
				padding-top: 100px;

				&__img5 {
					img {
						width: 100%;
					}
				}

				&__info {
					margin: 0;
					z-index: 1;
					align-items: center;
					flex-direction: column;

					.info-box {
						&__h3 {
							font-size: 28px;
							font-weight: bold;
							color: #fff;
							letter-spacing: 16px;
						}

						&__btn {
							height: 45px;
							color: #450505;
							width: 100%;
							justify-content: center;
							background: url(../assets/image/LandingPage/btn.svg)no-repeat top center;
							background-size: 100% 100%;
						}

						&__h1 {
							font-size: 36px;
							font-weight: bold;
							color: #fff;
							letter-spacing: 10px;
							padding: 20px 0;
						}

						&__h2 {
							color: #F7DE97;
							font-size: 28px;
							font-weight: 700;
							letter-spacing: 3px;
							line-height: 1.5;
						}

						&__time {
							color: #fff;
							font-size: 18px;
							letter-spacing: 2px;
							margin: 20px 0;
							line-height: 50px;
							background: url(../assets/image/LandingPage/c-3.svg)no-repeat top center;
							background-size: 100% 100%;
							display: flex;
							justify-content: center;
						}
					}

					.info-bg {
						width: 100%;
						padding: 20px 0;

						img {
							max-width: 100%;
						}
					}
				}


				&__tit1 {
					font-size: 24px;
					padding-bottom: 40px;
				}

				&__tit2 {
					color: #fff;
					font-size: 24px;
					text-align: center;
					padding-bottom: 30px;
					line-height: 1.5;
				}

				&__tit3 {
					font-size: 24px;
					padding-bottom: 30px;
				}

				&__tit4 {
					font-size: 24px;
					padding-top: 20px;
				}

				&__name {
					justify-content: center;
					font-size: 18px;
					padding-top: 30px;
					flex-direction: column;
					align-items: center;
					line-height: 32px;

					p {
						color: #D9BC96;
					}
				}
			}

			&-tips {
				margin: 0px 10px 20px;
				align-items: center;
				font-size: 14px;
				font-weight: 500;
				color: #fff;
				line-height: 32px;
				position: relative;
				z-index: 10;

				.text {
					text-align: center;
				}
			}

			&-country {
				margin: auto 10px;

				&__ul {
					padding-bottom: 20px;
					padding-left: 10px;
					width: 100%;
					flex-direction: column;

					&__li {
						align-items: center;
						color: #ffffff;
						font-size: 20px;
						width: 100%;

						&:last-child {
							margin-top: 20px;
						}

						.img {
							width: 90px;
							height: initial;
							margin-right: 6px;
						}

						.info {
							line-height: initial;
							flex: 1;
							font-size: 13px;

							.text {
								&:nth-child(2) {
									padding: 7px 0 5px;
								}
							}
						}
					}
				}
			}

			&-footer {
				margin: auto;
				align-items: center;
				font-size: 15px;
				color: #fff;
				letter-spacing: 2px;
				padding: 20px 0;

				&-bot {
					color: #F7BF83;
					padding-top: 14px;
					font-size: 14px;
					letter-spacing: 2px;
				}
			}
		}
	}
</style>