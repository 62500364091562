<template>
	<div :class="$t('Oxford.box-class-name')">
        <ss-header mode="Home" bg="rgb(38, 5, 5)"></ss-header>
    <div class="oxford-1">
        <img class="oxford-bg-pc" src="../assets/image/oxford/oxford-bg-pc.jpg" alt="">
        <img class="oxford-bg-m" src="../assets/image/oxford/oxford-bg-m.jpg" alt="">

        <div class="container">
            <img class="oxford-logo1" src="../assets/image/oxford/oxford-logo.png" alt="">

            <img class="oxford-logo2" src="../assets/image/oxford/oxford-logo2.png" alt="">

            <p class="p1" v-html="$t('Oxford.oxford-page1.text1')"></p>
            <p class="p2">{{$t('Oxford.oxford-page1.text2')}}<br>
                    <span>{{$t('Oxford.oxford-page1.text3')}}</span>
            </p>

            <div class="reserve">
                <a href="https://www.eventbrite.co.uk/e/what-economists-really-do-the-economics-of-tax-evasion-tickets-711341450427" target="_blank">{{$t('Oxford.button-text')}}</a>
                <p class="p3">{{$t('Oxford.oxford-page1.text5')}}</p>
            </div>
        </div>
    </div>

    <div class="oxford-2">
        <div class="container">
            <div class="content">
                <p class="p1">{{$t('Oxford.oxford-page2.text1')}}</p>
                <p class="p2">{{$t('Oxford.oxford-page2.text2')}}</p>
                <p class="p3">
                    {{$t('Oxford.oxford-page2.text3')}}
                </p>
                <p class="p3" v-if="$t('Oxford.oxford-page2.text4')">
                    {{$t('Oxford.oxford-page2.text4')}}
                </p>
                <p class="p4">{{$t('Oxford.oxford-page2.text5')}}</p>
                <p class="p3">
                    {{$t('Oxford.oxford-page2.text6')}}
                </p>
                <a href="https://economics.web.ox.ac.uk/article/what-economists-really-do-2023-24-sponsored-by-the-ebc-financial-group" target="_blank">{{$t('Oxford.button-text')}}</a>
            </div>
        </div>
    </div>

    <div class="oxford-3">
        <div class="container">
            <p class="title">{{$t('Oxford.oxford-page3.text1')}}</p>
            <div class="row">
                <div class="col-md-12 box">
                    <img src="../assets/image/oxford/oxford1.png" alt="">
                    <div class="box-right">
                        <p class="p1">Sarah Clifford</p>
                        <p class="p2">{{$t('Oxford.oxford-page3.text2')}}</p>
                        <p class="p3">
                            {{$t('Oxford.oxford-page3.text3')}}
                        </p>
                        <a href="https://economics.web.ox.ac.uk/people/sarah-clifford" target="_blank">{{$t('Oxford.button-text')}}</a>
                    </div>
                </div>
                <div class="col-md-12 box">
                    <img src="../assets/image/oxford/oxford2.png" alt="">
                    <div class="box-right">
                        <p class="p1">Abi Adams-Prassl</p>
                        <p class="p2">{{$t('Oxford.oxford-page3.text4')}}</p>
                        <p class="p3">
                            {{$t('Oxford.oxford-page3.text5')}}
                        </p>
                        <a href="https://economics.web.ox.ac.uk/people/abi-adams-prassl-1" target="_blank">{{$t('Oxford.button-text')}}</a>
                    </div>
                </div>
                <div class="col-md-12 box">
                    <img src="../assets/image/oxford/oxford3.png" alt="">
                    <div class="box-right">
                        <p class="p1">David Barrett</p>
                        <p class="p2">{{$t('Oxford.oxford-page3.text6')}}</p>
                        <p class="p3">
                            {{$t('Oxford.oxford-page3.text7')}}
                        </p>
                        <a @click="jumPage('LEARN-72-34C')" style="cursor: pointer;">{{$t('Oxford.button-text')}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="oxford-4">
        <div class="container">
            <div class="row">
                <p class="p1">{{$t('Oxford.oxford-page4.text1')}}</p>
                <p class="p2" v-html="$t('Oxford.oxford-page4.text2')"></p>

                <a href="/about-us/">{{$t('Oxford.button-text')}}</a>

                <p class="p1">{{$t('Oxford.oxford-page4.text3')}}</p>
                <p class="p2" v-html="$t('Oxford.oxford-page4.text4')"></p>
                <img src="../assets/image/oxford/oxford-logo3.png" alt="">
            </div>
        </div>
    </div>

    <img class="oxford-footer-bg" src="../assets/image/oxford/oxford-footer-bg-pc.png" alt="">
    <img class="oxford-footer-bg-m" src="../assets/image/oxford/oxford-footer-bg-m.png" alt="">
</div>
</template>

<script>
	export default {
		data() {
			return {
				seoInfo: "",
			}
		},
		metaInfo() {
			return {
				title: this.seoInfo.title || 'EBC联合牛津大学研讨会 | EBC Group',
				meta: [ // set meta
					{
						name: 'keyWords',
						content: this.seoInfo.keyWords || ''
					},
					{
						name: 'description',
						content: this.seoInfo.description || ''
					}
				]
			}
		},
		created() {
		},
		mounted() {
		},
        methods: {
            jumPage(id) {
				this.$router.push({
					name: 'LearningCenterInfo',
					query: {
						id: id
					}
				})
			},
        }
	}
</script>

<style>
	@charset "UTF-8";
.oxford-box-cn{
  position: relative;
  padding-bottom: 160px;
}
.oxford-box-en{
  position: relative;
  padding-bottom: 160px;
}

@font-face{
    font-family: 'Norwester';
    src : url('../assets/font/norwester.otf');
    font-style: normal;
    font-display: swap;
}
@font-face {
  font-family: 'League Spartan';
  src: url('../assets/font/LeagueSpartan-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'League Spartan';
  src: url('../assets/font/LeagueSpartan-Bold.ttf');
  font-weight: 700;
}

.oxford-1{
    position: relative;
    padding-top: 72px;
}

.oxford-1 .oxford-bg-pc{
    position: absolute;
    width: 100%;
    top: 72px;
    z-index: -1;
}

.oxford-1 .oxford-logo2{
    position: absolute;
    top: 106px;
    right: 26px;
    width: 17%;
}

.oxford-1 .oxford-logo1{
    margin-top: 180px;
    width: 48%;
}

.oxford-1 .p1{
    color: #FBB50B;
    text-transform: uppercase;
    font-size: 78px;
    font-family: Norwester;
    letter-spacing: 1.56px;
    margin-top: 82px;
}

.oxford-1 .p2{
    color: #ffffff;
    text-transform: uppercase;
    font-size: 30px;
    letter-spacing: 0.6px;
    font-family: League Spartan;
    font-weight: 700;
    line-height: 44px;
    margin-top: 26px;
}
.oxford-1 .p2 span{
    color: #FBB50B;
}

.oxford-1 .reserve{
    margin-top: 60px;
}
.oxford-1 .reserve a{
    background: #FBB50B;
    padding: 18px 28px 12px;
    color: #0D1D41;
    font-weight: 700;
    font-size: 26px;
    font-family: League Spartan;
    text-transform: uppercase;
    display: inline-block;
    float: left;
}
.oxford-1 .reserve a:hover{
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #FBB50B;
}
.oxford-1 .reserve .p3{
    color: #ffffff;
    font-family: "League Spartan";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.48px;
    text-transform: uppercase;
    float: left;
    margin-top: 18px;
    margin-left: 30px;
}

.oxford-2{
    
}

.oxford-2 .content{
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 14px 34px 0px rgba(0, 0, 0, 0.06);
    margin: 260px auto 100px;
    text-align: left;
    padding: 60px 4% 50px;
}

.oxford-2 .p1{
    color: #1E284C;
    font-family: "League Spartan";
    font-size: 46px;
    font-weight: 700;
    text-transform: uppercase;
}

.oxford-2 .p2{
    color: #143785;
    font-family: "League Spartan";
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 30px;
    margin-top: 24px;
}

.oxford-2 .p3{
    color: #525C60;
    font-family: auto;
    font-size: 16px;
    line-height: 30px;
    margin-top: 20px;
}

.oxford-2 .p4{
    color: #1E284C;
    font-family: "League Spartan";
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 40px;
}
.oxford-2 a{
    background: #FBB50B;
    padding: 16px 24px 12px;
    color: #0D1D41;
    font-weight: 700;
    font-size: 18px;
    font-family: League Spartan;
    display: inline-block;
    margin-top: 20px;
}
.oxford-2 a:hover{
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #FBB50B;
}

.oxford-3 .title{
    padding: 20px 10% 52px;
    color: #1E284C;
    font-size: 36px;
    font-family: "League Spartan";
    font-weight: 700;

}
.oxford-3 .row{
    width: 82%;
    margin: 0 auto;
}

.oxford-3 .row img{
    width: 30%;
    float: left;
}

.oxford-3 .box-right{
    width: 67%;
    float: right;
}

.oxford-3 .box{
    margin-bottom: 70px;
}

.oxford-3 .box .p1{
    color: #1E284C;
    font-size: 22px;
    font-family: auto;
    font-weight: 700;
    margin-top: 12px;
}

.oxford-3 .box .p2{
    color: #525C60;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 29.772px */
    margin-top: 20px;
    margin-bottom: 20px;
}

.oxford-3 .box .p3{
    color: #525C60;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    line-height: 30px; /* 29.772px */
}

.oxford-3 .box a{
    background: #FBB50B;
    padding: 16px 24px 12px;
    color: #0D1D41;
    font-weight: 700;
    font-size: 18px;
    font-family: League Spartan;
    display: inline-block;
    margin-top: 20px;
}
.oxford-3 .box a:hover{
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #FBB50B;
}

.oxford-4{
  margin-top: 0px;
}
.oxford-4 .row{
    width: 82%;
    margin: 0 auto;
}
.oxford-4 .p1{
    color: #1E284C;
    font-size: 36px;
    font-family: "League Spartan";
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 60px;
}
.oxford-4 .p2{
    color: #525C60;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 30px; 
    margin-top: 20px;
}

.oxford-4 img{
  margin-top: 100px;
}

.oxford-footer-bg{
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: -1;
}
.oxford-footer-bg-m{
  display: none;
}

.oxford-bg-m{
  display: none;
}
.oxford-1 .p1 br{
  display: none;
}

.oxford-4 a{
    background: #FBB50B;
    color: #0D1D41;
    font-weight: 700;
    font-size: 18px;
    font-family: League Spartan;
    display: inline-block;
    margin-top: 20px;
    width: max-content;
    text-align: center;
    height: 46px;
    line-height: 50px;
    margin-left: 12px;
    padding: 0 28px;
}
.oxford-4 a:hover{
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #FBB50B;
}
.oxford-box-cn .oxford-4 a{
    font-size: 16px;
}


.oxford-box-cn .oxford-1 .p1,.oxford-box-cn .oxford-2 .p1{
  font-family: 'Noto Serif SC';
  font-weight: 700;
}
.oxford-box-cn .oxford-1 .p1{
  font-size: 64px;
}
.oxford-box-cn .oxford-1 .p2{
  margin-top: 36px;
}
.oxford-box-cn .oxford-1 .p2 span{
  font-size: 22px;
}
.oxford-box-cn .oxford-1 .reserve a{
  font-size: 22px;
}
.oxford-box-cn .oxford-1 .reserve .p3{
  font-size: 18px;
  font-weight: 400;
  margin-top: 14px;
}
.oxford-box-cn .oxford-2 .p3{
    font-size: 16px;
}
.oxford-box-cn .oxford-2 a{
  font-size: 16px;
}
.oxford-box-cn .oxford-3 .box a{
  font-size: 16px;
}
.oxford-box-cn .oxford-3 .box .p3{
  font-size: 16px;
}
.oxford-box-cn .oxford-4 .p2{
  font-size: 16px;
}
.oxford-box-cn .oxford-2 .p1{
  font-size: 42px;
}


@media(max-width:1600px) {
    .oxford-1 .oxford-logo1{
        margin-top: 140px;
        width: 40%;
    }
    .oxford-1 .p1{
        font-size: 70px;
        margin-top: 58px;
    }
    .oxford-1 .reserve{
        margin-top: 40px;
    }
    .oxford-2 .content{
        margin-top: 160px;
    }

    .oxford-box-cn .oxford-1 .p1{
      font-size: 58px;
    }
    .oxford-box-cn .oxford-1 .p2{
      margin-top: 34px;
    }
}

@media(max-width:1400px) {
    .oxford-1 .p1{
        font-size: 66px;
        margin-top: 50px;
    }
    .oxford-1 .p2{
        font-size: 26px;
        line-height: 34px;
    }
    .oxford-1 .reserve{
        margin-top: 30px;
    }
    .oxford-2 .content{
        margin-top: 150px;
    }
    .oxford-2 .p1{
        font-size: 40px;
    }
    .oxford-box-cn .oxford-1 .p1{
      font-size: 54px;
    }
    .oxford-box-cn .oxford-1 .p2{
      margin-top: 34px;
    }
    .oxford-box-cn .oxford-2 .p1{
          font-size: 38px;
    }
}

@media(max-width:1300px) {
    .oxford-1 .container{
        padding: 0 5%;
    }
    .oxford-2 .container{
        padding: 0 5%;
    }
    .oxford-1 .p1{
        font-size: 60px;
        margin-top: 40px;
    }
    .oxford-1 .p2{
      font-size: 24px;
      line-height: 32px;
    }
    .oxford-1 .reserve a{
      font-size: 22px;
    }
    .oxford-1 .reserve .p3{
      font-size: 22px;
      margin-top: 14px;
    }
    .oxford-2 .content{
        margin-top: 140px;
    }
}


@media(max-width:996px) {
    .oxford-bg-m{
        display: block;
        position: absolute;
        width: 100%;
        top: 66px;
        z-index: -1;
    }
    .oxford-bg-pc{
        display: none;
    }
    .oxford-1 .oxford-logo2{
        position: unset;
    }
    .oxford-1 .oxford-logo1{
        margin-top: 40px;
        width: 91%;
    }
    .oxford-1 .oxford-logo2{
        width: 46%;
        margin-top: 24px;
    }
    .oxford-1 .p1 br{
        display: block;
    }
    .oxford-1 .p1{
        line-height: 117.9%; /* 131.196px */
        font-size: 56px;
    }
    .oxford-1 .p2{
        font-size: 18px;
        line-height: 150%;
    }
    .oxford-1 .reserve a{
        font-size: 18px;
        padding: 16px 24px 10px;
        margin-right: 30%;
    }
    .oxford-1 .reserve .p3{
        margin-left: 0px;
        font-size: 15px;
    }
    .oxford-1 .reserve{
      margin-top: 20px;
    }
    .oxford-1 .reserve a{
        float: unset;
    }
    .oxford-1 .reserve .p3{
      float: unset;
    }
    .oxford-2 .content{
      margin-top: 10px;
      padding-top: 40px;
      padding-bottom: 40px;
    }
    .oxford-2 .container{
      padding: 0 5%;
    }
    .oxford-2 .p1{
      font-size: 26px;
    }
    .oxford-1{
        padding-bottom: 40px;
    }
    .oxford-2 .p2{
        font-size: 16px;
        line-height: 24px;
        margin-top: 16px;
    }
    .oxford-2 .p3{
        font-size: 15px;
        margin-top: 10px;
        line-height: 26px;
    }
    .oxford-2 .p4{
        font-size: 20px;
        margin-top: 26px;
    }
    .oxford-2 .content{
      margin-bottom: 20px;
    }
    .oxford-3 .title{
      padding: 20px 3% 30px;
      font-size: 26px;
      text-align: center;
    }
    .oxford-3 .row{
      width: 100%;
    }
    .oxford-3 .box{
      text-align: center;
    }
    .oxford-3 .row img{
      float: unset;
      width: 50%;
    }
    .oxford-3 .box-right{
      float: unset;
      width: 100%;
      padding: 0 1%;
    }
    .oxford-3 .box .p1{
      font-size: 18px;
      margin-top: 16px;
    }
    .oxford-3 .box .p2{
      font-size: 15px;
      line-height: 26px;
      margin-top: 16px;
      margin-bottom: 10px;
    }
    .oxford-3 .box .p3{
      font-size: 15px;
      line-height: 26px;
    }
    .oxford-3 .box a{
      margin-top: 18px;
    }
    .oxford-4 .row{
      width: 100%;
    }
    .oxford-4 .p1{
      margin-top: 0px;
      font-size: 26px;
      line-height: 28px;
      padding: 0 2%;
    }
    .oxford-4 .p2{
      font-size: 15px;
      line-height: 26px;
      margin-top: 10px;
      margin-bottom: 20px;
      padding: 0 2%;
    }
    .oxford-4 img{
      margin-top: 0px;
      padding: 0 2%;
      margin-top: 30px;
    }
    .oxford-box-cn{
      padding-bottom: 100px;
    }
    .oxford-box-en{
      padding-bottom: 100px;
    }
    .oxford-footer-bg-m{
      position: absolute;
      bottom: 0px;
      width: 100%;
      z-index: -1;
      display: block;
    }
    .oxford-footer-bg{
      display: none;
    }

    .oxford-box-cn .oxford-1 .p1{
      font-size: 54px;
      margin-top: 80px;
    }
    .oxford-box-cn .oxford-1 .p2 span{
      font-size: 16px;
    }
    .oxford-box-cn .oxford-1 .reserve a{
      font-size: 16px;
    }
    .oxford-box-cn .oxford-1 .reserve .p3{
        font-size: 15px;
    }
    .oxford-box-cn .oxford-1 .p2{
      margin-top: 40px;
    }
    .oxford-box-cn .oxford-1 .reserve{
      margin-top: 30px;
    }
    .oxford-box-cn .oxford-2 .p1{
      font-size: 24px;
      line-height: 34px;
    }
    .oxford-box-cn .oxford-2 .p3{
      font-size: 15px;
    }
    .oxford-box-cn .oxford-3 .box .p2{
      font-size: 16px;
    }
    .oxford-box-cn .oxford-3 .box .p3{
      font-size: 15px;
    }
    .oxford-box-cn .oxford-4 .p2{
      font-size: 15px;
    }
    .oxford-4 a{
        padding: 0 24px;
        margin-left: 2%;
        margin-top: 0;
        height: 48px;
        line-height: 52px;
        margin-bottom: 40px;
    }
}

@media(max-width:430px) {
    .oxford-2 .content{
      margin-top: 18%;
      box-shadow: unset;
      background: none;
      padding: 30px 0;
    }
}

@media(max-width:420px) {
    .oxford-2 .content{
      margin-top: 15%;
    }
}

@media(max-width:410px) {
    .oxford-2 .content{
      margin-top: 10%;
    }
    .oxford-4 .p1{
      font-size: 24px;
    }
}

@media(max-width:395px) {
    .oxford-1 .p1{
        font-size: 52px;
    }
    .oxford-2 .content{
      margin-top: 8%;
    }
    .oxford-box-cn .oxford-1 .p1{
      font-size: 46px;
      margin-top: 70px;
    }
}

@media(max-width:380px) {
    .oxford-1 .p1{
        font-size: 50px;
    }
    .oxford-1 .p2{
        font-size: 16px;
    }
    .oxford-2 .content{
      margin-top: 4%;
    }
}
</style>